import TaskModalWrapper from '../TaskModal/TaskModalWrapper';
import { ListPrependComponentProps } from 'Modules/Core/Types/CTMModule';
import { FC } from 'react';

const ListPrepend: FC<ListPrependComponentProps> = () => {
  return (
    <>
      <TaskModalWrapper />
    </>
  );
};
export default ListPrepend;
