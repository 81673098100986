import AddIcon from '@mui/icons-material/Add';
import { Avatar, AvatarGroup, Badge, CardHeader, IconButton } from '@mui/material';
import useCurrentUser from 'Modules/Employee/Hooks/UseCurrentUser';
import AvatarImage from 'assets/images/users/avatar-1.jpg';
import { Link } from 'react-router-dom';

export default function Status() {
  const currentUser = useCurrentUser();
  return (
    <CardHeader
      avatar={
        <Badge color="success" variant="dot">
          <AvatarGroup>
            <Avatar src={AvatarImage} children="MS" />
          </AvatarGroup>
        </Badge>
      }
      title={`${currentUser?.firstName} ${currentUser?.lastName}`}
      titleTypographyProps={{
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
      action={
        <IconButton component={Link} to="/chat/new">
          <AddIcon />
        </IconButton>
      }
    />
  );
}
