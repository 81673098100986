import CTMModule, { CTMRecord, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { pickValueFromRecordByPropertyPath } from 'helpers/ModuleUtils';

export type Template = CTMStrictRecord &
  CTMRecord & {
    id?: string;
    name?: string;
    system?: boolean;
  };

interface CustomTemplateItemPaths {
  getRecord: ({ id }: { id: string }) => string;
}

const module: CTMModule<Template, CustomTemplateItemPaths> = {
  id: 'TemplateModule',
  dataClass: 'CTM\\CustomField\\Entity\\Template',
  urlPrefix: 'core-template',
  name: 'Moduły',
  role: null,
  api: {
    item: {
      get: ({ id }) => `/custom-fields/templates/${id}`,
      getRecord: ({ id }) => `/custom-fields/templates/${id}/record`,
      put: ({ id }) => `/custom-fields/templates/${id}`,
      delete: ({ id }) => `/custom-fields/templates/${id}`,
    },
    collection: {
      get: `/custom-fields/templates`,
      post: `/custom-fields/templates`,
    },
  },
  recordLabel: (record, allFields) => {
    const { name } = pickValueFromRecordByPropertyPath<Template, any>(record, ['name'], allFields);
    return name ? `${name ?? ''}` : null;
  },
};

export default module;
