import ModuleForm from '../Module/ModuleForm';
import ModalFormWrapper from './ModalFormWrapper';
import { Button, Tooltip } from '@mui/material';
import classnames from 'classnames';
import BaseInput from 'components/Form/BaseInput';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import { useModuleContext } from 'context/ModulesContext';
import { get } from 'helpers/Axios';
import _ from 'lodash';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface RelationSinglePickerProps {
  moduleName?: string;
  value: any;
  name: string;
  label?: string;
  onChange?: (value: any, name: string, selectedOption?: any) => void;
  fetchValue?: (value: any) => Promise<any>;
  optionTrackBy?: string | ((el: any) => any);
  optionLabel?: string | ((el: any) => string);
  disabled?: boolean;
  labelBadge?: string;
  errorKey?: string;
  tabIndex?: number;
  defaultFilters?: any;
  helpText?: string;
  labelerId?: number;
  [key: string]: any;
}

const RelationSinglePicker: React.FC<RelationSinglePickerProps> = props => {
  const {
    moduleName = '',
    value,
    name,
    label,
    onChange = () => undefined,
    fetchValue,
    optionTrackBy = '@id',
    optionLabel = 'name',
    disabled,
    labelBadge,
    errorKey,
    tabIndex,
    defaultFilters,
    helpText,
    labelerId,
    ...restProps
  } = props;

  const { t } = useTranslation();
  const moduleContext = useModuleContext(moduleName);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const showModal = useRef<any>(null);

  const mapToValue = (el: any) => {
    if (typeof el === 'string' || el === null || el === undefined) {
      return el;
    }

    return typeof optionTrackBy === 'function' ? optionTrackBy(el) : el?.[optionTrackBy as string];
  };

  const mapToLabel = (el: any) => {
    if (!el) {
      return null;
    }
    if (typeof el === 'string' || el === null || el === undefined) {
      return el;
    }

    return typeof optionLabel === 'function' ? optionLabel(el) : el?.[optionLabel as string] ?? `Error: ${el}`;
  };

  const fetchRecord = () => {
    if (fetchValue) {
      return fetchValue(value);
    } else {
      return get(value);
    }
  };

  const resolveDefaultOptions = () => {
    if (value) {
      if (typeof value === 'string') {
        fetchRecord()
          .then(record => {
            setSelectedOption(record);
            setInitialized(true);
          })
          .catch(err => {
            if (!initialized) {
              setInitialized(true);
              setSelectedOption(null);
            }
          });
      } else {
        setSelectedOption(value);
        setInitialized(true);
      }
    } else {
      setSelectedOption(null);
      setInitialized(true);
    }
  };

  useEffect(() => {
    resolveDefaultOptions();
  }, []);

  useEffect(() => {
    if (initialized) {
      setSelectedValue(mapToValue(selectedOption));
    }
  }, [selectedOption]);

  useEffect(() => {
    if (!initialized) return;

    if (!_.isEqual(mapToValue(selectedValue), mapToValue(value))) {
      resolveDefaultOptions();
    }
  }, [value]);

  useEffect(() => {
    if (!initialized) return;

    if (!_.isEqual(mapToValue(selectedValue), mapToValue(value))) {
      onChange(selectedValue, name, selectedOption);
    }
  }, [selectedValue]);

  const onChangeSelect = (opt: any) => {
    setSelectedOption(opt);
  };

  const id = () => {
    return (selectedOption?.['@id'] ?? '').split('/').pop();
  };

  if (!moduleContext) {
    return null;
  }

  return (
    <ModuleListPicker
      defaultFilters={defaultFilters}
      disableClear={!value}
      disabled={disabled === true}
      moduleName={moduleContext.configuration.urlPrefix}
      onChange={onChangeSelect}
    >
      <Tooltip title={mapToLabel(selectedOption)} arrow={true} leaveDelay={400} enterDelay={150}>
        <div className={classnames({ 'record-reader': id()?.length > 0 })}>
          <BaseInput
            {...restProps}
            label={label}
            value={mapToLabel(selectedOption)}
            name={name ?? ''}
            type={'text'}
            onChange={() => false}
            inputProps={{
              ...(restProps?.inputProps ?? {}),
              tabIndex: tabIndex,
            }}
            disabled={disabled}
            errorKey={errorKey}
            disableGroupMargin={true}
            helpText={helpText}
            labelerId={labelerId}
          />
          {(id() ?? '').length > 0 && (
            <div className="record-reader-actions ctm-relation-single-picker">
              <ModalFormWrapper
                ref={showModal}
                title={`${moduleContext.configuration.name} - Podgląd`}
                form={
                  <ModuleForm
                    moduleName={moduleContext.configuration.urlPrefix}
                    id={id()}
                    showContextActions={false}
                    showBackButton={false}
                    showConfigurationSwitcher={false}
                    afterSave={() => {
                      showModal.current.close();
                    }}
                    onError={console.log}
                    readonly={true}
                    overrideFormProps={{
                      recordCallback: undefined,
                      trackTabInUrl: false,
                    }}
                  />
                }
                wrapperComponent={Fragment}
              >
                <></>
              </ModalFormWrapper>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  showModal.current.open();

                  return false;
                }}
              >
                <i className="mdi mdi-eye" />
              </Button>
            </div>
          )}
        </div>
      </Tooltip>
    </ModuleListPicker>
  );
};

export default RelationSinglePicker;
