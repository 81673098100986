import { Room } from 'Modules/Chat/Types/ApiModel';
import { Employee } from 'Modules/Employee/Model';

export default function createRoomName(room: Room | undefined, currentUser: Employee | undefined) {
  if (!room) {
    return undefined;
  }

  if (room.name) {
    return room.name;
  }

  if (room.members.length === 1) {
    const member = room.members[0];
    return `${member.user.firstName} ${member.user.lastName}`;
  }

  return room.members
    .filter(member => member.user['@id'] !== currentUser?.['@id'])
    .map(member => `${member.user.firstName} ${member.user.lastName}`)
    .join(', ');
}
