import Checkbox from '@mui/material/Checkbox';
import NumberField from 'components/CustomFields/FieldTypes/NumberField';
import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

const SpecialProfit = props => {
  console.log(props.value);
  const [active, setActive] = useState<boolean>(props.value !== null && props.value !== '');
  const isReadonly = props.readonly || props.field.config.readonly;

  useEffect((): void => {
    props.onChange(active ? props.value ?? 10 : null, props.field.id);
  }, [active]);
  return (
    <>
      <Row>
        <Col xs={9}>
          <NumberField {...props} readonly={!active} />
        </Col>
        <Col xs={3}>
          <Checkbox
            style={{ marginTop: '26px' }}
            name={'specialOfferActive'}
            value={active}
            readOnly={isReadonly}
            checked={active}
            onChange={(e, val) => setActive(val)}
          />
        </Col>
      </Row>
    </>
  );
};

export default SpecialProfit;
