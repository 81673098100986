import Breadcrumbs from 'components/Theme/Common/Breadcrumb';

const Dashboard = () => {
  return (
    <div className="container-fluid">
      <Breadcrumbs breadcrumbItem="Dashboard" />
    </div>
  );
};

export default Dashboard;
