import TextField from '@mui/material/TextField';
import { ChangeEvent, FC, FocusEvent, useState } from 'react';

interface QuantityFieldProps {
  currentValue: number | null;
  onChange: (value: number) => void;
}

const QuantityField: FC<QuantityFieldProps> = ({ currentValue, onChange }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<number | null>(currentValue);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(parseFloat(e.target.value));
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onChange(parseFloat(e.target.value));
    setIsEditing(false);
  };

  return (
    <div onClick={handleDoubleClick}>
      {isEditing ? (
        <TextField type="number" value={value} onChange={handleChange} onBlur={handleBlur} autoFocus={true} />
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
};

export default QuantityField;
