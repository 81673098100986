import { PriorityFilter } from '@Core/Components/PriorityFilter';
import { PriorityView } from '@Core/Components/PriorityView';
import { Priority } from '@Core/Types/Priority';
import CRUDModule from 'Modules/Core/CRUDModule';
import { CTMModule, CTMRecord, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';
import RecordInputReader from 'components/Module/RecordInputReader';
import { getProperty } from 'dot-prop';

export function pickValueFromRecordByPropertyPathWithField<T = any>(record, propertyPath: string, allFields: any[]): null | T {
  if (record.hasOwnProperty(propertyPath) && record[propertyPath]) {
    return record[propertyPath];
  }
  const fieldId = allFields.find(field => field.propertyPath === propertyPath)?.id;

  if (!fieldId) {
    return null;
  }

  return record?.['@formValues']?.[fieldId] ?? null;
}

export function pickValueFromRecordByPropertyPath<T extends CTMRecord = CTMRecord>(
  record: T,
  propertyPath: Extract<keyof T, string>,
  allFields: undefined | any[],
): null | string;

export function pickValueFromRecordByPropertyPath<T extends CTMRecord = CTMRecord, S = { [P in Extract<keyof T, string>]: T[P] }>(
  record: T,
  propertyPath: Extract<keyof S, string>[],
  allFields: undefined | any[],
): S;
export function pickValueFromRecordByPropertyPath<T extends CTMRecord = CTMRecord>(
  record: T,
  propertyPath: Extract<keyof T, string>[] | Extract<keyof T, string>,
  allFields: undefined | any[],
) {
  if (typeof propertyPath === 'string') {
    if (record.hasOwnProperty(propertyPath) && record[propertyPath]) {
      return record[propertyPath];
    }

    const resolvedAllFields: any[] = allFields ?? Object.values(record?.['@form'] ?? {});
    return pickValueFromRecordByPropertyPathWithField(record, propertyPath, resolvedAllFields);
  }

  const resolvedAllFields: any[] = allFields ?? Object.values(record?.['@form'] ?? {});

  return propertyPath.reduce((prev: object, curr: string) => {
    prev[curr] = pickValueFromRecordByPropertyPathWithField(record, curr, resolvedAllFields);
    return prev;
  }, {});
}

export function extractIRIFromRecord<T = any>(record: T): string | T {
  return record?.['@id'] ?? record;
}

export function createModuleColumnConfig<T extends CTMStrictRecord = object>(recordPropertyPath: string, module: CTMModule<T>): any {
  return {
    accessor: row => {
      return (
        <>
          <RecordInputReader
            useInput={false}
            label={false}
            value={getProperty(row, recordPropertyPath)}
            readonly={true}
            module={new CRUDModule<T>(module)}
          />
        </>
      );
    },
    Filter: SelectModuleRecordColumnFilter,
    filterModule: module,
  };
}

export function createPriorityColumn<T extends CTMStrictRecord = object>(
  getPriority: (record: T) => null | Priority,
  getIRI: (record: T) => null | string,
): any {
  return {
    id: 'priority',
    filterable: true,
    sortable: true,
    Header: 'Prio',
    noBreak: true,
    accessor: row => {
      const iri = getIRI(row);
      const priority = getPriority(row) ?? Priority.MEDIUM;
      return (
        <div style={{ textAlign: 'center' }} key={`${priority}${iri}`}>
          {iri && <PriorityView priority={priority} resourceIRI={iri} />}
          {!iri && <PriorityView priority={getPriority(row) ?? Priority.MEDIUM} />}
        </div>
      );
    },
    width: 30,
    Filter: PriorityFilter,
  };
}

export function setValuesToFormRecord<T = any>(record: any, allFields: any[], valuesToSet: object): T {
  if (!record.hasOwnProperty('@formValues')) {
    return record;
  }

  const fieldsToSet = Object.keys(valuesToSet);
  fieldsToSet.forEach(key => {
    if (key === '@formValues') {
      return;
    }

    const field = allFields.find(field => field.propertyPath === key);
    if (!field) {
      return;
    }

    record['@formValues'][field.id] = valuesToSet[key];
    record[key] = valuesToSet[key];
  });

  return record;
}
