import * as url from '../../helpers/Api/Url';
import LabelerId from '../CustomFields/LabelerId';
import { Media } from '@Core/Types/ApiModel';
import { Delete, Download, Preview } from '@mui/icons-material';
import PhotoLibrarySelect from 'Modules/Core/Components/PhotoLibrary/PhotoLibrarySelect';
import ResizeImageBox from 'Modules/Core/Components/ResizeImageBox';
import { useFileUpload } from 'Modules/Core/Hooks/useFileUpload';
import { axiosApi, get } from 'helpers/Axios';
import { downloadBlobFile } from 'helpers/File';
import { FC, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup, Col, FormFeedback, FormGroup, FormText, Label, Row } from 'reactstrap';
import { useAppSelector } from 'store';
import { setImages, toggle } from 'store/Lightbox/Lightbox';

type ActionsProps = {
  preview?: string | null;
  id?: string;
  iri?: string;
  originalName: string;
  onRemove?: (iri: string) => void;
  style?: any;
};

export const Actions: FC<ActionsProps> = ({ preview, id, iri, originalName, onRemove, style = {} }) => {
  const dispatch = useDispatch();

  const download = () => {
    axiosApi
      .get(url.DOWNLOAD_MEDIA(id), {
        responseType: 'blob',
        headers: {
          Accept: 'image/*',
        },
      })
      .then(response => {
        downloadBlobFile(response.data, originalName);
      });
  };

  const initPreview = () => {
    if (preview) {
      dispatch(setImages({ images: [preview] }));
    }
    dispatch(toggle());
  };

  return (
    <ButtonGroup style={{ position: 'absolute', left: '2%', bottom: '-24px', zIndex: 9999, ...style }}>
      <Button size={'sm'} color={'success'} onClick={initPreview}>
        <Preview /> Podgląd
      </Button>
      {id && (
        <>
          <Button size={'sm'} color={'primary'} onClick={download}>
            <Download />
            Pobierz
          </Button>
          <ResizeImageBox fileId={id} />
          {iri && onRemove && (
            <Button size={'sm'} color={'danger'} onClick={() => onRemove(iri)}>
              <Delete />
              Usuń
            </Button>
          )}
        </>
      )}
    </ButtonGroup>
  );
};

type ImageUploadProps = {
  className?: string;
  value: any;
  name?: string;
  helpText?: string;
  label?: string;
  returnObject?: boolean;
  onChange: (value: any, name: string) => void;
  disabled?: boolean;
  labelBadge?: string;
  errorKey?: string;
  autoHeight?: boolean;
  fullHeight?: boolean;
  fullInputHeight?: boolean;
  global?: boolean;
  showPreview?: boolean;
  moduleValues?: { [key: string]: any };
  librarySelect?: boolean;
  labelerId?: number;
};

const ImageUpload: FC<ImageUploadProps> = ({
  className = 'mb-3',
  value,
  name = '',
  helpText = undefined,
  label = '',
  returnObject = false,
  onChange,
  disabled = undefined,
  errorKey = undefined,
  autoHeight = false,
  fullHeight = undefined,
  fullInputHeight = undefined,
  showPreview = true,
  moduleValues = {},
  librarySelect = true,
  labelerId = undefined,
}) => {
  const { t } = useTranslation();
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));

  const moduleImages = Object.values(moduleValues).filter(file => (file?.['mimeType'] ?? '').indexOf('image') > -1) as Media[];
  const fileUpload = useFileUpload();
  const [preview, setPreview] = useState<string | null>(null);
  const hasError = () => {
    return violations.hasOwnProperty(errorKey ?? name);
  };
  const getError = () => {
    if (hasError()) {
      return violations[errorKey ?? name].join('\n');
    }
    return '';
  };

  const handleAcceptedFiles = files => {
    const file = files[0];
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    if (showPreview) {
      setPreview(file.preview);
    }

    fileUpload(file, res => {
      if (showPreview) {
        setPreview(res.contentUrl);
      }
      onChange(returnObject ? res : res['@id'], name);
    });
  };

  const resolveBackground = () => {
    if (!preview) {
      return;
    }

    return {
      backgroundImage: `url("${preview}")`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      ...(autoHeight
        ? {
            backgroundPosition: 'center',
          }
        : {}),
    };
  };

  const resolveClassNameHelpers = () => {
    if (!preview) {
      return `dropzone dropzone-image ${autoHeight ? 'auto-height' : ''}`;
    }

    return `dropzone dropzone-image dropzone-filled ${autoHeight ? 'auto-height' : ''}`;
  };

  useEffect(() => {
    if (value && typeof value === 'string') {
      get(value).then(res => {
        setPreview(res.contentUrl);
      });
    } else if (value && typeof value === 'object') {
      setPreview(value.contentUrl);
    }
  }, []);

  const onRemove = () => {
    onChange(null, name);
    setPreview(null);
  };

  const fromLibrarySelect = file => {
    if (showPreview) {
      setPreview(file.contentUrl);
    }
    onChange(returnObject ? file : file['@id'], name);
  };

  return (
    <Col xs={12} className={className} style={fullHeight ? { height: '100%' } : {}}>
      <Row style={fullHeight ? { height: '100%' } : {}}>
        <FormGroup style={fullInputHeight ? { height: 'calc(100% - 34px)' } : {}}>
          <Label>
            {/*{labelBadge && (*/}
            {/*  <span*/}
            {/*    className="badge bg-warning"*/}
            {/*    style={{*/}
            {/*      marginRight: 6,*/}
            {/*      padding: 6,*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {labelBadge}*/}
            {/*  </span>*/}
            {/*)}*/}
            {typeof labelerId === 'number' && <LabelerId labelerId={labelerId} />}
            {t(label)}
          </Label>
          {disabled && preview && (
            <>
              <img className={'img-fluid img-form'} src={preview} alt={preview} />
              {value?.id && <Actions preview={preview} id={value.id} iri={value['@id']} originalName={value.originalName} />}
            </>
          )}
          {disabled && !preview && <div className={'dropzone'}></div>}
          {!disabled && (
            <>
              <Dropzone accept={'image/*'} onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <div className={resolveClassNameHelpers()} style={resolveBackground()}>
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="dropzone-text">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
              {!value?.id && librarySelect && (
                <PhotoLibrarySelect
                  moduleImages={moduleImages}
                  onSelect={fromLibrarySelect}
                  buttonStyle={{ position: 'absolute', bottom: '-24px', left: '2%' }}
                />
              )}
              {value?.id && (
                <Actions onRemove={onRemove} preview={preview} iri={value['@id']} id={value.id} originalName={value.originalName} />
              )}
            </>
          )}
          {helpText && <FormText>{helpText}</FormText>}
          {hasError() && <FormFeedback>{getError()}</FormFeedback>}
        </FormGroup>
      </Row>
    </Col>
  );
};

export default ImageUpload;
