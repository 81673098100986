// import images
import logo from 'assets/images/logo-light.png';
import BaseInput from 'components/Form/BaseInput';
import { useFormik } from 'formik';
import { get } from 'helpers/Axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
//redux
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Col, Container, Form, FormFeedback, Input, Row } from 'reactstrap';
import { useAppSelector } from 'store';
// actions
import { loginUser, loginUserByPin } from 'store/actions';
// Formik validation
import * as Yup from 'yup';

const Login = props => {
  document.title = 'Logowanie | CTM';

  const dispatch = useDispatch();
  const [pin, setPin] = useState('');
  const [loginMethods, setLoginMethods] = useState({ pin: false });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Podaj adres email'),
      password: Yup.string().required('Podaj hasło'),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.history));
    },
  });

  const { error, loginType } = useAppSelector(state => ({
    error: state.Login.error,
    loginType: state.Login.loginType,
  }));

  const loginByPin = () => dispatch(loginUserByPin(pin, props.history));

  useEffect(() => {
    if (pin && (pin + '').length === 6) {
      loginByPin();
    }
  }, [pin]);

  useEffect(() => {
    if (error !== '') {
      setPin('');
    }
  }, [error]);

  useEffect(() => {
    get('/validate-authorization-methods')
      .then(data => setLoginMethods(data))
      .catch(console.error);
  }, []);

  return (
    <Container fluid className="p-0">
      <Row className="p-0 m-0">
        <Col xl={9} className="p-0">
          <div className="auth-full-bg pt-lg-5 p-4">
            <div className="w-100">
              <div className="bg-overlay"></div>
            </div>
          </div>
        </Col>
        <Col xl={3}>
          <div className="auth-full-page-content p-md-5 p-4">
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                <div className="mb-4 mb-md-5">
                  <a href="/" className="d-block auth-logo text-center">
                    <img src={logo} />
                  </a>
                </div>
                <div className="my-auto">
                  <div>
                    <h5 className="text-primary">Witaj ponownie!</h5>
                    <p className="text-muted">Zaloguj się aby kontynuować.</p>
                  </div>

                  <div className="mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {loginType === 'FORM' && error && <Alert color="danger">{error}</Alert>}

                      <div className="mb-3">
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Podaj adres email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={validation.touched.email && validation.errors.email ? true : false}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Input
                          name="password"
                          value={validation.values.password || ''}
                          type="password"
                          placeholder="Podaj hasło"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.password && validation.errors.password ? true : false}
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary btn-block" type="submit">
                          Zaloguj się
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Zapomniałeś hasła?
                        </Link>
                      </div>
                    </Form>
                  </div>

                  {loginMethods.pin && (
                    <>
                      <div className="mt-2">
                        <h5 className="text-primary">Zaloguj za pomocą kodu PIN</h5>
                      </div>

                      {loginType === 'PIN' && error && <Alert color="danger">{error}</Alert>}
                      <div className="mt-1">
                        <BaseInput
                          size={{ md: 12 }}
                          className={''}
                          value={pin}
                          type={'password'}
                          name="pin"
                          onChange={value => setPin(value)}
                          inputProps={{
                            placeholder: 'Podaj PIN',
                            autoFocus: true,
                            maxLength: 6,
                            onKeyDown: e => {
                              if ((pin + '').length >= 6 && e.key !== 'Backspace') {
                                e.preventDefault();
                              }
                            },
                          }}
                        />
                      </div>
                      <div className="text-center">
                        <Link to="/forgot-pin" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Zapomniałeś pinu?
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
