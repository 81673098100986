import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type DictionaryValue = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<DictionaryValue>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    id: 'description',
    filterable: true,
    sortable: true,
    Header: 'Opis',
    accessor: 'description',
  },
  {
    id: 'symbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol',
    accessor: row => (
      <span style={{ color: row.color ? row.color : '#303030' }}>
        {row.icon && <i className={`mdi mdi-${row.icon}`} />}
        {row.symbol && <span>&nbsp;{row.symbol}</span>}
      </span>
    ),
  },
];

const module: CTMModule<DictionaryValue> = {
  id: 'DictionaryValueModule',
  dataClass: 'CTM\\Core\\Entity\\DictionaryValue',
  urlPrefix: 'core-dictionary-values',
  name: 'Wartości słownika',
  role: 'CORE_DICTIONARY',
  api: {
    item: {
      get: ({ id }) => `/core/dictionary-values/${id}`,
      put: ({ id }) => `/core/dictionary-values/${id}`,
      delete: ({ id }) => `/core/dictionary-values/${id}`,
    },
    collection: {
      get: `/core/dictionary-values`,
      post: `/core/dictionary-values`,
    },
  },
  recordLabel: ({ name }) => name,
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
