import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import translationPL from 'locales/pl/translation.json';
import { initReactI18next } from 'react-i18next';

// the translations
const resources = {
  pl: {
    translation: translationPL,
  },
};

const language = localStorage.getItem('I18N_LANGUAGE');
if (!language) {
  localStorage.setItem('I18N_LANGUAGE', 'pl');
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('I18N_LANGUAGE') || 'pl',
    fallbackLng: 'pl', // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
