import useIsGranted from 'common/hooks/isGranted';
import BaseInput from 'components/Form/BaseInput';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

const Auth = ({ form, updateConfiguration }) => {
  const canEdit = useIsGranted('ROLE_EDIT_CONFIGURATION');

  const updateFormValue = (value, field) => {
    updateConfiguration({ ...form, [field]: value });
  };
  const updateIpsFormValue = (value, field) => {
    updateFormValue((value || '').split(','), field);
  };

  return (
    <Row>
      <Col md={6} lg={9}>
        <Row>
          <BaseInput
            size={{ md: 6 }}
            label={'Adresy ip dozwolone do logowania pinem'}
            value={form.loginByPinIps.join(',')}
            name="loginByPinIps"
            onChange={updateIpsFormValue}
            disabled={!canEdit}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default withRouter(Auth);
