import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type ProductVersion = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<ProductVersion>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
];

const module: CTMModule<ProductVersion> = {
  id: 'e7b4d7ff-c0e1-4c61-9b8a-4100d19ac44c',
  dataClass: 'CTM\\Manufacture\\Entity\\ProductVersion',
  urlPrefix: 'manufacture-product-versions',
  name: 'Wersje produktów',
  role: 'MANUFACTURE_PRODUCT',
  api: {
    item: {
      get: ({ id }) => `/manufacture/product-versions/${id}`,
      put: ({ id }) => `/manufacture/product-versions/${id}`,
      delete: ({ id }) => `/manufacture/product-versions/${id}`,
    },
    collection: {
      get: `/manufacture/product-versions`,
      post: `/manufacture/product-versions`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {},
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
