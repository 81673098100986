import {
  FETCH_APP_CONFIGURATION,
  FETCH_APP_CONFIGURATION_FAILED,
  FETCH_APP_CONFIGURATION_SUCCESS,
} from 'store/AppConfiguration/Configuration/ActionTypes';

export const fetchAppConfiguration = () => {
  return {
    type: FETCH_APP_CONFIGURATION,
    payload: {},
  };
};

export const fetchAppConfigurationSuccess = configuration => {
  return {
    type: FETCH_APP_CONFIGURATION_SUCCESS,
    payload: configuration,
  };
};

export const fetchAppConfigurationFailed = configuration => {
  return {
    type: FETCH_APP_CONFIGURATION_FAILED,
    payload: configuration,
  };
};
