import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type RootAppState = {
  language: string;
};

const initialState = (): RootAppState => ({
  language: 'pl',
});

export const Root = createSlice({
  initialState: initialState(),
  name: 'root',
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

export default Root.reducer;

export const { setLanguage: rootSetLanguage } = Root.actions;
