import { NAME_COLUMNS, getColumnsConfiguration } from 'common/hooks/Grid/Configuration';
import Switch from 'components/Form/Switch';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Card, CardBody, CardHeader } from 'reactstrap';

const Column = ({ columns = [], url = '', updateConfiguration }) => {
  const [configuration, setConfiguration] = useState(getColumnsConfiguration(columns, url));

  const changeVisible = column => {
    if (configuration.indexOf(column) > -1) {
      setConfiguration(configuration.filter(item => item !== column));

      return;
    }

    setConfiguration(configuration.concat(column));
  };

  useEffect(() => {
    updateConfiguration(NAME_COLUMNS, configuration);
  }, [configuration]);

  const getLabel = header => {
    return typeof header === 'function' ? ReactDOMServer.renderToStaticMarkup(header()).replace(/(<([^>]+)>)/gi, '') : header;
  };
  return (
    <Card className="w-100">
      <CardHeader className="bg-gray">Widoczność kolumn</CardHeader>
      <CardBody>
        {columns
          .filter(el => !el.id.toLowerCase().includes('action'))
          .map(column => (
            <div key={column.id}>
              <Switch
                size={{ md: 12 }}
                label={getLabel(column.Header)}
                value={configuration.indexOf(column.id) > -1}
                name={'column-' + column.id}
                onChange={() => {
                  changeVisible(column.id);
                }}
              />
            </div>
          ))}
      </CardBody>
    </Card>
  );
};

Column.propTypes = {
  columns: PropTypes.array,
  url: PropTypes.string,
  updateConfiguration: PropTypes.func,
};

export default Column;
