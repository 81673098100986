import { Portal } from '@mui/material';
import FieldCondition from 'pages/Configuration/CustomFields/FieldCondition';
import { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

const FieldConditionModal = ({ children, wrapperProps, ...rest }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <span
        {...wrapperProps}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
      >
        {children}
      </span>
      <Portal>
        <Modal isOpen={open} centered={true} size={'lg'} backdrop={true} toggle={() => setOpen(!open)}>
          <div className="modal-header">
            <h5 className="modal-title position-relative" id="staticBackdropLabel">
              Warunki ukrywania
            </h5>
            <button type="button" className="btn btn-sm btn-success" onClick={() => setOpen(false)} aria-label="Close">
              Zapisz i zamknij
            </button>
          </div>
          <ModalBody className="py-3 px-5">
            <FieldCondition {...rest} />
          </ModalBody>
        </Modal>
      </Portal>
    </>
  );
};

export default FieldConditionModal;
