import useErrorHandler from '../../../helpers/FormHandler';
import { TemplateModule } from '../../CTMModules';
import { Field } from '../Field';
import TabConfigurationModal from '../ModalComponents/TabConfigurationModal';
import { Template } from '../Template';
import ModuleTemplateTabs from './ModuleTemplateTabs';
import { AddCircle, ArrowCircleLeft, ArrowCircleRight, HighlightOff, SettingsApplications, VisibilityOff } from '@mui/icons-material';
import { Button, Tab, Tabs } from '@mui/material';
import { confirmRemove } from 'common/sweetalerts';
import FieldConditionModal from 'pages/Configuration/CustomFields/FieldConditionModal';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

interface ModuleTemplatesTemplateProps {
  templateId?: null | string;
  fields: Field[];
  onFieldSaved: any;
  moduleId: any;
}

function ModuleTemplatesTemplate(props: ModuleTemplatesTemplateProps, ref) {
  const { templateId, fields, onFieldSaved, moduleId } = props;
  const [template, setTemplate] = useState<null | Template>(null);
  const [initialTemplate, setInitialTemplate] = useState<null | Template>(null);
  const [selectedTab, setSelectedTab] = useState<null | string>(null);
  const errorHandler = useErrorHandler();
  const dispatch = useDispatch();

  useEffect(() => {
    if (templateId) {
      TemplateModule.api.get({ id: templateId }).then(res => {
        setTemplate(res);
        setInitialTemplate(JSON.parse(JSON.stringify(res)));
        setSelectedTab(res.tabs?.[0]?.id ?? null);
      });
    } else {
      setTemplate(null);
      setInitialTemplate(null);
    }
  }, [templateId]);

  useImperativeHandle(ref, () => ({
    save() {
      return new Promise((res, rej) => {
        if (template && template.hasOwnProperty('@id')) {
          TemplateModule.api
            .put(
              {
                ...template,
                defaultValues: undefined,
                image: template.image?.['@id'] ?? undefined,
                tabs: template.tabs.map(el => ({
                  ...el,
                  hideConditions:
                    el.hideConditions?.map(hideCondition => ({
                      ...hideCondition,
                      comparedField: hideCondition.comparedField?.['@id'] ?? hideCondition.comparedField,
                    })) ?? [],
                  sections:
                    el.sections?.map(section => ({
                      ...section,
                      hideConditions:
                        section.hideConditions?.map(hideCondition => ({
                          ...hideCondition,
                          comparedField: hideCondition.comparedField?.['@id'] ?? hideCondition.comparedField,
                        })) ?? [],
                    })) ?? [],
                })),
              },
              { id: template.id },
            )
            .then(res)
            .catch((...errors) => {
              errorHandler(...errors);
              rej(...errors);
            });
        }
      });
    },
    hasUnsavedChanges() {
      return JSON.stringify(template) !== JSON.stringify(initialTemplate);
    },
    reload() {
      TemplateModule.api.get({ id: templateId }).then(res => {
        setTemplate(res);
        setInitialTemplate(JSON.parse(JSON.stringify(res)));
      });
    },
    resetInit() {
      setInitialTemplate(JSON.parse(JSON.stringify(template)));
    },
    getTemplate() {
      return JSON.parse(JSON.stringify(template));
    },
  }));
  const updateTemplate = useCallback(cb => setTemplate(cb), [setTemplate]);

  const handleTabNameChange = ({ target: { value } }, id) => {
    updateTemplate(prev => ({
      ...prev,
      tabs: prev.tabs.map(el => {
        if (el.id === id) {
          el = { ...el, name: value };
        }
        return el;
      }),
    }));
  };
  const handleHideCondition = (hideConditions, id) => {
    updateTemplate(prev => ({
      ...prev,
      tabs: prev.tabs.map(el => {
        if (el.id === id) {
          el = { ...el, hideConditions: hideConditions };
        }
        return el;
      }),
    }));
  };

  const handleConfigurationChange = (config, id) => {
    updateTemplate(prev => ({
      ...prev,
      tabs: prev.tabs.map(el => {
        if (el.id === id) {
          el = { ...el, config: config };
        }
        console.log(el);
        return el;
      }),
    }));
  };

  if (!template) {
    return <>Wybierz szablon aby rozpocząć konfiguracje</>;
  }

  const moveTab = (fromIndex, toIndex) => {
    const modifiedData = [...template.tabs];

    const item = modifiedData.splice(fromIndex, 1)[0];
    modifiedData.splice(toIndex, 0, item);

    let position = 1;

    setTemplate(prevTemplate => ({
      ...prevTemplate,
      tabs: modifiedData.map(el => ({ ...el, position: position++ })),
    }));
  };

  const addNewTab = () => {
    const key = uuidv4();

    updateTemplate(prev => ({
      ...prev,
      tabs: [
        ...prev.tabs,
        {
          id: key,
          position: prev.tabs.length + 1,
          name: '',
          sections: [
            {
              id: uuidv4(),
              position: 1,
              useGridLayout: true,
              name: '',
              layout: [],
            },
          ],
        },
      ],
    }));

    return key;
  };
  const removeTab = id => {
    confirmRemove(
      () => {
        return new Promise<boolean>(res => {
          let lastIdBeforeRemove = null;
          let tabToRemoveFound = false;
          updateTemplate(prev => ({
            ...prev,
            tabs: prev.tabs
              .filter(el => {
                const remove = el.id === id;
                if (remove) {
                  tabToRemoveFound = true;
                }
                if (!remove && (!tabToRemoveFound || !lastIdBeforeRemove)) {
                  lastIdBeforeRemove = el.id;
                }
                return !remove;
              })
              .map((newTab, index) => ({ ...newTab, position: index + 1 })),
          }));
          setSelectedTab(lastIdBeforeRemove);
          res(true);
        });
      },
      'Czy jesteś pewien skasowania tej zakładki?',
      'Jeśli po skasowaniu się rozmyślisz możesz użyć przycisku zamknij spowoduje to utratę wszystkich niezapisanych zmian w szablonie.',
      false,
    );
  };
  const handleChangeTab = (ev, val) => {
    if (val === '__NEW_TAB__') {
      val = addNewTab();
    }
    setSelectedTab(val);
  };
  return (
    <>
      <Tabs value={selectedTab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto" className="template-tabs">
        {template.tabs?.map(el => (
          <Tab
            key={el.id}
            value={el.id}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {selectedTab === el.id && el.position > 1 && (
                  <div>
                    <ArrowCircleLeft
                      onClick={e => {
                        e.stopPropagation();
                        moveTab(el.position - 1, el.position - 2);
                      }}
                    />
                  </div>
                )}
                <div>
                  <input
                    style={{ width: '100%' }}
                    type="text"
                    value={el.name}
                    placeholder="Nazwa zakładki"
                    className="transparent-input"
                    onChange={ev => handleTabNameChange(ev, el.id)}
                  />
                </div>
                {selectedTab === el.id && (
                  <div style={{ padding: '0 8px 0 2px' }}>
                    <TabConfigurationModal
                      wrapperProps={{ style: { marginRight: '6px' } }}
                      value={el.config ?? {}}
                      onChange={config => handleConfigurationChange(config, el.id)}
                    >
                      <Button color="primary" size="small" variant="contained" style={{ color: '#fff', padding: 0, minWidth: 0 }}>
                        <SettingsApplications />
                      </Button>
                    </TabConfigurationModal>
                    <FieldConditionModal
                      wrapperProps={{}}
                      value={el.hideConditions ?? []}
                      fields={fields}
                      onChange={newConditions => handleHideCondition(newConditions, el.id)}
                    >
                      <Button color="primary" size="small" variant="contained" style={{ color: '#fff', padding: 0, minWidth: 0 }}>
                        <VisibilityOff />
                      </Button>
                    </FieldConditionModal>
                    <Button
                      color="error"
                      size="small"
                      variant="contained"
                      onClick={() => removeTab(el.id)}
                      style={{ color: '#fff', padding: 0, minWidth: 0, marginLeft: 6 }}
                    >
                      <HighlightOff />
                    </Button>
                  </div>
                )}
                {selectedTab === el.id && el.position < template.tabs.length && (
                  <div>
                    <ArrowCircleRight
                      onClick={e => {
                        e.stopPropagation();
                        moveTab(el.position - 1, el.position);
                      }}
                    />
                  </div>
                )}
              </div>
            }
          />
        ))}
        <Tab
          value={'__NEW_TAB__'}
          label={
            <div style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}>
              <div>
                <AddCircle />
              </div>
              <div style={{ paddingLeft: 4 }}>Nowa zakładka</div>
            </div>
          }
        />
      </Tabs>
      <ModuleTemplateTabs
        tabs={template.tabs}
        fields={fields}
        selectedTab={selectedTab}
        updateTemplate={updateTemplate}
        onFieldSaved={onFieldSaved}
        moduleId={moduleId}
      />
    </>
  );
}

export default forwardRef(ModuleTemplatesTemplate);
