import { ProductGroupModule } from 'Modules/CTMModules';
import DataGrid from 'components/DataGrid';
import SecuredView from 'components/Theme/Common/SecuredView';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'reactstrap';

const ChildGrid = props => {
  const columns = useMemo(
    () => [
      {
        id: 'name',
        filterable: true,
        sortable: true,
        Header: 'Nazwa',
        accessor: row => (
          <div>
            {row.childrenCount > 0 && <i style={{ color: `#484f56` }} className={'mdi font-size-16 mdi-folder'} />}
            {row.name}
          </div>
        ),
      },
      {
        id: 'actionsStickyRight',
        Header: 'Akcje',
        disableSortBy: true,
        disableFilters: true,
        maxWidth: '140px',
        accessor: row => (
          <ButtonGroup>
            <SecuredView role="ROLE_EDIT_MANUFACTURE_PRODUCT">
              <Link to={`/manufacture/products/${row.id}/edit`} className="btn btn-primary  btn-sm">
                <i className="mdi mdi-cogs" />
              </Link>
            </SecuredView>
            <Link to={`/manufacture/products/${row.id}`} className="btn btn-success  btn-sm">
              <i className="mdi mdi-eye" />
            </Link>
          </ButtonGroup>
        ),
        ...(props.modalListActions?.[0] ?? {}),
      },
    ],
    [],
  );

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <ChildGrid parent={row.id} modalListActions={props.modalListActions} />
      // <ProductTechnologies {...props} row={row}/>
    ),
    [],
  );

  return (
    <div style={{ padding: '0 0 0 30px', width: '100%' }}>
      <div className="list-tree w-100">
        <DataGrid
          url={`${ProductGroupModule.api.getAllUrl}?parent.id=${props.parent}`}
          className={'table-clickable'}
          columns={columns}
          defaultOrderBy={ProductGroupModule.configuration.list.defaultOrderBy}
          renderRowSubComponent={(...params) =>
            ProductGroupModule.configuration.list.renderRowSubComponent(...params, props.modalListActions)
          }
          defaultFilters={[]}
          onRowClick={ProductGroupModule.configuration.list.onRowClick}
          canRenderSubRow={ProductGroupModule.configuration.list.canRenderSubRow}
          defaultPerPage={50}
          disableTop={true}
          disableHeader={true}
          disableFooter={true}
          fullWidth={true}
          expandAll={true}
          responsive={false}
          allowChangeLimit={false}
          minRows={0}
          history={props.history}
        />
      </div>
    </div>
  );
};

export default ChildGrid;
