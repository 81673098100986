import { baseBuilder } from '../ModuleBuilder';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import IconPicker from 'components/CustomFields/FieldTypes/IconPicker';

export type ProductType = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<ProductType>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: row => (
      <>
        <span className="badge text-white font-size-12" style={{ padding: 6, background: `${row.color || '#edf0f5'}` }}>
          <i className={`mdi mdi-${row?.icon}`} />
        </span>
        &nbsp;{row.name}
      </>
    ),
  },
];
const module: CTMModule<ProductType> = baseBuilder<ProductType>({
  id: '4a549575-1219-4a04-b33d-0f78b8549386',
  dataClass: 'CTM\\Manufacture\\Entity\\ProductType',
  urlPrefix: 'manufacture-product-types',
  name: 'Typy produktów',
  role: 'MANUFACTURE_PRODUCT',
  baseUrl: '/manufacture/product-types',
  columns: columns,
  form: {
    fieldComponents: {
      icon: IconPicker,
    },
  },
});

export default module;
