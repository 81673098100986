import { Media as MediaItem } from 'Modules/Core/Types/ApiModel';
import { AxiosError } from 'axios';
import { GET_MEDIA } from 'helpers/Api/Url';
import { ApiCollection, ApiItem, get } from 'helpers/Axios';
import { useQuery } from 'react-query';

interface Media extends ApiItem, MediaItem {}

type MediaCollection = ApiCollection<Media>;

export default function usePhotoLibrary() {
  const query = useQuery<MediaCollection, AxiosError, Media[]>({
    cacheTime: 0,
    queryKey: 'PhotoLibrary',
    queryFn: () => get(GET_MEDIA + '?global=true&order[uploadedAt]=ASC&limit=9999'),
    select: data => data['hydra:member'],
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query.data || [],
  };
}
