import EnterQuantityInputModal from './EnterQuantityInputModal';
import { InputAdornment, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

interface EnterQuantityInputProps {
  productLabeler: string | null;
  productEAN: string | null;
  productSymbol: string | null;
  productName: string | null;
  onInputQuantity: (quantity: number, code: string | null, place: any | null) => void;
  locations: any[];
}

const EnterQuantityInput = (props: EnterQuantityInputProps) => {
  const { productLabeler, productSymbol, productEAN, productName, onInputQuantity, locations } = props;
  const [value, setValue] = useState<string>('');
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  useEffect(() => {
    if (value && (productEAN ?? '').length > 0 && value === productEAN) {
      setModalOpened(true);
    }

    if (value && `${productLabeler}`.length > 0 && value === `${productLabeler}`) {
      setModalOpened(true);
    }

    if (value && (productSymbol ?? '').length > 0 && value === productSymbol) {
      setModalOpened(true);
    }
  }, [value, productEAN, productLabeler, productSymbol]);

  return (
    <div>
      {modalOpened && (
        <EnterQuantityInputModal
          onClose={() => {
            setModalOpened(false);
            setValue('');
          }}
          productName={`[${productLabeler}]${productName}`}
          productEan={productEAN ?? ''}
          onSaveHandler={(quantity, place) => {
            onInputQuantity(quantity, value, place);
            setValue('');
            setModalOpened(false);
          }}
          locations={locations}
        />
      )}
      <Tooltip
        title={
          'Wprowadź EAN/Symbol/Kod z metkownicy aby zaktualizować skompletowaną ilość. W przypadku braku powyższych danych użyj przycisku "Brak"'
        }
      >
        <TextField
          label={'Wprowadź EAN/Symbol/Metkownicę'}
          disabled={false}
          value={value ?? ''}
          onChange={({ target: { value: val } }) => setValue(val ?? '')}
          variant="outlined"
          InputLabelProps={{ shrink: !!value }}
          InputProps={{
            readOnly: false,
            endAdornment: (
              <InputAdornment position="end" style={{ marginRight: -14, zIndex: 2 }}>
                <ButtonGroup>
                  <Button color={'warning'} variant={'contained'} onClick={() => setModalOpened(true)}>
                    Brak
                  </Button>
                </ButtonGroup>
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    </div>
  );
};

export default EnterQuantityInput;
