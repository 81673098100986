import Box from './Box';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Col, Container } from 'components/Theme/Grid';
import { memo } from 'react';

const Boxes: CustomFieldComponentType = ({ value }) => {
  return (
    <>
      <Container spacing={2}>
        {value.map((box, index) => (
          <Col key={index} md={6} xs={12}>
            <Box {...box} />
          </Col>
        ))}
      </Container>
    </>
  );
};

const BoxesMemo = memo(Boxes, (prev, next) => {
  return JSON.stringify({ value: prev.value }) === JSON.stringify({ value: next.value });
});

export default BoxesMemo;
