import { Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import { Product, ProductIngredient } from 'Modules/Manufacture/Types/Product';
import NumberInput from 'components/Form/MUI/NumberInput';
import PlaceholderInput from 'components/Form/MUI/PlaceholderInput';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

const DefaultRow: ProductIngredient = {
  product: null,
  quantity: 1,
  used: 0,
};

const TaskTabIngredientsPosition: FC<{ data: any; onChange: any; onRemove: any }> = ({ data, onChange, onRemove }) => {
  const [rowState, setRowState] = useState<ProductIngredient>(data);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(rowState)) {
      onChange && onChange(rowState);
    }
  }, [rowState]);

  return (
    <>
      <TableRow>
        <TableCell style={{ width: '30%' }} colSpan={rowState.product ? 1 : 3}>
          <ModuleListPicker<Product>
            moduleName={'manufacture-products'}
            onChange={gridRow => setRowState(prev => ({ ...prev, product: gridRow }))}
            disableClear={true}
            moduleFormComponentProps={undefined}
            moduleFormComponent={undefined}
            moduleFormWrapperProps={undefined}
            allowMultipleSelect={false}
            onMultipleSelect={console.error}
            defaultFilters={undefined}
            overrideUrl={undefined}
          >
            <PlaceholderInput
              label={!rowState.product ? 'Wybierz produkt aby dodać' : 'Produkt'}
              value={rowState.product?.relationFieldLabel}
            />
          </ModuleListPicker>
        </TableCell>
        {rowState.product && (
          <>
            <TableCell>
              <NumberInput
                value={rowState.quantity}
                label={'Ilość'}
                name={'quantity'}
                onChange={val => setRowState(prev => ({ ...prev, quantity: val ?? 1 }))}
              />
            </TableCell>
            <TableCell>
              <NumberInput
                value={rowState.used}
                label={'Użyto'}
                name={'used'}
                onChange={val => setRowState(prev => ({ ...prev, used: val ?? 0 }))}
              />
            </TableCell>
            <TableCell width={5}>
              <ButtonGroup>
                {onRemove && (
                  <Button onClick={() => onRemove(data.id)} className="btn btn-danger btn-sm">
                    <Tooltip title="Usuwanie elementu">
                      <i className="mdi mdi-delete" />
                    </Tooltip>
                  </Button>
                )}
              </ButtonGroup>
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};

const TaskTabIngredientsPositionMemo = memo(TaskTabIngredientsPosition);

const TaskTabIngredients: FC<{ taskId: string; ingredients: ProductIngredient[] }> = ({ taskId, ingredients }) => {
  const [rows, setRows] = useState<ProductIngredient[]>(ingredients ?? []);

  const handleChange = useCallback(data => {
    setRows(prevRows =>
      prevRows.map(el => {
        if (el.id === data.id) {
          return { ...el, ...data };
        }
        return el;
      }),
    );
  }, []);

  const handleNew = useCallback(newPosition => {
    setRows(prevRows => [...prevRows, { ...newPosition, id: newPosition.id ?? uuidv4() }]);
  }, []);

  const onRemove = useCallback((id: string | null | undefined) => {
    setRows(prevRows => prevRows.filter(item => item.id !== id));
  }, []);

  return (
    <div className={'ingredients'}>
      <Table style={{ minWidth: 640 }}>
        <TableBody>
          {(rows ?? []).map(el => (
            <TaskTabIngredientsPositionMemo key={`value_${el.id}`} data={el} onChange={handleChange} onRemove={onRemove} />
          ))}
          <TaskTabIngredientsPositionMemo key={`new_value_${rows.length}`} data={DefaultRow} onChange={handleNew} onRemove={onRemove} />
        </TableBody>
      </Table>
      <div className="col-md-12 text-end mt-2">
        <Button color={'success'} variant={'contained'} type="submit">
          <i className={'mdi mdi-content-save'} />
          &nbsp;Zapisz
        </Button>
      </div>
    </div>
  );
};
export default TaskTabIngredients;
