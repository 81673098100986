import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Employee } from 'Modules/Employee/Types/Employee';
import dayjs from 'dayjs';
import { FC } from 'react';

interface AmountOfDone {
  ['@id']: string;
  comment?: null | string;
  amount: number;
  unit?: null | string;
  changedAt: string;
  employee: Employee<string>;
}

interface TaskTabWorkHistoryProps {
  amountHistory: AmountOfDone[];
}

const TaskTabWorkHistory: FC<TaskTabWorkHistoryProps> = props => {
  return (
    <div className={'TaskTabWorkHistory'}>
      <Table padding={'none'}>
        <TableHead>
          <TableRow>
            <TableCell>Zmiana</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Pracownik</TableCell>
            <TableCell>Komentarz</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.amountHistory.map(el => (
            <TableRow key={el['@id']}>
              <TableCell>
                {el.amount > 0 && '+'}
                {el.amount}
              </TableCell>
              <TableCell>{dayjs(el.changedAt).locale('pl').format('LLL')}</TableCell>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar>
                    {el.employee.image && (
                      <>
                        <img
                          src={el.employee.image}
                          style={{ maxWidth: '100%' }}
                          alt={`${el.employee.firstName} ${el.employee.lastName}`}
                        />
                      </>
                    )}
                    {!el.employee.image && (
                      <>
                        {el.employee.firstName?.substring(0, 1)}
                        {el.employee.lastName?.substring(0, 1)}
                      </>
                    )}
                  </Avatar>
                  <span style={{ padding: '2px 6px' }}>
                    {el.employee.firstName} {el.employee.lastName}
                  </span>
                </div>
              </TableCell>
              <TableCell>{el.comment}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
export default TaskTabWorkHistory;
