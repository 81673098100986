// import { buildTreeLayout } from './FlowUtils';
import { Node, ProductNode, ProductNodeData, TechnologyNodeData } from './FlowUtils';
import { OnConnectStartParams } from '@reactflow/core/dist/esm/types/general';
import { Field } from 'Modules/CustomFields/Field';
import type { MouseEvent as ReactMouseEvent } from 'react';
import {
  Connection,
  Edge,
  EdgeChange,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from 'reactflow';
// import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';

// Step 1: Import subscribeWithSelector middleware

export const ProductNodeModeBasic = 'Basic';
export const ProductNodeModeTechnologyFiles = 'TechnologyFiles';
export type ProductNodeMode = typeof ProductNodeModeBasic | typeof ProductNodeModeTechnologyFiles;

type RFState = {
  nodes: Node[];
  edges: Edge[];
  fields: Field[];
  technologies: any[];
  nodesToDuplicateModal: boolean;
  nodesToDuplicate: ProductNode[];
  connectingNodeId: string | null;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  productNodeMode: ProductNodeMode;
  onConnectStart: any;
  onConnect: OnConnect;
  setNodes: (nodes: Node[]) => void;
  getNode: (id: string) => null | Node;
  getSelectedProducts: () => ProductNode[];
  setEdges: (edges: Edge[]) => void;
  removeNode: (nodeId: string) => void;
  setTechnologies: (technologies: any[]) => void;
  updateProduct: (productId: string, newData: any) => void;
  updateState: (newState: Partial<RFState>) => void;
  resetState: () => void;
};

const useStore = create<RFState>((set, get) => ({
  nodes: [],
  fields: [],
  technologies: [],
  nodesToDuplicate: [],
  edges: [],
  productNodeMode: 'Basic',
  connectingNodeId: null,
  nodesToDuplicateModal: false,
  onNodesChange: (changes: NodeChange[]) => {
    set({
      //@ts-ignore
      nodes: applyNodeChanges<ProductNodeData | TechnologyNodeData>(changes, get().nodes),
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
      connectingNodeId: null,
    });
  },
  onConnectStart: (event: ReactMouseEvent, params: OnConnectStartParams) => {
    set({
      connectingNodeId: params.nodeId,
    });
  },
  setNodes: (nodes: Node[]) => {
    set({ nodes });
  },
  updateState: (newState: Partial<RFState>) => {
    set({ ...newState });
  },
  getNode: (id: string): null | Node => {
    return get().nodes.find(el => el.id === id) ?? null;
  },
  setEdges: (edges: Edge[]) => {
    set({ edges });
  },
  setTechnologies: (technologies: any[]) => {
    set({ technologies });
  },
  getSelectedProducts: (): ProductNode[] => {
    return get().nodes.filter(el => el.type === 'productNode' && el.selected) as ProductNode[];
  },
  resetState: () => {
    set({
      nodes: [],
      fields: [],
      technologies: [],
      edges: [],
      connectingNodeId: null,
    });
  },
  updateProduct: (productId: string, newData: any) => {
    set({
      nodes: get().nodes.map(el => {
        if (el.type === 'productNode' && el.data?.product?.id === productId) {
          el.data = newData;
        }
        return el;
      }),
    });
  },
  removeNode: (nodeId: string) => {
    const edges = get().edges;
    const nodesToRemove = [nodeId];
    const depthNodes = [nodeId];
    while (depthNodes.length > 0) {
      const node = depthNodes.pop();
      const children = edges.filter(el => el.target === node).map(el => el.source);
      nodesToRemove.push(...children);
      depthNodes.push(...children);
    }
    set({
      nodes: get().nodes.filter(el => !nodesToRemove.includes(el.id)),
    });
  },
}));

export default useStore;
