import { useState } from 'react';
import { Col, Row } from 'reactstrap';

const PinPreview = ({ pin }) => {
  const [showPin, setShowPin] = useState(false);

  if (!pin || pin === '') {
    return null;
  }

  return (
    <Row>
      <Col style={{ maxWidth: 20, cursor: 'pointer' }}>
        <i className={`mdi mdi-eye${showPin ? '-off' : ''}`} onClick={() => setShowPin(!showPin)} />
      </Col>
      <Col>
        <span style={{ verticalAlign: 'middle' }}>{showPin ? pin : '******'}</span>
      </Col>
    </Row>
  );
};

export default PinPreview;
