import { Tooltip } from '@mui/material';
import classnames from 'classnames';
import { axiosApi } from 'helpers/Axios';
import { downloadBlobFile } from 'helpers/File';
import { FC } from 'react';
import { Button } from 'reactstrap';

type ReportDownloadButton = {
  url: string;
  name: string;
  description: string;
  label?: string;
};

const ReportDownloadButton: FC<ReportDownloadButton> = ({ url, name, description, label }) => {
  const download = () => {
    axiosApi
      .get(url, {
        responseType: 'blob',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
      .then(response => {
        downloadBlobFile(response.data, `${name}.xlsx`);
      });
  };

  return (
    <Button
      onClick={() => download()}
      className={classnames('btn btn-info ml-1', {
        'btn-sm': !label,
      })}
    >
      <Tooltip title={description}>
        <>
          <i className="mdi mdi-download" /> {label}
        </>
      </Tooltip>
    </Button>
  );
};

export default ReportDownloadButton;
