import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import CurrencySelectSingle from 'components/Form/CurrencySelectSingle';
import { useEffect, useMemo, useRef } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';

const Currency: CustomFieldComponentType = ({ field, value, fields, values, onChange, readonly, errorKey, customProps = {} }) => {
  const firstRender = useRef(true);
  const contractor = useMemo(() => {
    return values?.[fields.find(el => el.propertyPath === 'contractor')?.id as string];
  }, [values, fields]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (!contractor?.accountancy?.currency) {
      return;
    }

    onChange(contractor.accountancy.currency, field.id);
  }, [contractor?.['@id']]);

  useEffect(() => {}, [value]);

  return (
    <>
      <Col xs={12}>
        <Row>
          <FormGroup className="disable-group-margin">
            <CurrencySelectSingle
              size={{ md: 12 }}
              value={value ?? 'PLN'}
              name={field.id}
              tabIndex={field._tabindex}
              onChange={onChange}
              disabled={readonly}
              errorKey={errorKey}
              {...customProps}
            />
          </FormGroup>
        </Row>
      </Col>
    </>
  );
};

Currency.provideAllValues = true;

export default Currency;
