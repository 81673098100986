import { MessageCollectionElement } from 'Modules/Chat/Types/Api';
import { ApiCollection, get } from 'helpers/Axios';
import { useInfiniteQuery } from 'react-query';

export type MessageListQueryData = ReturnType<typeof useMessageListQuery>['data'];

export function createMessageListQueryKey(roomIri: string) {
  return [`${roomIri}/messages`, 'infinite'];
}

export default function useMessageListQuery(roomIri: string) {
  return useInfiniteQuery<ApiCollection<MessageCollectionElement>>({
    queryKey: createMessageListQueryKey(roomIri),
    queryFn: ({ pageParam }) => get(pageParam || `${roomIri}/messages`),
    getNextPageParam: lastPage => lastPage['hydra:view']['hydra:next'],
    getPreviousPageParam: firstPage => firstPage['hydra:view']['hydra:previous'],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: false,
    cacheTime: 0,
  });
}
