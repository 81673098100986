import { withSecure } from 'components/Theme/Common/SecuredView';
import CreateDocument from 'pages/Warehouse/Document/CreateDocument';
import DocumentForm from 'pages/Warehouse/Document/Form';
import DocumentList from 'pages/Warehouse/Document/List';
import ImportStore from 'pages/Warehouse/Import/ImportStore';
import ProductStoreReport from 'pages/Warehouse/Report/ProductStoreReport';
import StoreItemReport from 'pages/Warehouse/Report/StoreItemReport';
import WarehouseAmountReport from 'pages/Warehouse/Report/WarehouseAmountReport';

const ReadonlyDocumentForm = props => DocumentForm({ ...props, readonly: true });

export default [
  {
    path: '/warehouse/documents/add',
    exact: true,
    component: withSecure(CreateDocument, 'ROLE_SHOW_WAREHOUSE_DOCUMENT', true),
  },
  {
    path: '/warehouse/documents/add/:type',
    exact: false,
    component: withSecure(DocumentForm, 'ROLE_SHOW_WAREHOUSE_DOCUMENT', true),
  },
  {
    path: '/warehouse/documents/create',
    exact: false,
    component: withSecure(CreateDocument, 'ROLE_SHOW_WAREHOUSE_DOCUMENT', true),
  },
  {
    path: '/warehouse/documents',
    exact: true,
    component: withSecure(DocumentList, 'ROLE_SHOW_WAREHOUSE_DOCUMENT', true),
  },
  {
    path: '/warehouse/documents/:id/edit',
    exact: false,
    component: withSecure(DocumentForm, 'ROLE_SHOW_WAREHOUSE_DOCUMENT', true),
  },
  {
    path: '/warehouse/documents/:id',
    exact: false,
    component: withSecure(ReadonlyDocumentForm, 'ROLE_SHOW_WAREHOUSE_DOCUMENT', true),
  },
  {
    path: '/warehouse/import/store',
    exact: false,
    component: withSecure(ImportStore, 'ROLE_CREATE_WAREHOUSE_DOCUMENT', true),
  },
  {
    path: '/warehouse/report/store-items',
    exact: false,
    component: withSecure(StoreItemReport, 'ROLE_CREATE_WAREHOUSE_DOCUMENT', true),
  },
  {
    path: '/warehouse/report/warehouse-amounts',
    exact: false,
    component: withSecure(WarehouseAmountReport, 'ROLE_CREATE_WAREHOUSE_DOCUMENT', true),
  },
  {
    path: '/warehouse/report/product-stores',
    exact: false,
    component: withSecure(ProductStoreReport, 'ROLE_CREATE_WAREHOUSE_DOCUMENT', true),
  },
];
