import { OnlineStatus } from 'Modules/Chat/Types/Api';
import { get } from 'helpers/Axios';
import { useQuery } from 'react-query';

export function useOnlineUsersQuery() {
  return useQuery<OnlineStatus[]>({
    queryKey: '/chat/online-status',
    queryFn: () => get('/chat/online-status'),
    refetchInterval: 60000,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 15000,
    keepPreviousData: true,
  });
}

export default function useOnlineUsers() {
  return useOnlineUsersQuery().data || [];
}
