import { ProductModule } from '../../CTMModules';
import { DeleteForeverRounded, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { InputAdornment, TableCell, TableRow } from '@mui/material';
import CustomField from 'components/CustomFields/CustomField';
import PlaceholderInput from 'components/Form/MUI/PlaceholderInput';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import RecordInputReader from 'components/Module/RecordInputReader';
import _ from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

export const DefaultProductRow = {
  product: null,
};

const ProductCollectionRow = ({
  item,
  readonly,
  onChange,
  onRemove,
  cellStyles,
  newRow,
  index,
  customFields,
  selectedProducts,
  onUp,
  onDown,
}) => {
  const [row, setRow] = useState({ ...DefaultProductRow, ...item });
  const [productFullData, setProductFullData] = useState(null);
  const dispatch = useDispatch();
  const computedCellStyles = useMemo(
    () => ({
      position: 'relative',
      ...cellStyles,
    }),
    [JSON.stringify(cellStyles)],
  );
  const inputCurrencyAdornment = {
    min: 0,
    endAdornment: <InputAdornment position="end">zł</InputAdornment>,
  };

  useEffect(() => {
    if (!_.isEqual({ ...row, seq: 0 }, { ...item, seq: 0 })) {
      onChange(index, row);
    }
  }, [row, item]);

  useEffect(() => {
    if (row.product?.id) {
      ProductModule.api.get({ id: row.product?.id }).then(data => {
        setProductFullData(data);
      });
    }
  }, [row.product?.id]);

  const updateRowValue = (value, field) => {
    if (readonly) {
      return;
    }
    setRow(prevRow => ({ ...prevRow, [field]: value }));
  };

  const handleProductSelect = useCallback(gridRow => {
    setRow(prevState => {
      return {
        ...prevState,
        product: gridRow,
      };
    });
  }, []);

  return (
    <>
      <TableRow>
        {!newRow && (
          <TableCell style={computedCellStyles}>
            <div style={{ paddingLeft: readonly ? 0 : 56 }}>{index !== undefined ? index + 1 : ''}</div>
            {!readonly && row.product && (
              <DeleteForeverRounded
                style={{
                  color: '#b00020',
                  position: 'absolute',
                  left: 15,
                  top: '50%',
                  cursor: 'pointer',
                  transform: 'translate(-50%,-50%)',
                }}
                onClick={() => onRemove(index)}
              />
            )}
            <KeyboardArrowUp
              style={{
                color: '#b00020',
                position: 'absolute',
                left: 35,
                top: '50%',
                cursor: 'pointer',
                transform: 'translate(-50%,-50%)',
              }}
              onClick={() => onUp(index)}
            />
            <KeyboardArrowDown
              style={{
                color: '#b00020',
                position: 'absolute',
                left: 50,
                top: '50%',
                cursor: 'pointer',
                transform: 'translate(-50%,-50%)',
              }}
              onClick={() => onDown(index)}
            />
          </TableCell>
        )}
        {!row.product && (
          <TableCell
            style={{
              minWidth: 160,
              position: 'relative',
              ...cellStyles,
            }}
            colSpan={15}
          >
            <ModuleListPicker
              disabled={readonly}
              moduleName={'manufacture-products'}
              onChange={handleProductSelect}
              allowMultipleSelect={true}
              selectedIds={selectedProducts}
              onMultipleSelect={data => {
                onChange(
                  index,
                  data.map(el => ({ product: el })),
                );
              }}
              disableClear={true}
              readerValue={row.product}
              label={newRow === true ? 'Wybierz produkt aby dodać...' : 'Produkt'}
            />
          </TableCell>
        )}
        {row.product && (
          <>
            <TableCell
              style={{
                minWidth: 160,
                position: 'relative',
                ...cellStyles,
              }}
              colSpan={1}
            >
              <RecordInputReader value={row.product} module={ProductModule} readonly={true} />
            </TableCell>
            <TableCell style={{ minWidth: 180, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'Symbol'} value={row?.product?.symbol} />
            </TableCell>
            <TableCell style={{ minWidth: 80, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'ECM'} value={row?.product?.labelerId} />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'EAN'} value={row?.product?.ean} />
            </TableCell>
            {customFields.map((field, key) => {
              return productFullData ? (
                <TableCell key={key} style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
                  <CustomField
                    key={field.id}
                    field={productFullData['@form'][field.id]}
                    fields={customFields}
                    components={ProductModule.configuration.form.fieldComponents}
                    value={productFullData['@formValues'][field.id]}
                    values={productFullData['@formValues']}
                    onUpdate={() => {}}
                    readonly={true}
                    recordId={row.product?.id}
                  />
                </TableCell>
              ) : (
                <TableCell key={key} style={{ minWidth: 120, ...(cellStyles ?? {}) }} />
              );
            })}
          </>
        )}
      </TableRow>
    </>
  );
};

export default memo(ProductCollectionRow);
