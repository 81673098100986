import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Alert } from '@mui/material';
import { ProductSupplierModule } from 'Modules/CTMModules';
import ModuleList from 'components/Module/ModuleList';

const Suppliers: CustomFieldComponentType = ({ recordId }) => {
  if (!recordId) {
    return <Alert color="warning">Sekcja z dostawcami będzie dostępna po zapisaniu rekordu.</Alert>;
  }

  return (
    <>
      <ModuleList
        key={ProductSupplierModule.configuration.urlPrefix}
        moduleName={ProductSupplierModule.configuration.urlPrefix}
        useModal={true}
        defaultFilters={[{ id: 'product.id', value: recordId.split('/').pop() }]}
        overrideFormProps={{
          recordCallback: async fields => {
            return {
              '@formValues': {
                [fields.find(el => el.propertyPath === 'product').id]: recordId,
              },
              product: recordId,
            };
          },
          forceReadonlyField: field => {
            return ['product', 'priceFromLastDelivery'].includes(field.propertyPath);
          },
        }}
        overrideListProps={{
          defaultPerPage: 5,
          perPageOptions: [5, 10, 20, 30, 40, 50],
        }}
      />
    </>
  );
};

export default Suppliers;
