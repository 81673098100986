import { Button } from '@mui/material';
import classnames from 'classnames';
import { useState } from 'react';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

const BaseForm = ({
  title = '',
  onSubmit = () => console.error('You have to implement onSubmit prop'),
  isSubmitting = false,
  tabs = [],
  readonly = undefined,
  renderOnlySelectedTab = false,
  contextActions = <></>,
  children = undefined,
  hideTabs = false,
  submitActions = <></>,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit();
  };

  return (
    <Container fluid={true}>
      <Row className="mb-2">
        <Col xs={12} md={!contextActions ? 12 : 6} className="align-self-center">
          <h4>{title}</h4>
        </Col>
        <Col xs={12} md={!contextActions ? 12 : 6} className="align-self-center" style={{ textAlign: 'right' }}>
          {contextActions}
        </Col>
      </Row>
      <>
        {!hideTabs && (
          <Row>
            <Col>
              <Nav tabs className="bg-dark-accent border-radius-top position-relative">
                {tabs.map((el, index) => (
                  <NavItem key={`NavItem_${index}`}>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === index,
                        'dark-accent-color': activeTab !== index,
                      })}
                      onClick={() => {
                        toggle(index);
                      }}
                    >
                      {el.title}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Card className={classnames('border-small', { 'border-top-0 border-radius-top-left-none': !hideTabs })}>
              <CardBody style={{ padding: 0, paddingBottom: 12 }}>
                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  {tabs.map((el, index) => (
                    <TabPane tabId={index} key={`TabPane${index}`}>
                      {(!renderOnlySelectedTab || activeTab === index) && el.content}
                    </TabPane>
                  ))}
                </TabContent>
                {onSubmit !== false && !readonly && (tabs?.[activeTab]?.disabledSubmit ?? false) === false && (
                  <Row>
                    <div className="col-md-12 text-end">
                      {submitActions}
                      <Button
                        className={'ml-1'}
                        color={'success'}
                        variant={'contained'}
                        type="submit"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        <i className={isSubmitting ? 'mdi mdi-spin mdi-cogs' : 'mdi mdi-content-save'} />
                        &nbsp;Zapisz
                      </Button>
                    </div>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
      {children}
    </Container>
  );
};

export default BaseForm;
