import { ImageModule } from '@Modules/CTMModules';
import ListPicker from 'components/Form/ListPicker';
import Loader from 'components/Theme/Common/Loader';
import { useEffect, useState } from 'react';

const CFImageTypeConfiguration = ({ configuration, onConfigurationUpdated, system }) => {
  const [images, setImages] = useState([]);

  const updateConfiguration = (value, field) => {
    onConfigurationUpdated({ ...configuration, [field]: value });
  };

  useEffect(() => {
    const fetchImages = async () => {
      ImageModule.api.getAll().then(imageResponse => {
        setImages(imageResponse['hydra:member'].map(el => ({ label: el.name, val: el.id })));
      });
    };
    if (images.length === 0) {
      fetchImages();
    }
  }, []);

  if (images.length === 0) {
    return <Loader />;
  }

  return (
    <ListPicker
      label={'Zdjęcie'}
      name="targetImage"
      value={configuration?.targetImage ?? null}
      options={images}
      optionLabel="label"
      optionTrackBy="val"
      onChange={updateConfiguration}
      disableGroupMargin={true}
      disabled={system}
    />
  );
};

export default CFImageTypeConfiguration;
