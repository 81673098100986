import { CoreModuleModule, ProductGroupModule, ProductModule } from 'Modules/CTMModules';
import useIsGranted from 'common/hooks/isGranted';
import BaseInput from 'components/Form/BaseInput';
import ListPicker from 'components/Form/ListPicker';
import SelectMultiple from 'components/Form/SelectMultiple';
import SelectSingleAPI from 'components/Form/SelectSingleAPI';
import Switch from 'components/Form/Switch';
import { Col, Container } from 'components/Theme/Grid';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

const WooCommerce = ({ form, updateConfiguration }) => {
  const [productFields, setProductFields] = useState([]);
  const [productGroupFields, setProductGroupFields] = useState([]);

  const resolveProductFields = () => {
    CoreModuleModule.api.get({ id: ProductModule.configuration.id }).then(configuration => {
      setProductFields(configuration.fields);
    });
  };

  const resolveProductGroupFields = () => {
    CoreModuleModule.api.get({ id: ProductGroupModule.configuration.id }).then(configuration => {
      setProductGroupFields(configuration.fields);
    });
  };

  const canEdit = useIsGranted('ROLE_EDIT_CONFIGURATION');

  const updateFormValue = (value, field) => {
    updateConfiguration({ ...form, [field]: value });
  };

  const updateFieldValue = (value, field) => {
    const fieldsValues = {
      ...form.fields,
      ...{
        [field]: value,
      },
    };

    updateConfiguration({ ...form, fields: fieldsValues });
  };

  useEffect(() => {
    resolveProductFields();
    resolveProductGroupFields();
  }, []);

  return (
    <Container>
      <Col xs={12} md={12} lg={12}>
        <h5>Ogólne</h5>
      </Col>
      <Col xs={12} md={12} lg={12}>
        <Switch
          size={{ md: 12 }}
          label={'Aktywne'}
          value={form.active}
          name="active"
          onChange={updateFormValue}
          disabled={!canEdit}
          switchOnRight={true}
        />
      </Col>
      <Col xs={12} md={6} lg={4}>
        <BaseInput size={{ md: 12 }} label={'URL Sklepu'} value={form.url} name="url" onChange={updateFormValue} disabled={!canEdit} />
      </Col>
      <Col xs={12} md={6} lg={4}>
        <BaseInput
          size={{ md: 12 }}
          label={'Klucz klienta'}
          value={form.consumerKey}
          name="consumerKey"
          onChange={updateFormValue}
          disabled={!canEdit}
        />
      </Col>
      <Col xs={12} md={6} lg={4}>
        <BaseInput
          size={{ md: 12 }}
          label={'Klucz prywatny'}
          value={form.consumerSecret}
          name="consumerSecret"
          onChange={updateFormValue}
          disabled={!canEdit}
        />
      </Col>
      <Col sx={12} md={6} lg={4}>
        <SelectSingleAPI
          label={'Typ produktu dla eksportu'}
          disableGroupMargin={true}
          name={'productType'}
          value={form.productType}
          onChange={updateFormValue}
          endpoint={`manufacture/product-types`}
          isClearable={false}
        />
      </Col>
      <Col xs={12} md={12} lg={12}>
        <h5>Mapowanie pól</h5>
      </Col>
      <Col xs={12} md={6} lg={3}>
        <ListPicker
          label={'Nazwa'}
          options={productFields}
          disableGroupMargin={true}
          name={'name'}
          value={form.fields?.name ?? null}
          onChange={updateFieldValue}
          isClearable={false}
        />
      </Col>
      <Col xs={12} md={6} lg={3}>
        <ListPicker
          label={'Cena'}
          options={productFields}
          disableGroupMargin={true}
          name={'price'}
          value={form.fields?.price ?? null}
          onChange={updateFieldValue}
          isClearable={false}
        />
      </Col>
      <Col xs={12} md={6} lg={3}>
        <ListPicker
          label={'Opis'}
          options={productFields}
          disableGroupMargin={true}
          name={'description'}
          value={form.fields?.description ?? null}
          onChange={updateFieldValue}
          isClearable={false}
        />
      </Col>
      <Col xs={12} md={6} lg={3}>
        <ListPicker
          label={'Skrócony opis'}
          options={productFields}
          disableGroupMargin={true}
          name={'shortDescription'}
          value={form.fields?.shortDescription ?? null}
          onChange={updateFieldValue}
          isClearable={false}
        />
      </Col>
      <Col xs={12} md={6} lg={6}>
        <SelectMultiple
          label={'Zdjęcia'}
          options={productFields.filter(field => field.type === 'IMAGE' && !field.system)}
          disableGroupMargin={true}
          name={'images'}
          value={form.fields?.images ?? null}
          onChange={updateFieldValue}
          isClearable={false}
        />
      </Col>
      <Col xs={12} md={6} lg={3}>
        <ListPicker
          label={'Pole aktywnego importu produktu'}
          options={productFields.filter(field => field.type === 'BOOLEAN' && !field.system)}
          disableGroupMargin={true}
          name={'importProduct'}
          value={form.fields?.importProduct ?? null}
          onChange={updateFieldValue}
          isClearable={false}
        />
      </Col>
      <Col xs={12} md={6} lg={3}>
        <ListPicker
          label={'Pole aktywnego importu grupy produktu'}
          options={productGroupFields.filter(field => field.type === 'BOOLEAN' && !field.system)}
          disableGroupMargin={true}
          name={'importProductGroup'}
          value={form.fields?.importProductGroup ?? null}
          onChange={updateFieldValue}
          isClearable={false}
        />
      </Col>
    </Container>
  );
};

export default withRouter(WooCommerce);
