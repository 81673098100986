import { confirmAction } from '../../../../common/sweetalerts';
import { BaseLinkerIntegrationModule } from '@Modules/CTMModules';
import { CleaningServices, MoreVert, SettingsEthernet, SyncAltRounded, SyncOutlined } from '@mui/icons-material';
import { ListItemIcon, MenuList, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import { FC, useState } from 'react';

type BaseLinkerContextActionsProps = {
  integrationId: string;
};

type BaseLinkerContextAction = {
  icon: typeof SvgIcon;
  label: string;
  description: string;
  color: 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  action: (integrationId: string) => Promise<Record<string, never>>;
};

const actions: BaseLinkerContextAction[] = [
  {
    icon: SyncAltRounded,
    label: 'Synchronizuj Kategorie',
    color: 'success',
    description: 'Akcja ta spowoduje wgranie wszystkich kategorii do BaseLinker',
    action: integrationId => BaseLinkerIntegrationModule.api.custom.registerActionForSynchronizeProductGroups(integrationId),
  },
  {
    icon: SettingsEthernet,
    label: 'Połącz produkty',
    color: 'success',
    description: 'Akcja to połączu istniejące produkty z produktami z BaseLinker na podstawię powiązania kodu EAN',
    action: integrationId => BaseLinkerIntegrationModule.api.custom.registerActionForConnectProducts(integrationId),
  },
  {
    icon: SyncOutlined,
    label: 'Synchronizuj produkty',
    color: 'warning',
    description: 'Akcja ta spowoduje wgranie wszystkich produktów do BaseLinker',
    action: integrationId => BaseLinkerIntegrationModule.api.custom.registerActionForSynchronizeProducts(integrationId),
  },
  {
    icon: CleaningServices,
    label: 'Wyczyść powiązania produktów',
    color: 'error',
    description: 'Akcja ta spowoduje usunięcie wszystkich powiązań kategorii z BaseLinker',
    action: integrationId => BaseLinkerIntegrationModule.api.custom.registerActionForWipeProductsConnections(integrationId),
  },
  {
    icon: CleaningServices,
    label: 'Wyczyść powiązania kategorii',
    color: 'error',
    description: 'Akcja ta spowoduje usunięcie wszystkich powiązań produktów z BaseLinker',
    action: integrationId => BaseLinkerIntegrationModule.api.custom.registerActionForWipeProductGroupsConnections(integrationId),
  },
];

const BaseLinkerContextActions: FC<BaseLinkerContextActionsProps> = props => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const doAction = (action: BaseLinkerContextAction) => {
    setAnchorEl(null);
    confirmAction(
      () => action.action(props.integrationId),
      action.label,
      `${action.description}\nCzy na pewno chcesz wykonać tę akcję?`,
      true,
      'Akcja została zaplanowana i zostanie wykonana w tle.',
      false,
    );
  };

  return (
    <div>
      <MoreVert style={{ color: '#fff', cursor: 'pointer', borderColor: '#fff', minWidth: 'initial !important' }} onClick={handleClick} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList>
          {actions.map((action, index) => (
            <MenuItem onClick={() => doAction(action)} key={index}>
              <ListItemIcon>
                <action.icon fontSize="small" color={action.color} />
              </ListItemIcon>
              <Typography variant="inherit">{action.label}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
};

export default BaseLinkerContextActions;
