import { Download } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Spinner } from 'reactstrap';

interface ExportButtonProps {
  exports: { id: string; name: string; url?: string }[];
  onExport: (exportId: string, url: string | null) => Promise<boolean>;
}

const ExportButton = (props: ExportButtonProps) => {
  const { exports } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [exporting, setExporting] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleExport = (exportId: string, url: string | null) => {
    setExporting(true);
    props
      .onExport(exportId, url)
      .then(() => setExporting(false))
      .catch(() => setExporting(false));
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (exports.length === 0) {
    return null;
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
        size="small"
        disabled={exporting}
      >
        {exporting && (
          <>
            <Spinner size={'12px'} /> trwa export
          </>
        )}
        {!exporting && (
          <>
            <Download />
            Export
          </>
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {exports.map(item => (
          <MenuItem onClick={() => handleExport(item.id, item?.url ?? null)} key={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ExportButton;
