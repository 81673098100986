import { Divider, List } from '@mui/material';
import useChatManipulationFunctions from 'Modules/Chat/Hooks/UseChatManipulationFunctions';
import useOnlineUsers from 'Modules/Chat/Hooks/UseOnlineUsersQuery';
import useRoomsList from 'Modules/Chat/Hooks/UseRoomsList';
import useUnreadRoomsQuery from 'Modules/Chat/Hooks/UseUnreadRoomsQuery';
import RoomListElement, { RoomListElementPlaceHolder } from 'Modules/Chat/View/Components/Sidebar/RoomListElement';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export default function RecentRooms() {
  const { data, hasNextPage, fetchNextPage, isSuccess, isFetchingNextPage } = useRoomsList();
  const onlineUsers = useOnlineUsers();
  const unreadRooms = useUnreadRoomsQuery().data || [];
  const [loaderPlaceholderRef, loaderPlaceholderInView] = useInView();
  const { toggleRoomIsHidden, toggleRoomIsFavorite } = useChatManipulationFunctions();

  useEffect(() => {
    if (isSuccess && loaderPlaceholderInView && hasNextPage && !isFetchingNextPage) {
      void fetchNextPage();
    }
  }, [loaderPlaceholderInView, hasNextPage, fetchNextPage, isSuccess, isFetchingNextPage, loaderPlaceholderRef]);

  return (
    <List>
      {data
        .filter(room => !room.isHidden)
        .map(room => (
          <RoomListElement
            key={room.id}
            room={room}
            onlineUsers={onlineUsers}
            unreadRooms={unreadRooms}
            lastMessage={room.lastMessage}
            toggleHidden={toggleRoomIsHidden}
            toggleFavorite={toggleRoomIsFavorite}
            variant="recent"
          />
        ))}
      <div ref={loaderPlaceholderRef} hidden={!hasNextPage}>
        <RoomListElementPlaceHolder />
        <RoomListElementPlaceHolder />
        <RoomListElementPlaceHolder />
        <RoomListElementPlaceHolder />
        <RoomListElementPlaceHolder />
      </div>
      <Divider hidden={hasNextPage} children="To już wszystko" />
    </List>
  );
}
