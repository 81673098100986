import { useDispatch } from 'react-redux';
import { setImages, toggle } from 'store/Lightbox/Lightbox';

interface LightboxWrapperProps {
  children: any;
  images: string[];
}

const LightboxWrapper = (props: LightboxWrapperProps) => {
  const dispatch = useDispatch();

  return (
    <div
      className={'lightbox-wrapper'}
      onClick={() => {
        dispatch(setImages({ images: props.images }));
        dispatch(toggle());
      }}
    >
      {props.children}
    </div>
  );
};

export default LightboxWrapper;
