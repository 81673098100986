import { DictionaryModule } from 'Modules/CTMModules';
import RelationSinglePicker from 'components/Form/RelationSinglePicker';

const RelationTypeConfiguration = ({ configuration, onConfigurationUpdated, system, moduleId }) => {
  const updateConfiguration = (value, field) => {
    onConfigurationUpdated({ ...configuration, [field]: value });
  };

  return (
    <RelationSinglePicker
      moduleName={DictionaryModule.configuration.urlPrefix}
      size={{ md: 12 }}
      label={'Słownik'}
      value={configuration?.dictionaryId ?? null}
      name="dictionaryId"
      onChange={updateConfiguration}
      fetchValue={id => (id ? DictionaryModule.api.get({ id }) : null)}
      optionLabel="name"
      optionTrackBy="id"
      disableGroupMargin={true}
      disabled={system}
      defaultFilters={[{ id: 'category.id', value: moduleId }]}
    />
  );
};

export default RelationTypeConfiguration;
