import { Tooltip } from '@mui/material';
import useWarehouses from 'Modules/Warehouse/Hooks/UseWarehouses';
import { useDocumentTypes } from 'pages/Warehouse/Document/useDocumentTypes';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';

const CreateDocument = () => {
  const { isLoading: isLoadingDocumentTypes, data: documentTypes } = useDocumentTypes();
  const { isLoading: isLoadingWarehouses, data: warehouses } = useWarehouses();

  if (isLoadingDocumentTypes || isLoadingWarehouses) return 'Loading...';

  const getColor = string => {
    let hash = 0;

    string.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });

    let colour = '#';

    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, '0');
    }

    return colour;
  };

  return (
    <>
      <h3 className="text-center">Wybierz typ dokumentu który chcesz dodać</h3>
      {warehouses.map(warehouse => (
        <>
          <h4 className="text-center mt-4 mb-4">{warehouse.name}</h4>
          <div className={'d-flex justify-content-center w-100'} key={warehouse.id}>
            <div className={'d-flex flex-wrap justify-content-evenly'} style={{ maxWidth: 1280, border: '2px solid #495057' }}>
              {documentTypes.map(documentType => (
                <Tooltip title={documentType.name} placment={'bottom'} key={documentType.id} arrow={true}>
                  <div style={{ padding: 20 }}>
                    <Link to={`/warehouse/documents/add/${documentType.id}?warehouse=${warehouse.id}`}>
                      <Card className="document_type-card">
                        <div className="icon" style={{ backgroundColor: getColor(documentType.name) }}>
                          {documentType.code}
                        </div>
                      </Card>
                    </Link>
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default CreateDocument;
