import CTMModule from '../../../Core/Types/CTMModule';
import TreeNode from './TreeNode';
import Loader from '@Components/Theme/Common/Loader';
import { ProductGroupType } from 'Modules/Manufacture/Types/ProductGroup';
import TextInput from 'components/Form/MUI/TextInput';
import { useModuleContext } from 'context/ModulesContext';
import { axiosApi } from 'helpers/Axios';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SortableTree, { changeNodeAtPath, removeNodeAtPath } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { Button, Col, Row } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';
import { v4 as uuidv4 } from 'uuid';

const View: FC = () => {
  const ProductGroupsModule = useModuleContext<CTMModule<ProductGroupType>>('manufacture-product-groups');
  const [tree, setTree] = useState<ProductGroupType[]>([]);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [saved, setSaved] = useState<{ saved: number; all: number }>({ saved: 0, all: 0 });
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const dispatch = useDispatch();

  const sort = (record: ProductGroupType): void => {
    if (!record.children) {
      return;
    }

    record.children.sort((a: ProductGroupType, b: ProductGroupType): number => (a.position ?? 0) - (b.position ?? 0));
    record.children.forEach((child: ProductGroupType): void => sort(child));
  };

  useEffect((): void => {
    if (loading && tree.length > 0) {
      return;
    }

    ProductGroupsModule?.api.getAll({ params: { page: 1, limit: 1000 } }).then(data => {
      const map = new Map();
      data['hydra:member'].forEach((record: ProductGroupType) =>
        map.set(record['@id'], {
          ...record,
          title: ({ node, path }) => <TreeNode key={record['@id']} node={node} path={path} removeNode={removeNode} editNode={editNode} />,
          children: [],
          expanded: isExpanded,
        }),
      );
      data['hydra:member'].forEach((record: ProductGroupType): void => {
        if (!record.parent) {
          return;
        }
        map.get(record.parent).children.push(map.get(record['@id']));
      });

      const sortedValues: ProductGroupType[] = [...map.values()].filter(record => !record.parent);
      sortedValues.forEach((record: ProductGroupType): void => sort(record));
      sortedValues.sort((a: ProductGroupType, b: ProductGroupType): number => (a.position ?? 0) - (b.position ?? 0));

      setTree(sortedValues);
    });
  }, [ProductGroupsModule, loading, isExpanded]);

  const setPositions = (treeData: ProductGroupType[]): void => {
    treeData.forEach((node, index) => {
      node.position = index + 1;

      if (node.children && node.children.length > 0) {
        setPositions(node.children);
      }
    });
  };

  const onMoveNode = ({
    treeData,
    node,
    nextParentNode,
    nextPath,
  }: {
    treeData: ProductGroupType[];
    node: ProductGroupType;
    nextParentNode: ProductGroupType;
    nextPath: Array<{ node: ProductGroupType; treeIndex: number }>;
  }): void => {
    const parent: string | null = nextParentNode?.['@id'] ?? null;
    const updatedTreeData = changeNodeAtPath<ProductGroupType>({
      treeData: [...treeData],
      path: nextPath,
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: { ...node, parent: parent },
    });

    setTree(updatedTreeData);
  };

  const flattenTree = (treeData: ProductGroupType[]): ProductGroupType[] => {
    const flattenedArray: ProductGroupType[] = [];

    treeData.forEach(node => {
      flattenedArray.push(node);

      if (node.children && node.children.length > 0) {
        const childrenArray = flattenTree(node.children);
        flattenedArray.push(...childrenArray);
      }
    });

    return flattenedArray;
  };

  const save = async (): Promise<void> => {
    setLoading(true);

    const treeData: ProductGroupType[] = [...tree];

    setPositions(treeData);

    const records: ProductGroupType[] = flattenTree(treeData);

    setSaved({
      saved: 0,
      all: records.length,
    });

    const updatePromises = records.map(async (record: ProductGroupType): Promise<void> => {
      record.id
        ? await ProductGroupsModule?.api.put(
            {
              position: record.position,
              name: record.name,
              parent: record.parent,
            },
            { id: record.id },
          )
        : await ProductGroupsModule?.api.post({ position: record.position, name: record.name, parent: record.parent });
      setSaved(prevSaved => ({
        saved: prevSaved.saved + 1,
        all: prevSaved.all,
      }));
    });

    try {
      await Promise.all(updatePromises);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const removeNode = (path: string[] | number[], node: ProductGroupType): Promise<boolean> => {
    return new Promise(res => {
      setTree(prevState => {
        return removeNodeAtPath({
          treeData: [...prevState],
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
        });
      });

      if (!node?.id) {
        res(true);
        return;
      }

      ProductGroupsModule?.api.delete({ id: node.id }).then(() => res(true));
    });
  };

  const editNode = useCallback((path: string[] | number[], node: ProductGroupType, name: string): void => {
    setTree(prevState => {
      return changeNodeAtPath<ProductGroupType>({
        treeData: [...prevState],
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        newNode: { ...node, name: name },
      });
    });
  }, []);

  const addNode = useCallback((): void => {
    setTree(prevState => [
      ...prevState,
      {
        id: null,
        title: ({ node, path }) => <TreeNode key={uuidv4()} node={node} path={path} removeNode={removeNode} editNode={editNode} />,
        name: 'Nowa kategoria #' + (tree.length + 1),
        children: [],
      },
    ]);
  }, []);

  const exportAll = (): void => {
    axiosApi.post(ProductGroupsModule?.api.getAllUrl + '/export-prestashop', {}).then(() => {
      dispatch(addSingleToast({ title: `Zlecono eksport kategorii produktów.`, config: { appearance: 'success' } }));
    });
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <Loader />
        <div className="text-center">
          <span>
            Zapisywanie {saved.saved} / {saved.all}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <Row>
        <Col xs={3}>
          <TextInput label="Szukaj..." value={search} onChange={value => setSearch(value ?? '')} />
        </Col>
        <Col xs={3}>
          <Button onClick={() => setExpanded(true)} className={'btn btn-success'}>
            <i className="bx bx-down-arrow" /> Rozwiń wszystkie
          </Button>
          <Button onClick={() => setExpanded(false)} className={'btn btn-danger ml-1'}>
            <i className="bx bx-up-arrow" /> Zwiń wszystkie
          </Button>
        </Col>
      </Row>
      <SortableTree
        treeData={tree}
        onMoveNode={onMoveNode}
        onChange={(treeData: ProductGroupType[]) => setTree(treeData)}
        isVirtualized={false}
        searchMethod={({ node, searchQuery }) => (searchQuery !== '' ? node.name.toLowerCase().includes(searchQuery.toLowerCase()) : null)}
        searchQuery={search}
        isExpandDisabled={!isExpanded}
      />
      <Row>
        <Col xs={12}>
          <Button onClick={save} className={'btn btn-info mt-2'}>
            <i className="bx bx-save" /> Zapisz
          </Button>
          <Button onClick={addNode} className={'btn btn-success mt-2 ml-1'}>
            <i className="bx bx-add-to-queue" /> Dodaj nową
          </Button>
          <Button onClick={exportAll} className={'btn btn-danger mt-2 ml-1'}>
            <i className="bx bx-import" /> Aktualizuj na zewnątrz
          </Button>
        </Col>
        <Col xs={12}>
          <p className="font-italic mt-2">Ostatnia aktualizacja: 15:21, 17.01.2024</p>
        </Col>
      </Row>
    </div>
  );
};

export default View;
