import { Alert, AlertTitle, Button, Table, TableBody, TableCell, TableRow } from '@mui/material';
import ListPicker from 'components/Form/ListPicker';
import FieldConditionRow from 'pages/Configuration/CustomFields/FieldConditionRow';
import { Col, Row } from 'reactstrap';

const FieldCondition = ({ value = [], fields, onChange, templates = [] }) => {
  const updateFieldShowConditionAtPosition = (condition, index) => {
    const newCondition = { ...(value?.[index] ?? {}), ...condition };
    const newCollection = [].concat(value);
    newCollection[index] = newCondition;

    onChange(newCollection);
  };

  const addNewFieldShowCondition = () => {
    updateFieldShowConditionAtPosition(
      {
        comparedField: null,
        compareMethod: 'eq',
        value: null,
      },
      value?.length ?? 0,
    );
  };

  const removeFieldShowCondition = index => {
    const newCollection = [].concat(value);
    newCollection.splice(index, 1);
    onChange(newCollection);
  };

  return (
    <>
      {value.length === 0 && (
        <Row>
          <Col xs={12}>
            <h6 className="modal-title position-relative" id="staticBackdropLabel">
              <Alert severity="info">
                <AlertTitle>Brak reguł ukrywania</AlertTitle>

                <Button size="small" color="success" onClick={addNewFieldShowCondition}>
                  Kliknij aby utworzyć
                </Button>
              </Alert>
              {templates.length > 0 && (
                <ListPicker
                  value={null}
                  resetAfterSelect={true}
                  options={templates}
                  placeholder="Lub kopiuj reguły z innych ustawień w tym module"
                  optionLabel="name"
                  optionTrackBy="value"
                  onChange={selectedOption => onChange([...selectedOption.value])}
                />
              )}
            </h6>
          </Col>
          <Col xs={12}></Col>
        </Row>
      )}
      {value.length > 0 && (
        <Row>
          <h6>Warunki ukrywania</h6>
          <Table>
            <TableBody>
              {value.map((fieldCondition, i) => (
                <FieldConditionRow
                  key={`field_condition_${i}`}
                  fieldShowCondition={fieldCondition}
                  fields={fields}
                  onChange={val => updateFieldShowConditionAtPosition(val, i)}
                  onRemove={() => removeFieldShowCondition(i)}
                />
              ))}
              <TableRow>
                <TableCell colSpan={4}>
                  <Button color="info" onClick={addNewFieldShowCondition}>
                    Dodaj warunek
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Row>
      )}
    </>
  );
};

export default FieldCondition;
