import ListPicker, { ListPickerProps } from 'components/Form/ListPicker';
import { Currency, getAllISOCodes } from 'iso-country-currency';
import { FC } from 'react';

export const CURRENCIES_INDEXED: { [key: string]: Currency } = getAllISOCodes().reduce((prev, curr) => {
  prev[curr.currency] = { currency: curr.currency, symbol: curr.symbol };
  return prev;
}, {});
export const CURRENCIES = Object.values(CURRENCIES_INDEXED);
const CurrencySelectSingle: FC<Omit<ListPickerProps<Currency>, 'label' | 'options' | 'optionTrackBy' | 'optionLabel'>> = props => {
  return (
    <ListPicker
      {...props}
      label={'Waluta'}
      options={CURRENCIES}
      optionTrackBy={'currency'}
      optionLabel={({ currency, symbol }) => `${currency} [${symbol}]`}
    />
  );
};

export default CurrencySelectSingle;
