import { usePropertyValues } from '../../Core/Hooks/usePropertyValues';
import { ClientOrder } from '@Ecommerce/ClientOrder';
import { Close, DeleteForeverRounded } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ClientOrderModule } from 'Modules/CTMModules';
import StoreValues from 'Modules/Ecommerce/Components/StoreValues';
import ListPicker from 'components/Form/ListPicker';
import DateTimePickerInput from 'components/Form/MUI/DateTimePickerInput';
import NumberInput from 'components/Form/MUI/NumberInput';
import { post } from 'helpers/Axios';
import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

interface SplitOrderProps {
  clientOrder: ClientOrder;
}

interface Order {
  realizedAtOffered: string;
  status: number;
  reservation: number;
  rows: OrderRow[];
}

interface OrderRow {
  productId: string;
  quantity: number;
}

const DefaultOrder: Order = {
  realizedAtOffered: '',
  status: 500,
  reservation: 0,
  rows: [],
};

const SplitOrderForm: FC<SplitOrderProps & { onClose: () => void }> = ({ clientOrder, onClose }) => {
  const dispatch = useDispatch();
  const { data: statuses } = usePropertyValues('ecommerce-client-order', 'status');
  const { data: reservations } = usePropertyValues('ecommerce-client-order', 'reservation');

  const defaultRows = useMemo<OrderRow[]>(() => {
    const rows: OrderRow[] = [];

    clientOrder.rows.forEach((item, index) => {
      if (item.product && typeof item.product !== 'string' && item.product.id) {
        rows[index] = { productId: item.product.id, quantity: 0 };
      }
    });

    return rows;
  }, [clientOrder]);

  const [orders, setOrders] = useState<Order[]>([
    { ...DefaultOrder, rows: [...defaultRows] },
    { ...DefaultOrder, rows: [...defaultRows] },
  ]);

  const onSubmit = (): void => {
    const data = {
      orderId: clientOrder.id,
      orders: orders,
    };

    post(ClientOrderModule.configuration.api.collection.split, data).then(() => {
      dispatch(
        addSingleToast({
          title: 'Zamówienie zostało podzielone.',
          config: { appearance: 'success' },
        }),
      );

      onClose();
    });
  };

  const onAdd = (): void => {
    if (orders.length > 11) {
      dispatch(
        addSingleToast({
          title: 'Dozwolone jest dzielenie zamówienia na maksymalnie 12 nowych.',
          config: { appearance: 'error' },
        }),
      );
      return;
    }

    setOrders(prevState => [...prevState, { ...DefaultOrder, rows: [...defaultRows] }]);
  };

  const onRemove = (index: number): void => {
    setOrders(prevState => prevState.filter((e, i) => i !== index));
  };

  const onUpdateQuantity = (index: number, productId: string, quantity: number | null): void => {
    const orderRowIndex: number = orders[index].rows.findIndex(item => item.productId === productId);

    setOrders(prevPositions => {
      const newItems = [...prevPositions];
      newItems[index].rows[orderRowIndex] = { ...newItems[index].rows[orderRowIndex], ...{ quantity: quantity ?? 0 } };

      return newItems;
    });
  };

  const onUpdateField = (index: number, name: string, value: number | string | null): void => {
    setOrders(prevState => {
      prevState[index] = { ...prevState[index], [name]: value };
      return prevState;
    });
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Stany magazynowe</TableCell>
            <TableCell>Obecne zamówienie</TableCell>
            {orders.map((item, index) => (
              <TableCell key={index}>
                Nowe zamówienie {index + 1}
                {index > 1 && (
                  <DeleteForeverRounded
                    style={{
                      color: '#b00020',
                      cursor: 'pointer',
                    }}
                    onClick={() => onRemove(index)}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ListPicker
                value={clientOrder.status}
                className={'_'}
                options={statuses ?? []}
                optionLabel={item => (
                  <Button
                    variant={'contained'}
                    style={{
                      width: '100%',
                      padding: '3px',
                      color: '#fff',
                      margin: '0 2px',
                      backgroundColor: item?.color,
                    }}
                  >
                    {item?.label}
                  </Button>
                )}
                optionTrackBy="value"
                disabled={true}
                name={''}
              />
            </TableCell>
            {orders.map((item, index) => (
              <TableCell key={index}>
                <ListPicker
                  value={item.status}
                  className={'_'}
                  options={statuses ?? []}
                  name={''}
                  optionTrackBy="value"
                  optionLabel={item => (
                    <Button
                      variant={'contained'}
                      style={{
                        width: '100%',
                        padding: '3px',
                        color: '#fff',
                        margin: '0 2px',
                        backgroundColor: item?.color,
                      }}
                    >
                      {item?.label}
                    </Button>
                  )}
                  onChange={(value, name) => onUpdateField(index, name ?? 'status', value)}
                />
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Status rezerewacji</TableCell>
            <TableCell></TableCell>
            <TableCell>
              <ListPicker
                value={clientOrder.reservation}
                className={'_'}
                options={reservations ?? []}
                name={''}
                optionLabel="label"
                optionTrackBy="value"
                disabled={true}
              />
            </TableCell>
            {orders.map((item, index) => (
              <TableCell key={index}>
                <ListPicker
                  value={item.reservation}
                  className={'_'}
                  options={reservations ?? []}
                  name={''}
                  optionLabel="label"
                  optionTrackBy="value"
                  onChange={(value, name) => onUpdateField(index, name ?? 'reservation', value)}
                />
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell></TableCell>
            <TableCell>{typeof clientOrder.realizedAtOffered === 'string' ? clientOrder.realizedAtOffered : null}</TableCell>
            {orders.map((item, index) => (
              <TableCell key={index}>
                <DateTimePickerInput
                  value={item.realizedAtOffered}
                  name="realizedAtOffered"
                  onChange={(value, name) => onUpdateField(index, name ?? 'realizedAtOffered', value)}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {clientOrder.rows.map(
            (row, rowIndex) =>
              row.product &&
              typeof row.product !== 'string' && (
                <TableRow key={rowIndex}>
                  <TableCell>
                    [{row.product.labelerId}]{row.product.name}
                  </TableCell>
                  <TableCell>
                    <StoreValues product={row.product} />
                  </TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  {orders.map(
                    (item, index) =>
                      row.product &&
                      typeof row.product !== 'string' && (
                        <TableCell key={index}>
                          <NumberInput
                            value={item.rows[rowIndex].quantity}
                            label={'Nowa ilość'}
                            name={'quantity' + index + rowIndex}
                            onChange={value => {
                              //@ts-ignore
                              onUpdateQuantity(index, row.product.id, value);
                            }}
                          />
                        </TableCell>
                      ),
                  )}
                </TableRow>
              ),
          )}
        </TableBody>
      </Table>
      <div className={'align-self-center justify-content-end text-end'}>
        <Button onClick={onAdd} className={'btn btn-info mt-2'}>
          <i className="bx bx-add-to-queue" /> Dodaj zamówienie
        </Button>
        <Button onClick={onSubmit} className={'btn btn-success mt-2 ml-1'}>
          <i className="bx bx-customize" /> Podziel zamówiene
        </Button>
      </div>
    </>
  );
};

const SplitOrder: FC<SplitOrderProps> = ({ clientOrder }) => {
  const [opened, setOpened] = useState<boolean>(false);

  if (!clientOrder.parent || typeof clientOrder.parent === 'string' || !clientOrder.parent.id) {
    return null;
  }

  return (
    <>
      {clientOrder.parent && (
        <Link className={'btn btn-success ml-1'} to={ClientOrderModule.showUrl(clientOrder.parent.id)}>
          Zlecenie główne {clientOrder.parent.number}
        </Link>
      )}
      {clientOrder.children.length < 1 && (
        <>
          <Button onClick={() => setOpened(true)} className={'btn btn-success ml-1'}>
            <i className="bx bx-customize" /> Podziel zamówiene
          </Button>
          <Modal
            isOpen={opened}
            fullscreen={'xl'}
            backdrop={true}
            toggle={() => setOpened(!opened)}
            centered={true}
            size={'lg'}
            style={{
              maxWidth: '80%',
            }}
          >
            <ModalBody>
              <Button style={{ position: 'absolute', top: 10, right: 4 }} onClick={() => setOpened(false)}>
                <Close />
              </Button>
              <SplitOrderForm clientOrder={clientOrder} onClose={() => setOpened(false)} />
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

export default SplitOrder;
