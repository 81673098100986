import React, { FC } from 'react';

interface LoaderProps {
  children?: any | React.ReactNode;
  iconsClasses?: string;
}

const Loader: FC<LoaderProps> = ({ children, iconsClasses = 'mdi-48px' }) => {
  return (
    <div className="text-center">
      <i className={`mdi mdi-spin mdi-loading ${iconsClasses}`} />
      <br />
      <span>{children ?? <>Trwa ładowanie...</>}</span>
    </div>
  );
};

export default Loader;
