import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { memo } from 'react';

const DividerRaw = ({ label }) => {
  return (
    <div className={'accordion-button not-arrow'} style={{ whiteSpace: 'pre-line' }}>
      {label}
    </div>
  );
};

export const Divider = memo(DividerRaw);

const DividerField: CustomFieldComponentType = props => {
  return <Divider label={props.field?.config?.label ?? ''} />;
};

export default DividerField;
