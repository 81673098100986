import { useSystemFields } from '../../SystemFields/useSystemFields';
import Loader from '@Components/Theme/Common/Loader';
import MathField, { DEFAULT_KEYBOARDS, DEFAULT_KEYBOARD_LAYERS } from 'components/Form/MathField';
import { useMemo } from 'react';

const MathTypeConfiguration = ({ configuration, onConfigurationUpdated, fields }) => {
  const { isLoading, error, data: allSystemFields } = useSystemFields();

  const updateConfiguration = (value, field) => {
    onConfigurationUpdated({ ...configuration, [field]: value });
  };

  const mathFields = () => fields.filter(el => ['NUMBER', 'MATH'].includes(el.type));

  const systemFields = () => {
    return (allSystemFields ?? []).filter(el => ['NUMBER', 'MATH'].includes(el.type));
  };

  const actionRow = [
    { class: 'separator w5' },
    { class: 'separator w5' },
    { class: 'separator w5' },
    { class: 'separator w5' },
    { class: 'separator w5' },
    { class: 'separator w5' },
    {
      class: 'action',
      label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
      command: ['performWithFeedback', 'moveToPreviousChar'],
    },
    {
      class: 'action',
      label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
      command: ['performWithFeedback', 'moveToNextChar'],
    },
    {
      class: 'action font-glyph bottom right',
      label: '&#x232b;',
      command: ['performWithFeedback', 'deleteBackward'],
    },
  ];

  const fieldsKeyboardAL = useMemo(() => {
    const allFieldButtons = mathFields()
      .map(el => ({
        latex: `\\htmlData{id=${el['id'] ?? '000000'}}{ \\text{${((el.name ?? '') === '' ? 'Uzupełnij nazwę pola' : el.name).normalize(
          'NFD',
        )}} }$$`,
        label: `${(el.name ?? '') === '' ? 'Uzupełnij nazwę pola' : el.name}`,
        class: 'calculator-field-btn w20',
      }))
      .filter(e => new RegExp(`^[a-l]`).test(e.label.toLowerCase()))
      .sort((a, b) => {
        const nameA = a.label.toLowerCase(); // ignore upper and lowercase
        const nameB = b.label.toLowerCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

    const fieldRows = allFieldButtons.reduce(
      (prev, curr) => {
        if (prev[prev.length - 1].length === 8) {
          prev.push([]);
        }

        prev[prev.length - 1].push(curr);

        return prev;
      },
      [[]],
    );

    const rows = [
      [
        {
          class: 'tex small',
          label: '<span><i>x</i>&thinsp;²</span>',
          insert: '$$#@^{2}$$',
        },
        { class: 'small', latex: '\\frac{#0}{#0}' },
        { latex: '\\times' },
        { latex: '\\div' },
        { latex: '(' },
        { latex: ')' },
        { latex: '-' },
        { latex: '+' },
      ],
      [],
      ...fieldRows,
      [],
    ];
    return {
      styles: '',
      rows: [...rows, actionRow],
    };
  }, [fields]);
  const fieldsKeyboardMZ = useMemo(() => {
    const allFieldButtons = mathFields()
      .map(el => ({
        latex: `\\htmlData{id=${el['id'] ?? '000000'}}{ \\text{${((el.name ?? '') === '' ? 'Uzupełnij nazwę pola' : el.name).normalize(
          'NFD',
        )}} }$$`,
        label: `${(el.name ?? '') === '' ? 'Uzupełnij nazwę pola' : el.name}`,
        class: 'calculator-field-btn w20',
      }))
      .filter(e => !new RegExp(`^[a-l]`).test(e.label.toLowerCase()))
      .sort((a, b) => {
        const nameA = a.label.toLowerCase(); // ignore upper and lowercase
        const nameB = b.label.toLowerCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

    const fieldRows = allFieldButtons.reduce(
      (prev, curr) => {
        if (prev[prev.length - 1].length === 8) {
          prev.push([]);
        }

        prev[prev.length - 1].push(curr);

        return prev;
      },
      [[]],
    );

    const rows = [
      [
        {
          class: 'tex small',
          label: '<span><i>x</i>&thinsp;²</span>',
          insert: '$$#@^{2}$$',
        },
        { class: 'small', latex: '\\frac{#0}{#0}' },
        { latex: '\\times' },
        { latex: '\\div' },
        { latex: '(' },
        { latex: ')' },
        { latex: '-' },
        { latex: '+' },
      ],
      [],
      ...fieldRows,
      [],
    ];
    return {
      styles: '',
      rows: [...rows, actionRow],
    };
  }, [fields]);

  const fieldsKeyboardSystem = useMemo(() => {
    const allFieldButtons = systemFields()
      .map(el => ({
        latex: `\\htmlData{id=${el['id'] ?? '000000'}}{ \\text{${((el.name ?? '') === '' ? 'Uzupełnij nazwę pola' : el.name).normalize(
          'NFD',
        )}} }$$`,
        label: `${(el.name ?? '') === '' ? 'Uzupełnij nazwę pola' : el.name}`,
        class: 'calculator-field-btn w20',
      }))
      .sort((a, b) => {
        const nameA = a.label.toLowerCase(); // ignore upper and lowercase
        const nameB = b.label.toLowerCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

    const fieldRows = allFieldButtons.reduce(
      (prev, curr) => {
        if (prev[prev.length - 1].length === 8) {
          prev.push([]);
        }

        prev[prev.length - 1].push(curr);

        return prev;
      },
      [[]],
    );

    const rows = [
      [
        {
          class: 'tex small',
          label: '<span><i>x</i>&thinsp;²</span>',
          insert: '$$#@^{2}$$',
        },
        { class: 'small', latex: '\\frac{#0}{#0}' },
        { latex: '\\times' },
        { latex: '\\div' },
        { latex: '(' },
        { latex: ')' },
        { latex: '-' },
        { latex: '+' },
      ],
      [],
      ...fieldRows,
      [],
    ];

    return {
      styles: '',
      rows: [...rows, actionRow],
    };
  }, [allSystemFields]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <MathField
        label="Obliczenia"
        mathFieldStyles={{ width: '100%' }}
        value={configuration?.math?.latex ?? null}
        name={'mathJSFormula'}
        id={'mathJSFormula'}
        keyboards={{
          ...DEFAULT_KEYBOARDS,
          'fields-keyboard-al': {
            label: 'Pola z modułu [A-L]',
            tooltip: 'Pola z modułu [A-L]',
            layer: 'fields-keyboard-al',
          },
          'fields-keyboard-mz': {
            label: 'Pola z modułu [M-Z]',
            tooltip: 'Pola z modułu [M-Z]',
            layer: 'fields-keyboard-mz',
          },
          'fields-keyboard-system': {
            label: 'Pola systemowe',
            tooltip: 'Pola systemowe',
            layer: 'fields-keyboard-system',
          },
        }}
        keyboardLayers={{
          ...DEFAULT_KEYBOARD_LAYERS,
          'fields-keyboard-al': fieldsKeyboardAL,
          'fields-keyboard-mz': fieldsKeyboardMZ,
          'fields-keyboard-system': fieldsKeyboardSystem,
        }}
        virtualKeyboards={'custom-ctm fields-keyboard-al fields-keyboard-mz fields-keyboard-system'}
        onChange={(v, name, { latexExpanded }) => {
          updateConfiguration(
            {
              latex: latexExpanded,
              _json: window.ce.parse(latexExpanded).json,
            },
            'math',
          );
        }}
      />
    </>
  );
};

export default MathTypeConfiguration;
