import { DeleteForeverRounded } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import { ProductModule } from 'Modules/CTMModules';
import StoreValues from 'Modules/Ecommerce/Components/StoreValues';
import NumberInput from 'components/Form/MUI/NumberInput';
import PlaceholderInput from 'components/Form/MUI/PlaceholderInput';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import RecordInputReader from 'components/Module/RecordInputReader';
import _ from 'lodash';
import { FC, memo, useEffect, useState } from 'react';


interface ProcurementOrderRowFormProps {
  item: any;
  readonly?: boolean;
  onChange: any;
  onRemove?: any;
  cellStyles: any;
  newRow: boolean;
  index: number;
  recordId?: string | null;
}

const ProcurementOrderRowForm: FC<ProcurementOrderRowFormProps> = ({
  item,
  readonly,
  onChange,
  onRemove,
  cellStyles,
  newRow,
  index,
  recordId,
}) => {
  const [row, setRow] = useState({ product: null, quantity: 0, ...item });

  useEffect(() => {
    if (!_.isEqual(row, item)) {
      onChange(index, row);
    }
  }, [row]);

  useEffect(() => {
    if (!_.isEqual(row, item)) {
      setRow({ product: null, quantity: 0, quantityProduced: 0, ...item });
    }
  }, [item]);

  const updateRowValue = (value: any, field: string | undefined | null) => {
    if (!field) {
      return;
    }

    setRow(prevRow => ({ ...prevRow, [field]: value }));
  };

  return (
    <>
      <TableRow>
        {!newRow && (
          <TableCell
            style={{
              position: 'relative',
              ...cellStyles,
            }}
          >
            <div style={{ paddingLeft: readonly ? 0 : 25 }}>{index !== undefined ? index + 1 : ''}</div>
            {!readonly && row.product && (
              <DeleteForeverRounded
                style={{
                  color: '#b00020',
                  position: 'absolute',
                  left: 15,
                  top: '50%',
                  cursor: 'pointer',
                  transform: 'translate(-50%,-50%)',
                }}
                onClick={() => onRemove(index)}
              />
            )}
          </TableCell>
        )}
        <TableCell
          style={{
            width: '30%',
            minWidth: 220,
            position: 'relative',
            ...cellStyles,
          }}
          colSpan={row.product ? 1 : 14}
        >
          <div>
            <ModuleListPicker
              disabled={readonly}
              moduleName={'manufacture-products'}
              onChange={gridRow =>
                setRow(prevState => {
                  return {
                    ...prevState,
                    product: gridRow,
                  };
                })
              }
              disableClear={true}
              defaultFilters={[]}
              overrideUrl={undefined}
              moduleFormComponent={undefined}
              moduleFormComponentProps={undefined}
              moduleFormWrapperProps={undefined}
              allowMultipleSelect={false}
              onMultipleSelect={console.error}
            >
              <RecordInputReader
                readonly={readonly}
                value={row.product}
                module={ProductModule}
                label={newRow ? 'Wybierz produkt aby dodać...' : 'Produkt'}
              />
            </ModuleListPicker>
          </div>
        </TableCell>
        {row.product && (
          <>
            <TableCell style={{ minWidth: 220, ...(cellStyles ?? {}) }}>
              <StoreValues product={row.product} />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'Symbol'} value={row?.product?.symbol} />
            </TableCell>
            <TableCell style={{ minWidth: 80, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'ID'} value={row?.product?.labelerId} />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'EAN'} value={row?.product?.ean} />
            </TableCell>
            <TableCell style={{ minWidth: 90, ...(cellStyles ?? {}) }}>
              <NumberInput
                value={row.quantity}
                label={'Ilość'}
                name={'quantity'}
                disabled={readonly}
                onChange={(value, field) => {
                  updateRowValue(value, field);
                }}
                inputProps={{ min: 1 }}
                namePrefix={'rows[' + index + ']'}
              />
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};

export default memo(ProcurementOrderRowForm);
