import { Field } from 'Modules/CustomFields/Field';
import { Product, RequiredProduct } from 'Modules/Manufacture/Product';
import { Technology } from 'Modules/Manufacture/Types/Technology';
import { Node as BaseNode, Edge, MarkerType } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';

export const TECHNOLOGY_BLOCK_INITIAL_HEIGHT = 2000;
export const TECHNOLOGY_BLOCK_INITIAL_WIDTH = 2000;
export const TECHNOLOGY_BLOCK_WIDTH = 200;
export const TECHNOLOGY_BLOCK_HEIGHT = 140;
export const TECHNOLOGY_BLOCK_WIDTH_RESERVED = TECHNOLOGY_BLOCK_WIDTH * 1.5;
export const TECHNOLOGY_BLOCK_HEIGHT_RESERVED = TECHNOLOGY_BLOCK_HEIGHT * 1.4;
export const PRODUCT_BLOCK_WIDTH = 250;
export const PRODUCT_BLOCK_HEIGHT = 140;
export const PRODUCT_BLOCK_HEIGHT_RESERVED = PRODUCT_BLOCK_HEIGHT * 1.1;
export const PRODUCT_BLOCK_WIDTH_RESERVED = PRODUCT_BLOCK_WIDTH * 1.2;

export const computeMaxFlowColumnWidthFactor = (edges: Edge[], currentNodeId: string): number => {
  const childrens = edges.filter(el => el.target === currentNodeId);

  const factor = childrens.map(el => {
    const elementChildrens = edges.filter(el2 => el2.target === el.source);
    if (elementChildrens.length === 0) {
      return 1;
    }

    return computeMaxFlowColumnWidthFactor(edges, el.source);
  });

  return factor.reduce((acc, el) => acc + el, 0);
};

export type ProductNodeData = {
  requiredProductId?: null | string;
  product: null | Product;
  productFilter: any;
  quantity: number;
  unit: any;
  label: string;
  root: boolean;
};

export type TechnologyNodeData = {
  label: string;
  ingredients?: boolean;
};

export type ProductNode = BaseNode<ProductNodeData, 'productNode'>;
export type TechnologyNode = BaseNode<TechnologyNodeData, 'technologyNode' | 'technologyNodeLabel' | 'group'>;
export type Node = ProductNode | TechnologyNode;

type GroupedNodes = {
  [key: string]: Node[];
};

export const alignNodesInTree = (
  productNodes: ProductNode[],
  groupNodes: TechnologyNode[],
  edges: Edge[],
  currentNodeId: string,
  offsetLeft: number,
  allignedInGroups: GroupedNodes,
) => {
  const currentNode: ProductNode | undefined = productNodes.find(el => el.id === currentNodeId);
  if (typeof currentNode?.position?.x !== 'undefined') {
    const currentGroupNode = groupNodes.find(el => el.id === currentNode?.parentId);
    const lowerGroupNodes = (
      currentNode.data.root ? groupNodes : groupNodes.filter(el => el.position.y >= (currentGroupNode?.position.y ?? 0))
    ).map(el => el.id);
    const technologyKey = currentNode?.parentId ?? '_';
    if (!allignedInGroups.hasOwnProperty(technologyKey)) {
      allignedInGroups[technologyKey] = [];
    }

    let x = 0;
    const childrens = edges
      .filter(el => el.target === currentNodeId)
      .sort((ed1, ed2) => {
        const node1 = productNodes.find(el => el.id === ed1.source);
        const node2 = productNodes.find(el => el.id === ed2.source);

        if (typeof node1?.data?.product?.technology != 'string' && typeof node2?.data?.product?.technology != 'string') {
          if ((node1?.data?.product?.technology?.position ?? 0) < (node2?.data?.product?.technology?.position ?? 0)) return -1;
          if ((node1?.data?.product?.technology?.position ?? 0) > (node2?.data?.product?.technology?.position ?? 0)) return 1;
          if ((node1?.position.x ?? 0) > (node2?.position.x ?? 0)) return 1;
          if ((node1?.position.x ?? 0) < (node2?.position.x ?? 0)) return -1;
        }
        return 0;
      });

    // if (currentNodeWidthFactor > 1) {
    //   x = offsetLeft + (PRODUCT_BLOCK_WIDTH_RESERVED * (currentNodeWidthFactor - 1)) / 2;
    // } else {
    //   x = offsetLeft;
    // }
    // if (currentNodeWidthFactor > 1) {
    //   x = offsetLeft + (PRODUCT_BLOCK_WIDTH_RESERVED * (currentNodeWidthFactor - 1)) / 2;
    // } else {
    //   x = offsetLeft;
    // }
    //
    // if (currentNodeWidthFactor > 1) {
    //   x = offsetLeft + (PRODUCT_BLOCK_WIDTH_RESERVED * (currentNodeWidthFactor - 1)) / 2;
    // x = (lastX > offsetLeft ? lastX : offsetLeft) + PRODUCT_BLOCK_WIDTH_RESERVED * (currentNodeWidthFactor - 1);
    // x = (lastX > offsetLeft ? lastX : offsetLeft) + PRODUCT_BLOCK_WIDTH_RESERVED * currentNodeWidthFactor;
    // x = offsetLeft;
    // } else {
    x = offsetLeft;
    // }

    // if (lastX === x) {
    //   x += PRODUCT_BLOCK_WIDTH_RESERVED;
    // }
    currentNode.position.x = x;
    if (!(currentNode?.data.root && typeof currentNode?.data.product?.technology === 'undefined')) {
      allignedInGroups[technologyKey].push(currentNode);
    }
    childrens.forEach((el, i) => {
      if (i === 0) {
        alignNodesInTree(productNodes, groupNodes, edges, el.source, offsetLeft, allignedInGroups);
      } else {
        alignNodesInTree(
          productNodes,
          groupNodes,
          edges,
          el.source,
          // offsetLeft + PRODUCT_BLOCK_WIDTH_RESERVED * i + PRODUCT_BLOCK_WIDTH_RESERVED * widthFactor,
          // offsetLeft +
          //   PRODUCT_BLOCK_WIDTH_RESERVED +
          //   (widthFactor > 1 ? PRODUCT_BLOCK_WIDTH_RESERVED * widthFactor : 0) +
          //   i * PRODUCT_BLOCK_WIDTH_RESERVED,
          // offsetLeft + PRODUCT_BLOCK_WIDTH_RESERVED * i,
          Object.keys(allignedInGroups).reduce(
            (acc, curr) =>
              Math.max(
                acc,
                Math.max(
                  offsetLeft,
                  ...allignedInGroups[curr].filter(el => lowerGroupNodes.includes(el?.parentId ?? '')).map(el => el.position.x),
                ),
              ),
            0,
          ) + PRODUCT_BLOCK_WIDTH_RESERVED,
          allignedInGroups,
        );
      }
    });
  }
};

export const buildIngredientsNodes = (technologies: Technology[]): TechnologyNode[] => [
  {
    id: '0',
    type: 'group',
    data: { label: 'Surowce', ingredients: true },
    position: { x: 0, y: (technologies.length + 1) * TECHNOLOGY_BLOCK_HEIGHT_RESERVED },
    draggable: false,
    deletable: false,
    selectable: true,
    connectable: false,
    zIndex: 10,
    style: {
      width: TECHNOLOGY_BLOCK_INITIAL_WIDTH,
      height: TECHNOLOGY_BLOCK_HEIGHT,
      background: 'rgba(0,0,0,.1)',
    },
  },
  {
    id: `0-label`,
    type: 'technologyNodeLabel',
    data: { label: 'Surowce', ingredients: true },
    position: {
      x: ((40 + PRODUCT_BLOCK_HEIGHT) * -1) / 2,
      y: (technologies.length + 1) * TECHNOLOGY_BLOCK_HEIGHT_RESERVED + (PRODUCT_BLOCK_HEIGHT - 40) / 2,
    },
    draggable: false,
    deletable: false,
    selectable: false,
    connectable: false,
    zIndex: 20000,
    style: {
      width: TECHNOLOGY_BLOCK_HEIGHT,
      height: 40,
      color: '#fff',
    },
  },
];

export const buildTechnologiesNodes = (technologies: Technology[]): TechnologyNode[] =>
  [].concat(
    //@ts-ignore
    ...technologies
      .sort((first, second) => second.position - first.position)
      .map<TechnologyNode[]>((el, i) => [
        {
          id: el['@id'],
          type: 'group',
          data: { label: el.name ?? '', ingredients: false },
          position: { x: 0, y: TECHNOLOGY_BLOCK_HEIGHT_RESERVED + i * TECHNOLOGY_BLOCK_HEIGHT_RESERVED },
          draggable: false,
          deletable: false,
          selectable: true,
          connectable: false,
          zIndex: 10,
          style: {
            width: TECHNOLOGY_BLOCK_INITIAL_WIDTH,
            height: TECHNOLOGY_BLOCK_HEIGHT,
            background: 'rgba(0,0,0,.1)',
          },
        },
        {
          id: `${el['@id']}-label`,
          type: 'technologyNodeLabel',
          selectable: false,
          data: { label: el.name ?? '', ingredients: false },
          position: {
            x: ((60 + PRODUCT_BLOCK_HEIGHT) * -1) / 2,
            y: TECHNOLOGY_BLOCK_HEIGHT_RESERVED + i * TECHNOLOGY_BLOCK_HEIGHT_RESERVED + (PRODUCT_BLOCK_HEIGHT - 60) / 2,
          },
          draggable: false,
          deletable: false,
          connectable: false,
          zIndex: 10,
          style: {
            width: TECHNOLOGY_BLOCK_HEIGHT,
            height: 60,
            color: '#fff',
          },
        },
      ]),
  );

export const buildTreeLayout = (nodes: Node[], edges: Edge[]) => {
  if (nodes.length === 0) return { nodes, edges };

  const rootNode: ProductNode = nodes.find(el => el.type === 'productNode' && (el as ProductNode).data.root) as ProductNode;
  const productNodes: ProductNode[] = nodes.filter(el => el.type === 'productNode') as ProductNode[];
  const groupNodes: TechnologyNode[] = nodes
    .filter(el => el.type === 'group')
    .sort((first, second) => first.position.y - second.position.y) as TechnologyNode[];

  alignNodesInTree(productNodes, groupNodes, edges, rootNode?.id ?? '', PRODUCT_BLOCK_WIDTH_RESERVED, {});
  const widthFactor = computeMaxFlowColumnWidthFactor(edges, rootNode?.id ?? '');
  groupNodes.forEach((el, i) => {
    if (typeof el?.style?.width !== 'undefined') {
      el.style = { ...el.style, width: PRODUCT_BLOCK_WIDTH_RESERVED * (widthFactor + 2) };
    }
  });
  return {
    nodes,
    edges,
  };
};

export const buildMapFromTechnologies = (technologies: Technology[]): TechnologyNode[] => {
  return [...buildIngredientsNodes(technologies), ...buildTechnologiesNodes(technologies)];
};

export type TreeIngredients = { nodes: Node[]; edges: Edge[] };
export const buildTreeFromProduct = (requiredProduct: RequiredProduct, tree: TreeIngredients, requiredById: string | null): void => {
  const isRoot = null === requiredById;
  const productNode: ProductNode = {
    id: uuidv4(),
    type: 'productNode',
    parentId: isRoot ? null : requiredProduct?.product?.technology?.['@id'] ?? '0',
    extent: 'parent',
    data: {
      label: requiredProduct.name ?? '',
      root: isRoot,
      product: requiredProduct.product,
      productFilter: requiredProduct.productFilter,
      unit: requiredProduct.unit ?? requiredProduct.product?.unit ?? null,
      quantity: requiredProduct.quantity,
    },
    position: { x: 0, y: 0 },
    draggable: !isRoot,
    selectable: !isRoot,
    connectable: true,
    zIndex: 100,
    style: {
      width: PRODUCT_BLOCK_WIDTH,
      height: PRODUCT_BLOCK_HEIGHT,
      background: '#fff',
      color: '#303030',
    },
  };
  tree.nodes.push(productNode);

  if (requiredById) {
    const edge: Edge = {
      id: uuidv4(),
      source: productNode.id,
      target: requiredById,
      type: 'smoothstep',
      pathOptions: {
        borderRadius: 40,
      },
      deletable: false,
      animated: true,
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
    };
    tree.edges.push(edge);
  }

  requiredProduct.product?.requiredProducts.forEach(el => buildTreeFromProduct(el, tree, productNode.id));
};

export const extractTechnologiesFromProduct = (product: Product): string[] => {
  const technologies: string[] = [];
  if (product.technology) {
    technologies.push(product.technology['@id']);
  }
  const requiredProducts = product.requiredProducts ?? [];
  requiredProducts.forEach(el => {
    if (el.product) {
      technologies.push(...extractTechnologiesFromProduct(el.product));
    }
  });
  return technologies;
};

export const getSystemFieldConfiguration = (fields: Field[], propertyPath: string): Field | undefined =>
  fields.find(el => el.propertyPath === propertyPath);

export const updateSystemFieldValueOnProduct = (
  product: Product,
  fields: Field[],
  propertyPath,
  val: any,
  selectedLanguage: string,
): Product => {
  const fieldConfiguration = getSystemFieldConfiguration(fields, propertyPath);

  const newObj: Product = {
    ...product,
    '@formValues': { ...(product['@formValues'] ?? {}) },
  };

  let hasMultiLanguage = false;
  if (fieldConfiguration !== undefined) {
    if (fieldConfiguration.multiLanguage) {
      hasMultiLanguage = true;
      newObj['@formValues'] = {
        ...newObj['@formValues'],
        [fieldConfiguration.id ?? '']: {
          '@ContentTranslation': true,
          value: {
            ...newObj['@formValues']?.[fieldConfiguration.id ?? '']?.value,
            [selectedLanguage]: val,
          },
        },
      };
    } else {
      newObj['@formValues'] = { ...newObj['@formValues'], [fieldConfiguration.id ?? '']: val };
    }

    if (!hasMultiLanguage || (hasMultiLanguage && selectedLanguage === 'pl')) {
      newObj[propertyPath] = val;
    }
  }
  return newObj;
};
export default {};
