import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type LightboxState = {
  toggler: boolean;
  images: string[];
};

const initialState = (): LightboxState => ({
  toggler: false,
  images: [],
});

export const Lightbox = createSlice({
  initialState: initialState(),
  name: 'lightbox',
  reducers: {
    setImages: (state, action: PayloadAction<{ images: string[] }>) => {
      state.images = action.payload.images;
    },
    toggle: state => {
      state.toggler = !state.toggler;
    },
  },
});

export default Lightbox.reducer;

export const { setImages, toggle } = Lightbox.actions;
