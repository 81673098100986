import { FieldModule } from '../../CTMModules';
import FieldConfiguration from '../ConfigurationComponents/FieldConfiguration';
import { Col, Container } from 'components/Theme/Grid';
import { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

function FieldConfigurationModal(props) {
  const { defaultOpened, afterClose, title, fields, moduleId, onSave, field } = props;
  const [opened, setOpened] = useState<boolean>(defaultOpened ?? false);

  useEffect(() => {
    setOpened(defaultOpened);
  }, [defaultOpened]);

  useEffect(() => {
    if (!opened) {
      afterClose && afterClose();
    }
  }, [opened]);
  return (
    <Modal isOpen={opened} centered={true} backdrop={true} toggle={() => false} size="lg">
      <ModalBody>
        <Container>
          <Col xs={12}>
            <h4 style={{ textAlign: 'center' }}>{title ?? 'Konfiguracja pola'}</h4>
          </Col>
        </Container>
        <FieldConfiguration onClose={() => setOpened(false)} fields={fields} field={field} moduleId={moduleId} onSave={onSave} />
      </ModalBody>
    </Modal>
  );
}

export function NewFieldModal(props) {
  const { children, fields, moduleId, afterSave } = props;
  const [opened, setOpened] = useState<boolean>(false);

  const handleSave = newFieldData => {
    const data = { ...newFieldData, module: `/custom-fields/modules/${moduleId}` };
    FieldModule.api
      .post(data)
      .then(res => {
        setOpened(false);
        afterSave();
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <>
      <div onClick={() => setOpened(true)}>{children}</div>
      {opened && (
        <FieldConfigurationModal
          defaultOpened={true}
          title={'Dodaj nowe pole'}
          afterClose={() => setOpened(false)}
          fields={fields}
          moduleId={moduleId}
          onSave={handleSave}
          field={{
            id: uuidv4(),
            position: 1,
            name: '',
            type: 'STRING',
            size: { xs: undefined, sm: undefined, md: 6, lg: undefined },
          }}
        />
      )}
    </>
  );
}
export function EditFieldModal(props) {
  const { field: givenField, children, fields, moduleId, afterSave } = props;
  const [opened, setOpened] = useState<boolean>(false);

  const handleSave = newFieldData => {
    const data = { ...newFieldData };
    FieldModule.api
      .put(data, { id: givenField.id })
      .then(res => {
        setOpened(false);
        afterSave();
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <>
      <div onClick={() => setOpened(true)}>{children}</div>
      {opened && (
        <FieldConfigurationModal
          defaultOpened={true}
          title={`Edytuj pole "${givenField.name}"`}
          afterClose={() => setOpened(false)}
          fields={fields}
          moduleId={moduleId}
          onSave={handleSave}
          field={givenField}
        />
      )}
    </>
  );
}

export default FieldConfigurationModal;
