import ModuleListScanner from '@Components/Module/ModuleListScanner';
import CurrencyExchange from '@Core/Services/CurrencyExchange';
import { ProductGroupModule, ProductModule } from '@Modules/CTMModules';
import { DeleteForeverRounded } from '@mui/icons-material';
import { InputAdornment, TableCell, TableRow } from '@mui/material';
import { TARIFF_TYPE_QUANTITY } from 'Modules/Contractor/Contractor';
import StoreValues from 'Modules/Ecommerce/Components/StoreValues';
import { CURRENCIES_INDEXED } from 'components/Form/CurrencySelectSingle';
import NumberInput from 'components/Form/MUI/NumberInput';
import PlaceholderInput from 'components/Form/MUI/PlaceholderInput';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import RecordInputReader from 'components/Module/RecordInputReader';
import { get } from 'helpers/Axios';
import _ from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';

export const DefaultProductRow = {
  product: null,
  unit: null,
  vat: null,
  quantity: 1,
  unitPriceNet: 0,
  unitPriceGross: 0,
};

const FormProductRow = ({ item, readonly, onChange, onRemove, cellStyles, newRow, contractor, index, currency, exchangeDate }) => {
  const [row, setRow] = useState({ ...DefaultProductRow, ...item });
  const firstRender = useRef(true);
  const [lastTariff, setLastTariff] = useState(null);
  const dispatch = useDispatch();
  const isHomeCurrency = useMemo(() => currency === 'PLN', [currency]);
  const computedCellStyles = useMemo(
    () => ({
      position: 'relative',
      ...cellStyles,
    }),
    [JSON.stringify(cellStyles)],
  );
  const [currencyExchange, setCurrencyExchange] = useState(null);
  const [lastSalePrice, setLastSalePrice] = useState(0);

  useEffect(() => {
    if (!_.isEqual(row, item)) {
      onChange(index, row);
    }
  }, [row, item]);

  // useEffect(() => {
  //   if (!_.isEqual(JSON.stringify(row), JSON.stringify(item))) {
  //     setRow({ ...DefaultProductRow, ...item });
  //   }
  // }, [item]);

  useEffect(() => {
    const firstSet = currencyExchange === null;
    CurrencyExchange.getCurrencyExchangeRate('PLN', currency, exchangeDate).then(result => {
      setCurrencyExchange(result);
      if (!firstSet && !readonly) {
        setTimeout(() => {
          setRow(prevRow => {
            const fifoPrice = currency === 'PLN' ? prevRow.product?.fifoPrice ?? 0 : result.exchangeSell(prevRow.product?.fifoPrice ?? 0);
            const tmpRow = { ...prevRow };
            const vat = parseInt(prevRow?.vat?.value ?? 0);

            const newUnitPriceNet = (fifoPrice ?? row.unitPriceGross) * (1 + tmpRow.margin / 100) * 100;
            tmpRow.unitPriceNet = newUnitPriceNet;
            tmpRow.unitPriceGross = parseFloat(newUnitPriceNet * (1 + vat / 100));

            return tmpRow;
          });
        }, 50);
      }
    });
  }, [currency, exchangeDate]);

  useEffect(() => {
    if (!contractor || !item.product) {
      return;
    }

    const paramString = new URLSearchParams({
      ['product.id']: item.product.id,
      ['order.client.id']: contractor.id,
      ['order[order.createdAt]']: 'desc',
      ['order.status']: 3000,
      limit: 1,
    }).toString();

    get(`ecommerce/client-order-rows?${paramString}`).then(result => {
      const item = result['hydra:member'][0] ?? null;
      setLastSalePrice(item ? item.unitPriceNet / 100 : 0);
    });
  }, [contractor, item.product]);

  const updateRowValue = (value, field) => {
    if (readonly) {
      return;
    }
    setRow(prevRow => ({ ...prevRow, [field]: value }));
  };

  const updateMargin = useCallback(
    value => {
      if (readonly) {
        return;
      }
      setRow(prevRow => {
        const fifoPrice = isHomeCurrency ? prevRow.product?.fifoPrice ?? 0 : currencyExchange.exchangeSell(prevRow.product?.fifoPrice ?? 0);
        const tmpRow = { ...prevRow };
        const vat = parseInt(prevRow?.vat?.value ?? 0);
        tmpRow.margin = value;

        const newUnitPriceNet = (fifoPrice ?? row.unitPriceGross) * (1 + tmpRow.margin / 100) * 100;
        tmpRow.unitPriceNet = newUnitPriceNet;
        tmpRow.unitPriceGross = parseFloat(newUnitPriceNet * (1 + vat / 100));

        return tmpRow;
      });
    },
    [isHomeCurrency, currencyExchange, readonly],
  );

  const updatePricePart = useCallback(
    (value, field, calculateMargin = true) => {
      if (readonly) {
        return;
      }

      setRow(prevRow => {
        const tmpRow = { ...prevRow };
        const vat = parseInt(value?.value ?? prevRow?.vat?.value ?? 0);

        if (field === 'unitPriceNet') {
          tmpRow.unitPriceNet = value;
          tmpRow.unitPriceGross = parseFloat(value * (1 + vat / 100));
        } else if (field === 'unitPriceGross') {
          tmpRow.unitPriceGross = value;
          tmpRow.unitPriceNet = parseFloat((value * 100) / (100 + vat));
        } else if (field === 'vat') {
          tmpRow.vat = value;
          tmpRow.unitPriceGross = parseFloat(tmpRow.unitPriceNet * (1 + vat / 100));
        }
        if (calculateMargin && tmpRow.product?.fifoPrice > 0) {
          const fifoPrice = isHomeCurrency ? tmpRow.product?.fifoPrice : currencyExchange.exchangeSell(tmpRow.product?.fifoPrice ?? 0.0);
          tmpRow.margin = parseFloat(((tmpRow.unitPriceNet - fifoPrice * 100) / (fifoPrice * 100)).toFixed(2)) * 100;
        }

        return tmpRow;
      });
    },
    [readonly, isHomeCurrency, currencyExchange],
  );

  const setUnitPriceNet = (quantity, vatValue = null) => {
    if (readonly) {
      return;
    }
    const tariff =
      contractor?.accountancy?.tariffType === TARIFF_TYPE_QUANTITY
        ? (row?.product?.priceValues?.priceQuantities ?? []).find(
            item => item.from && quantity >= item.from && item.to && quantity <= item.to,
          )?.tariff ?? contractor?.tariff
        : contractor?.tariff;

    setLastTariff(tariff);

    if (JSON.stringify(tariff) === JSON.stringify(lastTariff)) {
      return;
    } else {
      dispatch(
        addSingleToast({
          title: `Nastąpiła automatyczna zmiana cennika dla towaru ${row?.product?.name}`,
          config: { appearance: 'info' },
        }),
      );
    }

    const vat = vatValue ?? parseInt(row?.vat?.value ?? 0);

    const fifoPrice = isHomeCurrency ? row.product?.fifoPrice ?? 0 : currencyExchange?.exchangeSell(row.product?.fifoPrice ?? 0);

    setRow(prevRow => ({
      ...prevRow,
      unitPriceNet: Math.round(fifoPrice * 100 * (1 + (tariff?.profit ?? 0) / 100)).toFixed(2),
      unitPriceGross: Math.round(fifoPrice * 100 * (1 + (tariff?.profit ?? 0) / 100) * (1 + vat / 100)).toFixed(2),
      margin: tariff?.profit ?? 0,
    }));
  };

  useEffect(() => {
    if (readonly) {
      return;
    }
    if (firstRender.current) {
      firstRender.current = false;
      setLastTariff(
        contractor?.accountancy?.tariffType === TARIFF_TYPE_QUANTITY
          ? (row?.product?.priceValues?.priceQuantities ?? []).find(
              item => item.from && row.quantity >= item.from && item.to && row.quantity <= item.to,
            )?.tariff ?? contractor?.tariff
          : contractor?.tariff,
      );
      return;
    }

    setRow(prevState => ({ ...prevState, vat: contractor?.valueAddedTax }));
    if (row.product) {
      setUnitPriceNet(row.quantity, contractor?.valueAddedTax?.value);
    }
  }, [contractor?.['@id']]);

  const handleProductSelect = useCallback(
    gridRow => {
      setRow(prevState => {
        const tmpTariff =
          contractor?.accountancy?.tariffType === TARIFF_TYPE_QUANTITY
            ? (gridRow?.priceValues?.priceQuantities ?? []).find(
                item => item.from && prevState.quantity >= item.from && item.to && prevState.quantity <= item.to,
              )?.tariff ?? contractor?.tariff
            : contractor?.tariff;

        setLastTariff(tmpTariff);
        const unitPriceNet = Math.round((gridRow?.fifoPrice ?? 0) * 100 * (1 + (tmpTariff?.profit ?? 0) / 100));
        const unitPriceGross = Math.round(
          (gridRow?.fifoPrice ?? 0) * 100 * (1 + (tmpTariff?.profit ?? 0) / 100) * (1 + (contractor?.valueAddedTax?.value ?? 0) / 100),
        );

        return {
          ...prevState,
          product: gridRow,
          unitPriceNet: isHomeCurrency ? unitPriceNet : currencyExchange?.exchangeSell(unitPriceNet).toFixed(0),
          unitPriceGross: isHomeCurrency ? unitPriceGross : currencyExchange?.exchangeSell(unitPriceGross).toFixed(0),
          unit: gridRow?.unit ?? null,
          margin: tmpTariff?.profit ?? 0,
        };
      });
    },
    [contractor],
  );

  const handleUnitChange = useCallback(gridRow => setRow(prev => ({ ...prev, unit: gridRow })), []);

  const handleVatChange = useCallback(record => updatePricePart(record, 'vat'), [updatePricePart]);

  const inputCurrencyAdornment = useMemo(() => {
    return {
      min: 0,
      endAdornment: <InputAdornment position="end">{CURRENCIES_INDEXED[currency]?.symbol}</InputAdornment>,
    };
  }, [currency]);

  const handleUnitPriceGrossChange = useCallback(
    (value, field) => updatePricePart(parseInt(Math.round(parseFloat(value) * 100)), field),
    [updatePricePart],
  );
  return (
    <>
      <TableRow>
        {!newRow && (
          <TableCell style={computedCellStyles}>
            <div style={{ paddingLeft: readonly ? 0 : 26 }}>{index !== undefined ? index + 1 : ''}</div>
            {!readonly && row.product && (
              <DeleteForeverRounded
                style={{
                  color: '#b00020',
                  position: 'absolute',
                  left: 15,
                  top: '50%',
                  cursor: 'pointer',
                  transform: 'translate(-50%,-50%)',
                }}
                onClick={() => onRemove(index)}
              />
            )}
          </TableCell>
        )}
        <TableCell
          style={{
            minWidth: 160,
            position: 'relative',
            ...cellStyles,
          }}
          colSpan={row.product ? 1 : 15}
        >
          <ModuleListScanner
            showColumns={[
              'labelerId',
              'name',
              'symbol',
              'ean',
              'species.id',
              'lastSupplyPrice',
              'productQuantity.places',
              'group.id',
              'type.id',
            ]}
            disabled={readonly}
            moduleName={'manufacture-products'}
            onChange={handleProductSelect}
            disableClear={true}
            forceFocus={true}
            inputScannerLabel={'Wprowadź EAN/Symbol'}
            filterFields={['orEan', 'orSymbol']}
            defaultFilters={[{ id: 'active', value: true }]}
            onClickReset={false}
            isSelected={!!row.product}
          >
            <RecordInputReader
              namePrefix={'rows[' + index + ']'}
              name={'product'}
              readonly={readonly}
              value={row.product}
              module={ProductModule}
              label={'Produkt'}
            />
          </ModuleListScanner>
        </TableCell>
        {row.product && (
          <>
            <TableCell style={{ minWidth: 220, ...(cellStyles ?? {}) }}>
              <StoreValues product={row.product} />
            </TableCell>
            <TableCell style={{ minWidth: 180, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'Symbol'} value={row?.product?.symbol} />
            </TableCell>
            <TableCell style={{ minWidth: 80, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'ECM'} value={row?.product?.labelerId} />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <RecordInputReader readonly={true} value={row?.product?.group} module={ProductGroupModule} label={'Grupa'} />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'EAN'} value={row?.product?.ean} />
            </TableCell>
            <TableCell style={{ minWidth: 80, ...(cellStyles ?? {}) }}>
              <ModuleListPicker
                disabled={readonly}
                moduleName={'manufacture-units'}
                disableClear={!row.unit}
                onChange={handleUnitChange}
                label={'Jednotska'}
                fallback={'Szt'}
                readerValue={row?.unit}
              />
            </TableCell>
            <TableCell style={{ minWidth: 90, ...(cellStyles ?? {}) }}>
              <NumberInput
                value={row.quantity}
                label={'Ilość'}
                name={'quantity'}
                disabled={readonly}
                onChange={(value, field) => {
                  updateRowValue(value, field);
                  setUnitPriceNet(value);
                }}
                inputProps={{ min: 1 }}
                namePrefix={'rows[' + index + ']'}
              />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <NumberInput
                value={lastSalePrice.toFixed(2)}
                label={'Cena z ost. sprzedaży'}
                name={'lastSalePrice'}
                disabled={true}
                onChange={() => false}
                inputProps={inputCurrencyAdornment}
              />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <NumberInput
                value={
                  isHomeCurrency
                    ? (row.product?.fifoPrice ?? 0).toFixed(2)
                    : currencyExchange?.exchangeSell(row.product?.fifoPrice ?? 0).toFixed(2)
                }
                label={'FIFO Netto'}
                name={'fifo'}
                disabled={true}
                inputProps={inputCurrencyAdornment}
              />
            </TableCell>
            <TableCell style={{ minWidth: 90, ...(cellStyles ?? {}) }}>
              <NumberInput
                value={row.margin}
                label={'Marża %'}
                name={'margin'}
                disabled={readonly}
                onChange={updateMargin}
                inputProps={{ min: 0 }}
                namePrefix={'rows[' + index + ']'}
                forcedErrorMessage={row.margin < 0 ? 'Cena poniżej wartości FIFO!' : undefined}
              />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <NumberInput
                value={(row.unitPriceNet / 100).toFixed(2)}
                label={'Cena jdn. netto'}
                name={'unitPriceNet'}
                disabled={readonly}
                onChange={(value, field) => {
                  updatePricePart(parseInt(Math.round(Number(value) * 100)), field);
                }}
                inputProps={inputCurrencyAdornment}
                namePrefix={'rows[' + index + ']'}
              />
            </TableCell>
            <TableCell style={{ minWidth: 70, ...(cellStyles ?? {}) }}>
              <ModuleListPicker
                disabled={readonly}
                moduleName={'core-accountancy-value-added-taxes'}
                disableClear={!row.vat}
                onChange={handleVatChange}
                label={'Stawka VAT'}
                readerValue={row?.vat}
                name={`rows[${index}].vat`}
              />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <NumberInput
                value={(row.unitPriceGross / 100).toFixed(2)}
                label={'Cena jdn. brutto'}
                name={'unitPriceGross'}
                disabled={readonly}
                onChange={handleUnitPriceGrossChange}
                inputProps={inputCurrencyAdornment}
                namePrefix={'rows[' + index + ']'}
              />
            </TableCell>
          </>
        )}
      </TableRow>
      {row.product && currency !== 'PLN' && (
        <TableRow>
          <>
            <TableCell colSpan={9}></TableCell>
            <TableCell style={{ minWidth: 90, ...(cellStyles ?? {}) }}>&nbsp;</TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <NumberInput
                value={(row.product?.fifoPrice ?? 0).toFixed(2)}
                label={'FIFO Netto'}
                name={'fifo'}
                disabled={true}
                onChange={() => false}
                inputProps={{
                  endAdornment: <InputAdornment position="end">{CURRENCIES_INDEXED['PLN']?.symbol}</InputAdornment>,
                }}
              />
            </TableCell>
            <TableCell style={{ minWidth: 90, ...(cellStyles ?? {}) }}>&nbsp;</TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <NumberInput
                value={currencyExchange?.exchangeBuy(row.unitPriceNet / 100).toFixed(2)}
                label={'Cena jdn. netto'}
                name={'unitPriceNetHomeCurrency'}
                disabled={true}
                onChange={() => false}
                inputProps={{
                  min: 0,
                  endAdornment: <InputAdornment position="end">{CURRENCIES_INDEXED['PLN']?.symbol}</InputAdornment>,
                }}
                namePrefix={'rows[' + index + ']'}
              />
            </TableCell>
            <TableCell style={{ minWidth: 70, ...(cellStyles ?? {}) }}>&nbsp;</TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <NumberInput
                value={currencyExchange?.exchangeBuy(row.unitPriceGross / 100).toFixed(2)}
                label={'Cena jdn. brutto'}
                name={'unitPriceGrossHomeCurrency'}
                disabled={true}
                onChange={() => false}
                inputProps={{
                  min: 0,
                  endAdornment: (
                    <InputAdornment position="end" className={'test'}>
                      {CURRENCIES_INDEXED['PLN']?.symbol}
                    </InputAdornment>
                  ),
                }}
                namePrefix={'rows[' + index + ']'}
              />
            </TableCell>
          </>
        </TableRow>
      )}
    </>
  );
};

export default memo(FormProductRow);
