import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import StringField from 'components/CustomFields/FieldTypes/StringField';
import { Alert } from 'reactstrap';

const FormatField = params => {
  return (
    <>
      <StringField {...params} />
      <Alert color="info">
        Aby zapisać własny format numeracji dokumentów , stwórz wzór w oparciu o generowane automatycznie wartości:
        <br />
        <br />
        <ul>
          <li>
            Numer dokumentu - wymagany
            <ul>
              <li>
                Liczony uwzględniając wszystkie typy
                <ul>
                  <li>%Ny% - numer dokumentu w roku</li>
                  <li>%Nm% - numer dokumentu w miesiącu</li>
                  <li>%Nd% - numer dokumentu w dniu</li>
                </ul>
              </li>
              <li>
                Liczony uwzględniając tylko aktualny typ dokumentu
                <ul>
                  <li>%ny% - numer dokumentu w roku</li>
                  <li>%nm% - numer dokumentu w miesiącu</li>
                  <li>%nd% - numer dokumentu w dniu</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Rok
            <ul>
              <li>%R% - pełny (np. 2022)</li>
              <li>%r% - dwie ostatnie cyfry (np. 22)</li>
            </ul>
          </li>
          <li>
            Miesiąc
            <ul>
              <li>%M% - z zerem (np. 06)</li>
              <li>%m% - bez zera (np. 6)</li>
            </ul>
          </li>
          <li>
            Dzień
            <ul>
              <li>%D% - z zerem (np. 01)</li>
              <li>%d% - bez zera (np. 1)</li>
            </ul>
          </li>
          <li>
            Typ dokumentu
            <ul>
              <li>%DTs% - kod typu dokumentu (np. PZ)</li>
            </ul>
          </li>
        </ul>
        <br />
        Przykłady:
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">Format</TableCell>
              <TableCell variant="head">Wynik</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>%DTs%/%R%/%M%/%nm%</TableCell>
              <TableCell>PZ/2022/06/12345</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>%DTs%/%r%/%m%/%nm%</TableCell>
              <TableCell>PZ/22/6/12345</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>%R%/%Ny%</TableCell>
              <TableCell>2022/54214</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Alert>
    </>
  );
};

export default FormatField;
