import { Task } from '../../Types/Task';
import AmountColumn from '../List/AmountColumn';
import StatusPicker from '../List/StatusPicker';
import UserPicker from '../List/UserPicker';
import AmountChangeModal from './AmountChange/AmountChangeModal';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FC, useState } from 'react';

const TaskModalColumnMain: FC<{ task: Task }> = ({ task }) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Ogólne informacje" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Status:</TableCell>
                <TableCell>
                  <StatusPicker id={task.id} value={task.status} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pracownik:</TableCell>
                <TableCell>
                  <UserPicker task={task} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Stan:</TableCell>
                <TableCell>
                  <AmountColumn key={task.id} task={task} />
                  <AmountChangeModal defaultOpened={false} taskIRI={task['@id']}>
                    <Button variant={'contained'} color={'success'} size={'small'} fullWidth={true}>
                      Zmień ilość
                    </Button>
                  </AmountChangeModal>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </List>
      </Collapse>
    </>
  );
};

export default TaskModalColumnMain;
