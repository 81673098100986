import { Employee } from 'Modules/Employee/Model';
import { useAppSelector } from 'store';

interface RootState {
  Login: {
    user?: {
      details: Employee;
      exp: number;
      iat: number;
      roles: string[];
    };
  };
}

export default function useCurrentUser() {
  return useAppSelector(state => state.Login.user?.details);
}
