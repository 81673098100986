import { ProductModule } from '../../CTMModules';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const ProductSymbol = 'PACKING_SHIPMENT';
const PackingAndShipmentRowPositionButton = ({ values, onChange, fields }) => {
  const rowsFieldId = fields.find(el => el.propertyPath === 'rows')?.id;
  const contractorFieldId = fields.find(el => el.propertyPath === 'contractor')?.id;
  const costOfShipmentNetFieldId = fields.find(el => el.propertyPath === 'costOfShipmentNet')?.id;
  const costOfPackingNetFieldId = fields.find(el => el.propertyPath === 'costOfPackingNet')?.id;
  const costOfPalletNetFieldId = fields.find(el => el.propertyPath === 'costOfPalletNet')?.id;
  const addOrUpdatePosition = async () => {
    const hasPackingPosition = values?.[rowsFieldId]?.some(el => el.product?.symbol === ProductSymbol);
    const value = values?.[costOfShipmentNetFieldId] + values?.[costOfPackingNetFieldId] + values?.[costOfPalletNetFieldId];

    if (!hasPackingPosition) {
      const packingProducts = (await ProductModule.api.getAll({ params: { symbol: ProductSymbol, active: true } }))['hydra:member'];

      if (packingProducts.length === 0) {
        const newPackingProduct = await ProductModule.api.post({
          symbol: ProductSymbol,
          name: 'Pakowanie i wysyłka',
          active: true,
        });

        packingProducts.push(newPackingProduct);
      }
      const defaultVat = values?.[contractorFieldId]?.valueAddedTax ?? null;

      const newRow = {
        seq: values?.[rowsFieldId]?.length ?? 0,
        product: packingProducts[0],
        quantity: 1,
        vat: defaultVat,
        unitPriceNet: Math.round(value * 100),
        unitPriceGross: Math.round(value * 100 * (1 + (defaultVat?.value ?? 0) / 100)),
        margin: 0,
      };
      onChange([...(values?.[rowsFieldId] ?? []), newRow], rowsFieldId);
    } else {
      onChange(
        values?.[rowsFieldId].map(el => {
          if (el.product?.symbol === ProductSymbol) {
            return {
              ...el,
              unitPriceNet: Math.round(value * 100),
              unitPriceGross: Math.round(value * 100 * 1.23),
            };
          } else {
            return el;
          }
        }),
        rowsFieldId,
      );
    }
  };
  return (
    <Tooltip title="Dodaje/aktualizuje koszt pakowania/wysyłki jako pozycję zamówienia" placement="right">
      <Button variant="contained" color="primary" onClick={addOrUpdatePosition} disabled={false}>
        <i className="fa fa-plus-circle" />
      </Button>
    </Tooltip>
  );
};

export default PackingAndShipmentRowPositionButton;
