import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SendIcon from '@mui/icons-material/Send';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { Backdrop, Badge, Box, CircularProgress, IconButton, TextField, Tooltip } from '@mui/material';
import { NewMessageModel } from 'Modules/Chat/Types/Api';
import { Media } from 'Modules/Core/Types/ApiModel';
import { AxiosError, AxiosResponse } from 'axios';
import * as url from 'helpers/Api/Url';
import { axiosApi } from 'helpers/Axios';
import { ClipboardEvent, DragEvent, PropsWithChildren, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';
import { useCounter } from 'react-use';

type SendMessageMutation = UseMutationResult<unknown, unknown, NewMessageModel>;

interface MessageInputProps {
  sendMessageMutation: SendMessageMutation;
  isDisabled?: boolean;
  autoFocus?: boolean;
}
const onDragOver = (e: DragEvent) => void e.preventDefault();

interface FileListItem {
  id: number;
  readonly file: File;
  media?: Media;
}

export default memo(function MessageInput({ sendMessageMutation, isDisabled, autoFocus }: MessageInputProps) {
  const [currentMessage, setCurrentMessage] = useState('');
  const counterRef = useRef(0);
  const [fileItems, setFileItems] = useState<FileListItem[]>([]);
  const removeFile = useCallback((id: number) => setFileItems(items => items.filter(item => item.id !== id)), []);
  const persistFile = useCallback((id: number, media: Media) => {
    setFileItems(items => items.map(item => (item.id !== id ? item : { ...item, media: media })));
  }, []);
  const [dragOverCounter, { inc, dec, reset }] = useCounter(0);
  const onDragLeave = useCallback(() => dec(1), [dec]);
  const onDragEnter = useCallback(() => inc(1), [inc]);
  const handleDataTransfer = useCallback((data: DataTransfer) => {
    setFileItems(items => [
      ...items,
      ...Array.from(data.files)
        .filter(file => file.type)
        .map(file => ({
          id: counterRef.current++,
          file: file,
          persisted: false,
        })),
    ]);
  }, []);
  const onPaste = useCallback(
    (e: ClipboardEvent) => {
      if (e.clipboardData.files.length > 0) {
        e.preventDefault();
        handleDataTransfer(e.clipboardData);
      }
    },
    [handleDataTransfer],
  );
  const onDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      reset();
      handleDataTransfer(e.dataTransfer);
    },
    [reset, handleDataTransfer],
  );

  const onSubmit = e => {
    e.preventDefault();
    if (isDisabled) {
      return;
    }
    if (sendMessageMutation.isLoading) {
      return;
    }

    const media = fileItems
      .map(item => item.media)
      .filter((media): media is Media => Boolean(media))
      .map(media => media['@id']);

    if (currentMessage.trim().length === 0 && media.length === 0) {
      return;
    }

    sendMessageMutation.mutate({ content: currentMessage, media: media });
    setFileItems(items => items.filter(item => !media.includes(item.media?.['@id'] as string)));
    setCurrentMessage('');
  };

  const onClickAction = e => {
    currentMessage.length === 0 ? sendMessageMutation.mutate({ content: '👍', media: [] }) : onSubmit(e);
  };

  return (
    <Box
      p={1}
      sx={{ flexGrow: 0, flexShrink: 1, position: 'relative' }}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
    >
      <form onSubmit={onSubmit}>
        <Backdrop open={dragOverCounter > 0} sx={{ position: 'absolute' }}>
          Upuść pliki tutaj
        </Backdrop>
        <Box display="flex" alignItems="end">
          <InputContainer>
            <FilesPreview files={fileItems} persistFile={persistFile} removeFile={removeFile} />
            <TextField
              variant="standard"
              multiline={false}
              placeholder="Aa"
              onPaste={onPaste}
              InputProps={{ disableUnderline: true, sx: { padding: 0 } }}
              value={currentMessage}
              onChange={e => setCurrentMessage(e.target.value)}
              autoFocus={autoFocus}
            />
          </InputContainer>
          <IconButton sx={{ height: 32, width: 32 }} onClick={onClickAction}>
            {currentMessage.length === 0 && <ThumbUpAltIcon fontSize="small" color="primary" />}
            {currentMessage.length !== 0 && <SendIcon fontSize="small" color="primary" />}
          </IconButton>
        </Box>
      </form>
    </Box>
  );
});

function InputContainer({ children }: PropsWithChildren) {
  return (
    <Box
      sx={theme => ({ borderRadius: theme.spacing(2), width: '100%', padding: theme.spacing(1), backgroundColor: 'rgba(0, 0, 0, 0.08)' })}
      children={children}
    />
  );
}

interface FilesPreviewProps {
  files: FileListItem[];
  persistFile: (id: number, media: Media) => void;
  removeFile: (id: number) => void;
}

function FilesPreview({ files, persistFile, removeFile }: FilesPreviewProps) {
  return (
    <div style={{ width: '100%' }} hidden={files.length === 0}>
      {files.map(item => (
        <FilePreview key={item.id} item={item} removeFile={removeFile} persistFile={persistFile} />
      ))}
    </div>
  );
}

interface FilePreviewProps {
  item: FileListItem;
  persistFile: (id: number, media: Media) => void;
  removeFile: (id: number) => void;
}

function FilePreview({ item, persistFile, removeFile }: FilePreviewProps) {
  const { id, file, media } = item;
  const localUrl = useMemo(() => URL.createObjectURL(file), [file]);

  const uploadMutation = useMutation<AxiosResponse<Media>, AxiosError, File>({
    mutationFn: (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return axiosApi.post(url.POST_MEDIA, formData);
    },
    onSuccess: ({ data }) => persistFile(id, data),
  });

  !uploadMutation.isLoading && !uploadMutation.isSuccess && !uploadMutation.isError && uploadMutation.mutate(item.file);

  return (
    <Badge
      sx={{ margin: 1 }}
      badgeContent={
        <IconButton size="small" sx={{ backgroundColor: 'grey' }} onClick={() => removeFile(id)}>
          <RemoveCircleIcon fontSize="inherit" />
        </IconButton>
      }
    >
      <Tooltip title={file.name} placement="top" enterDelay={1000}>
        <Box
          sx={{
            width: 50,
            height: 50,
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            backgroundImage: `url(${file.type.includes('image') ? localUrl : undefined})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: 3,
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Backdrop open={uploadMutation.isLoading} sx={{ position: 'relative', width: '100%', height: '100%', borderRadius: 3 }}>
            <CircularProgress size={25} />
          </Backdrop>
        </Box>
      </Tooltip>
    </Badge>
  );
}
