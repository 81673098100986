import { Avatar, CardHeader, Chip, Divider, Typography } from '@mui/material';
import useOnlineUsers from 'Modules/Chat/Hooks/UseOnlineUsersQuery';
import { MessageCollectionElement, NewMessageModel } from 'Modules/Chat/Types/Api';
import { Room } from 'Modules/Chat/Types/ApiModel';
import SectionCard from 'Modules/Chat/View/Components/Atoms/SectionCard';
import MessageInput from 'Modules/Chat/View/Components/RoomView/MessageInput';
import MessagesBox from 'Modules/Chat/View/Components/RoomView/MessagesBox';
import MessagesWithInput from 'Modules/Chat/View/Components/RoomView/MessagesWithInput';
import { AxiosError } from 'axios';
import useApiResourceQuery, { ApiCollection, ApiItem, Serialized, get, post } from 'helpers/Axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { GroupBase, MultiValueGenericProps, OptionProps, components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { LoadOptions } from 'react-select-async-paginate/ts/types';

interface Option extends ApiItem {
  id: string;
  firstName: string | null;
  lastName: string | null;
  image?: string;
}

interface AdditionalInfo {
  page: number;
}

type CreateRoomResponse = Serialized<
  Room,
  'id',
  {
    id: string;
    latestMessage: Omit<MessageCollectionElement, 'room'>;
  }
>;

const loadOptions: LoadOptions<Option, GroupBase<Option>, AdditionalInfo> = async (inputValue, options, additional) => {
  const currentPage = (additional?.page || 0) + 1;
  const result = await get<ApiCollection<Option>>('/employee/employees', {
    params: {
      searchRelationFieldLabel: inputValue,
      page: currentPage,
    },
  });

  return {
    options: result['hydra:member'],
    hasMore: Boolean(result['hydra:view']['hydra:next']),
    additional: {
      page: currentPage,
    },
  };
};

export default function CreateRoom() {
  const [selected, setSelected] = useState<readonly Option[]>([]);
  const onlineUsers = useOnlineUsers();
  const history = useHistory();
  const createRoom = useMutation<Room, AxiosError, readonly Option[]>({
    mutationFn: users =>
      post('/chat/rooms', {
        user: users.map(user => user['@id'])[0],
        users: users.map(user => user['@id']),
      }),
    onSuccess: data => history.replace('/chat/' + data.id),
  });

  const [selectedRoomResource] = useApiResourceQuery<Room>('/chat/rooms/by-exact-users', {
    qp: {
      users: selected.map(user => user.id),
    },
    enabled: selected.length > 0,
    cacheTime: 0,
    staleTime: 10000,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const createRoomWithMessage = useMutation<CreateRoomResponse, AxiosError, NewMessageModel>({
    mutationFn: message =>
      post('/chat/rooms', {
        users: selected.map(user => user['@id']),
        message: message,
      }),
    onSuccess: data => history.replace(`/chat/${data.id}`),
  });

  const onAddMessage = () => {
    if (selectedRoomResource.data) {
      history.push(`/chat/${selectedRoomResource.data.id}`);
    }
  };

  const OptionComponent = ({ children, ...props }: OptionProps<Option>) => {
    return (
      <components.Option {...props}>
        <Typography>
          <i className={`mdi mdi-circle mr-1 font-size-10 ${onlineUsers.includes(props.data['@id']) ? 'text-success' : ''}`} />
          &nbsp;&nbsp;
          {children}
        </Typography>
      </components.Option>
    );
  };

  const removeElement = (iri: string) => setSelected(selected => selected.filter(v => v['@id'] !== iri));

  const MultiValueContainerComponent = (props: MultiValueGenericProps<Option>) => {
    return (
      <Chip
        avatar={<Avatar src={props.data.image} />}
        label={`${props.data.firstName} ${props.data.lastName}`}
        onDelete={() => removeElement(props.data['@id'])}
        sx={{ mr: 1, mb: 1 }}
      />
    );
  };

  return (
    <SectionCard>
      <CardHeader
        title={
          <AsyncPaginate
            isMulti
            isDisabled={createRoom.isLoading}
            value={selected}
            onChange={setSelected}
            loadOptions={loadOptions}
            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
            components={{
              Option: OptionComponent,
              MultiValueContainer: MultiValueContainerComponent,
            }}
            getOptionValue={option => option['@id']}
            styles={{
              control: styles => ({
                ...styles,
                backgroundColor: 'transparent',
                border: 'none',
                boxShadow: 'none',
              }),
            }}
          />
        }
      />
      <Divider />
      {selectedRoomResource.data && selectedRoomResource.isSuccess ? (
        <MessagesWithInput room={selectedRoomResource.data} onAddMessage={onAddMessage} />
      ) : (
        <>
          <MessagesBox liveMessages={[]} chunks={undefined} hasNextPage={true} isFetchingNextPage={false} />
          <MessageInput sendMessageMutation={createRoomWithMessage} isDisabled={selected.length === 0} />
        </>
      )}
    </SectionCard>
  );
}
