import usePhotoLibrary from 'Modules/Core/Hooks/usePhotoLibrary';
import ImageUpload, { Actions } from 'components/Form/ImageUpload';
import { put } from 'helpers/Axios';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Label, Row } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

const PhotoLibrary: FC = () => {
  const { data: images, refetch } = usePhotoLibrary();
  const dispatch = useDispatch();

  const onAdd = (): void => {
    refetch();
    dispatch(addSingleToast({ title: `Dodano zdjęcie.`, config: { appearance: 'success' } }));
  };

  const onRemove = (iri: string): void => {
    put(iri, { global: false }).then(() => {
      refetch();
      dispatch(addSingleToast({ title: `Usunięto zdjęcie.`, config: { appearance: 'success' } }));
    });
  };
  return (
    <div className="container-fluid">
      <Row className={'mb-4'}>
        <Col xs={12}>
          <Label>Galeria</Label>
        </Col>
        {images.map(image => (
          <Col
            xs={3}
            key={image.id}
            style={{
              position: 'relative',
              marginBottom: '20px',
              height: '200px',
              backgroundImage: `url('${image.thumbnailContentUrl}')`,
              backgroundSize: 'auto',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Actions
              preview={image.thumbnailContentUrl}
              id={image.id}
              iri={image['@id']}
              originalName={image.originalName}
              onRemove={onRemove}
              style={{ bottom: 0 }}
            />
          </Col>
        ))}
      </Row>
      <ImageUpload
        showPreview={false}
        className="_"
        label={'Dodaj zdjęcie'}
        name={'file'}
        value={null}
        onChange={onAdd}
        returnObject={true}
        global={true}
        librarySelect={false}
      />
    </div>
  );
};

export default PhotoLibrary;
