import { LeadModule } from '../CTMModules';
import ImportLead from 'Modules/Contractor/Import/Lead';
import { withSecure } from 'components/Theme/Common/SecuredView';

export default [
  {
    path: '/modules/' + LeadModule.configuration.urlPrefix + '/import',
    exact: false,
    component: withSecure(ImportLead, 'ROLE_CREATE_CONTRACTOR_LEAD', true),
  },
];
