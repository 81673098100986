import * as URL from 'helpers/Api/Url';
import { del, get as getAxios, post, put } from 'helpers/Axios';

const all = () => getAxios(URL.GET_WAREHOUSE_DOCUMENT);
const fetch = id => getAxios(URL.GET_WAREHOUSE_DOCUMENT(id));
const create = data => post(URL.POST_WAREHOUSE_DOCUMENT, data);
const update = (id, data) => put(URL.PUT_WAREHOUSE_DOCUMENT(id), data);
const remove = id => del(URL.DELETE_WAREHOUSE_DOCUMENT(id));
const byOwner = owner => getAxios(URL.GET_WAREHOUSE_DOCUMENT('') + `?owner=${owner}`);

export { all, fetch, create, update, remove, byOwner };
