import CTMModule, { CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { Employee } from 'Modules/Employee/Types/Employee';
import { SelectColumnFilter, SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';
import { ChangesModal } from 'components/History/ChangesTable';
import dayjs from 'dayjs';

export type Log = CTMStrictRecord & {
  loggedAt: string;
  actionType: string;
  actorRawInfo: string;
  actor: Employee;
};

const LogActionTypes: {
  value: string;
  label: string;
}[] = [
  { value: 'INSERT', label: 'Utworzenie' },
  { value: 'UPDATE', label: 'Aktualizacja' },
  { value: 'REMOVE', label: 'Skasowanie' },
];

const indexedActionTypes = LogActionTypes.reduce((acc, { value, label }) => {
  acc[value] = label;
  return acc;
}, {});

const columns: CTMListColumn<Log>[] = [
  {
    id: 'requestId',
    filterable: true,
    sortable: false,
    Header: 'Request',
    accessor: 'requestId',
  },
  {
    id: 'actionType',
    filterable: true,
    sortable: false,
    Header: 'Typ zmiany',
    accessor: ({ actionType }) => indexedActionTypes[actionType] || 'Nieznany',
    Filter: SelectColumnFilter,
    filterOptions: [{ value: null, label: 'Wszystkie' }, ...LogActionTypes],
  },
  {
    id: 'actor.id',
    Header: 'Autor zmiany',
    accessor: ({ actor, actorRawInfo }) => (
      <>{actor === null ? ((actorRawInfo + '').length > 0 ? actorRawInfo : 'System') : `${actor.firstName} ${actor.lastName}`}</>
    ),
    sortable: false,
    filterable: true,
    Filter: SelectModuleRecordColumnFilter,
    filterModuleName: 'employee-employee',
  },
  {
    id: 'objectName',
    filterable: false,
    sortable: false,
    Header: 'Zmieniony obiekt',
    accessor: row => (row['@objectName'].includes('Entity\\') ? row['@objectName'].split('\\').pop() : row['@objectName']),
  },
  {
    id: 'rootObjectId',
    filterable: true,
    sortable: false,
    Header: 'ID zmienionego obiektu',
    accessor: 'rootObjectId',
  },
  {
    id: 'loggedAt',
    filterable: false,
    sortable: true,
    Header: 'Data zmiany',
    accessor: ({ loggedAt }) => dayjs(loggedAt).locale('pl').format('llll'),
  },
  {
    id: 'changes',
    filterable: false,
    sortable: false,
    Header: 'Zmiany',
    accessor: row => <ChangesModal row={row} />,
  },
];

const module: CTMModule<Log> = {
  id: '10e04c8d-ad80-475a-bda9-bce485cde807',
  dataClass: 'CTM\\Core\\Entity\\EntityLog',
  urlPrefix: 'core-logs',
  name: 'Logi',
  role: 'CORE_LOGS',
  api: {
    item: {
      get: ({ id }) => `/core/entity-logs/${id}`,
      put: ({ id }) => `/core/entity-logs/${id}`,
      delete: ({ id }) => `/core/entity-logs/${id}`,
    },
    collection: {
      get: `/core/entity-logs`,
      post: `/core/entity-logs`,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'loggedAt', desc: true }],
  },
};

export default module;
