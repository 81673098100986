import DictionaryCategory from './DictionaryCategory';
import CTMModule, { CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';
import { pickValueFromRecordByPropertyPath } from 'helpers/ModuleUtils';
import ValuesField from 'pages/Configuration/Dictionary/FormComponents/ValuesField';

export type Dictionary = CTMStrictRecord & {
  name?: string;
  category?: { name?: string };
  values?: any;
};

const columns: CTMListColumn<Dictionary>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    id: 'category.id',
    filterable: true,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: DictionaryCategory,
    sortable: true,
    Header: 'Kategoria',
    accessor: 'category.name',
  },
];

interface CustomDictionaryItemPaths {
  getValues: ({ id }: { id: string | number }) => string;
}

const module: CTMModule<Dictionary, CustomDictionaryItemPaths> = {
  id: '96e7c6b8-b5b9-4053-99c3-e9f6436a84ca',
  dataClass: 'CTM\\Core\\Entity\\Dictionary',
  urlPrefix: 'core-dictionary',
  name: 'Słowniki',
  role: 'CORE_DICTIONARY',
  api: {
    item: {
      get: ({ id }) => `/core/dictionaries/${id}`,
      getValues: ({ id }) => `/core/dictionaries/${id}/values`,
      put: ({ id }) => `/core/dictionaries/${id}`,
      delete: ({ id }) => `/core/dictionaries/${id}`,
    },
    collection: {
      get: `/core/dictionaries`,
      post: `/core/dictionaries`,
    },
  },
  recordLabel: (record, allFields) => {
    const { name, category } = pickValueFromRecordByPropertyPath<Dictionary, Pick<Dictionary, 'name' | 'category'>>(
      record,
      ['name', 'category'],
      allFields,
    );
    return name ? `${category?.name ? `[${category?.name}]` : ''}>${name ?? ''}` : null;
  },
  form: {
    defaultRecord: () => ({}),
    prepareRecordToSave: record => ({ ...record, category: record.category?.['@id'] ?? record.category ?? null }),
    fieldComponents: {
      values: ValuesField,
    },
  },
  list: {
    role: false,
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
