import CTMModule, { CTMApiMixed, CTMListColumn, CTMRecord, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';

interface BaseBuilderParameters<T extends CTMRecord | CTMStrictRecord = CTMRecord> {
  id: string;
  dataClass: string;
  urlPrefix: string;
  name: string;
  role: string;
  baseUrl: string;
  columns: CTMListColumn<T>[];
  form?: undefined | Partial<CTMModule['form']>;
  list?: undefined | Partial<CTMModule['list']>;
}

export function baseBuilder<T extends CTMRecord | CTMStrictRecord>(parameters: BaseBuilderParameters<T>) {
  const module = {
    id: parameters.id,
    dataClass: parameters.dataClass,
    urlPrefix: parameters.urlPrefix,
    name: parameters.name,
    role: parameters.role,
    api: {
      item: {
        get: ({ id }) => `${parameters.baseUrl}/${id}`,
        put: ({ id }) => `${parameters.baseUrl}/${id}`,
        delete: ({ id }) => `${parameters.baseUrl}/${id}`,
      },
      collection: {
        get: parameters.baseUrl,
        post: parameters.baseUrl,
      },
    },
    recordLabel: ({ name }) => name,
    form: {
      ...(parameters.form ?? {}),
    },
    list: {
      columns: parameters.columns,
      defaultFilters: [],
      defaultOrderBy: [{ id: 'id', desc: true }],
      ...(parameters.list ?? {}),
    },
  };
  return module as unknown as CTMModule<T, CTMApiMixed, CTMApiMixed, any>;
}

export default { baseBuilder };
