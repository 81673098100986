import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import FileUpload from 'components/Form/FileUpload';
import MultipleFileUpload from 'components/Form/MultipleFileUpload';

const FileField: CustomFieldComponentType = ({ field, value, onChange, readonly, errorKey, customProps = {} }) => {
  const isMultiple = field.config.multiple === true;
  const FormComponent = isMultiple ? MultipleFileUpload : FileUpload;

  return (
    <>
      <FormComponent
        size={{ md: 12 }}
        label={field.name}
        name={field.id}
        value={value ?? (isMultiple ? [] : null)}
        onChange={onChange}
        returnObject={true}
        disabled={readonly}
        errorKey={errorKey}
        labelerId={field.labelerId}
        {...customProps}
      />
    </>
  );
};

export default FileField;
