import LabelerId from '../CustomFields/LabelerId';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import { confirmRemove } from 'common/sweetalerts';
import { postMedia } from 'helpers/Api/CoreHelper';
import moment from 'moment';
import { FC } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, FormText, Label, Row } from 'reactstrap';
import { useAppSelector } from 'store';

export interface MultipleFileUploadProps {
  className?: string;
  value: any[];
  name: string;
  helpText?: string;
  label?: string;
  size?: { md: number };
  style?: any;
  onChange: (value: any, name: string) => void;
  disabled?: boolean;
  labelBadge?: string;
  errorKey?: string;
  dropzone?: (getRootProps: any, getInputProps: any) => JSX.Element;
  disableGroupMargin?: boolean;
  labelerId?: number;
}

const MultipleFileUpload: FC<MultipleFileUploadProps> = ({
  className = 'mb-3',
  value,
  name,
  helpText,
  label = null,
  size = { md: 6 },
  style = {},
  onChange,
  disabled,
  errorKey,
  dropzone,
  disableGroupMargin,
  labelerId,
}) => {
  const { t } = useTranslation();
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));

  const hasError = () => {
    return violations.hasOwnProperty(errorKey ?? name ?? '');
  };
  const getError = () => {
    if (hasError()) {
      return violations[errorKey ?? name ?? ''].join('\n');
    }
    return '';
  };

  const handleAcceptedFiles = files => {
    const uploads = files.map(file => {
      return new Promise((res, rej) => {
        const data = new FormData();
        data.append('file', file);
        postMedia(data)
          .then(response => {
            res(response);
          })
          .catch(err => {
            rej(err);
          });
      });
    });

    Promise.all(uploads).then(values => {
      onChange([...value, ...values], name);
    });
  };

  return (
    <Col {...size} className={className} style={style}>
      <Row>
        <FormGroup className={classnames({ 'disable-group-margin': disableGroupMargin })} style={{ minHeight: '50px' }}>
          {label && (
            <Label>
              {typeof labelerId !== 'undefined' && <LabelerId labelerId={labelerId} />}
              {t(label)}
            </Label>
          )}
          {!disabled && (
            <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>
              {({ getRootProps, getInputProps }) =>
                dropzone ? (
                  dropzone(getRootProps, getInputProps)
                ) : (
                  <div className="dropzone dropzone-image" style={{ minHeight: 'initial' }}>
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="dropzone-text">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                    </div>
                  </div>
                )
              }
            </Dropzone>
          )}
          {helpText && <FormText>{helpText}</FormText>}
          {hasError() && <FormFeedback>{getError()}</FormFeedback>}
        </FormGroup>
      </Row>
      {(value || []).length > 0 && (
        <div className=" d-flex flex-wrap space-between mt-2">
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Nazwa pliku</th>
                <th>Data dodania</th>
                <th>Pobierz</th>
                {!disabled && <th>Skasuj</th>}
              </tr>
            </thead>
            <tbody>
              {value.filter(Boolean).map(el => (
                <tr key={el.id}>
                  <td>
                    <i className="mdi mdi-file font-size-14" />
                  </td>
                  <td>
                    {(el.originalName.includes('png') || el.originalName.includes('jpg') || el.originalName.includes('jpeg')) && (
                      <Tooltip
                        className="mui-file-tooltip"
                        title={
                          <div style={{ maxWidth: '80vw', zIndex: 9999 }}>
                            <img src={el.contentUrl} style={{ maxWidth: '100%' }} />
                          </div>
                        }
                        style={{ zIndex: 9999 }}
                      >
                        <div>{el.originalName}</div>
                      </Tooltip>
                    )}
                    {!(el.originalName.includes('png') || el.originalName.includes('jpg') || el.originalName.includes('jpeg')) && (
                      <>{el.originalName}</>
                    )}
                  </td>
                  <td>{moment(el.uploadedAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                  <td>
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() =>
                        Object.assign(document.createElement('a'), {
                          target: '_blank',
                          rel: 'noopener noreferrer',
                          href: el.contentUrl,
                        }).click()
                      }
                    >
                      <i className="mdi mdi-download" /> Pobierz
                    </button>
                  </td>
                  {!disabled && (
                    <td>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() =>
                          confirmRemove(
                            () => {
                              return new Promise((res, rej) => {
                                onChange([...value.filter(v => v && v.id !== el.id)], name);
                                res(true);
                              });
                            },
                            'Czy jesteś pewien?',
                            'Zamierzasz skasować plik, tej akcji nie można cofnąć',
                            false,
                          )
                        }
                      >
                        <i className="mdi mdi-delete" /> Skasuj
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Col>
  );
};

export default MultipleFileUpload;
