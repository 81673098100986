import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import BaseInput from 'components/Form/BaseInput';
import QueryString from 'query-string';
import YouTube from 'react-youtube';
import { InputGroupText } from 'reactstrap';

const YouTubePreview = ({ url, height }) => {
  const getVideoId = () => {
    if (url) {
      if (url.startsWith('https://www.youtube') || url.startsWith('https://youtube')) {
        const urlObject = QueryString.parseUrl(url);
        if (urlObject.query?.v) {
          return urlObject.query.v as string;
        } else {
          return null;
        }
      }

      if (url.startsWith('https://youtu.be')) {
        const urlObject = new URL(url);

        return urlObject.pathname.substring(1);
      }
    }

    return null;
  };
  const videoId = getVideoId();

  if (!videoId) {
    return null;
  }
  return (
    <YouTube
      videoId={videoId}
      opts={{
        width: '100%',
        height: (height || 450) + 'px',
      }}
    />
  );
};

const YouTubeField: CustomFieldComponentType = ({ field, value, onChange, readonly, errorKey, customProps = {} }) => {
  const parseurl = val => {
    if (!val) {
      return onChange(val, field.id);
    }

    if (!val.startsWith('https://www.youtube') && !val.startsWith('https://youtube') && !val.startsWith('https://youtu.be')) {
      return onChange('', field.id);
    }

    return onChange(val, field.id);
  };

  return (
    <>
      <BaseInput
        size={{ md: 12 }}
        label={field.name || 'Nazwa pola'}
        value={value}
        name={field.id}
        type={'text'}
        onChange={parseurl}
        disabled={readonly}
        errorKey={errorKey}
        afterInputContent={
          <>
            {value && (
              <InputGroupText
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  Object.assign(document.createElement('a'), {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    href: value,
                  }).click();
                }}
              >
                <i className={`mdi mdi-open-in-new`} />
              </InputGroupText>
            )}
          </>
        }
        {...customProps}
      />
      <YouTubePreview url={value} height={field?.config?.height ?? 450} />
    </>
  );
};

export default YouTubeField;
