import { baseBuilder } from '../ModuleBuilder';
import SourceView from './Components/SourceView';
import IconPicker from '@Components/CustomFields/FieldTypes/IconPicker';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type ClientOrderSource = CTMRecord & {
  name: string;
  icon: null | string;
  color: null | string;
};

const columns: CTMListColumn<ClientOrderSource>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: row => <SourceView source={row} />,
  },
];

const module: CTMModule<ClientOrderSource> = baseBuilder<ClientOrderSource>({
  id: '0327bdd9-9772-4b17-b10b-548516a4a51d',
  dataClass: 'CTM\\Ecommerce\\Entity\\ClientOrderSource',
  urlPrefix: 'ecommerce-client-order-sources',
  name: 'Źródła zamówień',
  role: 'CONFIGURATION',
  baseUrl: '/ecommerce/client-order-sources',
  columns: columns,
  list: {
    role: 'ROLE_EMPLOYEE',
  },
  form: {
    fieldComponents: {
      icon: IconPicker,
    },
  },
});

export default module;
