import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type ProductColorType = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<ProductColorType>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
];

const module: CTMModule<ProductColorType> = {
  id: 'd319e468-4c69-4f57-917b-f1605b930efb',
  dataClass: 'CTM\\Manufacture\\Entity\\ProductColorType',
  urlPrefix: 'manufacture-product-color-types',
  name: 'Typy kolorów',
  role: 'MANUFACTURE_PRODUCT',
  api: {
    item: {
      get: ({ id }) => `/manufacture/product-color-types/${id}`,
      put: ({ id }) => `/manufacture/product-color-types/${id}`,
      delete: ({ id }) => `/manufacture/product-color-types/${id}`,
    },
    collection: {
      get: `/manufacture/product-color-types`,
      post: `/manufacture/product-color-types`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {},
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
