import { baseBuilder } from '../ModuleBuilder';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type ProductKind = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<ProductKind>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
];

const module: CTMModule<ProductKind> = baseBuilder<ProductKind>({
  id: 'ff3031f8-65dc-47ab-8355-3214540cc843',
  dataClass: 'CTM\\Manufacture\\Entity\\ProductKind',
  urlPrefix: 'manufacture-product-kinds',
  name: 'Rodzaje produktów',
  role: 'MANUFACTURE_PRODUCT',
  baseUrl: '/manufacture/product-kinds',
  columns: columns,
});

export default module;
