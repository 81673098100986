import useFormError from '../useFormError';
import TextField from '@mui/material/TextField';
import { memo } from 'react';

interface PlaceholderProps {
  className?: string;
  value?: null | string;
  label?: string;
  name?: string;
  forcedErrorMessage?: string;
  disabled?: boolean;
  inputProps?: any;
  namePrefix?: string;
}

const Placeholder = ({ value, className, label, name, disabled, inputProps, forcedErrorMessage, namePrefix }: PlaceholderProps) => {
  const [hasErrors, errorMessage] = useFormError((namePrefix ? namePrefix + '.' : '') + (name ?? ''));

  const containsError = (forcedErrorMessage ?? '').length > 0 || hasErrors;
  return (
    <TextField
      helperText={hasErrors ? errorMessage : forcedErrorMessage}
      error={containsError}
      className={className}
      label={label}
      disabled={disabled}
      InputProps={{ readOnly: true, ...(inputProps ?? {}) }}
      value={value ?? ''}
      onChange={() => undefined}
      variant="outlined"
      InputLabelProps={{ shrink: !!value }}
    />
  );
};

export default memo(Placeholder);
