import { useModuleContext } from 'context/ModulesContext';
import { useQuery } from 'react-query';

export const queryKey = 'allSystemFields';

export const useSystemFields = () => {
  const SystemFieldModule = useModuleContext('core-system-field');
  return useQuery(
    queryKey,
    () =>
      SystemFieldModule?.api
        .getAll({
          params: {
            page: 1,
            limit: 1000,
          },
        })
        .then(res => res['hydra:member']),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: false,
    },
  );
};

export default { useSystemFields };
