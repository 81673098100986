import ProductFilterFilters from './Components/ProductFilter/ProductFilterFilters';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type ProductFilter = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<ProductFilter>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
];

const module: CTMModule<ProductFilter> = {
  id: 'b3d6860f-a277-4877-b689-363b73bb8bb9',
  dataClass: 'CTM\\Manufacture\\Entity\\ProductFilter',
  urlPrefix: 'manufacture-product-filters',
  name: 'Filtry towarów',
  role: 'MANUFACTURE_PRODUCT',
  api: {
    item: {
      get: ({ id }) => `/manufacture/product-filters/${id}`,
      put: ({ id }) => `/manufacture/product-filters/${id}`,
      delete: ({ id }) => `/manufacture/product-filters/${id}`,
    },
    collection: {
      get: `/manufacture/product-filters`,
      post: `/manufacture/product-filters`,
    },
  },
  form: {
    fieldComponents: {
      filters: ProductFilterFilters,
    },
  },
  recordLabel: ({ name }) => name,
  list: {
    columns: columns,
    defaultFilters: [{ id: 'exists[name]', value: true }],
    defaultOrderBy: [{ id: 'name', desc: true }],
  },
};

export default module;
