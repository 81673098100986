import LabelerId from '../CustomFields/LabelerId';
import classnames from 'classnames';
import BaseInput from 'components/Form/BaseInput';
import { ApiCollection, ApiItem, get } from 'helpers/Axios';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { Col, FormFeedback, FormGroup, FormText, Label, Row } from 'reactstrap';
import { useAppSelector } from 'store';

export interface SelectSingleAPIProps<T extends ApiItem = any> {
  className?: string;
  value?: T;
  name: string;
  helpText?: string;
  placeholder?: string;
  label?: string;
  size?: { md: number };
  style?: any;
  onChange?: (value: any, name: string, selectedOption: any) => void;
  optionTrackBy?: keyof T | ((el: T) => string);
  optionLabel?: keyof T | ((el: T) => string);
  endpoint: string;
  buildQuery?: (value: any) => any;
  filterOptions?: (el: T) => boolean;
  disabled?: boolean;
  errorKey?: string;
  isClearable?: boolean;
  disableGroupMargin?: boolean;
  clearAfterSelect?: boolean;
  tabIndex?: number;
  labelerId?: number;
}

const SelectSingleAPI: FC<SelectSingleAPIProps> = <T extends ApiItem = any>(props: SelectSingleAPIProps<T>) => {
  const {
    className = 'mb-3',
    value,
    name,
    helpText,
    placeholder = 'szukaj...',
    label = null,
    size = { md: 12 },
    style = {},
    onChange = () => undefined,
    optionTrackBy = '@id',
    optionLabel = 'name',
    endpoint = '/',
    buildQuery = value => ({ value: value }),
    filterOptions = () => true,
    disabled,
    errorKey,
    isClearable,
    disableGroupMargin,
    clearAfterSelect,
    tabIndex,
    labelerId,
  } = props;
  const { t } = useTranslation();
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));
  const [selectedOption, setSelectedOption] = useState<T | null>(null);
  const [selectedValue, setSelectedValue] = useState<T | any>(null);
  const [defaultOptions, setDefaultOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);

  const mapToValue = (el: T | string | null | undefined): string | null | undefined => {
    if (typeof el === 'string' || el === null || el === undefined) {
      return el;
    }

    if (typeof optionTrackBy === 'function') {
      return optionTrackBy(el);
    }

    return el[optionTrackBy] + '';
  };

  const mapToLabel = el => {
    if (typeof el === 'string' || el === null) {
      return el;
    }

    return typeof optionLabel === 'function' ? optionLabel(el) : el[optionLabel];
  };

  const fetchOptions = (filterValue?: string) => {
    return new Promise<T[]>((res, rej) => {
      get<ApiCollection<T>>(endpoint, { params: filterValue ? buildQuery(filterValue) : {} })
        .then(response => {
          if (response.hasOwnProperty('hydra:member')) {
            res(response['hydra:member'].filter(filterOptions));
          } else {
            console.error('Invalid endpoint provided!');
            res([]);
          }
        })
        .catch(err => rej(err));
    });
  };

  const hasError = () => {
    return violations.hasOwnProperty(errorKey ?? name ?? '');
  };

  const getError = () => {
    if (hasError()) {
      return violations[errorKey ?? name ?? ''].join('\n');
    }
    return '';
  };
  //
  const resolveDefaultOptions = () => {
    let tmpNewOptions;
    fetchOptions()
      .then(newOptions => {
        tmpNewOptions = newOptions;
        setDefaultOptions(newOptions);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
        if (!_.isEqual(mapToValue(selectedValue), mapToValue(value))) {
          if (tmpNewOptions.length > 0) {
            setSelectedOption(tmpNewOptions.find(el => mapToValue(el) === mapToValue(value)));
          } else {
            value && setSelectedOption(value);
          }
        }
      });
  };
  useEffect(() => {
    resolveDefaultOptions();
  }, []);

  useEffect(() => {
    setSelectedValue(mapToValue(selectedOption));
  }, [selectedOption]);

  useEffect(() => {
    if (!_.isEqual(selectedValue, mapToValue(value))) {
      onChange(selectedValue, name, selectedOption);
      if (clearAfterSelect) {
        setSelectedOption(null);
        setSelectedValue(null);
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    if (!_.isEqual(mapToValue(selectedValue), mapToValue(value)) && defaultOptions.length > 0) {
      setSelectedOption(defaultOptions.find(el => mapToValue(el) === mapToValue(value)) ?? null);
    }
  }, [value, defaultOptions]);

  const loadOptions = async (inputValue: string, callback: any): Promise<any> => {
    const newOptions = await fetchOptions(inputValue);
    callback(newOptions);
  };

  const onChangeSelect = opt => {
    setSelectedOption(opt);
  };

  if (disabled) {
    const inputProps = { ...props, onChange: () => undefined };
    return <BaseInput {...inputProps} value={selectedOption ? mapToLabel(selectedOption) : ''} />;
  }

  return (
    <Col {...size} className={className} style={style}>
      <Row>
        <FormGroup className={classnames({ 'disable-group-margin': disableGroupMargin })}>
          {label && (
            <Label>
              {typeof labelerId !== 'undefined' && <LabelerId labelerId={labelerId} />}
              {t(label)}
            </Label>
          )}
          {!loading && (
            <AsyncSelect<T>
              defaultOptions={defaultOptions}
              loadOptions={loadOptions}
              onChange={onChangeSelect}
              getOptionLabel={mapToLabel}
              getOptionValue={(...args) => mapToValue(...args) ?? ''}
              value={selectedOption}
              classNamePrefix="select2-selection"
              placeholder={placeholder}
              isClearable={isClearable}
              tabIndex={tabIndex}
            />
          )}
          {helpText && <FormText>{helpText}</FormText>}
          {hasError() && <FormFeedback>{getError()}</FormFeedback>}
        </FormGroup>
      </Row>
    </Col>
  );
};

export default SelectSingleAPI;
