import * as url from 'helpers/Api/Url';
import { del, get, post, put } from 'helpers/Axios';

const getModules = () => get(url.GET_MODULES, { params: { pagination: false, limit: 100 } });

const getCustomTabs = module => get(url.GET_CUSTOM_TABS(module), { params: { pagination: false } });

const getCustomTab = id => get(url.GET_CUSTOM_TAB(id));

const deleteCustomTab = id => del(url.DELETE_CUSTOM_TAB(id));

const postCustomTab = data => post(url.POST_CUSTOM_TABS, data);

const putCustomTab = (id, data) => put(url.PUT_CUSTOM_TABS(id), data);

const getFormConfiguration = dataClass => get(url.GET_FORM_CONFIGURATION(dataClass));

export { getModules, getCustomTabs, deleteCustomTab, getCustomTab, postCustomTab, putCustomTab, getFormConfiguration };
