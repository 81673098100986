import Loader from '@Components/Theme/Common/Loader';
import { ProductGroupModule, ProductTypeModule } from 'Modules/CTMModules';
import ReportDownloadButton from 'components/DataGrid/Button/ReportDownloadButton';
import RelationSinglePicker from 'components/Form/RelationSinglePicker';
import BaseForm from 'components/Theme/Common/BaseForm';
import { Col, Container } from 'components/Theme/Grid';
import moment from 'moment';
import { useMemo, useState } from 'react';

const ProductStoreReport = () => {
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({ limit: 9999999999999 });

  const url = useMemo(() => {
    const parameters = Object.keys(query)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
      .join('&');
    return `warehouse/report/warehouse-product-stores?${parameters}&order[quantityStock]=DESC`;
  }, [query]);

  setTimeout(() => setLoading(false), 500);

  const updateFormValue = (value, field) => {
    setQuery({ ...query, [field]: value });
  };

  const DetailsTab = () => (
    <>
      <Container justifyContent="space-between">
        <Col xs={12} md={6} lg={3}>
          <RelationSinglePicker
            moduleName={ProductTypeModule.configuration.urlPrefix}
            size={{ md: 12 }}
            label={'Typ'}
            value={query?.typeId}
            name="typeId"
            onChange={(iri, field, row) => {
              updateFormValue(row?.id ?? '', field);
            }}
            fetchValue={id => (id ? ProductTypeModule.api.get({ id }) : null)}
            optionLabel="name"
            optionTrackBy="id"
            disableGroupMargin={true}
          />
        </Col>
        <Col xs={12} md={6} lg={3}>
          <RelationSinglePicker
            moduleName={ProductGroupModule.configuration.urlPrefix}
            size={{ md: 12 }}
            label={'Grupa'}
            value={query?.groupId}
            name="groupId"
            onChange={(iri, field, row) => {
              updateFormValue(row?.id ?? '', field);
            }}
            fetchValue={id => (id ? ProductGroupModule.api.get({ id }) : null)}
            optionLabel="name"
            optionTrackBy="id"
            disableGroupMargin={true}
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <div className={'align-self-center justify-content-end mt-4'}>
            <ReportDownloadButton
              url={url}
              name={`raport_stany_zapotrzebowanie_${moment().format('YYYY_MM_DD_HH_mm_ss')}`}
              description={'Kliknij aby pobrać raport'}
              label={'Pobierz raprot'}
            />
          </div>
        </Col>
      </Container>
    </>
  );

  if (loading) {
    return (
      <div className="container-fluid">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <BaseForm
        title={`Raport stanów i zapotrzebowania`}
        tabs={[{ title: 'Ogólne', content: <DetailsTab /> }]}
        readonly={true}
        contextActions={
          <>
            <button
              className="btn btn-primary ml-1"
              onClick={() => {
                history.goBack();
              }}
            >
              <i className="mdi mdi-arrow-left" />
              Wróć do listy
            </button>
          </>
        }
      />
    </div>
  );
};

export default ProductStoreReport;
