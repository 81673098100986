import { ProductNodeData, updateSystemFieldValueOnProduct } from './FlowUtils';
import useStore, { ProductNodeModeBasic } from './store';
import { Description, PermMedia, PictureAsPdf, Preview, Settings } from '@mui/icons-material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Button, ButtonGroup, InputAdornment, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ProductModule } from 'Modules/CTMModules';
import LanguagePicker from 'Modules/Core/Components/LanguagePicker';
import { useFileUpload } from 'Modules/Core/Hooks/useFileUpload';
import { ProductType } from 'Modules/Manufacture/ProductType';
import { Unit } from 'Modules/Manufacture/Unit';
import classNames from 'classnames';
import NumberInput from 'components/Form/MUI/NumberInput';
import TextInput from 'components/Form/MUI/TextInput';
import ModalFormWrapper, { ModalFormWrapperRef } from 'components/Form/ModalFormWrapper';
import ModuleForm from 'components/Module/ModuleForm';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import { colorTransform } from 'helpers/Common';
import { createRef, memo, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { Handle, Position } from 'reactflow';
import { useAppDispatch, useAppSelector } from 'store';
import { addSingleToast } from 'store/Toast/actions';

interface FlowProductNodeProps {
  data: ProductNodeData;
  id: string;
}

function FlowProductNodeModeTechnologyFiles(props: FlowProductNodeProps) {
  const selectedLanguage = useAppSelector(state => state.Root.language);
  const { data, id } = props;
  const dispatch = useAppDispatch();
  const showModal = createRef<ModalFormWrapperRef>();
  const fileUpload = useFileUpload();
  const typeColor = data.product?.type?.color ?? '#fff';
  const { updateProduct, removeNode, currentNode, fields } = useStore(
    state => ({
      updateProduct: state.updateProduct,
      removeNode: state.removeNode,
      currentNode: state.getNode(id),
      fields: state.fields,
    }),
    shallowEqual,
  );
  const [value, setValue] = useState<string>('');
  useEffect(() => {
    if (typeof data.product?.technology !== 'string') {
      setValue(data.product?.technology?.fileTypes?.[0]?.['@id'] ?? '');
    }
  }, [JSON.stringify(data.product?.technology)]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const nameFieldId = fields.find(el => el.propertyPath === 'name')?.id ?? '';

  if (typeof data.product?.technology === 'string') {
    return null;
  }

  const resolveIconForFile = file => {
    if (file.mimeType.includes('image')) {
      return PermMedia;
    }
    if (file.mimeType.includes('pdf')) {
      return PictureAsPdf;
    }

    return Description;
  };

  const getFilesByTpe = (type: string): any[] => {
    return data.product?.technologyFiles.filter(el => (el.technologyFileType?.['@id'] ?? el.technologyFileType) === type);
  };

  const handleFileUploadSuccess = (fileResponse: any) => {
    const technologyFile = {
      technologyFileType: value,
      file: fileResponse,
    };
    updateProduct(data.product?.id ?? '', {
      ...data,
      product: {
        ...data.product,
        technologyFiles: [...(data.product?.technologyFiles ?? []), technologyFile],
      },
    });
  };

  const removeFile = (fileId: string) => {
    updateProduct(data.product?.id ?? '', {
      ...data,
      product: {
        ...data.product,
        technologyFiles: (data.product?.technologyFiles ?? []).filter(el => el.file?.['@id'] !== fileId),
      },
    });
  };

  const handleDropFiles = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (typeof data.product?.technology !== 'string' && (data.product?.technology?.fileTypes ?? []).length === 0) {
      dispatch(
        addSingleToast({
          title: `Produkt "${data.product?.name}" Nie posiada dostępnych slotów technologicznych na wgrywanie plików.`,
          config: { appearance: 'error' },
        }),
      );
      return;
    }
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...e.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) {
            fileUpload(file, handleFileUploadSuccess);
          }
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...e.dataTransfer.files].forEach((file, i) => {
        fileUpload(file, handleFileUploadSuccess);
      });
    }
    e.dataTransfer.clearData();
  };

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <div className="side-action-container">
        <div className="side-action-actions" style={{ borderColor: colorTransform(typeColor, -15) ?? '' }}>
          <Settings
            style={{ color: '#556ee6' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              showModal.current?.open();
            }}
          />
          {!data.root && <RemoveCircleIcon style={{ color: '#f46a6a' }} onClick={() => removeNode(id)} />}
        </div>
      </div>
      <TextInput
        disabled={false}
        label={''}
        value={data.product?.['@formValues']?.[nameFieldId]?.value[selectedLanguage] ?? data.product?.name ?? ''}
        className="nodrag bg-white"
        inputProps={{
          min: 0,
          style: { background: colorTransform(typeColor, -15) ?? '' },
          endAdornment: (
            <InputAdornment position="end">
              <span className="labeler-id">{data.product?.labelerId}</span>
            </InputAdornment>
          ),
        }}
      />
      <div
        className="product-flow-node mode-technology nodrag"
        style={{ background: typeColor }}
        onDrop={handleDropFiles}
        onDragOver={e => e.preventDefault()}
      >
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          {data.product?.technology?.fileTypes?.map((el, index) => (
            <Tab key={el['@id']} label={`${el.name} (${getFilesByTpe(el['@id']).length})`} value={el['@id']} />
          ))}
        </Tabs>
        <div className="files-container nowheel">
          <Table padding="none">
            <TableBody>
              {getFilesByTpe(value).map((el, index) => {
                const Icon = resolveIconForFile(el.file);

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Icon />
                    </TableCell>
                    <TableCell>{el.file?.originalName}</TableCell>
                    <TableCell width={30}>
                      <ButtonGroup>
                        <Button size="small" variant="contained" onClick={() => window.open(el.file?.contentUrl, '_blank')}>
                          <Preview />
                        </Button>
                        <Button size="small" variant="contained" color="error" onClick={() => removeFile(el.file?.['@id'])}>
                          <RemoveCircleIcon style={{ color: '#fff' }} />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
      {(currentNode?.parentId ?? '_') != '0' && <Handle type="target" position={Position.Bottom} isConnectable={true} />}
      {!(data?.root ?? false) && <Handle type="source" position={Position.Top} isConnectable={true} />}
    </div>
  );
}

function FlowProductNodeModeBasic(props: FlowProductNodeProps) {
  const selectedLanguage = useAppSelector(state => state.Root.language);
  const { data, id } = props;
  const showModal = createRef<ModalFormWrapperRef>();
  const typeColor = data.product?.type?.color ?? '#fff';
  const { updateProduct, removeNode, currentNode, fields } = useStore(
    state => ({
      updateProduct: state.updateProduct,
      removeNode: state.removeNode,
      currentNode: state.getNode(id),
      fields: state.fields,
    }),
    shallowEqual,
  );

  const nameFieldId = fields.find(el => el.propertyPath === 'name')?.id ?? '';
  const handleProductTypeChange = productType => {
    handleProductFieldChange(productType, 'type');
  };
  const handleProductFieldChange = (newValue: any, field: string | undefined) => {
    updateProduct(data.product?.id ?? '', {
      ...data,
      product: updateSystemFieldValueOnProduct(JSON.parse(JSON.stringify(data.product)), fields, field, newValue, selectedLanguage),
    });
  };

  const handleQuantityChange = (quantity: number | null) => {
    updateProduct(data.product?.id ?? '', {
      ...data,
      quantity: quantity ?? 1,
    });
  };

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <div className="side-action-container">
        <div className="side-action-actions" style={{ borderColor: colorTransform(typeColor, -15) ?? '' }}>
          <Settings
            style={{ color: '#556ee6' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              showModal.current?.open();
            }}
          />
          {!data.root && <RemoveCircleIcon style={{ color: '#f46a6a' }} onClick={() => removeNode(id)} />}
        </div>
      </div>
      <ModalFormWrapper
        ref={showModal}
        title={`Edycja`}
        form={() => (
          <ModuleForm
            moduleName={'manufacture-products'}
            id={data.product?.id ?? ''}
            showContextActions={false}
            showBackButton={false}
            showConfigurationSwitcher={false}
            overrideFormProps={{
              customTabs: ProductModule.configuration.form?.customTabs?.filter(el => el.title !== `Składniki`) ?? [],
              trackTabInUrl: false,
              forceReadonlyField: (field: any, values: any, fields: any[], recordId: null | string): boolean => {
                return (
                  ProductModule.configuration.form?.forceReadonlyField?.(field, values, fields, recordId) ||
                  field.propertyPath === 'technology'
                );
              },
            }}
            afterSave={(res, type, productData) => {
              ProductModule.api.get({ id: data.product?.id ?? '' }).then(res => {
                updateProduct(data.product?.id ?? '', {
                  ...data,
                  product: res,
                  unit: res.unit,
                });
                showModal.current?.close();
              });
            }}
            readonly={false}
          />
        )}
      >
        <></>
      </ModalFormWrapper>

      <ModuleListPicker<ProductType> moduleName={'manufacture-product-types'} onChange={handleProductTypeChange}>
        <TextInput
          disabled={false}
          label={''}
          value={data.product?.type?.name ?? 'Wybierz typ produktu'}
          className="nodrag bg-white node-product-type"
          inputProps={{
            min: 0,
            style: { background: colorTransform(typeColor, -15) ?? '' },
            startAdornment: (
              <InputAdornment position="start">
                <i
                  className={classNames('mdi ', {
                    [`mdi-${data.product?.type?.icon ?? 'folder'}`]: true,
                  })}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <span className="labeler-id">{data.product?.labelerId}</span>
              </InputAdornment>
            ),
          }}
        />
      </ModuleListPicker>
      <div className="product-flow-node" style={{ background: typeColor }}>
        <div>
          <TextInput
            disabled={false}
            label={'Nazwa produktu'}
            value={data.product?.['@formValues']?.[nameFieldId]?.value?.[selectedLanguage] ?? data.product?.name ?? ''}
            name="name"
            className="nodrag bg-white mt-1"
            onChange={handleProductFieldChange}
            labelProps={{
              style: {
                marginLeft:
                  (data.product?.['@formValues']?.[nameFieldId]?.value?.[selectedLanguage] ?? data.product?.name ?? '').length === 0 ? 25 : 0,
                color: '#303030',
              },
            }}
            inputProps={{
              min: 0,
              style: { paddingLeft: 0 },
              startAdornment: (
                <InputAdornment position="start" style={{ paddingLeft: 0, minWidth: 35 }}>
                  <LanguagePicker isReadonly={false} backgroundColor="transparent" border="none" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          {!(data?.root ?? false) && (
            <div style={{ maxWidth: '40%', paddingRight: 8 }}>
              <NumberInput
                disabled={false}
                label={'Ilość'}
                value={data.quantity ?? 1}
                className="nodrag bg-white"
                onChange={handleQuantityChange}
                inputProps={{
                  min: 0,
                  endAdornment: (
                    <ModuleListPicker<Unit>
                      moduleName={'manufacture-units'}
                      name="unit"
                      onChange={unit => handleProductFieldChange(unit, 'unit')}
                    >
                      <InputAdornment position="end">
                        {data.product?.unit?.name ?? <span style={{ color: 'red' }}>n/a</span>}
                      </InputAdornment>
                    </ModuleListPicker>
                  ),
                }}
              />
            </div>
          )}
          <div style={{ width: '100%' }}>
            <TextInput
              disabled={false}
              label={'Kod'}
              value={data.product?.symbol}
              name="symbol"
              onChange={handleProductFieldChange}
              className="nodrag bg-white"
            />
          </div>
        </div>
      </div>
      {(currentNode?.parentId ?? '_') != '0' && <Handle type="target" position={Position.Bottom} isConnectable={true} />}
      {!(data?.root ?? false) && <Handle type="source" position={Position.Top} isConnectable={true} />}
    </div>
  );
}

function FlowProductNode(props: FlowProductNodeProps) {
  const { productNodeMode } = useStore(state => ({ productNodeMode: state.productNodeMode }), shallowEqual);

  if (productNodeMode === ProductNodeModeBasic) {
    return <FlowProductNodeModeBasic {...props} />;
  }

  return <FlowProductNodeModeTechnologyFiles {...props} />;
}

export default memo(FlowProductNode, (left, right) => {
  return shallowEqual(left, right);
});
