import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Avatar,
  AvatarProps,
  Badge,
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  styled,
} from '@mui/material';
import { ChatManipulationFunction } from 'Modules/Chat/Hooks/UseChatManipulationFunctions';
import useRoomInfo from 'Modules/Chat/Hooks/UseRoomInfo';
import { Room } from 'Modules/Chat/Types/ApiModel';
import { memo, useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

const RoomListItem = styled(ListItem)(({ theme }) => ({
  '&:not(:hover) .MuiListItemSecondaryAction-root > .MuiIconButton-root': {
    display: 'none',
  },
  '.MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
  '.MuiListItemText-root': {
    overflow: 'hidden',
    '& .MuiListItemText-primary': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  '& a:hover': {
    color: 'inherit',
  },
}));

interface RoomListElementProps {
  room: Room;
  onlineUsers: string[];
  unreadRooms: string[];
  toggleHidden: ChatManipulationFunction['toggleRoomIsHidden'];
  toggleFavorite: ChatManipulationFunction['toggleRoomIsFavorite'];
  lastMessage: any;
  variant: 'recent' | 'favorite';
}

export default memo(function RoomListElement({
  room,
  onlineUsers,
  unreadRooms,
  variant,
  toggleHidden,
  toggleFavorite,
}: RoomListElementProps) {
  const path = `/chat/${room.id}`;
  const info = useRoomInfo(room, onlineUsers, unreadRooms);
  const isOpen = Boolean(useRouteMatch(path));
  const handleSecondaryActionClick = useCallback(() => {
    variant === 'recent' ? toggleHidden(room['@id'], true) : toggleFavorite(room['@id'], false);
  }, [variant, room, toggleHidden, toggleFavorite]);

  const secondaryAction =
    (info.isRead && (
      <IconButton size="small" onClick={handleSecondaryActionClick} disabled={isOpen}>
        <ClearIcon fontSize="small" />
      </IconButton>
    )) ||
    undefined;

  const avatarProps: AvatarProps = {
    src: typeof info.avatar === 'string' ? info.avatar : undefined,
    children: typeof info.avatar !== 'string' && info.avatar,
    sx: { width: 24, height: 24 },
  };

  return (
    <RoomListItem disablePadding dense secondaryAction={secondaryAction}>
      <ListItemButton selected={isOpen} component={Link} to={path}>
        <ListItemIcon>
          <Badge color={info.online ? 'success' : undefined} variant="dot">
            <Avatar {...avatarProps} />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={info.name} primaryTypographyProps={{ fontWeight: !info.isRead ? 'bold' : undefined }} />
        {!info.isRead && (
          <Box paddingRight={1.75}>
            <CircleIcon fontSize="inherit" color="primary" />
          </Box>
        )}
      </ListItemButton>
    </RoomListItem>
  );
});

export function RoomListElementPlaceHolder() {
  return (
    <RoomListItem disablePadding dense>
      <ListItemButton disabled>
        <ListItemIcon>
          <Avatar sx={{ width: 24, height: 24 }}>
            <Skeleton variant="circular" height="100%" width="100%" />
          </Avatar>
        </ListItemIcon>
        <ListItemText primary={<Skeleton />} />
      </ListItemButton>
    </RoomListItem>
  );
}
