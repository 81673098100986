import CreateRoom from './Components/CreateRoom';
import RoomView from './Components/RoomView';
import Sidebar from './Components/Sidebar';
import { Grid } from '@mui/material';
import { Redirect, Route, Switch } from 'react-router-dom';

export default function ChatPage() {
  return (
    <Grid container spacing={0} height="calc(100vh - 120px)" minHeight={400} overflow="auto">
      <Grid item xs={12} md={4} lg={3} xl={2} minWidth={300} maxHeight="100%" id="chat-rooms-container">
        <Sidebar />
      </Grid>
      <Grid item xs={12} sm maxHeight="100%">
        <Switch>
          <Route path="/chat/new" exact component={CreateRoom} />
          <Route path="/chat/:id" exact render={props => <RoomView key={props.match.params.id} />} />
          <Redirect to="/chat" />
        </Switch>
      </Grid>
    </Grid>
  );
}
