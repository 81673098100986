import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';
import { storeErrors } from 'store/form/errors/actions';

export const useErrorHandler = () => {
  const dispatch = useDispatch();

  return err => {
    let handled = false;
    dispatch(addSingleToast({ title: `Wystąpił problem zapisem rekordu.`, config: { appearance: 'error' } }));
    if (err.response) {
      const { status, data } = err.response;

      if (status === 422 && data.hasOwnProperty('violations')) {
        dispatch(storeErrors(data.violations));
        handled = true;
      }
    } else {
      console.error(err);
      dispatch(addSingleToast({ title: '' + err, config: { appearance: 'error' } }));
    }
    if (!handled) {
      Promise.reject(err);
    }
  };
};

export default useErrorHandler;
