import {
  API_ERROR,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_BY_PIN,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  SET_PERMISSIONS,
} from 'store/auth/login/actionTypes';

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginUserByPin = (pin, history) => {
  return {
    type: LOGIN_USER_BY_PIN,
    payload: { pin, history },
  };
};

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = error => {
  if (error.hasOwnProperty('response')) {
    switch (error.response.status) {
      case 400:
        error = error.response.data.title;
        break;
      case 401:
        error = error.response.data.message;
        break;
      default:
        console.error(error.response);
        error = 'Wysąpił błąd z odpowiedzią z API.';
    }
  }
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const setPermissions = permissions => {
  return {
    type: SET_PERMISSIONS,
    payload: permissions,
  };
};
