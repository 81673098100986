import { baseBuilder } from '../ModuleBuilder';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type ProductSpecies = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<ProductSpecies>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
];

const module: CTMModule<ProductSpecies> = baseBuilder<ProductSpecies>({
  id: '27aa5669-c4d0-4f27-a002-1dcde51d4992',
  dataClass: 'CTM\\Manufacture\\Entity\\ProductSpecies',
  urlPrefix: 'manufacture-product-species',
  name: 'Gatunek produktów',
  role: 'MANUFACTURE_PRODUCT',
  baseUrl: '/manufacture/product-species',
  columns: columns,
});

export default module;
