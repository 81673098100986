import BaseInput from 'components/Form/BaseInput';

const YouTubeTypeConfiguration = ({ configuration, onConfigurationUpdated }) => {
  const updateConfiguration = (value, field) => {
    onConfigurationUpdated({ ...configuration, [field]: value });
  };

  return (
    <BaseInput
      size={{ md: 12 }}
      label={'Wysokość ramki z filmem'}
      value={configuration?.height ?? 450}
      name={'height'}
      type={'number'}
      onChange={updateConfiguration}
    />
  );
};

export default YouTubeTypeConfiguration;
