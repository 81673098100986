import ListPicker from 'components/Form/ListPicker';

const FileTypeConfiguration = ({ configuration, onConfigurationUpdated }) => {
  const updateConfiguration = (value, field) => {
    onConfigurationUpdated({ ...configuration, [field]: value });
  };

  return (
    <ListPicker
      name={'multiple'}
      label={'Tryb działania'}
      value={configuration?.multiple === true}
      options={[
        { val: true, label: 'Pozwól wgrywać wiele plików' },
        { val: false, label: 'Pozwól na wgranie tylko jednego pliku' },
      ]}
      optionLabel="label"
      optionTrackBy="val"
      onChange={updateConfiguration}
      disableGroupMargin={true}
    />
  );
};

export default FileTypeConfiguration;
