import { InfiniteRoomListQueryKey, RoomsCollection } from 'Modules/Chat/Hooks/UseRoomsList';
import { Room } from 'Modules/Chat/Types/ApiModel';
import { patch } from 'helpers/Axios';
import { InfiniteData, QueryClient } from 'react-query';

export default async function toggleRoomIsHidden(roomIri: string, isHidden: boolean, queryClient: QueryClient) {
  patch<Room>(`${roomIri}/properties`, {
    isHidden: isHidden,
  }).then(updatedRoom => {
    queryClient.setQueryData<InfiniteData<RoomsCollection> | undefined>(InfiniteRoomListQueryKey, data => {
      if (!data) {
        return data;
      }

      return {
        ...data,
        pages: data.pages.map(page => ({
          ...page,
          'hydra:member': page['hydra:member'].map(room => (room['@id'] !== updatedRoom['@id'] ? room : updatedRoom)),
        })),
      };
    });
  });
}
