import useIsGranted from 'common/hooks/isGranted';
import SelectMultiple from 'components/Form/SelectMultiple';
import { Col, Container } from 'components/Theme/Grid';
import { withRouter } from 'react-router-dom';

const Manufacture = ({ form, updateConfiguration }) => {
  const canEdit = useIsGranted('ROLE_EDIT_CONFIGURATION');

  const updateFormValue = (value, field) => {
    updateConfiguration({ ...form, [field]: value });
  };

  return (
    <Container>
      <Col xs={12} md={12} lg={12}>
        <h5>Ogólne</h5>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <SelectMultiple
          size={{ md: 12 }}
          label={'Dni generowania zleceń na produkcję'}
          value={form.productionOrderDays}
          name="productionOrderDays"
          onChange={updateFormValue}
          disabled={!canEdit}
          optionTrackBy={'value'}
          options={[
            { value: 7, name: '7' },
            { value: 14, name: '14' },
            { value: 21, name: '21' },
            { value: 28, name: '28' },
          ]}
        />
      </Col>
    </Container>
  );
};

export default withRouter(Manufacture);
