import { ApiCollection, get } from 'helpers/Axios';
import { useQuery } from 'react-query';

export const UNREAD_ROOMS_QUERY_KEY = ['/chat/rooms/unread'];

export type UnreadRoomsQueryData = string[];

export default function useUnreadRoomsQuery() {
  return useQuery<UnreadRoomsQueryData>({
    queryKey: UNREAD_ROOMS_QUERY_KEY,
    queryFn: async () => (await get<ApiCollection>('/chat/rooms/unread'))['hydra:member'].map(item => item['@id']),
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    staleTime: Infinity,
  });
}
