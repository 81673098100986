import { Alert, Paper } from '@mui/material';
import { Col, Container } from 'components/Theme/Grid';
import { useState } from 'react';

function TemplateSelector({ templates, onSelectTemplate }) {
  const [filter, setFilter] = useState('');

  const resolveTemplates = () => {
    let filteredTemplates = templates;

    if (filter && filter !== '') {
      filteredTemplates = filteredTemplates.filter(el => {
        const name = (el.name ?? '').toLowerCase();

        return name.includes(filter.toLowerCase());
      });
    }

    return filteredTemplates;
  };

  return (
    <Container>
      <Col xs={12}>
        <Alert severity="info">Wybrany moduł posiada wiele szablonów wybierz jeden aby dodać nowy rekord</Alert>
        <Paper variant="outlined" style={{ padding: 6, background: '#192031', color: '#fff', marginTop: 12 }}>
          <input
            type="text"
            value={filter}
            placeholder="Szukaj szablonu..."
            onChange={({ target: { value } }) => setFilter(value)}
            className="transparent-input"
            style={{ textAlign: 'center', width: '100%' }}
          />
        </Paper>
      </Col>
      <Col xs={12}>
        <Container justifyItems="center" justifyContent="center">
          {resolveTemplates().map(template => (
            <Col key={template.id}>
              <Paper
                variant="outlined"
                style={{
                  padding: '24px 12px',
                  textAlign: 'center',
                  background: template.color ?? '#192031',
                  color: '#fff',
                  cursor: 'pointer',
                  minWidth: 240,
                }}
                onClick={() => onSelectTemplate(template)}
              >
                <i className={`mdi mdi-${template.icon ?? 'form-select'}`} style={{ fontSize: '2em' }} />
                <br />
                <span style={{ fontSize: '1.1em' }}>{template.name}</span>
              </Paper>
            </Col>
          ))}
        </Container>
      </Col>
    </Container>
  );
}

export default TemplateSelector;
