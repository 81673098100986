import DocumentForm from '../Warehouse/Document/Form';
import { withSecure } from 'components/Theme/Common/SecuredView';
import CategoryForm from 'pages/Configuration/Dictionary/CategoryForm';
import CategoryList from 'pages/Configuration/Dictionary/CategoryList';
import General from 'pages/Configuration/General';
import SingleModuleConfiguration from 'pages/Configuration/SingleModuleConfiguration';
import SystemFieldForm from 'pages/Configuration/SystemFields/Form';
import SystemFieldList from 'pages/Configuration/SystemFields/List';

const ReadonlySystemFieldForm = props => DocumentForm({ ...props, readonly: true });

export default [
  {
    path: '/configuration/general',
    exact: false,
    component: withSecure(General, 'ROLE_SHOW_CONFIGURATION|ROLE_EDIT_CONFIGURATION', true),
  },
  {
    path: '/configuration/fields/:module',
    exact: false,
    component: withSecure(SingleModuleConfiguration, 'ROLE_SHOW_CONFIGURATION|ROLE_EDIT_CONFIGURATION', true),
  },
  { path: '/modules/core-dictionary/list/:categoryId', exact: true, component: () => <CategoryList /> },
  { path: '/modules/core-dictionary/add/:categoryId', exact: true, component: () => <CategoryForm readonly={false} /> },
  {
    path: '/modules/core-dictionary/edit/:categoryId/:id',
    exact: true,
    component: () => <CategoryForm readonly={false} />,
  },
  {
    path: '/modules/core-dictionary/show/:categoryId/:id',
    exact: true,
    component: () => <CategoryForm readonly={true} />,
  },
  {
    path: '/configuration/system-field/create',
    exact: false,
    component: withSecure(SystemFieldForm, 'ROLE_EDIT_CONFIGURATION', true),
  },
  {
    path: 'configuration/system-field/:id/edit',
    exact: false,
    component: withSecure(SystemFieldForm, 'ROLE_EDIT_CONFIGURATION', true),
  },
  {
    path: 'configuration/system-field/:id',
    exact: false,
    component: withSecure(ReadonlySystemFieldForm, 'ROLE_EDIT_CONFIGURATION', true),
  },
  {
    path: '/configuration/system-field',
    exact: false,
    component: withSecure(SystemFieldList, 'ROLE_SHOW_CONFIGURATION', true),
  },
];
