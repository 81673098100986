import CRUDModule from 'Modules/Core/CRUDModule';
import CTMModule from 'Modules/Core/Types/CTMModule';
import { createContext, useContext, useMemo } from 'react';

interface ModulesContextValue {
  modules: { [key: string]: CRUDModule<CTMModule> };
}

const ModulesContext = createContext<ModulesContextValue>({
  modules: {},
});

export const useModulesContext = () => {
  return useContext<ModulesContextValue>(ModulesContext);
};

export function useModuleContext<T extends CTMModule<any>, ThrowOnError extends boolean = false>(
  module: string,
  findBy: null | 'dataClass' | 'id' = null,
  throwError = false,
): ThrowOnError extends true ? CRUDModule<T> : null | CRUDModule<T> {
  const ctx = useModulesContext();

  return useMemo<CRUDModule<T>>((): CRUDModule<T> => {
    if (!findBy) {
      const moduleContext = ctx.modules[module] ?? null;

      if (!moduleContext && throwError) {
        throw 'Module not found ' + module;
      }

      //@ts-ignore
      return moduleContext;
    }

    const moduleContext = Object.values(ctx.modules).find(el => el.configuration[findBy] === module) ?? null;

    if (!moduleContext && throwError) {
      throw 'Module not found ' + module;
    }

    //@ts-ignore
    return moduleContext;
  }, [module, findBy]);
}

export default ModulesContext;
