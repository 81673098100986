import LockButton from './LockButton';

interface LockButtonFormProps {
  id: string;
  locked: boolean;
  listRef: any;
  ApiFormComponent: any;
  big?: boolean;
}

const LockButtonForm = (props: LockButtonFormProps) => {
  return (
    <LockButton
      {...props}
      onChanged={newState => {
        props.ApiFormComponent?.current?.setRecord({ locked: newState });
      }}
    />
  );
};

export default LockButtonForm;
