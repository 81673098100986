import Contractor from '../Contractor/Contractor';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import { createModuleColumnConfig } from 'helpers/ModuleUtils';

export type Client = CTMRecord & {
  id?: string;
};

const columns: CTMListColumn<Client>[] = [
  {
    id: 'firstName',
    filterable: true,
    sortable: true,
    Header: 'Imię',
    accessor: 'firstName',
  },
  {
    id: 'lastName',
    Header: 'Nazwisko',
    accessor: 'lastName',
    filterable: true,
    sortable: true,
  },
  {
    id: 'email',
    Header: 'Email',
    accessor: 'email',
    filterable: true,
    sortable: true,
  },
  {
    id: 'phone',
    Header: 'Telefon',
    accessor: 'phone',
    filterable: true,
    sortable: true,
  },
  {
    id: 'contractor.id',
    Header: 'Kontrahent',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('contractor', Contractor),
  },
];

const module: CTMModule<Client> = {
  id: '1707bb2e-b8fc-465a-96ee-a640518d4871',
  dataClass: 'CTM\\Ecommerce\\Entity\\Client',
  urlPrefix: 'ecommerce-client',
  name: 'Klienci',
  role: 'ECOMMERCE_ORDER',
  api: {
    item: {
      get: ({ id }) => `/ecommerce/clients/${id}`,
      put: ({ id }) => `/ecommerce/clients/${id}`,
      delete: ({ id }) => `/ecommerce/clients/${id}`,
    },
    collection: {
      get: `/ecommerce/clients`,
      post: `/ecommerce/clients`,
    },
  },
  recordLabel: (record, allFields) => record.email,
  form: {},
  list: {
    columns: columns,
    storeFilters: true,
  },
};

export default module;
