import LightboxContainer from './components/Lightbox/LightboxContainer';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { ThemeProvider } from '@mui/material';
import App from 'App';
import { EmployeeModule } from 'Modules/CTMModules';
import GlobalTheme from 'components/Theme/GlobalTheme';
import ToastContainer from 'components/Toasts/ToastContainer';
import { get, setUpInterceptor } from 'helpers/Axios';
import 'i18n';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from 'serviceWorker';
import store from 'store';
import { getConfiguration } from 'store/Employee/Configuration/Actions';
import { logoutUser, setPermissions } from 'store/auth/login/actions';

const queryClient = new QueryClient();

const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <ThemeProvider theme={GlobalTheme}>
        <React.Fragment>
          <BrowserRouter>
            <SnackbarProvider>
              <QueryClientProvider client={queryClient}>
                <App />
                <ToastContainer />
                <LightboxContainer />
              </QueryClientProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </React.Fragment>
      </ThemeProvider>
    </Provider>,
  );
  serviceWorker.unregister();
};

setUpInterceptor(store);

if (store.getState().Login.token) {
  get(EmployeeModule.configuration.api.item.myPermissions)
    .then(({ permissions }) => {
      store.dispatch(setPermissions(permissions));
      store.dispatch(getConfiguration());
      renderApp();
    })
    .catch(() => {
      store.dispatch(logoutUser());
      renderApp();
    });
} else {
  renderApp();
}
