import CharsCounter from '@Components/CustomFields/Shared/CharsCounter';
import BaseScalarInput, { BaseScalarInputType } from 'components/CustomFields/FieldTypes/BaseScalarInput';

const StringField: BaseScalarInputType = props =>
  BaseScalarInput({
    ...props,
    type: 'text',
    nextRowContent: <CharsCounter maxChars={props.field.config.limit ?? 0} value={props.value ?? ''} />,
  });

export default StringField;
