import { createTheme } from '@mui/material';
import { plPL as corePlPL } from '@mui/material/locale';
import { plPL as datePickerPlPL } from '@mui/x-date-pickers';

export default createTheme(
  {
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
          fullWidth: true,
          size: 'small',
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
        },
      },
    },
    palette: {
      primary: {
        main: '#556EE6FF',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#74788DFF',
      },
      error: {
        main: '#F46A6AFF',
      },
      warning: {
        main: '#F1B44CFF',
        contrastText: '#ffffff',
      },
      success: {
        main: '#34C38FFF',
        contrastText: '#ffffff',
      },
      info: {
        main: '#50A5F1FF',
        contrastText: '#ffffff',
      },
    },
  },
  corePlPL,
  datePickerPlPL,
);
