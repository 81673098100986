import { createModuleColumnConfig } from '../../helpers/ModuleUtils';
import Contractor, { Contractor as ContractorModel } from '../Contractor/Contractor';
import SelectStatusColumnFilter from './Components/SelectColumStatusFilter';
import StatusView from 'Modules/Core/Components/StatusView';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import dayjs from 'dayjs';

export type Transaction = CTMRecord & {
  contractor?: string | ContractorModel;
  id?: string;
};

const columns: CTMListColumn<Transaction>[] = [
  {
    id: 'id',
    filterable: true,
    sortable: true,
    Header: 'ID',
    noBreak: true,
    accessor: 'id',
  },
  {
    id: 'contractor.id',
    Header: 'Kontrahent',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('contractor', Contractor),
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'ecommerce-transaction'} status={status} />,
    filterable: true,
    Filter: SelectStatusColumnFilter,
    filter: 'equals',
  },
  {
    id: 'amount',
    Header: 'Wartość',
    accessor: ({ amount, currency }) => amount + ' ' + currency,
    filterable: true,
    sortable: true,
    minWidth: 160,
  },
  {
    id: 'createdAt',
    Header: 'Data utworzenia',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'settledAt',
    Header: 'Data płatności',
    accessor: ({ settledAt }) => (settledAt ? dayjs(settledAt).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'paymentGateway',
    filterable: true,
    sortable: true,
    Header: 'Bramka płatności',
    noBreak: true,
    accessor: 'paymentGateway',
  },
];

const module: CTMModule<Transaction> = {
  id: 'cea1f2db-7488-4e54-8ba2-28e32b627b6e',
  dataClass: 'CTM\\Ecommerce\\Entity\\Transaction',
  urlPrefix: 'ecommerce-transaction',
  name: 'Transakcje',
  role: 'ECOMMERCE_ORDER',
  api: {
    item: {
      get: ({ id }) => `/ecommerce/transactions/${id}`,
      put: ({ id }) => `/ecommerce/transactions/${id}`,
      delete: ({ id }) => `/ecommerce/transactions/${id}`,
    },
    collection: {
      get: `/ecommerce/transactions`,
      post: `/ecommerce/transactions`,
    },
  },
  recordLabel: (record, allFields) => record.id,
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'createdAt', desc: true }],
    storeFilters: false,
    board: false,
  },
};

export default module;
