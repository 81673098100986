import useStore from './store';
import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { Viewport, useOnViewportChange } from 'reactflow';

export default function ViewPortController() {
  const { nodes, setNodes } = useStore(
    state => ({
      nodes: state.nodes,
      setNodes: state.setNodes,
    }),
    shallowEqual,
  );
  const handleViewportChange = useCallback(
    (viewport: Viewport) =>
      setNodes(
        nodes.map(node => {
          if (node.type === 'technologyNodeLabel') {
            return { ...node, position: { x: Math.abs(Math.min(0, viewport.x) / viewport.zoom) - 45, y: node.position.y }, zIndex: 50000 };
          }
          return node;
        }),
      ),
    [nodes],
  );
  useOnViewportChange({
    onChange: handleViewportChange,
    onEnd: handleViewportChange,
  });

  return null;
}
