import { AddCircle } from '@mui/icons-material';
import { Button, Paper } from '@mui/material';
import { Col, Container } from 'components/Theme/Grid';
import Place from 'pages/Warehouse/Warehouse/FormComponents/Place';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';

const Places = ({ record, onUpdate, readonly }) => {
  const [places, setPlaces] = useState(record.places);

  const onChange = useCallback((index, newPlace) => {
    setPlaces(prevPlaces =>
      prevPlaces.map((item, i) => {
        return index !== i ? item : { ...newPlace };
      }),
    );
  }, []);

  const addNewPlace = useCallback(() => {
    setPlaces(prevPlaces => {
      return [...prevPlaces, { name: null, description: null }];
    });
  }, []);

  useEffect(() => {
    onUpdate({ ...record, places: places });
  }, [places]);

  return (
    <Fragment>
      <Container>
        <Col xs={12}></Col>
        <Col xs={12}>
          <Container>
            {places.map((place, index) => (
              <Place key={index} record={place} index={index} onChange={onChange} readonly={readonly} />
            ))}
          </Container>
        </Col>
        <Col xs={12}>
          <Paper style={{ padding: 12 }}>
            <Button fullWidth={true} onClick={() => addNewPlace()}>
              <AddCircle /> Utwórz nowe miejsce magazynowe
            </Button>
          </Paper>
        </Col>
      </Container>
    </Fragment>
  );
};

export const TabConfiguration = {
  title: 'Miejsca magazynowe',
  component: memo(Places),
};

export default Places;
