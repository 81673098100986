import Statuses, { StatusValues } from '../../Types/Statuses';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TaskModule } from 'Modules/CTMModules';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';
import { v4 as uuidv4 } from 'uuid';

const Label = (color, iconClass, text) => (
  <span style={{ paddingRight: 4, color, display: 'flex', alignItems: 'center' }}>
    <i className={iconClass} style={{ paddingRight: 4, fontSize: 24, display: 'initial' }} />
    <span style={{ background: color, color: '#fff', padding: '2px 4px', borderRadius: 10 }}>{text}</span>
  </span>
);

const Labels = [
  {
    value: StatusValues.canceled,
    label: Label('#c62828', 'bx bx-stop-circle', Statuses[StatusValues.canceled]),
  },
  {
    value: StatusValues.created,
    label: Label('#ba68c8', 'bx bx-checkbox', Statuses[StatusValues.created]),
  },
  {
    value: StatusValues.waiting,
    label: Label('#0288d1', 'bx bx-user-voice', Statuses[StatusValues.waiting]),
  },
  // {
  //     value: StatusValues.readyForWork,
  //     label: Label('#0288d1', 'bx bx-user-voice', Statuses[StatusValues.readyForWork]),
  // },
  {
    value: StatusValues.inProgress,
    label: Label('#ed6c02', 'bx bx-wrench', Statuses[StatusValues.inProgress]),
  },
  {
    value: StatusValues.done,
    label: Label('#2e7d32', 'bx bx-happy', Statuses[StatusValues.done]),
  },
];
const StatusPicker = ({ id, value }) => {
  const [val, setVal] = useState<number>(parseInt(value));
  const [key, setKey] = useState<string>(uuidv4());
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = newVal => {
    setAnchorEl(null);
    if (newVal !== value) {
      setLoading(true);
      TaskModule.api
        .put({ status: newVal }, { id: id })
        .then(() => {
          setVal(newVal);
          setLoading(false);
          dispatch(addSingleToast({ title: `Zapisano zmiany`, config: { appearance: 'success' } }));
        })
        .catch(err => {
          setLoading(false);
          setKey(uuidv4());
          dispatch(
            addSingleToast({ title: err?.response?.['hydra:description'] ?? 'Wystąpił błąd z zapisem', config: { appearance: 'error' } }),
          );
        });
    }
  };

  const SelectedLabel = useMemo(() => {
    const displayLabel = Labels.find(el => el.value === val);

    if (displayLabel) {
      return <>{displayLabel.label}</>;
    }
    return <>-</>;
  }, [val]);

  if (loading) {
    return <Button startIcon={!loading ? null : <CircularProgress size={16} />}>zapisywanie...</Button>;
  }
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {SelectedLabel}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {Labels.map(el => (
          <MenuItem key={el.value} onClick={() => handleChange(el.value)}>
            {el.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default StatusPicker;
