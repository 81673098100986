import { ProductModule } from 'Modules/CTMModules';
import { get } from 'helpers/Axios';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { addSingleToast } from 'store/Toast/actions';

export default function Ebay({ productId }: { productId: string }) {
  const token = useAppSelector(root => root.Login.token);
  const dispatch = useDispatch();
  if (!token) {
    return <></>;
  }

  const downloadURL = `${process.env.REACT_APP_API_BASE_URL}${ProductModule.configuration.api.item.downloadEbayTemplate({
    id: productId ?? '',
  })}?token=${encodeURIComponent(token)}`;

  const onClick = async () => {
    const template = await get(downloadURL, {
      headers: {
        accept: 'text/html',
      },
    });

    dispatch(
      addSingleToast({
        title: `Skopiowano do schowka. Wklej w odpowiednie miejsce.`,
        config: { appearance: 'success', placement: 'top-right' },
      }),
    );

    setTimeout(() => {
      const win = window.open(
        '',
        '',
        `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${window.outerWidth},height=${
          window.outerHeight - 300
        },top=300,left=0`,
      );

      if (win) {
        win.document.body.innerHTML = template;
        win.document.title = 'Podgląd szablonu, zamknie się automatycznie po 5 minutach';
        setTimeout(() => {
          win.close();
        }, 300000);
      }
    }, 200);

    navigator.clipboard.writeText(template);
  };

  return <img src="/ebay.png" alt="ebay" style={{ width: '36px', cursor: 'pointer' }} onClick={onClick} />;
}
