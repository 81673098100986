import { CLEAR_ERRORS, STORE_ERRORS } from 'store/form/errors/actionTypes';

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
    payload: {},
  };
};

export const storeErrors = violations => {
  return {
    type: STORE_ERRORS,
    payload: { violations },
  };
};
