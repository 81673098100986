import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import BaseInput from 'components/Form/BaseInput';
import { axiosApi } from 'helpers/Axios';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';

const ProcessErrandRowTable: FC<{ ingredients: Array<any>; external: boolean }> = ({ ingredients, external }) => {
  const dispatch = useDispatch<any>();
  const buttonStyle = { borderRadius: '50%', padding: '2px', minWidth: '30px', color: '#fff' };

  const getMissingQuantity: (quantityAvailable: number, quantityNeeded: number) => number = (quantityAvailable, quantityNeeded) => {
    if (quantityAvailable < quantityNeeded) {
      return Math.abs(quantityAvailable - quantityNeeded);
    }

    return 0;
  };

  const onFieldChange: (value: number, name: string, id: string) => void = (value, name, id) => {
    axiosApi
      .put(`/manufacture/errand-row-product-ingredients/${id}`, {
        [name]: value,
      })
      .then(() => {
        dispatch(addSingleToast({ title: `Zapisano zmiany`, config: { appearance: 'success' } }));
      })
      .catch(data => {
        dispatch(
          addSingleToast({
            title: data?.response?.['hydra:description'] ?? 'Wystąpił błąd z zapisem',
            config: { appearance: 'error' },
          }),
        );
      });
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Symbol</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Nazwa</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Wymagana ilość</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Ilość w magazynie</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Brakująca ilość</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Rezerwacja</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>{external ? 'Zamówienie' : 'Produkcja'}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {ingredients.map(item => (
          <TableRow key={item.id}>
            <TableCell>{item.product.labelerId}</TableCell>
            <TableCell>{item.product.symbol}</TableCell>
            <TableCell>{item.product.name}</TableCell>
            <TableCell style={{ textAlign: 'center' }}>{item.quantity}</TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <Button variant={'contained'} color={'success'} style={buttonStyle}>
                {item.product.quantityAvailable}
              </Button>
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <Button variant={'contained'} color={'error'} style={buttonStyle}>
                {getMissingQuantity(item.product.quantityAvailable, item.quantity)}
              </Button>
            </TableCell>
            <TableCell>
              <BaseInput
                type="number"
                name={'reserve'}
                value={parseFloat(item.reserve)}
                onChange={(value, name) => onFieldChange(parseFloat(value), name, item.id)}
              />
            </TableCell>
            <TableCell>
              <BaseInput
                type="number"
                name={external ? 'order' : 'produce'}
                value={parseFloat(external ? item.order : item.produce)}
                onChange={(value, name) => onFieldChange(parseFloat(value), name, item.id)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ProcessErrandRowTable;
