import { Cancel } from '@mui/icons-material';
import { Button, TableCell, TableRow } from '@mui/material';
import SelectInput from 'components/Form/MUI/SelectInput';
import RelationSinglePicker from 'components/Form/RelationSinglePicker';
import { Col, Container } from 'components/Theme/Grid';
import { FC } from 'react';

const AdditionalActionRow: FC<{ additionalAction: any; onUpdated: any; onRemove: any; readonly: boolean }> = props => {
  const { additionalAction, onUpdated, onRemove, readonly } = props;
  const updateFormValue = (value, field) => {
    onUpdated({ ...additionalAction, [field]: value });
  };

  return (
    <TableRow>
      <TableCell size="small" variant="body">
        <Button variant="contained" color="error" size="small" style={{ color: '#fff' }} onClick={onRemove}>
          <Cancel />
        </Button>
      </TableCell>
      <TableCell size="small" variant="body">
        {additionalAction.position}
      </TableCell>
      <TableCell size="small" variant="body">
        <SelectInput
          value={additionalAction.type}
          onChange={updateFormValue}
          isLoading={false}
          disabled={readonly}
          options={[
            { value: 'BEFORE', label: 'Przed' },
            { value: 'AFTER', label: 'Po' },
          ]}
          trackKey={'value'}
          name={'type'}
          labelKey={'label'}
          isClearable={false}
        />
      </TableCell>
      <TableCell size="small" variant="body">
        <SelectInput
          value={additionalAction.trigger}
          onChange={updateFormValue}
          isLoading={false}
          disabled={readonly}
          options={[
            { value: 'TASK_CREATED', label: 'Zadanie zostało utworzone' },
            { value: 'TASK_STATUS_DONE', label: 'Zadanie zmieniło status na wykonane' },
          ]}
          trackKey={'value'}
          name={'trigger'}
          labelKey={'label'}
          isClearable={false}
        />
      </TableCell>
      <TableCell size="small" variant="body">
        <SelectInput
          value={additionalAction.action}
          onChange={updateFormValue}
          isLoading={false}
          disabled={readonly}
          options={[
            { value: 'ACTION_CREATE_TASK', label: 'Utwórz zadania poprzedzające' },
            { value: 'ACTION_MERGE_TASKS', label: 'Połącz zadania następujące' },
          ]}
          trackKey={'value'}
          name={'action'}
          labelKey={'label'}
          isClearable={false}
        />
      </TableCell>
      <TableCell size="small" variant="body">
        {additionalAction.action === 'ACTION_CREATE_TASK' && (
          <>
            <Container>
              <Col xs={12}>
                <RelationSinglePicker
                  moduleName={'manufacture-technology'}
                  size={{ md: 12 }}
                  label={'Technologia'}
                  value={additionalAction.actionTechnology}
                  name={'actionTechnology'}
                  tabIndex={-1}
                  onChange={(iri, id) => {
                    updateFormValue(iri, id);
                  }}
                  optionLabel="relationFieldLabel"
                  disabled={readonly}
                />
              </Col>
            </Container>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default AdditionalActionRow;
