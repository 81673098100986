import classnames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, FormText, InputGroup, Label, Row } from 'reactstrap';
import { useAppSelector } from 'store';

export interface PlaceholderInputProps {
  className?: string;
  value: any;
  name?: string;
  helpText?: string;
  label?: string;
  size?: { md: number };
  onClick?: () => void;
  beforeInputContent?: any;
  afterInputContent?: any;
  disableGroupMargin?: boolean;
  errorKey?: string;
  fullHeight?: boolean;
  fullInputHeight?: boolean;
  verticalCenter?: boolean;
  hideArrows?: boolean;
  placeholder?: any;
}

const PlaceholderInput: FC<PlaceholderInputProps> = ({
  className = 'mb-3',
  value,
  name,
  helpText,
  label = null,
  size = { md: 12 },
  onClick = () => undefined,
  beforeInputContent,
  afterInputContent,
  disableGroupMargin,
  errorKey,
  fullHeight,
  fullInputHeight,
  verticalCenter,
  hideArrows = false,
  placeholder = false,
}) => {
  const { t } = useTranslation();
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));

  const hasError = () => {
    return violations.hasOwnProperty(errorKey ?? name ?? '');
  };
  const getError = () => {
    if (hasError()) {
      return violations[errorKey ?? name ?? ''].join('\n');
    }
    return '';
  };

  return (
    <Col {...size} className={className} style={fullHeight ? { height: '100%' } : {}}>
      <Row className={classnames('', { 'h-100': fullHeight })}>
        <FormGroup className={classnames({ 'disable-group-margin': disableGroupMargin })}>
          {label && label !== '' && <Label>{t(label)}</Label>}
          <InputGroup
            style={{
              ...(verticalCenter ? { top: '50%', transform: 'translateY(-50%)' } : {}),
              ...(fullInputHeight ? { height: 'calc(100% - 34px)' } : {}),
            }}
          >
            {beforeInputContent}
            <div className={classnames('form-control', { 'hide-arrows': hideArrows })} onClick={onClick}>
              {value ?? placeholder ?? <>&nbsp;</>}
            </div>
            {afterInputContent}
            {helpText && <FormText>{helpText}</FormText>}
            {hasError() && <FormFeedback>{getError()}</FormFeedback>}
          </InputGroup>
        </FormGroup>
      </Row>
    </Col>
  );
};

export default PlaceholderInput;
