import classnames from 'classnames';
import { FC } from 'react';

const CheckboxColumn: FC<{ active: boolean | null | undefined }> = ({ active }) => {
  return (
    <div className="font-size-20 text-center" style={{ margin: '0 auto' }}>
      <i
        className={classnames('mdi', {
          'mdi-check-circle text-success': active === true,
          'mdi-minus-circle text-danger': active !== true,
        })}
      />
    </div>
  );
};

export default CheckboxColumn;
