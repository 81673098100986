import { usePropertyValues } from '../Hooks/usePropertyValues';
import { Button } from '@mui/material';
import { FC } from 'react';

const Status: FC<{ moduleName: string; propertyName?: string; status?: number }> = ({ moduleName, propertyName, status }) => {
  if (!propertyName) {
    throw new Error('propertyName is required');
  }

  const { data: statuses } = usePropertyValues(moduleName, propertyName);

  if (status === null) {
    return null;
  }

  const item = statuses?.find(item => item.value === status);

  return (
    <Button
      variant={'contained'}
      startIcon={item?.icon ? <i className={`mdi mdi-${item?.icon}`} style={{ lineHeight: 'initial', paddingLeft: 4 }}></i> : undefined}
      style={{
        padding: '3px',
        color: '#fff',
        margin: '0 2px',
        backgroundColor: item?.color,
        width: '100%',
      }}
    >
      {item?.label}
    </Button>
  );
};

export default Status;

Status.defaultProps = {
  propertyName: 'status',
};
