import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import IconInput from '@Components/Form/IconInput';
import { InputGroupText } from 'reactstrap';

const IconPicker: CustomFieldComponentType = ({ field, value, onChange, readonly }) => {
  return (
    <>
      <IconInput
        label={field.name}
        value={value}
        name={field.id}
        onChange={onChange}
        disabled={readonly}
        beforeInputContent={
          <InputGroupText>
            <i className={`mdi mdi-${value}`} />
          </InputGroupText>
        }
        afterInputContent={
          <InputGroupText style={{ cursor: 'pointer' }} onClick={() => window.open('https://materialdesignicons.com/', '_blank')}>
            <i className={`mdi mdi-open-in-new`} />
            &nbsp;Zobacz dostępne opcje
          </InputGroupText>
        }
      />
    </>
  );
};
export default IconPicker;
