import ModuleListPicker from 'components/Module/ModuleListPicker';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { rootSetLanguage } from 'store/Root/Root';

const IconMap = {
  en: 'us',
};

const LanguagePicker = ({ isReadonly, backgroundColor = 'transparent', border = '1px solid rgb(206, 212, 218)' }) => {
  const selectedLanguage = useAppSelector(state => state.Root.language) as string;
  const dispatch = useDispatch();
  const handleLanguagePick = useCallback(languageRow => {
    dispatch(rootSetLanguage(languageRow.isoCode));
  }, []);

  return (
    <ModuleListPicker
      disabled={isReadonly}
      moduleName={'core-language'}
      onChange={handleLanguagePick}
      disableClear={true}
      defaultFilters={[]}
      overrideUrl={undefined}
      moduleFormComponent={undefined}
      moduleFormComponentProps={undefined}
      moduleFormWrapperProps={undefined}
      allowMultipleSelect={false}
      onMultipleSelect={console.error}
    >
      <div
        style={{
          width: 35,
          height: '20px',
          border: border,
          backgroundColor: backgroundColor,
          position: 'relative',
          borderRadius: '4px',
          marginRight: 5,
          overflow: 'hidden',
          cursor: 'pointer',
        }}
        title={'Zmień język pola'}
      >
        <div
          className={`fi fi-${IconMap?.[selectedLanguage] ?? selectedLanguage}`}
          style={{ width: '90%', height: 'calc(100% - 2px)', position: 'absolute', left: '5%', top: '50%', transform: 'translateY(-50%)' }}
        >
          &nbsp;
        </div>
      </div>
    </ModuleListPicker>
  );
};

export default LanguagePicker;
