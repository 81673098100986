import ProcessErrandRow from './ProcessErrandRow';
import { Button } from '@mui/material';
import { ErrandModule } from 'Modules/CTMModules';
import classnames from 'classnames';
import useApiResourceQuery from 'helpers/Axios';
import { useMemo, useState } from 'react';
import { Link, useHistory, useParams, withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

const Process: () => JSX.Element = () => {
  const id = useParams<{ id: string }>().id;
  const iri = useMemo<string>(() => '/manufacture/errands/' + id, [id]);
  const history = useHistory<any>();
  const [activeTab, setActiveTab] = useState<number>(0);

  const toggle: (index: number) => void = index => {
    if (activeTab === index) {
      return;
    }
    setActiveTab(index);
  };

  const [{ data: errand, isLoading: isLoading }] = useApiResourceQuery<any>(iri, {
    onError: () => history.replace(`/modules/manufacture-errand/show/${id}`),
  });

  const confirm: () => void = () => {
    ErrandModule.api
      .put(
        {
          status: 3000,
        },
        { id },
      )
      .then(() => {
        history.replace(`/modules/manufacture-errand/show/${id}`);
      });
  };

  if (isLoading) {
    return <>Trwa ładowanie danych.</>;
  }

  return (
    <Container fluid={true}>
      <Row className="mb-2">
        <Col xs={10} md={10} className="align-self-center">
          <h4>Procesowanie {errand.number}</h4>
        </Col>
        <Col xs={2} md={2} className={'align-self-center justify-content-end text-end'}>
          <Link to={`/modules/manufacture-errand/edit/${id}`} className="btn btn-primary ml-1">
            <i className="mdi mdi-arrow-left" />
            Wróć do zlecenia
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav tabs className="bg-dark-accent border-radius-top position-relative">
            {errand.rows.map((item, index) => (
              <NavItem key={`NavItem_${index}`}>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: activeTab === index,
                    'dark-accent-color': activeTab !== index,
                  })}
                  onClick={() => {
                    toggle(index);
                  }}
                >
                  {item.product.relationFieldLabel}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className={classnames('border-small', 'border-top-0 border-radius-top-left-none')}>
            <CardBody>
              <TabContent activeTab={activeTab} className="p-3 text-muted">
                {errand.rows.map((item, index) => (
                  <TabPane tabId={parseInt(index)} key={`TabPane${index}`}>
                    <ProcessErrandRow errandRowId={item.id} />
                  </TabPane>
                ))}
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12 text-end">
          <Button color={'success'} variant={'contained'} type="submit" onClick={confirm}>
            <i className={'mdi mdi-content-save'} />
            &nbsp;Wygeneruj komplet dokumentów i wyslij zlecenie na produkcję
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default withRouter(Process);
