import PackingAndShipmentRowPositionButton from './PackingAndShipmentRowPositionButton';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { ButtonGroup } from '@mui/material';
import NumberField from 'components/CustomFields/FieldTypes/NumberField';

const CostOfShipmentNet: CustomFieldComponentType = ({ field, fields, values, value, onChange, readonly, errorKey }) => {
  return (
    <>
      <NumberField
        field={field}
        readonly={readonly}
        errorKey={errorKey}
        value={value}
        onChange={onChange}
        fields={[]}
        afterInputContent={
          <>
            {!readonly && (
              <ButtonGroup>
                <PackingAndShipmentRowPositionButton values={values} onChange={onChange} fields={fields} />
              </ButtonGroup>
            )}
          </>
        }
      />
    </>
  );
};
CostOfShipmentNet.provideAllValues = true;
export default CostOfShipmentNet;
