import { useModuleContext } from 'context/ModulesContext';
import { useQuery, useQueryClient } from 'react-query';

export const queryKey = 'allDocumentTypes';

export const useDocumentTypes = () => {
  const DocumentTypesModule = useModuleContext('warehouse-document-types');
  return useQuery(queryKey, () => DocumentTypesModule.api.getAll({ params: { page: 1, limit: 1000 } }).then(res => res['hydra:member']), {
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchInterval: false,
  });
};

export const useInvalidateDocumentTypes = () => {
  const queryClient = useQueryClient();
  return queryClient.invalidateQueries(queryKey);
};

export default { queryKey, useDocumentTypes, useInvalidateDocumentTypes };
