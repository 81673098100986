import ModuleList from '../../../../components/Module/ModuleList';
import { TransactionModule } from '../../../CTMModules';
import { FC } from 'react';

interface TransactionTab {
  record: any;
}

const TransactionTab: FC<TransactionTab> = ({ record }) => {
  return (
    <ModuleList
      key={TransactionModule.configuration.urlPrefix}
      moduleName={TransactionModule.configuration.urlPrefix}
      defaultFilters={[{ id: 'clientOrder.id', value: record.id }]}
      useModal={false}
      overrideListProps={{ storeFilters: false }}
    />
  );
};

export default TransactionTab;
