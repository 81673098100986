import { Button } from '@mui/material';
import { usePropertyValues } from 'Modules/Core/Hooks/usePropertyValues';
import ListPicker from 'components/Form/ListPicker';
import { useEffect, useState } from 'react';

export const SelectStatusColumnFilter = ({ column }) => {
  const [val, setVal] = useState(column.filterValue ?? null);
  const { data: statuses, isLoading } = usePropertyValues('warehouse-assembling-order', 'status');

  useEffect(() => {
    setVal(column.filterValue ?? null);
  }, [column.filterValue]);

  if (isLoading) {
    return <></>;
  }

  return (
    <ListPicker
      className="_"
      disableGroupMargin={true}
      size={{ md: 12 }}
      value={val}
      name={'search-field'}
      onChange={value => {
        if (value !== null) {
          setVal(value);
          column.setFilter(value);
        } else {
          setVal(null);
          column.setFilter(null);
        }
      }}
      options={[{ val: null, label: 'Wszystkie', color: '#50A5F1FF' }, ...statuses]}
      optionLabel={item => (
        <Button
          variant={'contained'}
          style={{
            width: '100%',
            padding: '3px',
            color: '#fff',
            margin: '0 2px',
            backgroundColor: item?.color,
          }}
        >
          {item?.label}
        </Button>
      )}
      placeholder={`${column.Header}...`}
      optionTrackBy="value"
      isClearable={true}
    />
  );
};

export default SelectStatusColumnFilter;
