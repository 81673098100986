import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Tooltip, Typography } from '@mui/material';
import { DictionaryModule, DictionaryValueModule } from 'Modules/CTMModules';
import StringField from 'components/CustomFields/FieldTypes/StringField';
import PlaceholderInput from 'components/Form/PlaceholderInput';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import { get } from 'helpers/Axios';
import { InnerForm } from 'pages/Configuration/Dictionary/CreateDictionaryValueModal';
import { useEffect, useState } from 'react';

const ModuleDictionaryValueForm = ({ modal, afterSave, dictionary }) => {
  return (
    <InnerForm
      dictionary={dictionary}
      onSaved={res => {
        afterSave(res);
        modal?.current?.close();
      }}
      onClose={() => modal?.current?.close()}
    />
  );
};

const DictionaryField: CustomFieldComponentType = ({ field, value, onChange, readonly, errorKey, customProps = {}, ...restProps }) => {
  const [val, setVal] = useState(value);

  useEffect(() => {
    if (typeof value === 'string') {
      get(value).then(res => {
        setVal(res);
      });
    } else if (JSON.stringify(val) !== JSON.stringify(value)) {
      setVal(value);
    }
  }, [value]);

  return (
    <ModuleListPicker
      disableClear={!val}
      disabled={readonly}
      moduleName={DictionaryValueModule.configuration.urlPrefix}
      overrideUrl={DictionaryModule.configuration.api.item.getValues({ id: field.config.dictionaryId })}
      onChange={obj => {
        onChange(obj, field.id);
      }}
      moduleFormComponent={ModuleDictionaryValueForm}
      moduleFormComponentProps={{ dictionary: `/core/dictionaries/${field.config.dictionaryId}` }}
      moduleFormWrapperProps={{ modalStyles: { maxWidth: 600 } }}
    >
      {val && (
        <Tooltip
          title={
            <Typography variant={'subtitle2'} component={'div'}>
              {val.description}
            </Typography>
          }
          placement={'right-end'}
          disableHoverListener={(val.description ?? '').length === 0}
        >
          <PlaceholderInput
            label={field.name || 'Nazwa pola'}
            name={field.id}
            value={
              <span style={{ color: val.color ? val.color : '#303030' }}>
                {val.icon && <i className={`mdi mdi-${val.icon}`} />}
                {val.name}
              </span>
            }
          />
        </Tooltip>
      )}

      {!val && (
        <StringField
          {...restProps}
          field={field}
          onChange={() => false}
          value={val?.name ?? null}
          readonly={readonly}
          errorKey={errorKey}
        />
      )}
    </ModuleListPicker>
  );
};

export default DictionaryField;
