export enum Priority {
  LOW = 1000,
  MEDIUM = 2000,
  HIGH = 3000,
  CRITICAL = 4000,
}

export enum PriorityColor {
  LOW = '#80deea',
  MEDIUM = '#303030',
  HIGH = '#ef6c00',
  CRITICAL = '#d50000',
}

export {};
