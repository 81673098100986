import useFormError from '../useFormError';
import { BaseInputProps } from './Types';
import { FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

interface CheckboxInputProps extends BaseInputProps {
  value?: boolean;
  onChange?: (value: undefined | boolean | null, name: undefined | string) => void;
}

const CheckboxInput = ({ value, label, onChange, name, disabled }: CheckboxInputProps) => {
  const [hasErrors, errorMessage] = useFormError(name ?? '');

  return (
    <FormControl required error={hasErrors} component="fieldset" variant="outlined">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              value={value === true}
              disabled={disabled}
              checked={value === true}
              onChange={(e, val) =>
                onChange ? onChange(val, name) : console.warn('CheckboxInput component not implement onChange callback')
              }
            />
          }
          label={label}
        />
      </FormGroup>
      {hasErrors && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxInput;
