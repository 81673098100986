import BaseInput from 'components/Form/BaseInput';

const DividerTypeConfiguration = ({ configuration, onConfigurationUpdated }) => {
  const updateConfiguration = (value, field) => {
    onConfigurationUpdated({ ...configuration, [field]: value });
  };

  return (
    <BaseInput
      size={{ md: 12 }}
      label={'Text widoczny na belce'}
      value={configuration?.label ?? ''}
      name={'label'}
      onChange={updateConfiguration}
      type={'textarea'}
    />
  );
};

export default DividerTypeConfiguration;
