import { FileDownload, UploadFile } from '@mui/icons-material';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Collapse from '@mui/material/Collapse';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
// web.cjs is required for IE11 support
import { TransitionProps } from '@mui/material/transitions';
import { ProductModule, TechnologyModule } from 'Modules/CTMModules';
import { Product } from 'Modules/Manufacture/Types/Product';
import { Technology, TechnologyFileType } from 'Modules/Manufacture/Types/Technology';
import MultipleFileUpload from 'components/Form/MultipleFileUpload';
import { Col, Container } from 'components/Theme/Grid';
import _ from 'lodash';
import { memo, useMemo } from 'react';
import { UseQueryResult, useQueries } from 'react-query';
import { useAppSelector } from 'store';
import { v4 as uuidv4 } from 'uuid';

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  return (
    <div>
      <Collapse {...props} />
    </div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} TransitionComponent={TransitionComponent} />)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

interface ProductTechnologyFilesTreeProps {
  product: Product;
  onUpdate: any;
}

interface TechnologyTree {
  [key: string]: TechnologyTreeItemTechnology;
}

interface TechnologyTreeItemTechnology {
  technology: Technology;
  fileTypes: {
    [key: string]: TechnologyTreeItemFileType;
  };
}

interface TechnologyTreeItemFileType {
  fileType: TechnologyFileType;
  products: {
    [key: string]: TechnologyTreeItemProduct;
  };
}

interface TechnologyTreeItemProduct {
  product: Product;
}

interface TechnologyDictionary {
  [key: string]: Technology;
}

const extractTechnologiesFromNestedProduct = (product: Product, technologies: TechnologyDictionary = {}): TechnologyDictionary => {
  if (product?.technology) {
    const key = product.technology?.['@id'] ?? product.technology;
    if (!technologies.hasOwnProperty(key)) {
      technologies[key] = product.technology;
    }
  }
  product.requiredProducts.forEach(el => {
    if (el.product) {
      extractTechnologiesFromNestedProduct(el.product, technologies);
    }
  });
  return technologies;
};
const buildTree = (product: Product, technologies: TechnologyDictionary, currentTree: TechnologyTree = {}): TechnologyTree => {
  Object.values(technologies).forEach(el => {
    if (!currentTree.hasOwnProperty(el['@id'])) {
      currentTree[el['@id']] = {
        technology: el,
        fileTypes: el.fileTypes?.reduce<any>((prev, curr) => {
          prev[curr['@id']] = {
            fileType: curr,
            products: {},
          };

          return prev;
        }, {}),
      };
    }
  });
  if (product?.technology) {
    const technologyKey = product.technology?.['@id'] ?? product.technology;
    technologies[product.technology?.['@id'] ?? product.technology]?.fileTypes?.forEach(el => {
      currentTree[technologyKey].fileTypes[el['@id']].products[product['@id']] = { product };
    });
  }

  product.requiredProducts.forEach(el => {
    if (el.product) {
      buildTree(el.product, technologies, currentTree);
    }
  });

  return currentTree;
};

export const useTechnologiesDetails = (ids: string[]): UseQueryResult<Technology>[] =>
  useQueries(
    ids.map(id => {
      return {
        queryKey: ['technologyDisableCustomFields', id],
        queryFn: () => TechnologyModule.api.get({ id: id }, { params: { disableCustomFields: true } }),
        options: {
          cacheTime: 10 * 60 * 1000,
          staleTime: 10 * 60 * 1000,
          refetchOnWindowFocus: false,
          refetchInterval: false,
        },
      };
    }),
  );
const useNestedDataResolver = (product): TechnologyTree => {
  const technologies = extractTechnologiesFromNestedProduct(product);
  const ids = _.uniq(Object.values(technologies).map(el => el.id ?? ''));
  const queryData = useTechnologiesDetails(ids);

  const technologiesWithAllData = useMemo(() => {
    const tmp = {};

    queryData.forEach(el => {
      tmp[el.data?.['@id']] = el.data;
    });

    return tmp;
  }, [JSON.stringify(ids), queryData]);

  if (queryData.some(el => el.isLoading)) {
    return {};
  }

  const treeData = buildTree(product, technologiesWithAllData);

  return treeData;
};

interface TechnologyItemProps {
  recordId: string | number;
  item: TechnologyTreeItemTechnology;
  onUpdate: any;
}

function TechnologyItem({ item, recordId, onUpdate }: TechnologyItemProps) {
  return (
    <StyledTreeItem
      nodeId={item.technology['@id']}
      label={
        <Container spacing={0}>
          <Col>{item.technology.name}</Col>
        </Container>
      }
    >
      {Object.values(item.fileTypes).map(el => (
        <FileTypeItem key={el.fileType['@id']} item={el} technology={item.technology} recordId={recordId} onUpdate={onUpdate} />
      ))}
    </StyledTreeItem>
  );
}

interface FileTypeItemProps {
  recordId: string | number;
  technology: Technology;
  item: TechnologyTreeItemFileType;
  onUpdate: any;
}

function FileTypeItem({ item, technology, recordId, onUpdate }: FileTypeItemProps) {
  const token = useAppSelector(root => root.Login.token);

  if (!token || !item.fileType.id) {
    return <></>;
  }
  const downloadURL = `${process.env.REACT_APP_API_BASE_URL}${ProductModule.configuration.api.item.downloadTechnologyFiles({
    id: recordId,
    technologyFileTypeId: item.fileType.id,
  })}?depth=true&token=${encodeURIComponent(token)}`;
  return (
    <StyledTreeItem
      nodeId={item.fileType['@id']}
      label={
        <Container spacing={0}>
          <Col>{item.fileType.name}</Col>
          <Col>
            <FileDownload
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                window.open(downloadURL, '_blank');
              }}
              style={{ background: '#2e7d32', color: '#fff', padding: 2, borderRadius: 5, margin: '2px 6px' }}
            />
          </Col>
        </Container>
      }
    >
      {Object.values(item.products).map(el => (
        <ProductItem
          key={el.product['@id'] + item.fileType['@id']}
          fileType={item.fileType}
          technology={technology}
          product={el.product}
          onUpdate={onUpdate}
        />
      ))}
    </StyledTreeItem>
  );
}

interface ProductItemProps {
  technology: Technology;
  fileType: TechnologyFileType;
  product: Product;
  onUpdate: any;
}

function ProductItem({ product, fileType, onUpdate }: ProductItemProps) {
  const token = useAppSelector(root => root.Login.token);

  if (!token || !fileType.id) {
    return <></>;
  }

  const downloadURL = `${process.env.REACT_APP_API_BASE_URL}${ProductModule.configuration.api.item.downloadTechnologyFiles({
    id: product.id,
    technologyFileTypeId: fileType.id,
  })}?token=${encodeURIComponent(token)}`;
  const files =
    product.technologyFiles?.filter(el => (el.technologyFileType?.['@id'] ?? el.technologyFileType) === (fileType?.['@id'] ?? fileType)) ??
    [];

  const assignNewFilesToProductInDepth = (productId, newFiles, currentProduct) => {
    return {
      ...currentProduct,
      technologyFiles: [...currentProduct.technologyFiles, ...(currentProduct.id === productId ? newFiles : [])],
      requiredProducts: (currentProduct?.requiredProducts ?? []).map(el => ({
        ...el,
        product: assignNewFilesToProductInDepth(productId, newFiles, el.product),
      })),
    };
  };
  const handleFilesUpload = uploadedFiles => {
    const newFiles = uploadedFiles.map(file => ({
      name: file.originalName,
      description: null,
      file: file,
      technologyFileType: fileType['@id'],
      _key: uuidv4(),
    }));
    onUpdate(prevProduct => {
      return { ...assignNewFilesToProductInDepth(product.id, newFiles, prevProduct) };
    });
  };
  return (
    <StyledTreeItem
      nodeId={product['@id'] + (fileType?.['@id'] ?? fileType)}
      label={
        <Container spacing={0}>
          <Col>{`[${product.labelerId}] ${product.name}`}</Col>
          <Col>
            <div className="d-flex">
              {files.length > 0 && (
                <div>
                  <FileDownload
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.open(downloadURL, '_blank');
                    }}
                    style={{ background: '#2e7d32', color: '#fff', padding: 2, borderRadius: 5, margin: '2px 6px' }}
                  />
                </div>
              )}

              <div>
                <MultipleFileUpload
                  value={[]}
                  size={{ md: 12 }}
                  disableGroupMargin={true}
                  className="_"
                  onChange={handleFilesUpload}
                  dropzone={(getRootProps, getInputProps) => (
                    <div
                      {...getRootProps()}
                      onClick={e => {
                        getRootProps().onClick(e);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <input {...getInputProps()} />
                      <UploadFile style={{ background: '#2a3042', color: '#fff', padding: 2, borderRadius: 5, margin: '2px 6px' }} />
                    </div>
                  )}
                  name={'uploadedFiles'}
                />
              </div>
            </div>
          </Col>
        </Container>
      }
    >
      {files.map(el => (
        <StyledTreeItem
          key={el['@id']}
          nodeId={el['@id']}
          label={
            <Container spacing={0}>
              <Col>{el.name ?? el.file?.originalName ?? 'Brak nazwy pliku'}</Col>
              <Col>
                <FileDownload
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(el.file.contentUrl, '_blank');
                  }}
                  style={{ background: '#2e7d32', color: '#fff', padding: 2, borderRadius: 5, margin: '2px 6px' }}
                />
              </Col>
            </Container>
          }
        />
      ))}
    </StyledTreeItem>
  );
}

function ProductTechnologyFilesTree({ product, onUpdate }: ProductTechnologyFilesTreeProps) {
  const treeData = useNestedDataResolver(product);
  const token = useAppSelector(root => root.Login.token);

  if (!token) {
    return <></>;
  }

  const downloadURL = `${process.env.REACT_APP_API_BASE_URL}${ProductModule.configuration.api.item.downloadTechnologyFiles({
    id: product.id,
    technologyFileTypeId: '00000000-0000-0000-0000-000000000000',
  })}?depth=true&token=${encodeURIComponent(token)}`;
  return (
    <div style={{ margin: 24, padding: 12, border: '1px solid #2a3042', borderRadius: 10 }}>
      <TreeView
        aria-label="customized"
        defaultExpanded={['1', ...Object.values(treeData).map(el => el.technology?.['@id'])]}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        sx={{ flexGrow: 1, overflowY: 'auto' }}
      >
        <StyledTreeItem
          nodeId="1"
          label={
            <Container spacing={0}>
              <Col>Wszystkie pliki składników</Col>
              <Col>
                <FileDownload
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(downloadURL, '_blank');
                  }}
                />
              </Col>
            </Container>
          }
        >
          {Object.values(treeData).map(el => {
            return <TechnologyItem key={el.technology['@id']} item={el} recordId={product.id} onUpdate={onUpdate} />;
          })}
        </StyledTreeItem>
      </TreeView>
    </div>
  );
}

export default memo(ProductTechnologyFilesTree);
