import { Warning } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { ClientOrderModule } from 'Modules/CTMModules';
import SecuredView from 'components/Theme/Common/SecuredView';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

interface ViewTroubleProps {
  clientOrderId: string;
  initialTrouble: string | null;
}

const ViewTrouble: FC<ViewTroubleProps> = ({ clientOrderId, initialTrouble }) => {
  const dispatch = useDispatch();
  const [trouble, setTrouble] = useState<string | null>(initialTrouble);

  const solve = (): void => {
    ClientOrderModule.api.put({ trouble: null }, { id: clientOrderId }).then(() => {
      setTrouble(null);
      dispatch(addSingleToast({ title: `Problem został rozwiązany.`, config: { appearance: 'success' } }));
    });
  };
  return trouble ? (
    <Col xs={12} md={12} className="mb-md-2 mt-2">
      <Alert icon={<Warning />} color={'error'}>
        {trouble}
        <SecuredView role={`ROLE_EDIT_${ClientOrderModule.configuration.role}`}>
          <Button color={'success'} variant={'contained'} className={'btn  ml-1'} onClick={solve}>
            Problem rozwiązany
          </Button>
        </SecuredView>
      </Alert>
    </Col>
  ) : null;
};

export default ViewTrouble;
