import { PriorityView } from '@Core/Components/PriorityView';
import { Priority, PriorityColor } from '@Core/Types/Priority';
import {
  FilterAltOffRounded,
  FilterAltRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  KeyboardDoubleArrowUpRounded,
  RemoveRounded,
} from '@mui/icons-material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FC, MouseEvent, useEffect, useState } from 'react';

type PriorityPickerProps = { value: Priority | null; onChange: (value: null | Priority) => void; disableNull?: boolean };

export const PriorityPicker: FC<PriorityPickerProps> = ({ value, onChange, disableNull }) => {
  const [val, setVal] = useState(value ?? null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (priority: null | Priority) => {
    setAnchorEl(null);
    setVal(priority);
    onChange(priority);
  };

  return (
    <div>
      <Button aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
        {val === null && <FilterAltRounded style={{ color: '#303030' }} />}
        {val !== null && <PriorityView priority={val} />}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!disableNull && (
          <MenuItem onClick={() => handleClose(null)}>
            <FilterAltOffRounded />
          </MenuItem>
        )}
        <MenuItem onClick={() => handleClose(Priority.LOW)}>
          <KeyboardArrowDownRounded style={{ color: PriorityColor.LOW }} />
        </MenuItem>
        <MenuItem onClick={() => handleClose(Priority.MEDIUM)}>
          <RemoveRounded style={{ color: PriorityColor.MEDIUM }} />
        </MenuItem>
        <MenuItem onClick={() => handleClose(Priority.HIGH)}>
          <KeyboardArrowUpRounded style={{ color: PriorityColor.HIGH }} />
        </MenuItem>
        <MenuItem onClick={() => handleClose(Priority.CRITICAL)}>
          <KeyboardDoubleArrowUpRounded style={{ color: PriorityColor.CRITICAL }} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export const PriorityFilter: FC<{ column: any }> = ({ column }) => {
  const [val, setVal] = useState(column.filterValue ?? null);

  useEffect(() => {
    column.setFilter(val);
  }, [val]);

  return <PriorityPicker value={val} onChange={setVal} />;
};
