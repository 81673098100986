import { Card } from '@mui/material';
import { DictionaryModule } from 'Modules/CTMModules';
import DataGrid from 'components/DataGrid';
import { InlineListActions } from 'components/Module/ModuleList';
import useErrorHandler from 'helpers/FormHandler';
import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CardBody } from 'reactstrap';

const CategoryList = () => {
  const list = useRef();
  const editModal = useRef();
  const showModal = useRef();
  const errorHandler = useErrorHandler();
  const { categoryId } = useParams();
  const columns = useMemo(
    () => [
      ...DictionaryModule.configuration.list.columns.map(el => {
        if (el.id === 'category.id') {
          return { ...el, forceHidden: () => true };
        }
        return el;
      }),
      ...InlineListActions(errorHandler, list, editModal, showModal, DictionaryModule, {
        createUrl: `/modules/core-dictionary/add/${categoryId}`,
        showUrl: ({ id: rowId }) => `/modules/core-dictionary/show/${categoryId}/${rowId}`,
        editUrl: ({ id: rowId }) => `/modules/core-dictionary/edit/${categoryId}/${rowId}`,
      }),
    ],
    [categoryId],
  );
  return (
    <Card key={categoryId}>
      <CardBody style={{ padding: 0 }}>
        <DataGrid
          ref={list}
          url={DictionaryModule.api.getAllUrl}
          fullWidth={false}
          responsive={true}
          className={'table-clickable'}
          columns={columns}
          defaultOrderBy={DictionaryModule.configuration.list.defaultOrderBy}
          renderRowSubComponent={DictionaryModule.configuration.list.renderRowSubComponent}
          defaultFilters={[{ id: 'category.id', value: categoryId }]}
          canRenderSubRow={DictionaryModule.configuration.list.canRenderSubRow}
        />
      </CardBody>
    </Card>
  );
};

export default CategoryList;
