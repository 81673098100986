import Bom from './Bom';
import { Field } from '@CustomFields/Field';
import { CTMCustomTab } from 'Modules/Core/Types/CTMModule';
import { Product } from 'Modules/Manufacture/Product';
import classnames from 'classnames';
import ProductionTree from 'pages/Manufacture/Product/Components/ProductionTree';
import { FC, useCallback, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

interface IngredientViewProps {
  record: Product;
  parentRecord: Product | null;
  rootRecord: Product | null;
  parentTitlePath: string | null;
  onUpdate: any;
  readonly: boolean;
  fields: Field[];
}

const VIEW_BOM = 'BOM';
const VIEW_TREE = 'TREE';

const IngredientView: FC<IngredientViewProps> = ({ record, parentRecord, rootRecord, parentTitlePath, onUpdate, readonly, fields }) => {
  const [view, setView] = useState<string>(VIEW_BOM);

  const updateView = useCallback((view: string): void => {
    setView(view);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className={'align-self-center justify-content-end text-end mb-2'}>
          <ButtonGroup className="ml-1">
            <Button onClick={() => updateView(VIEW_BOM)} className={classnames('btn btn-primary', { 'btn-success': view === VIEW_BOM })}>
              <i className="mdi mdi-view-list" />
            </Button>
            <Button onClick={() => updateView(VIEW_TREE)} className={classnames('btn btn-primary', { 'btn-success': view === VIEW_TREE })}>
              <i className="mdi mdi-tree" />
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {view === VIEW_BOM && <Bom record={record} readonly={readonly} onUpdate={onUpdate} />}
      {view === VIEW_TREE && (
        <ProductionTree
          record={record}
          parentRecord={parentRecord}
          rootRecord={rootRecord}
          parentTitlePath={parentTitlePath}
          onUpdate={onUpdate}
          readonly={readonly}
          fields={fields}
        />
      )}
    </>
  );
};

export default IngredientView;

export const TabConfiguration: CTMCustomTab = {
  title: 'Składniki',
  component: IngredientView,
};
