import StatusView from '../Core/Components/StatusView';
import StatusPicker from 'Modules/Contractor/Components/Lead/StatusPicker';
import Timeline from 'Modules/Contractor/Components/Lead/Timeline';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import { SelectColumnFilter } from 'components/DataGrid/Filter';

export type Lead = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<Lead>[] = [
  {
    id: 'name',
    Header: 'Nazwa',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'contractor-lead'} status={status} />,
    filterable: true,
    Filter: SelectColumnFilter,
  },
  {
    id: 'address',
    Header: 'Adres',
    accessor: row => {
      return (
        (row.address.street ? row.address.street : '-') +
        ' ' +
        (row.address.house ? row.address.house : '-') +
        (row.address.flat ? '/' + row.address.flat : '') +
        ', ' +
        (row.address.post ? row.address.post : '-') +
        ' ' +
        (row.address.city ? row.address.city : '-')
      );
    },
  },
  {
    id: 'address.district',
    Header: 'Gmina',
    accessor: 'address.district',
    filterable: true,
    sortable: true,
  },
  {
    id: 'address.province',
    Header: 'Województwo',
    accessor: 'address.province',
    filterable: true,
    sortable: true,
  },
  {
    id: 'contact.phone',
    Header: 'Telefon',
    accessor: 'contact.phone',
    filterable: true,
    sortable: true,
  },
];

interface CustomLeadItemPaths {
  get: ({ id }: { id: string | number }) => string;
}

interface CustomLeadCollectionPaths {
  import: string;
}

export type CTMModuleLead = CTMModule<Lead, CustomLeadItemPaths, CustomLeadCollectionPaths>;

const module: CTMModuleLead = {
  id: '66c29668-a23c-413a-8d44-aba63bd1e991',
  dataClass: 'CTM\\Contractor\\Entity\\Lead\\Lead',
  urlPrefix: 'contractor-lead',
  name: 'Leady sprzedażowe',
  role: 'CONTRACTOR_LEAD',
  api: {
    item: {
      get: ({ id }) => `/contractor/leads/${id}`,
      put: ({ id }) => `/contractor/leads/${id}`,
      delete: ({ id }) => `/contractor/leads/${id}`,
    },
    collection: {
      get: `/contractor/leads`,
      post: `/contractor/leads`,
      import: `/contractor/leads/import`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {
      status: StatusPicker,
      events: Timeline,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
