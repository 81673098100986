import BaseInput, { BaseInputProps } from 'components/Form/BaseInput';
import { FC } from 'react';
import { InputGroupText } from 'reactstrap';

type IconInputProps = {
  value: any;
  name: string;
  label?: string | Element;
  size?: { md: number };
  onChange: (value: any, name: string) => void;
  disabled?: boolean;
} & Partial<BaseInputProps>;
const IconInput: FC<IconInputProps> = props => {
  const { value, name, label = null, size = { md: 12 }, onChange, disabled = undefined, ...rest } = props;
  return (
    <>
      <BaseInput
        {...rest}
        size={size}
        label={label ?? ''}
        value={value}
        name={name}
        type={'text'}
        onChange={onChange}
        disabled={disabled}
        beforeInputContent={
          <InputGroupText>
            <i className={`mdi mdi-${value}`} />
          </InputGroupText>
        }
        afterInputContent={
          <InputGroupText style={{ cursor: 'pointer' }} onClick={() => window.open('https://materialdesignicons.com/', '_blank')}>
            <i className={`mdi mdi-open-in-new`} />
            &nbsp;Zobacz dostępne opcje
          </InputGroupText>
        }
      />
    </>
  );
};

export default IconInput;
