import Message from './Message';
import { Box, CircularProgress, Divider, Stack } from '@mui/material';
import { MessageListQueryData } from 'Modules/Chat/Hooks/UseMessageListQuery';
import { MessageCollectionElement } from 'Modules/Chat/Types/Api';
import { MutableRefObject } from 'react';
import { InViewHookResponse } from 'react-intersection-observer';
import SimpleBar from 'simplebar-react';

interface MessagesBoxProps {
  lastMessageRef?: InViewHookResponse['ref'];
  oldestChunkRef?: InViewHookResponse['ref'];
  liveMessages: MessageCollectionElement[];
  chunks: MessageListQueryData;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  isInitialLoading?: boolean;
  scrollContainerRef?: MutableRefObject<HTMLElement | undefined>;
}

export default function MessagesBox({ chunks, ...props }: MessagesBoxProps) {
  const lastPageIndex = (chunks?.pages || []).length - 1;

  return (
    <Box sx={{ flexGrow: 1, flexShrink: 1, flexBasis: 'auto', overflowY: 'hidden' }}>
      <SimpleBar style={{ maxHeight: '100%' }} scrollableNodeProps={{ ref: props.scrollContainerRef }}>
        <Stack spacing={1} padding={2} direction="column-reverse">
          <div style={{ height: 5, bottom: 30 }} ref={props.lastMessageRef} />
          {props.liveMessages.map(message => (
            <Message message={message} key={message['@id']} />
          ))}
          {chunks?.pages.map((page, index) => (
            <MessagesChunk
              key={(chunks?.pageParams[index] as string) || 'initial'}
              inViewRef={index === lastPageIndex ? props.oldestChunkRef : undefined}
              messages={page['hydra:member']}
            />
          ))}
          {props.hasNextPage && (
            <Box alignSelf="center">
              <CircularProgress size={20} />
            </Box>
          )}
          {!props.hasNextPage && !props.isInitialLoading && <Divider children="To jest początek konwersacji" />}
        </Stack>
      </SimpleBar>
    </Box>
  );
}

interface MessagesChunkProps {
  inViewRef?: InViewHookResponse['ref'];
  messages: MessageCollectionElement[];
}

function MessagesChunk({ inViewRef, messages }: MessagesChunkProps) {
  return (
    <Stack direction="column-reverse" spacing={1} ref={inViewRef}>
      {messages.map(message => (
        <Message message={message} key={message['@id']} />
      ))}
    </Stack>
  );
}
