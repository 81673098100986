import SectionCard from '../Atoms/SectionCard';
import Status from './Status';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Box, Divider, Tab, Tabs } from '@mui/material';
import FavoriteRooms from 'Modules/Chat/View/Components/Sidebar/FavoriteRooms';
import RecentRooms from 'Modules/Chat/View/Components/Sidebar/RecentRooms';
import { useCallback, useState } from 'react';
import SimpleBar from 'simplebar-react';

enum RoomListTab {
  RECENT,
  FAVORITE,
}

export default function Sidebar() {
  const [activeListTab, setActiveListTab] = useState<RoomListTab>(RoomListTab.RECENT);
  const handleChangeTab = useCallback((e, v) => setActiveListTab(v), [setActiveListTab]);
  return (
    <SectionCard>
      <Status />
      <Divider />
      <Box>
        <Tabs value={activeListTab} onChange={handleChangeTab} variant="fullWidth">
          <Tab label="Ostatnie" icon={<ScheduleIcon />} value={0} />
          <Tab label="Ulubione" icon={<FavoriteIcon />} value={1} />
        </Tabs>
      </Box>
      <Divider />
      <Box sx={{ maxHeight: '100%', overflowY: 'hidden' }}>
        <SimpleBar style={{ maxHeight: '100%' }}>
          {activeListTab === RoomListTab.RECENT && <RecentRooms />}
          {activeListTab === RoomListTab.FAVORITE && <FavoriteRooms />}
        </SimpleBar>
      </Box>
    </SectionCard>
  );
}
