import AdditionalActionRow from './AdditionalActionRow';
import { Button, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

const AdditionalActionsField: FC<{ value?: any[]; readonly: boolean; onChange: any; field: any }> = ({
  value,
  readonly,
  field,
  onChange,
}) => {
  const values = (value ?? []).map(el => {
    el._id = uuidv4();

    return el;
  });
  const collectNewValue = newValue => {
    onChange([...values, newValue], field.id);
  };

  const onRemove = _id => {
    let i = 1;
    onChange([...values.filter(el => el._id !== _id).map(el => ({ ...el, position: i++ }))], field.id);
  };

  const updateExistingValue = (index, newValue) => {
    const newValues = [...values];
    newValues[index] = newValue;
    onChange(newValues, field.id);
  };
  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="head" style={{ width: 70 }}>
                    #
                  </TableCell>
                  <TableCell variant="head">Pozycja</TableCell>
                  <TableCell variant="head">Moment wykonania</TableCell>
                  <TableCell variant="head">Akcja wywołująca</TableCell>
                  <TableCell variant="head">Akcja do wykonania</TableCell>
                  <TableCell variant="head">Dodatkowe ustawienia</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((el, i) => (
                  <AdditionalActionRow
                    key={el['@id']}
                    readonly={readonly}
                    additionalAction={el}
                    onUpdated={newValue => updateExistingValue(i, newValue)}
                    onRemove={() => onRemove(el._id)}
                  />
                ))}
              </TableBody>
              {!readonly && (
                <TableFooter>
                  <TableRow>
                    <TableCell variant="head" colSpan={6}>
                      <Button
                        variant="contained"
                        color="success"
                        className="w-100"
                        onClick={() =>
                          collectNewValue({
                            _id: uuidv4(),
                            position: values.length + 1,
                            type: 'BEFORE',
                            trigger: 'TASK_CREATED',
                            action: 'ACTION_CREATE_TASK',
                            actionTechnology: null,
                          })
                        }
                      >
                        Dodaj nową akcję
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AdditionalActionsField;
