import PlaceholderInput from '../Form/MUI/PlaceholderInput';
import ModalFormWrapper from '../Form/ModalFormWrapper';
import ModuleForm from './ModuleForm';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import { get } from 'helpers/Axios';
import React, { memo, useEffect, useRef, useState } from 'react';

const RecordInputReader = ({
  readonly,
  label,
  value,
  module,
  fallback = '',
  name = '',
  useInput = true,
  disablePreview = false,
  errorKey = '',
  inputProps = {},
  namePrefix = '',
}) => {
  const [normalizedValue, setNormalizedValue] = useState('...');
  const [valueObject, setValueObject] = useState(null);
  const [iri, setIRI] = useState(null);
  const showModal = useRef();

  useEffect(() => {
    if (typeof value === 'string') {
      get(value).then(setValueObject);
    } else {
      setValueObject(value);
    }
  }, [value]);

  useEffect(() => {
    if (typeof value === 'string') {
      get(value).then(setValueObject);
    } else {
      setValueObject(value);
    }
  }, []);

  useEffect(() => {
    setNormalizedValue(valueObject?.relationFieldLabel);
    setIRI(valueObject?.['@id']);
  }, [valueObject]);

  const id = () => {
    return (iri ?? '').split('/').pop();
  };

  return (
    <div className={classnames({ 'record-reader': (iri ?? '').length > 0 })}>
      <Tooltip title={`${normalizedValue ?? fallback}`} arrow={true} leaveDelay={400} enterDelay={150} placement="left-start">
        <div>
          {useInput && (
            <PlaceholderInput
              namePrefix={namePrefix}
              name={name}
              disabled={readonly}
              label={label}
              value={normalizedValue ?? fallback}
              inputProps={inputProps}
            />
          )}
          {!useInput && <span>{normalizedValue ?? fallback}</span>}
          {(id() ?? '').length > 0 && !disablePreview && (
            <div className="record-reader-actions">
              <ModalFormWrapper
                ref={showModal}
                title={`${module.configuration.name} - Podgląd`}
                form={
                  <ModuleForm
                    moduleName={module.configuration.urlPrefix}
                    id={id()}
                    showContextActions={false}
                    showBackButton={false}
                    showConfigurationSwitcher={false}
                    afterSave={() => {
                      showModal.current.close();
                    }}
                    onError={console.log}
                    readonly={true}
                    overrideFormProps={{
                      recordCallback: undefined,
                      trackTabInUrl: false,
                    }}
                  />
                }
                wrapperComponent={React.Fragment}
              ></ModalFormWrapper>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();

                  showModal.current.open();
                  return false;
                }}
              >
                <i className="mdi mdi-eye" />
              </Button>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default memo(RecordInputReader);
