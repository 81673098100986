import { Task } from '../../Types/Task';
import { LinearProgress } from '@mui/material';
import { FC } from 'react';

interface AmountColumnProps {
  task: Task;
}
const AmountColumn: FC<AmountColumnProps> = ({ task }) => {
  return (
    <div style={{ minWidth: 150, textAlign: 'center' }}>
      <LinearProgress variant="determinate" value={Math.round((task.amountOfDone * 100) / task.amountToMake)} />
      {task.amountOfDone.toFixed(2)} / {task.amountToMake.toFixed(2)} {task.unit?.name ?? 'szt.'}
    </div>
  );
};

export default AmountColumn;
