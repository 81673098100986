import AddEventModal from '../Component/AddEventModal';
import Polish from '@fullcalendar/core/locales/pl';
import FullCalendar from '@fullcalendar/react';
import Plugins from 'Modules/Calendar/Plugins';
import { get } from 'helpers/Axios';
import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

interface Event {
  id: string;
  title: string;
  startAt: string;
}

const Calendar: FC = () => {
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const fetchEvents = (): void => {
    get('calendar/events').then(result => {
      setEvents(result['hydra:member']);
    });
  };

  useEffect(() => {
    fetchEvents();
  }, [open]);

  return (
    <div className="container-fluid">
      <Row>
        <Col xs={12}>
          <FullCalendar
            plugins={Plugins}
            locale={Polish}
            slotDuration={'00:15:00'}
            handleWindowResize={true}
            themeSystem="bootstrap"
            headerToolbar={{
              left: 'prev,next today timeGridDay,timeGridWeek',
              center: 'title',
              right: 'addEvent dayGridMonth,dayGridWeek,dayGridDay',
            }}
            customButtons={{
              addEvent: {
                text: 'Dodaj wydarzenie',
                click: () => setOpen(true),
              },
            }}
            events={events}
            editable={true}
            droppable={false}
            selectable={true}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
            }}
          />
        </Col>
        <AddEventModal open={open} onClose={() => setOpen(false)} />
      </Row>
    </div>
  );
};

export default Calendar;
