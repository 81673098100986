import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { axiosApi } from 'helpers/Axios';
import { FC, useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

export interface ErrandComponentBoxProps {
  show: boolean;
  productId: string | null | undefined;
  quantity: number;
  onClose: () => void;
}

const ErrandComponentBox: FC<ErrandComponentBoxProps> = ({ show, productId, quantity, onClose }) => {
  const [report, setReport] = useState<Array<any>>([]);
  const buttonStyle = { borderRadius: '50%', padding: '2px', minWidth: '30px', color: '#fff' };

  useEffect(() => {
    if (!productId || quantity < 1) {
      return;
    }

    axiosApi.get(`/manufacture/errand-component-reports/?product=${productId}&quantity=${quantity}`).then(response => {
      setReport(response.data['hydra:member']);
    });
  }, [productId, quantity]);

  return (
    <Modal
      isOpen={show}
      toggle={() => onClose()}
      centered={true}
      size={'lg'}
      style={{
        maxWidth: '1024px',
      }}
    >
      <ModalBody className="py-3 px-5">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>ID komponentu</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Symbol komponentu</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Nazwa komponentu</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Wymagana ilość komponentu dla jednej sztuki</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Wymagana ilość komponentu dla wszystkich sztuk</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Ilość w magazynie</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Brakująca ilość w magazynie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.map(item => (
              <TableRow key={item['@id']}>
                <TableCell>{item['ID komponentu']}</TableCell>
                <TableCell>{item['Symbol komponentu']}</TableCell>
                <TableCell>{item['Nazwa komponentu']}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>{item['Wymagana ilość komponentu dla jednej sztuki']}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>{item['Wymagana ilość komponentu dla wszystkich sztuk']}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <Button variant={'contained'} color={'success'} style={buttonStyle}>
                    {item['Ilość w magazynie']}
                  </Button>
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <Button variant={'contained'} color={'error'} style={buttonStyle}>
                    {item['Brakująca ilość w magazynie']}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ModalBody>
    </Modal>
  );
};

export default ErrandComponentBox;
