import CTMModule from '../../Core/Types/CTMModule';
import Loader from '@Components/Theme/Common/Loader';
import { CTMRecord } from 'Modules/Core/Types/CTMModule';
import classnames from 'classnames';
import SelectInput from 'components/Form/MUI/SelectInput';
import { Col, Container } from 'components/Theme/Grid';
import * as url from 'helpers/Api/Url';
import { axiosApi } from 'helpers/Axios';
import { downloadBlobFile } from 'helpers/File';
import { FC, useState } from 'react';
import { Button } from 'reactstrap';

interface DownloadTabProps {
  record: CTMRecord;
  module: CTMModule;
}

const DownloadTab: FC<DownloadTabProps> = ({ record, module }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [format, setFormat] = useState<string>('png');
  const sizes: { label: string; value: string }[] = [
    {
      label: '480p – 720 x 480 pikseli',
      value: '720x480',
    },
    {
      label: '720p (HD) – 1280 x 720 pikseli',
      value: '1280x720',
    },
    {
      label: '1080p (Full HD) – 1920 x 1080 pikseli',
      value: '1920x1080',
    },
    {
      label: '1440p (2K QHD) – 2560 x 1440 pikseli',
      value: '2560x1440',
    },
    {
      label: '2160p (4K UHD) – 3840 x 2160 pikseli',
      value: '3840x2160',
    },
    {
      label: 'Kwadrat 1080x1080 pikseli',
      value: '1080x1080',
    },
    {
      label: 'Kwadrat 800x800 pikseli',
      value: '800x800',
    },
    {
      label: 'Kwadrat 500x500 pikseli',
      value: '500x500',
    },
    {
      label: 'Kwadrat 350x350 pikseli',
      value: '350x350',
    },
    {
      label: 'Kwadrat 250x250 pikseli',
      value: '250x250',
    },
  ];

  const [size, setSize] = useState<string>(sizes[2].value);

  const download = (): void => {
    setLoading(true);
    const selectedSize: string[] = size.split('x');

    axiosApi
      .get(url.GET_MODULE_IMAGES(module.id, record.id, format, selectedSize[0], selectedSize[1], record.symbol), {
        responseType: 'blob',
        headers: {
          Accept: 'ip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed',
        },
      })
      .then(response => {
        downloadBlobFile(response.data, `${record.symbol ? record.symbol : record.id}.zip`);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <Loader />
      </div>
    );
  }

  return (
    <Container style={{ position: 'relative', padding: '30px 15px 30% 15px' }} spacing={2}>
      <Col xs={12} md={6} lg={3}>
        <h4>Pobieranie zdjęć dla rekordu</h4>
      </Col>
      <Col xs={12} md={6} lg={3}>
        <SelectInput
          name="format"
          label="Format plików"
          value={format}
          options={[
            { label: 'JPEG', value: 'jpeg' },
            { label: 'PNG', value: 'png' },
          ]}
          multiple={false}
          onChange={value => setFormat(typeof value === 'string' ? value : 'jpeg')}
          trackKey={'value'}
          labelKey={'label'}
        />
      </Col>
      <Col xs={12} md={6} lg={3}>
        <SelectInput
          name="size"
          label="Wielkość"
          value={size}
          options={sizes}
          multiple={false}
          onChange={value => setSize(typeof value === 'string' ? value : 'jpeg')}
          trackKey={'value'}
          labelKey={'label'}
        />
      </Col>
      <Col xs={12} md={6} lg={3}>
        <Button onClick={() => download()} className={classnames('btn btn-info mt-1')}>
          Pobierz
        </Button>
      </Col>
    </Container>
  );
};

export default DownloadTab;
