import { CoreModuleModule, ProductModule, TariffModule } from 'Modules/CTMModules';
import useIsGranted from 'common/hooks/isGranted';
import CurrencySelectMultiple from 'components/Form/CurrencySelectMultiple';
import ListPicker from 'components/Form/ListPicker';
import SelectMultiple from 'components/Form/SelectMultiple';
import { Col, Container } from 'components/Theme/Grid';
import { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';

const Store = ({ form, updateConfiguration }) => {
  const canEdit = useIsGranted('ROLE_EDIT_CONFIGURATION');

  const [productFields, setProductFields] = useState([]);
  const [tariffs, setTariffs] = useState([]);

  const resolveProductFields = () => {
    CoreModuleModule.api.get({ id: ProductModule.configuration.id }).then(configuration => {
      setProductFields(configuration.fields);
    });
  };

  const resolveTariffs = () => {
    TariffModule.api.getAll().then(data => {
      setTariffs(data['hydra:member']);
    });
  };

  useEffect(() => {
    resolveProductFields();
    resolveTariffs();
  }, []);

  const updateFormValue = (value, field) => {
    updateConfiguration({ ...form, [field]: value });
  };

  const updateFieldValue = (value, field) => {
    if (JSON.stringify(form.productFields?.[field]) === JSON.stringify(value)) {
      return;
    }

    const fieldsValues = {
      ...form.productFields,
      ...{
        [field]: value,
      },
    };

    updateConfiguration({ ...form, productFields: fieldsValues });
  };

  const isReady = useMemo(() => productFields.length > 0 && tariffs.length > 0, [productFields, tariffs]);
  const images = useMemo(() => productFields.filter(field => field.type === 'IMAGE' && !field.system), [productFields]);
  const properties = useMemo(
    () => productFields.filter(field => ['STRING', 'NUMBER'].indexOf(field.type) > -1 && !field.system),
    [productFields],
  );
  const movies = useMemo(() => productFields.filter(field => field.type === 'YOU_TUBE' && !field.system), [productFields]);
  const files = useMemo(() => productFields.filter(field => field.type === 'FILE' && !field.system), [productFields]);

  return (
    isReady && (
      <Container>
        <Col xs={12} md={12} lg={12}>
          <h5>Ogólne</h5>
        </Col>
        <Col xs={3} md={3} lg={3}>
          <CurrencySelectMultiple
            value={form.currencies ?? []}
            label={'Obsługiwane waluty'}
            name="currencies"
            onChange={updateFormValue}
            disabled={!canEdit}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Domyślny cennik'}
            options={tariffs}
            disableGroupMargin={true}
            name={'tariff'}
            value={form.tariff ?? null}
            onChange={updateFormValue}
            isClearable={false}
            disabled={!canEdit}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Promocyjny cennik'}
            options={tariffs}
            disableGroupMargin={true}
            name={'specialTariff'}
            value={form.specialTariff ?? null}
            onChange={updateFormValue}
            isClearable={false}
            disabled={!canEdit}
          />
        </Col>
        <Col xs={12} md={12} lg={12}>
          <h5>Mapowanie pól produktu</h5>
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Opis'}
            options={productFields.filter(field => field.type === 'TEXT' && !field.system)}
            disableGroupMargin={true}
            name={'description'}
            value={form.productFields?.description ?? null}
            onChange={updateFieldValue}
            isClearable={false}
            disabled={!canEdit}
            optionLabel={el => '[' + el.labelerId + ']' + el.name}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Skrócony opis'}
            options={productFields.filter(field => field.type === 'TEXT' && !field.system)}
            disableGroupMargin={true}
            name={'shortDescription'}
            value={form.productFields?.shortDescription ?? null}
            onChange={updateFieldValue}
            isClearable={false}
            disabled={!canEdit}
            optionLabel={el => '[' + el.labelerId + ']' + el.name}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Model 3D'}
            options={productFields.filter(field => field.type === 'TEXT' && !field.system)}
            disableGroupMargin={true}
            name={'model3D'}
            value={form.productFields?.model3D ?? null}
            onChange={updateFieldValue}
            isClearable={false}
            disabled={!canEdit}
            optionLabel={el => '[' + el.labelerId + ']' + el.name}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <SelectMultiple
            label={'Zdjęcia produktu'}
            options={images}
            disableGroupMargin={true}
            name={'images'}
            key={'images'}
            value={form.productFields?.images ?? []}
            onChange={updateFieldValue}
            isClearable={false}
            disabled={!canEdit}
            optionLabel={el => '[' + el.labelerId + ']' + el.name}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <SelectMultiple
            label={'Rysunki techniczne'}
            options={images}
            disableGroupMargin={true}
            name={'technicalImages'}
            key={'technicalImages'}
            value={form.productFields?.technicalImages ?? []}
            onChange={updateFieldValue}
            isClearable={false}
            disabled={!canEdit}
            optionLabel={el => '[' + el.labelerId + ']' + el.name}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <SelectMultiple
            label={'Paczka plików do pobrania'}
            options={[...files, ...images]}
            disableGroupMargin={true}
            name={'filesPackage'}
            key={'filesPackage'}
            value={form.productFields?.filesPackage ?? []}
            onChange={updateFieldValue}
            isClearable={false}
            disabled={!canEdit}
            optionLabel={el => '[' + el.labelerId + ']' + el.name}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <SelectMultiple
            label={'Specyfikacja(tabelka)'}
            options={properties}
            disableGroupMargin={true}
            name={'properties'}
            key={'properties'}
            value={form.productFields?.properties ?? []}
            onChange={updateFieldValue}
            isClearable={false}
            disabled={!canEdit}
            optionLabel={el => '[' + el.labelerId + ']' + el.name}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <SelectMultiple
            key={'files'}
            label={'Pliki do pobrania'}
            options={files}
            disableGroupMargin={true}
            name={'files'}
            value={form.productFields?.files ?? []}
            onChange={updateFieldValue}
            isClearable={false}
            disabled={!canEdit}
            optionLabel={el => '[' + el.labelerId + ']' + el.name}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <SelectMultiple
            key={'movies'}
            label={'Filmy'}
            options={movies}
            disableGroupMargin={true}
            name={'movies'}
            value={form.productFields?.movies ?? []}
            onChange={updateFieldValue}
            isClearable={false}
            disabled={!canEdit}
            optionLabel={el => '[' + el.labelerId + ']' + el.name}
          />
        </Col>
      </Container>
    )
  );
};

export default withRouter(Store);
