import { NewFieldModal } from './FieldConfigurationModal';
import FieldSection from './FieldSection';
import { Button, Paper } from '@mui/material';
import { Container } from 'components/Theme/Grid';
import { useState } from 'react';

function FieldsSection(props) {
  const { moduleData, onFieldSaved } = props;
  const [filter, setFilter] = useState('');

  const resolveFields = () => {
    let filteredFields = moduleData.fields ?? [];

    if (filter && filter !== '') {
      filteredFields = filteredFields.filter(el => {
        const name = ((el.name ?? '') === '' ? 'Nazwa pola' : el.name).toLowerCase();

        return name.includes(filter.toLowerCase());
      });
    }

    return filteredFields.sort((a, b) => {
      const nameA = ((a.name ?? '') === '' ? 'Nazwa pola' : a.name).toLowerCase(); // ignore upper and lowercase
      const nameB = ((b.name ?? '') === '' ? 'Nazwa pola' : b.name).toLowerCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };

  return (
    <Paper
      variant="outlined"
      style={{
        padding: 12,
        background: '#192031',
        color: '#fff',
        position: 'sticky',
        top: 0,
      }}
    >
      <NewFieldModal fields={moduleData.fields} moduleId={moduleData.id} afterSave={() => onFieldSaved()}>
        <Button color="success" size="small" variant="contained" style={{ color: '#fff', width: '100%', marginBottom: 12 }}>
          Dodaj nowe pole
        </Button>
      </NewFieldModal>
      <Paper variant="outlined" style={{ padding: 12, background: '#2a3042', color: '#fff' }}>
        <input
          type="text"
          value={filter}
          placeholder="Filtruj pola..."
          onChange={({ target: { value } }) => setFilter(value)}
          className="transparent-input"
        />
      </Paper>
      <hr />
      <Container className="dark-scroll" style={{ maxHeight: 'calc(100vh - 242px)', overflow: 'auto', paddingRight: 6 }}>
        {resolveFields().map(field => (
          <FieldSection
            field={field}
            key={field['@id']}
            fields={moduleData.fields}
            moduleId={moduleData.id}
            afterSave={() => onFieldSaved()}
          />
        ))}
      </Container>
    </Paper>
  );
}

export default FieldsSection;
