import CreateNote from './Create';
import NoteList from './List';
import NoteEditor from './NoteEditor';
import { Route, Switch } from 'react-router-dom';

export default function NotesPage() {
  return (
    <div className="container-fluid h-100 d-flex">
      <NoteList />
      <div className="flex-grow-1">
        <div className="w-100">
          <Switch>
            <Route path="/notes" exact />
            <Route path="/notes/create" component={CreateNote} />
            <Route path="/notes/:id" render={props => <NoteEditor {...props} key={props.match.params.id} />} />
          </Switch>
        </div>
      </div>
    </div>
  );
}
