import EnterQuantityInput from './EnterQuantityInput';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Box, InputAdornment, LinearProgress, List, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import { ProductModule } from 'Modules/CTMModules';
import PlaceholderInput from 'components/Form/MUI/PlaceholderInput';
import RecordInputReader from 'components/Module/RecordInputReader';
import { Col, Container } from 'components/Theme/Grid';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';
import { useAppSelector } from 'store';

type Colors = 'error' | 'info' | 'success' | 'warning';

interface AssemblingOrderRowProps {
  position: any;
  index: number;
  onChange: (index: number, position: any) => void;
  readonly?: boolean;
}

const CompletionLogsTable = ({ completionLogs, unit }: { completionLogs: any[]; unit: string }) => {
  if (completionLogs.length === 0) {
    return null;
  }

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Magazynier</TableCell>
            <TableCell>Kod</TableCell>
            <TableCell>Ilość {unit}</TableCell>
            <TableCell>Pobrane z miejsca</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {completionLogs.map((el, i) => (
            <TableRow key={i}>
              <TableCell>{el.warehouseMan}</TableCell>
              <TableCell>{el.code}</TableCell>
              <TableCell>{el.quantity}</TableCell>
              <TableCell>{el.place?.name ?? 'Brak informacji'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

const LocationsTable = ({ locations }: { locations: any[] }) => {
  if (locations.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardBody>
        <h3 className="text-center">
          Lokalizacje
          <br />
        </h3>
        <small>Towar znajdziesz w pozycjach podanych poniżej</small>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Lokalizacja</TableCell>
              <TableCell>Ilość</TableCell>
            </TableRow>
            {Object.values<any>(locations).map(el => (
              <TableRow key={el['@id']}>
                <TableCell>{el.place?.relationFieldLabel ?? '???'}</TableCell>
                <TableCell>{el.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardBody>
    </Card>
  );
};

const AssemblingOrderRow: FC<AssemblingOrderRowProps> = ({ position, index, onChange, readonly }) => {
  const [rowState, setRowState] = useState<any>(position);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { firstName, lastName } = useAppSelector(state => state.Login.user.details);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    if (JSON.stringify(position) !== JSON.stringify(rowState)) {
      setRowState(position);
    }
  }, [JSON.stringify(position)]);

  useEffect(() => {
    if (JSON.stringify(position) !== JSON.stringify(rowState)) {
      onChange(index, rowState);
    }
  }, [rowState]);

  const progress = Math.round((rowState.quantityCollected * 100) / rowState.quantity);
  let color: Colors = 'error';
  if (progress > 25 && progress <= 50) {
    color = 'warning';
  } else if (progress > 50 && progress <= 75) {
    color = 'info';
  } else if (progress > 75) {
    color = 'success';
  }

  const addCompletionLog = useCallback((quantity: number, code: string | null, place: any | null) => {
    const completionLog = {
      warehouseMan: `${firstName} ${lastName}`,
      quantity,
      code,
      place,
    };
    setRowState(prev => {
      const completionLogs = [...prev.completionLogs, completionLog];
      return {
        ...prev,
        quantityCollected: completionLogs.reduce((acc, curr) => acc + curr.quantity, 0),
        completionLogs,
      };
    });
  }, []);

  if (windowWidth < 1400) {
    return (
      <Box sx={{ marginBottom: '32px' }}>
        <Paper>
          <List>
            <ListItem>
              <div className="w-100">
                <RecordInputReader
                  readonly={true}
                  value={rowState.product}
                  module={ProductModule}
                  label={!rowState.product ? 'Wybierz produkt aby dodać' : 'Produkt'}
                />
              </div>
            </ListItem>
            <ListItem>
              <PlaceholderInput value={rowState.product?.symbol} label={'Symbol'} name={''} disabled={true} />
            </ListItem>
            <ListItem>
              <PlaceholderInput value={rowState.product?.ean} label={'EAN'} name={''} disabled={true} />
            </ListItem>
            <ListItem></ListItem>
            <ListItem>
              <PlaceholderInput
                value={`${rowState.quantityCollected}/${rowState.quantity}`}
                label={'Ilość zebrana/Ilość wymagana'}
                forcedErrorMessage={
                  rowState.quantityCollected > rowState.quantity
                    ? 'Przekroczono wymaganą ilość - upewnij się czy tak powinno być'
                    : undefined
                }
                name={'quantity'}
                disabled={true}
                inputProps={{
                  endAdornment: <InputAdornment position="end">{rowState.unit?.name ?? 'Szt'}</InputAdornment>,
                }}
              />
            </ListItem>
            <ListItem>
              <div className="w-100">
                {!readonly && (
                  <EnterQuantityInput
                    onInputQuantity={addCompletionLog}
                    productLabeler={rowState.product?.labelerId}
                    productEAN={rowState.product?.ean}
                    productName={rowState.product?.name}
                    productSymbol={rowState.product?.symbol}
                    locations={rowState.usePlaceQuantities}
                  />
                )}
              </div>
            </ListItem>
            <ListItem>
              <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={progress} color={color} />
              </Box>
            </ListItem>
            <ListItem>
              <LocationsTable locations={rowState.usePlaceQuantities} />
            </ListItem>
            <ListItem>
              <div className="w-100">
                <CompletionLogsTable completionLogs={rowState.completionLogs} unit={rowState.unit?.name ?? 'Szt'} />
              </div>
            </ListItem>
          </List>
        </Paper>
      </Box>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell style={{ width: '25%' }}>
          <RecordInputReader
            readonly={true}
            value={rowState.product}
            module={ProductModule}
            label={!rowState.product ? 'Wybierz produkt aby dodać' : 'Produkt'}
          />
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <PlaceholderInput value={rowState.product?.symbol} label={'Symbol'} name={''} disabled={true} />
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <PlaceholderInput value={rowState.product?.ean} label={'EAN'} name={''} disabled={true} />
        </TableCell>
        <>
          <TableCell style={{ width: '200px' }}>
            <PlaceholderInput
              value={`${rowState.quantityCollected}/${rowState.quantity}`}
              label={'Ilość zebrana/Ilość wymagana'}
              forcedErrorMessage={
                rowState.quantityCollected > rowState.quantity ? 'Przekroczono wymaganą ilość - upewnij się czy tak powinno być' : undefined
              }
              name={'quantity'}
              disabled={true}
              inputProps={{
                endAdornment: <InputAdornment position="end">{rowState.unit?.name ?? 'Szt'}</InputAdornment>,
              }}
            />
          </TableCell>
          <TableCell>
            {!readonly && (
              <EnterQuantityInput
                onInputQuantity={addCompletionLog}
                productLabeler={rowState.product?.labelerId}
                productEAN={rowState.product?.ean}
                productName={rowState.product?.name}
                productSymbol={rowState.product?.symbol}
                locations={rowState.usePlaceQuantities}
              />
            )}
          </TableCell>
          <TableCell rowSpan={2}>
            <LocationsTable locations={rowState.usePlaceQuantities} />
          </TableCell>
        </>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} padding="none">
          <CompletionLogsTable completionLogs={rowState.completionLogs} unit={rowState.unit?.name ?? 'Szt'} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5} padding="none">
          <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={progress} color={color} />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

const AssemblingOrderRowMemo = memo(AssemblingOrderRow);

const AssemblingOrderRows: CustomFieldComponentType = ({ value, onChange, readonly, field }) => {
  const [rows, setRows] = useState<any[]>(value ?? []);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(rows)) {
      setRows(value ?? []);
    }
  }, [JSON.stringify(value)]);

  const handleChange = useCallback(position => {
    setRows(prevRows =>
      prevRows.map(el => {
        if (el.id === position.id) {
          return { ...el, ...position };
        }
        return el;
      }),
    );
  }, []);

  const handleChangeRow = useCallback((index, row) => {
    setRows(prevRows =>
      prevRows.map((el, elIndex) => {
        if (elIndex === index) {
          return { ...el, ...row };
        }
        return el;
      }),
    );
  }, []);

  useEffect(() => {
    onChange(rows, field.id);
  }, [rows]);

  return (
    <>
      <Container spacing={2}>
        <Col xs={12}>
          <Paper style={{ overflowX: 'auto' }}>
            <Table style={{ minWidth: 480 }}>
              <TableBody>
                {(rows ?? []).map((el, index) => (
                  <AssemblingOrderRowMemo
                    key={`value_${el.id}`}
                    position={el}
                    index={index}
                    readonly={readonly}
                    onChange={handleChangeRow}
                  />
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Col>
      </Container>
    </>
  );
};

export default AssemblingOrderRows;
