import Product from '../../../Manufacture/Product';
import Place from '../../Place';
import { PlaceEventModule } from 'Modules/CTMModules';
import { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import { PlaceEvent, placeEvents } from 'Modules/Warehouse/PlaceEvent';
import Warehouse from 'Modules/Warehouse/Warehouse';
import ModuleList from 'components/Module/ModuleList';
import dayjs from 'dayjs';
import { createModuleColumnConfig } from 'helpers/ModuleUtils';
import { FC } from 'react';

interface PlaceEventTabProps {
  record: CTMRecord;
  field: string;
  property: string;
}

const columns: CTMListColumn<PlaceEvent>[] = [
  {
    id: 'type',
    Header: 'Rodzaj zdarzenia',
    accessor: ({ type }) => placeEvents[type],
    filterable: false,
    sortable: true,
  },
  {
    id: 'product.id',
    Header: 'Towar',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig('product', Product),
  },
  {
    id: 'quantityBefore',
    Header: 'Ilość przez zdarzeniem',
    accessor: ({ quantityBefore, unit }) => `${quantityBefore} ${unit?.name ?? 'szt.'}`,
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'quantity',
    Header: 'Ilość',
    accessor: ({ quantity, unit }) => `${quantity} ${unit?.name ?? 'szt.'}`,
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'unitPriceGross',
    Header: 'Cena jed. brutto',
    accessor: ({ unitPriceGross, unit }) => `${(unitPriceGross / 100).toFixed(2)}zł/${unit?.name ?? 'szt.'}`,
    filterable: false,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'storedAt',
    Header: 'Data zdarzenia',
    accessor: ({ storedAt }) => (storedAt ? dayjs(storedAt).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    minWidth: 160,
    noBreak: true,
  },
  {
    id: 'place.warehouse.id',
    Header: 'Magazyn',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig('place.warehouse', Warehouse),
  },
  {
    id: 'place.id',
    Header: 'Miejsce magazynowe',
    filterable: true,
    sortable: true,
    width: 50,
    ...createModuleColumnConfig('place', Place),
  },
];

const PlaceEventTab: FC<PlaceEventTabProps> = ({ record, field, property }) => {
  if (!record[property]) {
    return <></>;
  }

  return (
    <ModuleList
      overrideListProps={{ columns: columns }}
      defaultFilters={[{ id: field, value: record[property] }]}
      moduleName={PlaceEventModule.configuration.urlPrefix}
    />
  );
};

export default PlaceEventTab;
