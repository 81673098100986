import AdditionalActionsField from './Components/Technology/Form/AdditionalActionsField';
import FileTypesField from './Components/Technology/Form/FileTypesField';
import { Technology } from './Types/Technology';
import CTMModule, { CTMListColumn } from 'Modules/Core/Types/CTMModule';

const columns: CTMListColumn<Technology>[] = [
  {
    id: 'name',
    Header: 'Nazwa',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    id: 'shortName',
    Header: 'Nazwa skrócona',
    accessor: 'shortName',
    filterable: true,
    sortable: true,
  },
  {
    id: 'code',
    Header: 'Kod',
    accessor: 'code',
    filterable: true,
    sortable: true,
  },
  {
    id: 'position',
    Header: 'Kolejność',
    accessor: 'position',
    filterable: false,
    sortable: true,
  },
];

const module: CTMModule<Technology> = {
  id: 'e552b66a-3338-4027-a490-7698c058eea3',
  dataClass: 'CTM\\Manufacture\\Entity\\Technology',
  urlPrefix: 'manufacture-technology',
  name: 'Technologie',
  role: 'MANUFACTURE_TECHNOLOGY',
  api: {
    item: {
      get: ({ id }) => `/manufacture/technologies/${id}`,
      put: ({ id }) => `/manufacture/technologies/${id}`,
      delete: ({ id }) => `/manufacture/technologies/${id}`,
    },
    collection: {
      get: `/manufacture/technologies`,
      post: `/manufacture/technologies`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {
      fileTypes: FileTypesField,
      additionalActions: AdditionalActionsField,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
    storeFilters: true,
  },
};

export default module;
