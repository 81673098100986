import { ClientOrder } from '@Ecommerce/ClientOrder';
import { InsertLinkSharp } from '@mui/icons-material';
import { FC, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

type OpenOrderLinksProps = {
  order: ClientOrder;
};

const OpenOrderLinks: FC<OpenOrderLinksProps> = ({ order }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  if (order.links.length === 0) {
    return null;
  }
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="ml-1 d-inline-block">
      <DropdownToggle caret>
        <InsertLinkSharp /> Zewnętrzne systemy
      </DropdownToggle>
      <DropdownMenu>
        {order.links.map((link, index) => (
          <DropdownItem key={index} href={link.url} target="_blank">
            {link.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default OpenOrderLinks;
