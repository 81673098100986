import StatusView from '../Core/Components/StatusView';
import ProductionOrderQuantity from './Components/ProductionOrder/ProductionOrderQuantity';
import ProductionOrderRows from './Components/ProductionOrder/ProductionOrderRows';
import ProductGroup from './ProductGroup';
import { ProductModule } from '@Modules/CTMModules';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import StoreValues from 'Modules/Ecommerce/Components/StoreValues';
import ScheduleButton from 'Modules/Manufacture/Components/ProductionOrder/ScheduleButton';
import StatusPicker from 'Modules/Manufacture/Components/ProductionOrder/StatusPicker';
import EditableField from 'components/DataGrid/Field/EditableField';
import { SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';
import SelectStatusColumnFilter from 'components/DataGrid/Filter/SelectStatusColumnFilter';
import dayjs from 'dayjs';
import ProductGroupView from 'pages/Manufacture/Product/ListComponents/ProductGroup';
import { Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';

export type ProductionOrder = CTMRecord & {
  number?: string;
};

const columns: CTMListColumn<ProductionOrder>[] = [
  {
    id: 'number',
    filterable: true,
    sortable: true,
    Header: 'Numer',
    noBreak: true,
    accessor: 'number',
  },
  {
    id: 'externalNumber',
    filterable: true,
    sortable: true,
    Header: 'Numer zewnętrzny',
    noBreak: true,
    accessor: 'externalNumber',
    Cell: ({ row }) => (
      <EditableField
        id={row.original.id}
        currentValue={row.original.externalNumber}
        fieldName={'externalNumber'}
        moduleName={'manufacture-production-order'}
      />
    ),
  },
  {
    id: 'otherNumber',
    filterable: true,
    sortable: true,
    Header: 'Numer CTM',
    noBreak: true,
    accessor: 'otherNumber',
    Cell: ({ row }) => (
      <EditableField
        id={row.original.id}
        currentValue={row.original.otherNumber}
        fieldName={'otherNumber'}
        moduleName={'manufacture-production-order'}
      />
    ),
  },
  {
    id: 'rows.product.group.id',
    filterable: true,
    sortable: true,
    Header: 'Kategoria',
    accessor: 'product.group.name',
    Cell: ({ row }) => <ProductGroupView name={row.original.product.group?.name} path={row.original.product.group?.relationFieldLabel} />,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: ProductGroup,
  },
  {
    id: 'rows.product.labelerId',
    filterable: true,
    sortable: true,
    Header: 'ID',
    noBreak: true,
    accessor: 'product.labelerId',
    Cell: ({ row }) => (
      <Link target={'_blank'} to={ProductModule.editUrl(row.original.product.id)}>
        {row.original.product.labelerId}
      </Link>
    ),
    filterSearchField: 'rows.product.labelerId',
  },
  {
    id: 'rows.product.symbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol',
    noBreak: true,
    accessor: 'product.symbol',
    filterSearchField: 'rows.product.symbol',
  },
  {
    id: 'rows.product.ean',
    filterable: true,
    sortable: true,
    Header: 'EAN',
    noBreak: true,
    accessor: 'product.ean',
    filterSearchField: 'rows.product.ean',
  },
  {
    id: 'rows.product.name',
    filterable: true,
    sortable: true,
    Header: 'Produkt',
    noBreak: true,
    accessor: 'product.name',
    filterSearchField: 'rows.product.name',
  },
  {
    id: 'rows.product.store',
    filterable: false,
    sortable: false,
    Header: 'Stany',
    noBreak: true,
    accessor: '',
    Cell: ({ row }) => <StoreValues key={row.original.id} product={row.original.product} renderFetch={true} />,
  },
  {
    id: 'rows.quantity',
    filterable: true,
    sortable: true,
    Header: 'Ilość',
    Cell: ({ row }) => (
      <ProductionOrderQuantity
        key={row.original.rowId}
        id={row.original.rowId}
        quantity={row.original.quantity}
        url={'/manufacture/production-order-rows'}
      />
    ),
    noBreak: true,
    accessor: 'quantity',
  },
  {
    id: 'createdAt',
    Header: 'Data utworzenia',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('DD.MM.YYYY') : null),
    filterable: true,
    sortable: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'manufacture-production-order'} status={status} />,
    sortable: true,
    filterable: true,
    Filter: ({ column }) => <SelectStatusColumnFilter column={column} moduleName={'manufacture-production-order'} property={'status'} />,
  },
  {
    id: 'deadlineAt',
    Header: 'Data realizacji',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('DD.MM.YYYY') : null),
    filterable: true,
    sortable: true,
  },
];

const module: CTMModule<ProductionOrder> = {
  id: '108935d9-0ba6-4a3b-96e3-b438efe58c7b',
  dataClass: 'CTM\\Manufacture\\Entity\\Production\\ProductionOrder',
  urlPrefix: 'manufacture-production-order',
  name: 'Zlec. na produkcję',
  role: 'MANUFACTURE_PRODUCTION_ORDER',
  api: {
    item: {
      get: ({ id }) => `/manufacture/production-orders/${id}`,
      put: ({ id }) => `/manufacture/production-orders/${id}`,
      delete: ({ id }) => `/manufacture/production-orders/${id}`,
    },
    collection: {
      get: `/manufacture/production-orders`,
      post: `/manufacture/production-orders`,
    },
    custom: {
      schedule: `/manufacture/production-orders/schedule`,
    },
  },
  recordLabel: record => record.number,
  form: {
    prepareRecordToSave: record => ({
      ...record,
      rows: record.rows?.map(el => ({
        ...el,
        product: el.product?.['@id'] ?? el.product,
      })),
    }),
    fieldComponents: {
      status: StatusPicker,
      rows: ProductionOrderRows,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'createdAt', desc: false }],
    storeFilters: true,
    board: true,
    prependComponent: ({ listRef }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ScheduleButton />
      </div>
    ),
    boardCardOverride: ({ item, module }) => {
      return (
        <CardBody className="borad-width">
          <div>
            <h5 className="font-size-15">
              <Link to={module.showUrl(item.id)} className="text-dark">
                {item?.number}
              </Link>
            </h5>
          </div>
          <div className="text-end">
            <p className="mb-0 text-muted">{dayjs(item?.createdAt).locale('pl').format('LLL')}</p>
          </div>
        </CardBody>
      );
    },
  },
};

export default module;
