import { NAME_GRID, useGetConfiguration as getMainConfiguration } from 'common/hooks/Employee/Configuration';

export const NAME_COLUMNS = 'columns';

export const getConfiguration = url => {
  const data = getMainConfiguration(NAME_GRID)?.config;
  if (typeof data !== 'undefined' && data.hasOwnProperty(url)) {
    return data[url];
  }

  return null;
};

export const getColumns = columns => {
  return columns.map(item => item.id);
};

export const getColumnsConfiguration = (columns, url) => {
  const result = getConfiguration(url);
  if (!result || !result.hasOwnProperty(NAME_COLUMNS)) {
    return getColumns(columns, url);
  }
  return result[NAME_COLUMNS];
};
