import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Fragment, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const DisplayValue = params => {
  const {
    obj: { '@LogType': type, ...args },
  } = params;
  if (type === 'PURE') {
    return <span>{args.value ?? '-'}</span>;
  }
  if (type === 'ENTITY') {
    return <span>{args.value}</span>;
  }
  if (type === 'COLLECTION') {
    if (args.value.hasOwnProperty('length')) {
      return args.value.map((log, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && ','} <DisplayValue obj={log} />
          </Fragment>
        );
      });
    } else {
      const keys = Object.keys(args.value);

      return keys.map((key, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && ','} {key}: <DisplayValue obj={args.value[key]} />
          </Fragment>
        );
      });
    }
  }

  let viewValue = params.obj;

  try {
    viewValue = JSON.stringify(params.obj, undefined, 2);
  } catch (e) {}

  return (
    <span>
      <pre>{viewValue}</pre>
    </span>
  );
};

const ActionTypes = { UPDATE: 'zaktualizował(a)', CREATE: 'dodał(a)', DELETE: 'skasował(a)' };

const ChangesTable = ({ row: { changes, '@objectName': objectName, actor, actorRawInfo, actionType } }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={3} sx={{ p: 1 }}>
            {actor ? `${actor.firstName} ${actor.lastName} (${actor.email})` : actorRawInfo ?? '-'} {ActionTypes[actionType]} {objectName}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ p: 1 }}>Pole</TableCell>
          <TableCell sx={{ p: 1 }}>Wartość przed</TableCell>
          <TableCell sx={{ p: 1 }}>Wartość po</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(changes)
          .filter(el => !['currentObjectInfo'].includes(el))
          .map((key, index) => (
            <TableRow key={index}>
              <TableCell sx={{ p: 1 }}>{changes[key]['@label']}</TableCell>
              <TableCell sx={{ p: 1 }}>
                <DisplayValue obj={changes[key].from} />
              </TableCell>
              <TableCell sx={{ p: 1 }}>
                <DisplayValue obj={changes[key].to} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export const ChangesModal = props => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Modal isOpen={opened} centered={true} backdrop={true} toggle={() => setOpened(!opened)} size="xl">
        <ModalBody>
          <ChangesTable {...props} />
        </ModalBody>
      </Modal>
      <Button className="btn" color="primary" onClick={() => setOpened(true)}>
        <i className="mdi mdi-history" /> Pokaż zmiany
      </Button>
    </>
  );
};

export default ChangesTable;
