import classnames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { Col, FormFeedback, FormGroup, FormText, Label, Row } from 'reactstrap';
import { useAppSelector } from 'store';

const GroupHeading = props => {
  return (
    <components.GroupHeading
      {...props}
      onClick={() => {
        props.selectProps.onAddSelect(props.data.options);
      }}
    />
  );
};

export type SelectMultipleProps<T> = {
  className?: string;
  value?: string[] | T[];
  options?: T[] | { options: T[] }[];
  name?: string;
  helpText?: string;
  label?: string;
  size?: { md: number };
  onChange?: (value: any, name: string) => void;
  optionTrackBy?: keyof T | ((el: T) => string);
  optionLabel?: keyof T | ((el: T) => string | Element);
  disabled?: boolean;
  isClearable?: boolean;
  disableGroupMargin?: boolean;
  labelBadge?: string;
  tabIndex?: number;
};

function SelectMultiple<T = any>(props: SelectMultipleProps<T>) {
  const {
    className = 'mb-3',
    value = [],
    options = [],
    name,
    helpText,
    label = null,
    size = { md: 12 },
    onChange = () => undefined,
    optionTrackBy = '@id',
    optionLabel = 'name',
    disabled,
    labelBadge,
    tabIndex,
    disableGroupMargin,
    isClearable,
  } = props;
  const { t } = useTranslation();
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));
  const [selectedOptions, setSelectedOptions] = useState<T[]>([]);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [initialized, setInitialized] = useState<boolean>(false);

  const mapToValue = el => {
    if (typeof el === 'string') {
      return el;
    }

    return typeof optionTrackBy === 'function' ? optionTrackBy(el) : el[optionTrackBy];
  };

  const mapToLabel = el => {
    if (typeof el === 'string') {
      return el;
    }

    return typeof optionLabel === 'function' ? optionLabel(el) : el[optionLabel];
  };

  const hasError = () => {
    return violations.hasOwnProperty(name ?? '_');
  };
  const getError = () => {
    if (hasError()) {
      return violations[name ?? '_'].join('\n');
    }
    return '';
  };

  // @ts-ignore
  const flatOptions = options.reduce<SelectMultipleProps<T>['options']>((prev: T[], curr: T) => [...prev, ...(curr.options ?? [curr])], []);

  const resolveSelectedOptions = (values): T[] => {
    setInitialized(true);

    if (!values) {
      return [];
    }

    return flatOptions.filter(el => values.includes(mapToValue(el)));
  };

  const isArrayEqual = (x, y) => {
    return _.isEmpty(_.xorWith(x, y, _.isEqual));
  };

  useEffect(() => {
    setSelectedOptions(resolveSelectedOptions(value));
  }, []);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!isArrayEqual(selectedValues, resolveSelectedOptions(value))) {
      setSelectedOptions(resolveSelectedOptions(value));
    }
  }, [value, options]);

  useEffect(() => {
    if (!initialized) {
      return;
    }
    setSelectedValues(selectedOptions.map(mapToValue));
  }, [selectedOptions]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!isArrayEqual(selectedValues, value) && options.length > 0) {
      onChange(selectedValues, name ?? '_');
    }
  }, [selectedValues]);

  const onChangeSelect = newSelectedValues => {
    setSelectedOptions(newSelectedValues);
  };

  const onAddSelect = newValues => {
    onChangeSelect([...selectedOptions, ...newValues.filter(el => !selectedValues.includes(mapToValue(el)))]);
  };

  return (
    <Col {...size} className={className}>
      <Row>
        <FormGroup className={classnames({ 'disable-group-margin': disableGroupMargin })}>
          {label && <Label>{t(label)}</Label>}
          <Select
            components={{ GroupHeading }}
            isMulti={true}
            isDisabled={disabled}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isClearable={isClearable}
            value={selectedOptions}
            options={options}
            onChange={onChangeSelect}
            getOptionLabel={mapToLabel}
            getOptionValue={mapToValue}
            tabIndex={tabIndex}
            classNamePrefix="select2-selection"
          />
          {helpText && <FormText>{helpText}</FormText>}
          {hasError() && <FormFeedback>{getError()}</FormFeedback>}
        </FormGroup>
      </Row>
    </Col>
  );
}

export default SelectMultiple;
