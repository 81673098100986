import { Task } from '../../Types/Task';
import RelationTaskTable from './RelationTaskTable';
import TaskModalColumn from './TaskModalColumn';
import TaskModalDescription from './TaskModalDescription';
import TaskTabComments from './TaskTabComments';
import TaskTabIngredients from './TaskTabIngredients';
import TaskTabRelations from './TaskTabRelations';
import TaskTabWorkHistory from './TaskTabWorkHistory';
import { Close, NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Col, Container } from 'components/Theme/Grid';
import React, { FC, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'store';
import { closeTask } from 'store/TaskManagement/Actions';
import { TaskManagementState } from 'store/TaskManagement/Reducer';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const TaskModalBodyTabs: FC<{ task: Task }> = ({ task }) => {
  const [value, setValue] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!task.id) {
    return null;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Komentarze" />
          <Tab label="Powiązane zadania" />
          <Tab label="Historia pracy" />
          <Tab label="Produkty załadowcze" />
          <Tab label="Produkty odkładcze" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {value === 0 && <TaskTabComments comments={task.comments} taskIRI={task['@id']} />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {value === 1 && <TaskTabRelations requiredBy={task.requiredBy ?? []} requires={task.requires ?? []} />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {value === 2 && <TaskTabWorkHistory amountHistory={task.amountChanges} />}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {value === 3 && <TaskTabIngredients taskId={task.id} ingredients={task.ingredients ?? []} />}
      </TabPanel>
    </Box>
  );
};

const TaskModalBody: FC = () => {
  const dispatch = useAppDispatch();
  const task = useAppSelector(rootState => rootState.TaskManagement.task, shallowEqual);

  if (!task) {
    return null;
  }
  return (
    <>
      <Container spacing={0} style={{ padding: 16, fontSize: '1.1em' }}>
        <Col xs={12}>
          <Container spacing={0} direction={'row-reverse'}>
            <Col md={3} style={{ textAlign: 'right' }}>
              <Button onClick={() => dispatch(closeTask())}>
                <Close />
              </Button>
            </Col>
            <Col md={9}>
              <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" color={'#c5cfd9'}>
                <span>{task.errand?.number}</span>
                <span>
                  {task.key} - {task.name}
                </span>
              </Breadcrumbs>
            </Col>
          </Container>
        </Col>
        <Col xs={12}>
          <Container spacing={2} direction={'row-reverse'} className={'task-container'}>
            <Col md={3}>
              <Container spacing={0} className={'task-side-column'}>
                <Col xs={12}>
                  <TaskModalColumn task={task} />
                </Col>
              </Container>
            </Col>
            <Col md={9}>
              {task['@type'] === 'AggregatedTask' && <RelationTaskTable tasks={task.aggregatedTasks} />}
              {task['@type'] === 'ProductTask' && <TaskModalDescription id={task.id} description={task.description ?? ''} />}
              <TaskModalBodyTabs task={task} />
            </Col>
          </Container>
        </Col>
      </Container>
    </>
  );
};

type WatchedTaskManagementStore = Pick<TaskManagementState, 'taskIRI' | 'modalOpened' | 'loading'>;
const TaskModal: FC = () => {
  const dispatch = useAppDispatch();
  const { taskIRI, modalOpened, loading } = useAppSelector(rootState => {
    const watched: WatchedTaskManagementStore = {
      taskIRI: rootState.TaskManagement.taskIRI,
      modalOpened: rootState.TaskManagement.modalOpened,
      loading: rootState.TaskManagement.loading,
    };

    return watched;
  }, shallowEqual);

  if (!taskIRI) {
    return <></>;
  }
  return (
    <Modal
      isOpen={modalOpened}
      centered={true}
      size={'xl'}
      backdrop={true}
      toggle={() => dispatch(closeTask())}
      style={{ maxWidth: '90vw', maxHeight: '90vh', background: 'transparent' }}
      className={'task-modal'}
    >
      <ModalBody>
        {loading && (
          <div style={{ textAlign: 'center', padding: 12 }}>
            <CircularProgress size={64} />
          </div>
        )}
        {!loading && <TaskModalBody />}
      </ModalBody>
    </Modal>
  );
};

export default TaskModal;
