import { Close, MergeOutlined } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { ProductModule } from 'Modules/CTMModules';
import { Col, Container } from 'components/Theme/Grid';
import { MouseEvent, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
    PopoverClasses={{ root: 'table-selectable-actions' }}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function SelectionMergeTasks(props) {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [taskProducts, setTaskProducts] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = () => {
    if (modalOpened) {
      return;
    }
    if (props.selectedFlatRows.length <= 1) {
      return;
    }
    setModalOpened(true);
    setLoading(true);
    Promise.all(props.selectedFlatRows.map(selectedRow => ProductModule.api.get({ id: selectedRow.original.product.id }))).then(
      responses => {
        const productTasks = {};
        props.selectedFlatRows.forEach(selectedRow => {
          productTasks[selectedRow.original['@id']] = {
            task: selectedRow.original,
            product: responses.find(el => el['@id'] === selectedRow.original.product['@id']),
          };
        });
        setTaskProducts(Object.values(productTasks));
        setLoading(false);
      },
    );
  };

  const handleModalClose = () => {
    setModalOpened(!modalOpened);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Wybierz akcję
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleModalOpen} disableRipple>
          {props.selectedFlatRows.length <= 1 && (
            <>
              <i>Wybierz min. 2 zadania aby scalić</i>
            </>
          )}
          {props.selectedFlatRows.length > 1 && (
            <>
              <MergeOutlined />
              Scal zadania
              <Modal
                isOpen={modalOpened}
                centered={true}
                size={'xl'}
                backdrop={true}
                toggle={handleModalClose}
                style={{ maxWidth: '70vw', maxHeight: '90vw', background: 'transparent', zIndex: 1500 }}
                wrapClassName={'merge-tasks-modal'}
              >
                <ModalBody style={{ minHeight: '70vh' }}>
                  {modalOpened && (
                    <>
                      <Container spacing={0} style={{ padding: 16, fontSize: '1.1em' }}>
                        <Col xs={12}>
                          <Container spacing={0} direction={'row-reverse'}>
                            <Col xs={1} style={{ textAlign: 'right' }}>
                              <Button onClick={handleModalClose}>
                                <Close />
                              </Button>
                            </Col>
                            <Col xs={11} style={{ textAlign: 'center' }}>
                              <h1>Scal Zadania</h1>
                            </Col>
                          </Container>
                        </Col>
                        <Col xs={12}>
                          {loading && (
                            <div style={{ textAlign: 'center', padding: 12 }}>
                              <CircularProgress size={64} />
                            </div>
                          )}
                          {!loading && (
                            <>
                              {taskProducts.map(el => (
                                <div key={el.task['@id']}>{el.product.name}</div>
                              ))}
                            </>
                          )}
                        </Col>
                      </Container>
                    </>
                  )}
                </ModalBody>
              </Modal>
            </>
          )}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
