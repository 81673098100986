import { postMedia } from 'helpers/Api/CoreHelper';

export const useFileUpload =
  () =>
  (file: File, onSuccessUpload: (fileResponse: any) => void, onError: (error: any) => void = console.error) => {
    const data = new FormData();
    data.append('file', file);
    postMedia(data)
      .then(onSuccessUpload)
      .catch(err => {
        onError(err);
      });
  };
