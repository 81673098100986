import PackingAndShipmentRowPositionButton from './PackingAndShipmentRowPositionButton';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { ButtonGroup } from '@mui/material';
import NumberField from 'components/CustomFields/FieldTypes/NumberField';

const CostOfPackingNet: CustomFieldComponentType = ({ field, fields, values, value, onChange, readonly, errorKey }) => {
  return (
    <>
      <NumberField
        field={field}
        readonly={readonly}
        errorKey={errorKey}
        value={value}
        onChange={onChange}
        afterInputContent={
          <>
            {!readonly && (
              <ButtonGroup>
                <PackingAndShipmentRowPositionButton values={values} onChange={onChange} fields={fields} />
              </ButtonGroup>
            )}
          </>
        }
        fields={[]}
      />
    </>
  );
};
CostOfPackingNet.provideAllValues = true;
export default CostOfPackingNet;
