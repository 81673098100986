import { DeleteForeverRounded } from '@mui/icons-material';
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { PlaceModule, ProductModule, ProductTypeModule, UnitModule } from 'Modules/CTMModules';
import NumberInput from 'components/Form/MUI/NumberInput';
import PlaceholderInput from 'components/Form/MUI/PlaceholderInput';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import ModuleListScanner from 'components/Module/ModuleListScanner';
import RecordInputReader from 'components/Module/RecordInputReader';
import _ from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';

export const DefaultProductRow = {
  product: null,
  unit: null,
  quantity: 1,
  vat: 23,
  unitPriceNet: 0,
  unitPriceGross: 0,
  place: null,
};

const FormProductRow = ({ item, index, readonly, onChange, onRemove, cellStyles, warehouse, placeEvent }) => {
  const [row, setRow] = useState({ ...DefaultProductRow, ...item });

  useEffect(() => {
    if (!_.isEqual(row, item)) {
      onChange(row);
    }
  }, [row]);

  const updateRowValue = (value, field) => {
    setRow(prevRow => ({ ...prevRow, [field]: value }));
  };

  const updatePricePart = (value, field) => {
    setRow(prevRow => {
      const tmpRow = { ...prevRow };

      if (field === 'unitPriceNet') {
        tmpRow.unitPriceNet = value;
        tmpRow.unitPriceGross = parseInt(tmpRow.vat === 0 ? value : value * (1 + (tmpRow.vat ?? 0) / 100));
      } else if (field === 'unitPriceGross') {
        tmpRow.unitPriceGross = value;
        tmpRow.unitPriceNet = parseInt(tmpRow.vat === 0 ? value : (value * 100) / (100 + tmpRow.vat));
      } else if (field === 'vat') {
        tmpRow.vat = value;
        tmpRow.unitPriceGross = parseInt(tmpRow.vat === 0 ? tmp.unitPriceNet : tmpRow.unitPriceNet * (1 + (tmpRow.vat ?? 0) / 100));
      }

      return tmpRow;
    });
  };

  const places = useMemo(() => {
    const placesSnapshot = row?.placesInfo ?? [];
    if (placesSnapshot.length > 0) {
      return placesSnapshot.map(placeInfo => `${placeInfo.place?.name ?? '???'} (${placeInfo.quantity})`).join(', ');
    }

    return row?.product?.places;
  }, [row?.product?.places, row?.placesInfo]);

  return (
    <>
      <TableRow style={{ border: row.product ? '2px solid #bdbdbd' : 'initial', borderBottom: 'initial' }}>
        <TableCell style={{ width: '30%', position: 'relative', padding: '14px 6px 3px 6px', ...cellStyles }} colSpan={row.product ? 1 : 6}>
          <div style={{ paddingLeft: readonly ? 0 : 25 }}>
            <ModuleListScanner
              showColumns={[
                'labelerId',
                'name',
                'symbol',
                'ean',
                'species.id',
                'lastSupplyPrice',
                'productQuantity.places',
                'group.id',
                'type.id',
              ]}
              disabled={readonly}
              moduleName={'manufacture-products'}
              onChange={gridRow => {
                setRow(prev => ({
                  ...prev,
                  product: gridRow,
                  unit: gridRow?.unit ?? null,
                }));
                updatePricePart(parseInt(gridRow.lastSupplyPrice.toFixed(2) * 100), 'unitPriceNet');
              }}
              disableClear={true}
              forceFocus={true}
              inputScannerLabel={'Wprowadź EAN/Symbol'}
              filterFields={['orEan', 'orSymbol']}
              defaultFilters={[{ id: 'active', value: true }]}
              onClickReset={false}
              isSelected={!!row.product}
            >
              <RecordInputReader
                namePrefix={'rows[' + index + ']'}
                name={'product'}
                readonly={readonly}
                value={row.product}
                module={ProductModule}
                label={'Produkt'}
              />
            </ModuleListScanner>
          </div>
          {!readonly && row.product && (
            <DeleteForeverRounded
              style={{
                color: '#b00020',
                position: 'absolute',
                left: 15,
                top: '50%',
                cursor: 'pointer',
                transform: 'translate(-50%,-50%)',
              }}
              onClick={onRemove}
            />
          )}
        </TableCell>
        {row.product && (
          <>
            <TableCell style={{ padding: '14px 6px 3px 6px', ...cellStyles }}>
              <ModuleListPicker
                disabled={readonly}
                moduleName={'manufacture-units'}
                disableClear={!row.unit}
                onChange={gridRow => setRow(prev => ({ ...prev, unit: gridRow }))}
              >
                <RecordInputReader readonly={readonly} value={row?.unit} module={UnitModule} label={'Jednotska'} fallback={'Szt'} />
              </ModuleListPicker>
            </TableCell>
            <TableCell style={{ padding: '14px 6px 3px 6px', position: 'relative', ...cellStyles }}>
              <NumberInput
                namePrefix={'rows[' + index + ']'}
                value={row.quantity}
                label={'Ilość'}
                name={'quantity'}
                disabled={readonly}
                onChange={updateRowValue}
                inputProps={{
                  onFocus: e => (row.quantity === 0 ? updateRowValue('', e.target.name) : null),
                }}
              />
              {placeEvent === 'SUBTRACT' && (
                <div style={{ position: 'absolute', right: '12px', top: '14px', color: '#34c38fff', fontWeight: 700 }}>
                  / {row.product.quantityAvailable}
                </div>
              )}
            </TableCell>
            <Tooltip
              componentsProps={{}}
              title={
                <Typography variant={'subtitle2'} component={'div'}>
                  Cena z o.d.: {(row.product?.lastSupplyPrice ?? 0).toFixed(2).replace('.', ',')} zł
                </Typography>
              }
              placement={'top'}
              color={'danger'}
              arrow={true}
            >
              <TableCell style={{ position: 'relative', padding: '14px 6px 3px 6px', ...cellStyles }}>
                <NumberInput
                  value={row.unitPriceNet / 100}
                  label={'Cena jdn. netto'}
                  name={'unitPriceNet'}
                  disabled={readonly}
                  onChange={(value, field) => updatePricePart(parseInt((parseFloat(value) * 100).toFixed(2)), field)}
                  inputProps={{
                    onFocus: e => (row.quantity === 0 ? updateRowValue('', e.target.name) : null),
                    className: {
                      'text-danger': parseInt(row.unitPriceNet) !== parseInt((row.product.lastSupplyPrice ?? 0).toFixed(2) * 100),
                    },
                  }}
                />
              </TableCell>
            </Tooltip>
            <TableCell style={{ padding: '14px 6px 3px 6px', ...cellStyles }}>
              <NumberInput
                value={row.vat}
                label={'VAT'}
                name={'vat'}
                disabled={readonly}
                onChange={(value, field) => updatePricePart(parseInt(value), field)}
                inputProps={{
                  onFocus: e => (row.quantity === 0 ? updateRowValue('', e.target.name) : null),
                }}
              />
            </TableCell>
            <TableCell style={{ padding: '14px 6px 3px 6px', ...cellStyles }}>
              <NumberInput
                value={row.unitPriceGross / 100}
                label={'Cena jdn. brutto'}
                name={'unitPriceGross'}
                disabled={readonly}
                onChange={(value, field) => updatePricePart(parseInt((parseFloat(value) * 100).toFixed(2)), field)}
                inputProps={{
                  onFocus: e => (row.quantity === 0 ? updateRowValue('', e.target.name) : null),
                }}
              />
            </TableCell>
          </>
        )}
      </TableRow>
      {row.product && (
        <TableRow style={{ border: '2px solid #bdbdbd', borderTop: 'initial' }}>
          <TableCell style={{ minWidth: 100, padding: '3px 6px 14px 6px', paddingTop: 12, ...(cellStyles ?? {}) }}>
            <PlaceholderInput disabled={true} label={'Miejsca magazynowe'} value={places ?? ''} />
          </TableCell>
          <TableCell style={{ minWidth: 120, padding: '3px 6px 14px 6px', ...(cellStyles ?? {}) }}>
            <RecordInputReader readonly={true} value={row?.product?.type} module={ProductTypeModule} label={'Typ'} />
          </TableCell>
          <TableCell style={{ minWidth: 100, padding: '3px 6px 14px 6px', ...(cellStyles ?? {}) }}>
            <PlaceholderInput disabled={true} label={'Symbol'} value={row?.product?.symbol} />
          </TableCell>
          <TableCell style={{ minWidth: 60, padding: '3px 6px 14px 6px', ...(cellStyles ?? {}) }}>
            <PlaceholderInput disabled={true} label={'ECM'} value={row?.product?.labelerId} />
          </TableCell>
          <TableCell style={{ minWidth: 100, padding: '3px 6px 14px 6px', ...(cellStyles ?? {}) }}>
            <PlaceholderInput disabled={true} label={'EAN'} value={row?.product?.ean} />
          </TableCell>
          <TableCell style={{ minWidth: '120px', padding: '3px 6px 14px 6px', ...cellStyles }}>
            <ModuleListScanner
              disabled={readonly}
              moduleName={'warehouse-places'}
              disableClear={!row.place}
              onChange={gridRow => setRow(prev => ({ ...prev, place: gridRow }))}
              defaultFilters={warehouse ? [{ id: 'warehouse.id', value: warehouse?.id }] : []}
              item={row.place}
              forceFocus={false}
              inputScannerLabel={'Wprowadź nazwę miejsca magazynowego'}
              filterFields={['name']}
              onClickReset={true}
              isSelected={!!row.place}
              namePrefix={'rows[' + index + ']'}
              name={'place'}
            >
              <RecordInputReader
                namePrefix={'rows[' + index + ']'}
                name={'place'}
                readonly={readonly}
                value={row.place}
                module={PlaceModule}
                label={'Miejsce magazynowe'}
              />
            </ModuleListScanner>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default memo(FormProductRow);
