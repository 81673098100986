import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Note } from 'Modules/Note/Model';
import { AxiosError } from 'axios';
import NoteEditor from 'ckeditor5-custom-build';
import useApiResourceQuery, { del, patch } from 'helpers/Axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useUnmount } from 'react-use';
import useToggle from 'react-use/lib/useToggle';

interface UpdateNotePayload {
  title?: string;
  content?: string;
}

export default function NoteView() {
  const id = useParams<{ id: string }>().id;
  const iri = useMemo(() => '/notes/' + id, [id]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const onTitleChange = e => setTitle(e.target.value);
  const onContentChange = (event, editor) => {
    setContent(editor.getData());
  };
  const history = useHistory();
  const queryClient = useQueryClient();

  const [{ data: note, isLoading }, key] = useApiResourceQuery<Note>(iri, {
    onError: () => history.replace('/notes'),
  });

  useEffect(() => {
    if (!note) {
      return;
    }
    setTitle(note.title || '');
    setContent(note.content || '');
  }, [note]);

  const updateMutation = useMutation<Note, AxiosError, UpdateNotePayload>({
    mutationFn: payload => patch(iri, payload),
    onSuccess: data => {
      queryClient.setQueriesData(key, data);
      void queryClient.invalidateQueries('/notes');
    },
  });

  const updateNote = useCallback(() => {
    if (!note) {
      return;
    }

    const payload: UpdateNotePayload = {};

    if (updateMutation.isLoading || (note.title === title && note.content === content)) {
      return;
    }

    note.title !== title && (payload['title'] = title);
    note.content !== content && (payload['content'] = content);
    updateMutation.mutate(payload);
  }, [note, content, title, updateMutation]);

  useUnmount(updateNote);

  const [isTitleEditModeEnabled, toggleTitleEditModeEnabled] = useToggle(false);
  const onTitleBlur = useCallback(() => {
    toggleTitleEditModeEnabled();
    updateNote();
  }, [toggleTitleEditModeEnabled, updateNote]);

  const onContentBlur = updateNote;

  const remove = useCallback(() => {
    del(iri)
      .then(() => queryClient.invalidateQueries('/notes'))
      .then(() => history.replace('/notes'));
  }, [iri, queryClient, history]);

  if (isLoading) {
    return <>Trwa ładowanie twojej notatki...</>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        {isTitleEditModeEnabled ? (
          <input
            style={{ background: 'transparent', border: 'none', padding: 0 }}
            className="h2"
            autoFocus
            value={title}
            onChange={onTitleChange}
            onBlur={onTitleBlur}
          />
        ) : (
          <h2 onClick={toggleTitleEditModeEnabled}>{title || <i>Bez tytułu</i>}</h2>
        )}
        <button onClick={remove} className="btn btn-danger ml-1">
          <i className="mdi mdi-delete" /> Usuń
        </button>
      </div>
      <CKEditor data={content} onChange={onContentChange} onBlur={onContentBlur} editor={NoteEditor} />
    </div>
  );
}
