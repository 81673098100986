import { Product, RequiredProduct } from 'Modules/Manufacture/Product';
import { FC, useCallback, useEffect, useState } from 'react';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';

interface SidebarProps {
  record: Product;
  readonly: boolean;
  setRecord: (record: Product) => void;
  setPath: (path: string | null) => void;
  currentRecordPath: string | null;
}

interface ProductItemInterface {
  title: JSX.Element;
  id: string;
  children: ProductItemInterface[];
  expanded: boolean;
  propertyPath: string;
}

interface TitleProps {
  record: Product | null | undefined;
  path: string | null;
  currentRecordPath: string | null;
  setRecord: (record: Product) => void;
  setPath: (path: string | null) => void;
}

const Title: FC<TitleProps> = ({ record, path, currentRecordPath, setPath, setRecord }) => {
  if (!record) {
    return <></>;
  }

  return (
    <span
      style={
        currentRecordPath === path
          ? {
              fontWeight: 900,
              textDecoration: 'underline',
            }
          : {}
      }
      onClick={() => {
        setRecord(record);
        setPath(path);
      }}
    >
      <i style={{ color: `${record.type?.color || '#edf0f5'}` }} className={`mdi mdi-${record.type?.icon}`} />
      {record.name}
    </span>
  );
};

const Sidebar: FC<SidebarProps> = ({ record, setRecord, setPath, currentRecordPath }) => {
  const [tree, setTree] = useState<ProductItemInterface[]>([]);

  const mapProductToTree = useCallback(
    (requiredProducts: RequiredProduct[], propertyPath: string): ProductItemInterface[] => {
      return requiredProducts
        .filter(item => item.product)
        .map((item, index: number): ProductItemInterface => {
          const currentPropertyPath = `${propertyPath}${propertyPath === '' ? '' : '.product.'}requiredProducts[${index}]`;
          return {
            title: (
              <Title
                record={item.product}
                path={currentPropertyPath}
                currentRecordPath={currentRecordPath}
                setPath={setPath}
                setRecord={setRecord}
              />
            ),
            id: item.product?.id ?? '',
            children:
              (item.product?.requiredProducts ?? []).length > 0
                ? mapProductToTree(item.product?.requiredProducts ?? [], currentPropertyPath)
                : [],
            expanded: true,
            propertyPath: currentPropertyPath,
          };
        });
    },
    [record, currentRecordPath],
  );

  useEffect(() => {
    setTree([
      {
        title: <Title record={record} path={''} currentRecordPath={currentRecordPath} setPath={setPath} setRecord={setRecord} />,
        id: record.id ?? '',
        children: record.requiredProducts.length > 0 ? mapProductToTree(record.requiredProducts, '') : [],
        expanded: true,
        propertyPath: '',
      },
    ]);
  }, [mapProductToTree, record, currentRecordPath]);

  return (
    <div style={{ width: '30%', padding: '10px', overflow: 'scroll' }} className={'form-control'}>
      <SortableTree
        treeData={tree}
        isVirtualized={false}
        onChange={(treeData: ProductItemInterface[]) => setTree(treeData)}
        canDrag={false}
        theme={FileExplorerTheme}
      />
    </div>
  );
};

export default Sidebar;
