import { WarehouseModule } from 'Modules/CTMModules';
import { Warehouse as WarehouseItem } from 'Modules/Warehouse/Warehouse';
import { AxiosError } from 'axios';
import { ApiCollection, ApiItem } from 'helpers/Axios';
import { useQuery } from 'react-query';

interface Warehouse extends ApiItem, WarehouseItem {}

type WarehousesCollection = ApiCollection<Warehouse>;

export default function useWarehouses() {
  const query = useQuery<WarehousesCollection, AxiosError, Warehouse[]>({
    queryKey: WarehouseModule.configuration.urlPrefix,
    queryFn: () => WarehouseModule.api.getAll(),
    select: data => data['hydra:member'],
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query.data || [],
  };
}
