import TextField from '@mui/material/TextField';
import { axiosApi } from 'helpers/Axios';
import { ChangeEvent, FC, FocusEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';

interface ProductionOrderQuantityProps {
  id: string;
  quantity: number;
  url: string;
}

const ProductionOrderQuantity: FC<ProductionOrderQuantityProps> = ({ id, quantity, url }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(quantity.toString());

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsEditing(false);

    if (Number(e.target.value) < quantity) {
      dispatch(
        addSingleToast({
          title: `Nie możesz zmniejszyć wartości zamówienia.`,
          config: { appearance: 'error' },
        }),
      );
      setValue(quantity.toString());
      return;
    }

    axiosApi
      .put(url + `/${id}`, {
        quantity: Number(e.target.value),
      })
      .then(() => {
        dispatch(addSingleToast({ title: `Zapisano zmiany`, config: { appearance: 'success' } }));
      })
      .catch(data => {
        dispatch(
          addSingleToast({
            title: data?.response?.['hydra:description'] ?? 'Wystąpił błąd z zapisem',
            config: { appearance: 'error' },
          }),
        );
      });
  };

  return (
    <div onClick={handleDoubleClick}>
      {isEditing ? (
        <TextField type="text" value={value} onChange={handleChange} onBlur={handleBlur} autoFocus={true} />
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
};

export default ProductionOrderQuantity;
