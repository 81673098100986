import ErrandComponentBox, { ErrandComponentBoxProps } from './ErrandComponentBox';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Paper, Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import { ProductModule, UnitModule } from 'Modules/CTMModules';
import { ErrandRowType } from 'Modules/Manufacture/Types/Errand';
import { Product } from 'Modules/Manufacture/Types/Product';
import NumberInput from 'components/Form/MUI/NumberInput';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import RecordInputReader from 'components/Module/RecordInputReader';
import { Col, Container } from 'components/Theme/Grid';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

const DefaultRow: ErrandRowType = {
  product: null,
  unit: null,
  seq: 1,
  quantity: 1,
};

interface ErrandPositionProps {
  position: ErrandRowType;
  onChange?: (ErrandRowType) => void;
  onRemove?: (id: string | null | undefined) => void;
  onShowComponentBox?: (productId: string | null | undefined, quantity: number) => void;
  readonly?: boolean;
}

const ErrandPosition: FC<ErrandPositionProps> = ({ position, onChange, onRemove, onShowComponentBox, readonly }) => {
  const [rowState, setRowState] = useState<ErrandRowType>(position);

  useEffect(() => {
    if (JSON.stringify(position) !== JSON.stringify(rowState)) {
      onChange && onChange(rowState);
    }
  }, [rowState]);

  return (
    <>
      <TableRow>
        <TableCell style={{ width: '30%' }} colSpan={rowState.product ? 1 : 3}>
          <ModuleListPicker<Product>
            disabled={readonly}
            moduleName={'manufacture-products'}
            onChange={gridRow => setRowState(prev => ({ ...prev, product: gridRow }))}
            disableClear={true}
            moduleFormComponentProps={undefined}
            moduleFormComponent={undefined}
            moduleFormWrapperProps={undefined}
            allowMultipleSelect={false}
            onMultipleSelect={console.error}
            defaultFilters={undefined}
            overrideUrl={undefined}
          >
            <RecordInputReader
              readonly={readonly}
              value={rowState.product}
              module={ProductModule}
              label={!rowState.product ? 'Wybierz produkt aby dodać' : 'Produkt'}
            />
          </ModuleListPicker>
        </TableCell>
        {rowState.product && (
          <>
            <TableCell>
              <ModuleListPicker
                disabled={readonly}
                moduleName={'manufacture-units'}
                disableClear={!rowState.unit}
                onChange={gridRow => setRowState(prev => ({ ...prev, unit: gridRow }))}
                moduleFormComponentProps={undefined}
                moduleFormComponent={undefined}
                moduleFormWrapperProps={undefined}
                allowMultipleSelect={false}
                onMultipleSelect={console.error}
                defaultFilters={undefined}
                overrideUrl={undefined}
              >
                <RecordInputReader readonly={readonly} value={rowState?.unit} module={UnitModule} label={'Jednotska'} fallback={'Szt'} />
              </ModuleListPicker>
            </TableCell>
            <TableCell>
              <NumberInput
                value={rowState.quantity}
                label={'Ilość'}
                name={'quantity'}
                disabled={readonly}
                onChange={val => setRowState(prev => ({ ...prev, quantity: val ?? 1 }))}
              />
            </TableCell>
            <TableCell width={5}>
              <ButtonGroup>
                {!readonly && onRemove && (
                  <Button onClick={() => onRemove(position.id)} className="btn btn-danger btn-sm">
                    <Tooltip title="Usuwanie elementu">
                      <i className="mdi mdi-delete" />
                    </Tooltip>
                  </Button>
                )}
                {onShowComponentBox && position.product && (
                  <Button className="btn btn-success btn-sm" onClick={() => onShowComponentBox(position.product?.id, position.quantity)}>
                    <Tooltip title="Podgląd zapotrzebowania">
                      <i className="mdi mdi-magnify" />
                    </Tooltip>
                  </Button>
                )}
              </ButtonGroup>
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};

const ErrandPositionMemo = memo(ErrandPosition);

const ErrandPositions: CustomFieldComponentType = ({ value, onChange, readonly, field }) => {
  const [rows, setRows] = useState<ErrandRowType[]>(value ?? []);

  const handleChange = useCallback(position => {
    setRows(prevRows =>
      prevRows.map(el => {
        if (el.id === position.id) {
          return { ...el, ...position };
        }
        return el;
      }),
    );
  }, []);

  const handleNew = useCallback(newPosition => {
    setRows(prevRows => [...prevRows, { ...newPosition, id: newPosition.id ?? uuidv4() }]);
  }, []);

  const onRemove = useCallback((id: string | null | undefined) => {
    setRows(prevRows => prevRows.filter(item => item.id !== id));
  }, []);

  const onShowComponentBox = useCallback((productId: string | null | undefined, quantity: number) => {
    setComponentBox({
      ...componentBox,
      ...{
        productId: productId,
        quantity: quantity,
        show: true,
      },
    });
  }, []);

  const [componentBox, setComponentBox] = useState<ErrandComponentBoxProps>({
    show: false,
    productId: '',
    quantity: 0,
    onClose: () =>
      setComponentBox({
        ...componentBox,
        ...{
          show: false,
        },
      }),
  });

  useEffect(() => {
    onChange(rows, field.id);
  }, [rows]);

  return (
    <>
      <Container spacing={2}>
        <Col xs={12}>
          <Paper style={{ overflowX: 'auto' }}>
            <Table style={{ minWidth: 640 }}>
              <TableBody>
                {(rows ?? []).map(el => (
                  <ErrandPositionMemo
                    key={`value_${el.id}`}
                    position={el}
                    onChange={handleChange}
                    onRemove={onRemove}
                    readonly={readonly}
                    onShowComponentBox={onShowComponentBox}
                  />
                ))}
                {!readonly && (
                  <ErrandPositionMemo
                    key={`new_value_${rows.length}`}
                    position={DefaultRow}
                    onChange={handleNew}
                    onRemove={onRemove}
                    onShowComponentBox={onShowComponentBox}
                  />
                )}
              </TableBody>
            </Table>
          </Paper>
        </Col>
      </Container>
      <ErrandComponentBox {...componentBox} />
    </>
  );
};

export default ErrandPositions;
