import useIsGranted from 'common/hooks/isGranted';
import BaseInput from 'components/Form/BaseInput';
import Switch from 'components/Form/Switch';
import { Col, Container } from 'components/Theme/Grid';
import { withRouter } from 'react-router-dom';

const ThreeDBinPacking = ({ form, updateConfiguration }) => {
  const canEdit = useIsGranted('ROLE_EDIT_CONFIGURATION');

  const updateFormValue = (value, field) => {
    updateConfiguration({ ...form, [field]: value });
  };

  return (
    <Container>
      <Col xs={12} md={12} lg={2}>
        <Switch
          size={{ md: 12 }}
          label={'Aktywne'}
          value={form.active}
          name="active"
          onChange={updateFormValue}
          disabled={!canEdit}
          switchOnRight={true}
        />
      </Col>
      <Col xs={6} md={6} lg={4}>
        <BaseInput
          size={{ md: 12 }}
          label={'Username'}
          value={form.username}
          name="username"
          onChange={updateFormValue}
          disabled={!canEdit}
        />
      </Col>
      <Col xs={6} md={6} lg={4}>
        <BaseInput size={{ md: 12 }} label={'Api key'} value={form.apiKey} name="apiKey" onChange={updateFormValue} disabled={!canEdit} />
      </Col>
    </Container>
  );
};

export default withRouter(ThreeDBinPacking);
