// Import scss
import modules from 'Modules/CTMModules';
import MercureFeedProvider from 'Modules/Mercure/Context/MercureFeedProvider';
import 'assets/scss/theme.scss';
import NonAuthLayout from 'components/Theme/NonAuthLayout';
// layouts Format
import VerticalLayout from 'components/Theme/VerticalLayout/';
import ModulesContext from 'context/ModulesContext';
import moment from 'moment';
import 'moment/locale/pl';
import PropTypes from 'prop-types';
import React from 'react';
import 'react-grid-layout/css/styles.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { connect } from 'react-redux';
import 'react-resizable/css/styles.css';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import 'reactflow/dist/style.css';
// Import Routes all
import { authProtectedRoutes, publicRoutes } from 'routes';
// Import all middleware
import Authmiddleware from 'routes/route';

moment.locale('pl');

export const queryClient = new QueryClient();

const App = () => {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <MercureFeedProvider>
          <ModulesContext.Provider value={{ modules }}>
            <Router>
              <Switch>
                {publicRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    exact={route.hasOwnProperty('exact') ? route.exact : true}
                  />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={VerticalLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact={route.hasOwnProperty('exact') ? route.exact : true}
                  />
                ))}
              </Switch>
            </Router>
          </ModulesContext.Provider>
        </MercureFeedProvider>
      </QueryClientProvider>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
