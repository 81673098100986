import { Node } from './FlowUtils';
import useStore from './store';
import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { Edge, useOnSelectionChange } from 'reactflow';

const SelectionGuard = () => {
  const { allNodes, allEdges, updateState } = useStore(
    state => ({
      updateState: state.updateState,
      allNodes: state.nodes,
      allEdges: state.edges,
    }),
    shallowEqual,
  );

  const extractParents = (currentNode: Node, edges: Edge[], extractDepth = true) => {
    const currentNodeParents = edges
      .filter(edge => edge.source === currentNode.id)
      .map(edge => allNodes.find(node => node.id === edge.target));
    const parentParents = !extractDepth ? [] : currentNodeParents.map(parent => extractParents(parent as Node, edges));

    return [...currentNodeParents, ...parentParents.flat()];
  };

  const onChange = useCallback(
    ({ nodes }) => {
      const selectedNodes = nodes.map(el => el.id);
      nodes.forEach(node => {
        const parents = extractParents(node, allEdges);
        const hasParentSelected = parents.some(parent => selectedNodes.includes(parent.id));

        if (hasParentSelected) {
          selectedNodes.push(...extractParents(node, allEdges, false).map(parent => parent.id));
        }
      });

      setTimeout(() => {
        updateState({ nodes: allNodes.map(node => ({ ...node, selected: selectedNodes.includes(node.id) })) });
      }, 150);
    },
    [allNodes],
  );

  useOnSelectionChange({
    onChange,
  });
  return <></>;
};

export default SelectionGuard;
