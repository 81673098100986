import { extractIRIFromRecord } from '../../helpers/ModuleUtils';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type Slide = CTMRecord & {
  name?: string;
  file?: CTMRecord | string | null;
  language?: CTMRecord | string | null;
};

const columns: CTMListColumn<Slide>[] = [
  {
    id: 'name',
    Header: 'Nazwa',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    id: 'language.isoCode',
    Header: 'Język',
    accessor: ({ language }) =>
      typeof language !== 'string' ? (
        <>
          <span className={`fi fi-${language?.icon}`}></span> {language?.relationFieldLabel.toUpperCase()}
        </>
      ) : (
        <></>
      ),
    filterable: true,
    sortable: true,
  },
  {
    id: 'position',
    Header: 'Pozycja',
    accessor: 'position',
    filterable: true,
    sortable: true,
  },
];

const module: CTMModule<Slide> = {
  id: 'e6d192bd-da3d-48d4-ba06-28a819d5d89e',
  dataClass: 'CTM\\Store\\Entity\\Slide',
  urlPrefix: 'store-slides',
  name: 'Slider sklepu',
  role: 'STORE_SLIDE',
  api: {
    item: {
      get: ({ id }) => `/ecommerce/store/slides/${id}`,
      put: ({ id }) => `/ecommerce/store/slides/${id}`,
      delete: ({ id }) => `/ecommerce/store/slides/${id}`,
    },
    collection: {
      get: `/ecommerce/store/slides`,
      post: `/ecommerce/store/slides`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {},
    prepareRecordToSave: record => ({
      ...record,
      file: extractIRIFromRecord(record?.file),
      language: extractIRIFromRecord(record?.language),
    }),
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'position', desc: false }],
  },
};

export default module;
