import { useEffect, useState } from 'react';

export default function useTabIsActive() {
  const [isActive, setActive] = useState(document.visibilityState === 'visible');

  useEffect(() => {
    const onVisibilityChange = () => setActive(document.visibilityState === 'visible');
    const onBlur = () => setActive(false);
    const onFocus = () => setActive(true);
    document.addEventListener('visibilitychange', onVisibilityChange);
    window.addEventListener('focus', onFocus);
    window.addEventListener('blue', onBlur);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blue', onBlur);
    };
  }, [setActive]);

  return isActive;
}
