import useIsGranted from 'common/hooks/isGranted';
import BaseInput from 'components/Form/BaseInput';
import Switch from 'components/Form/Switch';
import { Col, Container } from 'components/Theme/Grid';
import { withRouter } from 'react-router-dom';

const MailerConfiguration = ({ form, updateConfiguration }) => {
  const canEdit = useIsGranted('ROLE_EDIT_CONFIGURATION');

  const updateFormValue = (value, field) => {
    updateConfiguration({ ...form, [field]: value });
  };

  return (
    <Container>
      <Col xs={12} md={12} lg={12}>
        <h5>Ogólne</h5>
      </Col>
      <Col xs={12} md={3}>
        <BaseInput size={{ md: 12 }} label={'Host'} value={form.host} name="host" onChange={updateFormValue} disabled={!canEdit} />
      </Col>
      <Col xs={12} md={1}>
        <BaseInput
          size={{ md: 12 }}
          label={'Port'}
          value={form.port}
          name="port"
          onChange={(val, field) => updateFormValue(val !== '' ? parseInt(val) : null, field)}
          disabled={!canEdit}
          type="number"
        />
      </Col>
      <Col xs={12} md={3}>
        <BaseInput
          size={{ md: 12 }}
          label={'Nazwa użytkownika'}
          value={form.username}
          name="username"
          onChange={updateFormValue}
          disabled={!canEdit}
        />
      </Col>
      <Col xs={12} md={3}>
        <BaseInput
          size={{ md: 12 }}
          label={'Hasło'}
          value={form.password}
          name="password"
          type="password"
          onChange={updateFormValue}
          disabled={!canEdit}
        />
      </Col>
      <Col xs={12} md={2}>
        <Switch
          size={{ md: 12 }}
          label={'SSL'}
          value={form.encryption === 'ssl'}
          name="active"
          onChange={val => updateFormValue(val ? 'ssl' : null, 'encryption')}
          disabled={!canEdit}
          switchOnRight={true}
        />
        <Switch
          size={{ md: 12 }}
          label={'TLS'}
          value={form.encryption === 'tls'}
          name="tls"
          onChange={val => updateFormValue(val ? 'tls' : null, 'encryption')}
          disabled={!canEdit}
          switchOnRight={true}
        />
      </Col>
      <Col xs={12}>
        <h5>Nadawca</h5>
        <p>Skonfigurowanie tych wartości pozwoli na zmianę nazwy nadawcy w mailu, pamiętaj że Twój mailer musi akceptować taką wartość.</p>
      </Col>
      <Col xs={12} md={3}>
        <BaseInput
          size={{ md: 12 }}
          label={'E-mail nadawcy'}
          value={form.from}
          name="from"
          onChange={updateFormValue}
          disabled={!canEdit}
        />
      </Col>
      <Col xs={12} md={3}>
        <BaseInput
          size={{ md: 12 }}
          label={'Nazwa nadawcy'}
          value={form.fromName}
          name="fromName"
          onChange={updateFormValue}
          disabled={!canEdit}
        />
      </Col>
      <Col xs={12}>
        <h5>Odpowiedź</h5>
        <p>Skonfigurowanie tych wartości pozwoli na zmianę adresu automatycznej odpowiedzi.</p>
      </Col>
      <Col xs={12} md={3}>
        <BaseInput
          size={{ md: 12 }}
          label={'E-mail do odpowiedzi'}
          value={form.replyTo}
          name="replyTo"
          onChange={updateFormValue}
          disabled={!canEdit}
        />
      </Col>
      <Col xs={12} md={3}>
        <BaseInput
          size={{ md: 12 }}
          label={'Nazwa odpowiedz do'}
          value={form.replyToName}
          name="replyToName"
          onChange={updateFormValue}
          disabled={!canEdit}
        />
      </Col>
      <Col xs={12}>
        <h5>Bcc</h5>
        <p>Skonfigurowanie tych wartości pozwoli na otrzymywanie kopii każdego wysłanego emaila przez system.</p>
      </Col>
      <Col xs={12} md={3}>
        <BaseInput size={{ md: 12 }} label={'E-mail Bcc'} value={form.bcc} name="bcc" onChange={updateFormValue} disabled={!canEdit} />
      </Col>
      <Col xs={12} md={3}>
        <BaseInput
          size={{ md: 12 }}
          label={'Nazwa Bcc'}
          value={form.bccName}
          name="bccName"
          onChange={updateFormValue}
          disabled={!canEdit}
        />
      </Col>
    </Container>
  );
};

export default withRouter(MailerConfiguration);
