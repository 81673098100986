import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import ListPicker from 'components/Form/ListPicker';
import Switch from 'components/Form/Switch';

const BooleanField: CustomFieldComponentType = ({ field, value, onChange, readonly, errorKey, customProps = {} }) => {
  if (field.config.component === 'SELECT') {
    return (
      <ListPicker
        name={field.id}
        label={field.name}
        value={value || false}
        options={[
          { val: true, label: 'Tak' },
          { val: false, label: 'Nie' },
        ]}
        optionLabel="label"
        optionTrackBy="val"
        onChange={onChange}
        disabled={readonly}
        errorKey={errorKey}
        labelerId={field.labelerId}
        {...customProps}
      />
    );
  }
  return (
    <>
      <Switch
        size={{ md: 12 }}
        label={field.name}
        value={value || false}
        name={field.id}
        onChange={onChange}
        disabled={readonly}
        switchOnRight={true}
        labelerId={field.labelerId}
        {...customProps}
      />
    </>
  );
};

export default BooleanField;
