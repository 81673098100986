import ClientRegistrationRequest from '../../ClientRegistrationRequest';
import { Tooltip } from '@mui/material';
import { confirmAction } from 'common/sweetalerts';
import { put } from 'helpers/Axios';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

const AcceptClientRegistration = ({ id }: { id: string }) => {
  const history = useHistory();

  const onClick = () => {
    confirmAction(
      () =>
        new Promise((res, rej) => {
          put(ClientRegistrationRequest.api.item.accept({ id }), {})
            .then(data => {
              res(true);
              history.push(`/modules/ecommerce-client/show/${data.id}`);
            })
            .catch(rej);
        }),
      'Czy jesteś pewien?',
      'Tej akcji nie można cofnąć!',
      true,
      'Rejestracja została zaakceptowana. Klient został utworzony.',
      false,
    );
  };

  return (
    <>
      <Tooltip title={'Zaakceptuj rejestrację tego klienta'}>
        <div>
          <Button onClick={onClick} className="btn btn-success btn-sm">
            <i className="mdi mdi-account-check" /> Akceptuj
          </Button>
        </div>
      </Tooltip>
    </>
  );
};

export default AcceptClientRegistration;
