import { FieldTypesIndexed } from '../const/FieldTypes';
import { EditFieldModal } from './FieldConfigurationModal';
import { SettingsApplications } from '@mui/icons-material';
import { Button, Paper } from '@mui/material';
import { Col } from 'components/Theme/Grid';

function FieldSection(props) {
  const { field, fields, moduleId, afterSave } = props;

  return (
    <Col xs={12} key={field['@id']}>
      <Paper
        variant="outlined"
        style={{ padding: 6, background: '#2a3042', color: '#fff' }}
        draggable={true}
        unselectable="on"
        onDragStart={e => {
          e.dataTransfer.setData('Text', field.id ?? '');
        }}
      >
        <div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <div>{(field.name ?? '') === '' ? 'Nazwa pola' : field.name}</div>
            <div>
              <EditFieldModal fields={fields} field={field} moduleId={moduleId} afterSave={afterSave}>
                <Button color="primary" size="small" variant="contained" style={{ color: '#fff', padding: 0, minWidth: 0 }}>
                  <SettingsApplications />
                </Button>
              </EditFieldModal>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <i>{FieldTypesIndexed[field.type ?? ''] ?? field.type ?? 'Nieznany typ'}</i>
          </div>
        </div>
      </Paper>
    </Col>
  );
}

export default FieldSection;
