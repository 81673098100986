import { BaseInputProps } from './Types';
import { FormLabel } from '@mui/material';
// import { ArrowDropDown } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
// import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
// import ListItemText from '@mui/material/ListItemText';
import { MenuProps as MUIMenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import { memo, useEffect, useState } from 'react';
import Select, { GroupBase } from 'react-select';
// import SelectType from 'react-select/dist/declarations/src/Select';
import { SelectComponentsConfig } from 'react-select/dist/declarations/src/components';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';

interface SelectProps extends BaseInputProps {
  label?: string;
  value?: null | string[] | any[] | string;
  options?: any[];
  valueFormat?: string;
  trackKey: string;
  labelKey: string | ((any) => string);
  multiple?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  onChange?: (value: string | (string | null)[] | null, name: undefined | string) => void;
  customStyles?: StylesConfig;
}

interface SelectOption {
  id: string;
  label: string;
}

const MenuProps: Partial<MUIMenuProps> = {
  PaperProps: {
    style: {
      maxHeight: 300,
      paddingTop: 8.5,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};
const defaultCustomStyles: StylesConfig = {
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    background: 'transparent',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  container: () => ({
    height: '38px',
    width: '100%',
    transition: 'max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    padding: '2px 8px 8px 2px',
    border: '1px solid rgba(0, 0, 0,0.23)',
    borderRadius: '4px',
  }),
  menu: () => ({
    backgroundColor: 'white',
    boxShadow: '1px 2px 6px #888888', // should be changed as material-ui
    position: 'absolute',
    left: 0,
    top: `calc(100% + 1px)`,
    width: '100%',
    zIndex: 2,
    maxHeight: 48 * 4.5,
  }),
  menuList: () => ({
    maxHeight: 48 * 4.5,
    overflowY: 'auto',
  }),
};
const Option = props => {
  const handleClick = event => {
    props.selectOption(props.data, event);
  };

  const { children, isFocused, isSelected, onFocus } = props;
  return (
    <MenuItem
      onFocus={onFocus}
      selected={isFocused}
      onClick={handleClick}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {children}
    </MenuItem>
  );
};

const components: SelectComponentsConfig<unknown, boolean, GroupBase<unknown>> = {
  Option: Option,
  // DropdownIndicator: ArrowDropDown,
};
const SelectInput = ({
  label,
  value,
  name,
  valueFormat,
  trackKey = '@id',
  labelKey = 'name',
  onChange,
  disabled,
  multiple = false,
  isLoading = false,
  isClearable = true,
  options = [],
  customStyles = {},
}: SelectProps) => {
  const mapToOption = (param: object | string): SelectOption => {
    if (param.constructor === String) {
      const matchedOption = selectOptions.find(el => mapValues(el) === param);

      if (matchedOption) {
        return matchedOption;
      }

      return {
        id: param,
        label: param,
      };
    }
    return {
      id: param[trackKey],
      label: typeof labelKey === 'function' ? labelKey(param) : param[labelKey],
    };
  };

  const isSelectOption = (x: any): x is SelectOption => true;

  const mapToOptions = (param: undefined | null | string | string[] | object | object[]): SelectOption[] | SelectOption | null => {
    if (param === null || param === undefined) {
      return multiple ? [] : null;
    }
    if (typeof param === 'string') {
      return multiple ? [mapToOption(param)] : mapToOption(param);
    }
    if (param.constructor === Array && !multiple) {
      return mapToOption(param);
    }
    if (param.constructor === Array && multiple) {
      return param.map(mapToOption);
    }

    return multiple ? [mapToOption(param)] : mapToOption(param);
  };
  const mapValue = (param: null | string | object): string | null => {
    if (!param) {
      return null;
    }
    if (typeof param === 'string') {
      return param;
    }

    let val;
    if (isSelectOption(param)) {
      val = param.id;
    } else {
      val = param[trackKey];
    }

    return typeof val === 'string' ? val : null;
  };
  const mapValues = (param: undefined | null | string | string[] | object | object[]): null | string | (null | string)[] => {
    if (param === null || param === undefined) {
      return multiple ? [] : null;
    }
    if (typeof param === 'string') {
      return multiple ? [param] : param;
    }
    if (param.constructor === Array && !multiple) {
      return mapValue(param[Object.keys(param)[0]]);
    }
    if (param.constructor === Array && multiple) {
      return param.map(mapValue);
    }

    return multiple ? [mapValue(param)] : mapValue(param);
  };

  const [selectOptions, setSelectOptions] = useState<SelectOption[]>(options?.map(mapToOption) ?? []);
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[] | SelectOption | null>(mapToOptions(value));

  useEffect(() => {
    setSelectedOptions(mapToOptions(value));
  }, [value]);

  useEffect(() => {
    setSelectOptions(options?.map(mapToOption) ?? []);
  }, [options]);

  const muiHandleChange = event => {
    setSelectedOptions(event);
    onChange && onChange(mapValues(event), name);
  };

  return (
    <FormControl className={'position-relative'} variant="outlined" style={{ width: '100%' }} size="small">
      <FormLabel className={'react-select-label'}>{label}</FormLabel>
      <Select
        className={'ctm-select'}
        value={selectedOptions}
        components={components}
        styles={{ ...defaultCustomStyles, ...customStyles }}
        isClearable={isClearable === true}
        options={selectOptions}
        isMulti={multiple}
        isDisabled={disabled}
        onChange={muiHandleChange}
        isLoading={isLoading === true}
      />
    </FormControl>
  );
};

export default memo(SelectInput);
