import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { DictionaryModule, DictionaryValueModule } from 'Modules/CTMModules';
import { get } from 'helpers/Axios';
import useErrorHandler from 'helpers/FormHandler';
import CreateDictionaryValueModal from 'pages/Configuration/Dictionary/CreateDictionaryValueModal';
import DictionaryValueRow from 'pages/Configuration/Dictionary/DictionaryValueRow';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';
import { clearErrors } from 'store/form/errors/actions';

const ValuesField: CustomFieldComponentType = ({ readonly, recordId }) => {
  const errorHandler = useErrorHandler();
  const dispatch = useDispatch();
  const [values, setValues] = useState<any[]>([]);

  const id = !recordId ? null : recordId.split('/').pop();

  useEffect(() => {
    const id = !recordId ? null : recordId.split('/').pop();
    if (!id) {
      return;
    }
    dispatch(clearErrors());
    const fetchData = async () => {
      const dictionaryValues = await get(DictionaryModule.configuration.api.item.getValues({ id: id }), { params: { limit: 1000 } });
      setValues(dictionaryValues['hydra:member']);
    };

    if (id) {
      fetchData();
    } else {
      setValues([]);
    }
  }, [recordId]);

  const collectNewValue = newValue => {
    dispatch(addSingleToast({ title: `Dodano nową wartość do słownika`, config: { appearance: 'success' } }));
    setValues([...values, newValue]);
  };

  const onRemove = iri => {
    dispatch(addSingleToast({ title: `Skasowano wartość z słownika`, config: { appearance: 'warning' } }));
    setValues([...values.filter(el => el['@id'] !== iri)]);
  };

  const updateExistingValue = (index, newValue) => {
    DictionaryValueModule.api
      .put(newValue, { id: values[index].id })
      .then(() => {
        dispatch(addSingleToast({ title: `Zapisano zmiany`, config: { appearance: 'warning' } }));
        const newValues = [...values];
        newValues[index] = newValue;
        setValues(newValues);
      })
      .catch(errorHandler);
  };

  return (
    <>
      {id && (
        <Row>
          <Col>
            <Card className="border-small">
              {!readonly && values.length > 6 && (
                <CardHeader style={{ textAlign: 'right' }}>
                  <CreateDictionaryValueModal
                    onSaved={collectNewValue}
                    dictionary={`/core/dictionaries/${id}`}
                    buttonProps={{ fullWidth: false }}
                  />
                </CardHeader>
              )}
              <CardBody>
                <Table>
                  <TableHead>
                    <TableRow>
                      {!readonly && (
                        <TableCell variant="head" style={{ width: 65 }}>
                          #
                        </TableCell>
                      )}
                      <TableCell variant="head">Nazwa</TableCell>
                      <TableCell variant="head">Opis</TableCell>
                      <TableCell variant="head">Symbol</TableCell>
                      <TableCell variant="head">Ikona</TableCell>
                      <TableCell variant="head">Kolor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.map((el, i) => (
                      <DictionaryValueRow
                        key={el['@id']}
                        readonly={readonly}
                        dictionaryValue={el}
                        onUpdated={newValue => updateExistingValue(i, newValue)}
                        onRemove={() => onRemove(el['@id'])}
                      />
                    ))}
                  </TableBody>
                  {!readonly && (
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <CreateDictionaryValueModal onSaved={collectNewValue} dictionary={`/core/dictionaries/${id}`} />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ValuesField;
