import { Room } from 'Modules/Chat/Types/ApiModel';
import { ApiCollection, get } from 'helpers/Axios';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

export const InfiniteRoomListQueryKey = ['/chat/rooms', 'infinite'];

export type RoomsCollection = ApiCollection<Room>;

export default function useRoomsList() {
  const { data, ...resource } = useInfiniteQuery<RoomsCollection>({
    queryKey: InfiniteRoomListQueryKey,
    queryFn: ({ pageParam }) => get(pageParam || '/chat/rooms'),
    getNextPageParam: lastPage => {
      return lastPage?.['hydra:view']?.['hydra:next'] ?? undefined;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    staleTime: Infinity,
  });

  const rooms: Room[] = useMemo(() => {
    return (data?.pages.map(page => page['hydra:member']) || []).flat();
  }, [data]);

  return {
    ...resource,
    data: rooms,
  };
}
