import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store';

const isGranted = (permissions, requiredRole) => permissions.includes(requiredRole);

function useIsGranted(role) {
  const { permissions } = useAppSelector(
    state => ({
      permissions: state.Login.permissions,
    }),
    shallowEqual,
  );

  return useMemo(() => role.split('|').some(el => isGranted(permissions, el)), [role, permissions]);
}

export default useIsGranted;

/**
 * https://reactjs.org/docs/hooks-custom.html#:~:text=GitHub-,Building%20Your%20Own%20Hooks,-Hooks
 */
export function useIsGrantedFn() {
  const { permissions } = useAppSelector(
    state => ({
      permissions: state.Login.permissions,
    }),
    shallowEqual,
  );

  return role => role && role.split('|').some(el => isGranted(permissions, el));
}
