import TabPanel from './TabPanel';
import { LoggableMemoStrongCompare } from 'Modules/Common/Utils/MemoComparision';
import { memo } from 'react';

function ModuleTemplateTabs(props) {
  const { tabs, fields, selectedTab, updateTemplate, onFieldSaved, moduleId } = props;

  return (
    <>
      {tabs?.map(el => (
        <TabPanel
          key={el.id}
          id={el.id}
          sections={el.sections}
          fields={fields}
          selectedTab={selectedTab}
          updateTemplate={updateTemplate}
          onFieldSaved={onFieldSaved}
          moduleId={moduleId}
        />
      ))}
    </>
  );
}

export const X = memo(ModuleTemplateTabs, LoggableMemoStrongCompare('ModuleTemplateTabs'));
export default ModuleTemplateTabs;
