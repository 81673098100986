import NodeTable from './NodeTable';
import Sidebar from './Sidebar';
import { CTMCustomTab } from 'Modules/Core/Types/CTMModule';
import { Product } from 'Modules/Manufacture/Product';
import { deleteProperty, getProperty, setProperty } from 'dot-prop';
import _ from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface BomProps {
  record: Product;
  readonly: boolean;
  onUpdate: any;
}

const Bom: FC<BomProps> = ({ record, readonly, onUpdate }) => {
  const [currentRecord, setCurrentRecord] = useState<Product | null>(null);
  const [currentRecordPath, setCurrentRecordPath] = useState<string | null>(null);

  const getRecordByPropertyPath = (propertyPath: string) => {
    if (propertyPath === '') {
      return record;
    } else {
      return getProperty<Product, string, null>(record, propertyPath);
    }
  };

  const setRecord = useCallback((record: Product) => {
    setCurrentRecord(record);
  }, []);

  const setPath = useCallback((path: string | null) => {
    setCurrentRecordPath(path);
  }, []);

  const updateRecordByPropertyPath = (propertyPath: string, val: any): void => {
    if (propertyPath === '') {
      onUpdate({ ...record, ...val, _v: uuidv4() });
    } else {
      onUpdate(setProperty({ ...record, _v: uuidv4() }, propertyPath, val));
    }
  };

  useEffect(() => {
    if (currentRecordPath === null) {
      return;
    }

    setCurrentRecord(getRecordByPropertyPath(currentRecordPath));
  }, [record]);

  const deleteRecordByPropertyPath = (propertyPath: string) => {
    const tmpRecord = _.cloneDeep(record);
    const collectionPropertyPath = propertyPath.substring(0, propertyPath.lastIndexOf('['));
    deleteProperty(tmpRecord, propertyPath);
    const tmpArray = [];
    tmpArray.push(...getProperty<Product, string, []>(tmpRecord, collectionPropertyPath, []));
    setProperty(tmpRecord, collectionPropertyPath, tmpArray.filter(Boolean));
    onUpdate(tmpRecord);

    if (currentRecordPath === '') {
      setCurrentRecord(tmpRecord);
    } else {
      setCurrentRecord(getProperty<Product, string, null>(tmpRecord, currentRecordPath + '.product'));
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar record={record} readonly={readonly} setRecord={setRecord} currentRecordPath={currentRecordPath} setPath={setPath} />
      {currentRecord && (
        <NodeTable
          record={currentRecord}
          path={currentRecordPath}
          readonly={readonly}
          updateRecordByPropertyPath={updateRecordByPropertyPath}
          deleteRecordByPropertyPath={deleteRecordByPropertyPath}
        />
      )}
    </div>
  );
};

export default Bom;

export const TabConfiguration: CTMCustomTab = {
  title: 'BOM',
  component: Bom,
};
