import { Backspace } from '@mui/icons-material';
import { Button } from '@mui/material';
import { usePropertyValues } from 'Modules/Core/Hooks/usePropertyValues';
import ListPicker from 'components/Form/ListPicker';
import RelationSinglePicker from 'components/Form/RelationSinglePicker';
import SelectSingleAPI from 'components/Form/SelectSingleAPI';
import { useModuleContext } from 'context/ModulesContext';
import { get } from 'helpers/Axios';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Input } from 'reactstrap';

export const Filter = ({ column }) => {
  return <>{column.canFilter && column.render('Filter')}</>;
};

export const DefaultColumnFilter = ({ column: { filterValue, setFilter, Header, autofocus } }) => {
  const input = useRef();
  const [val, setVal] = useState(filterValue);

  useEffect(() => {
    if (autofocus && input.current) {
      input.current.focus();
    }
  }, [input.current, autofocus]);

  const debouncedEventHandler = useMemo(
    () =>
      debounce(newVal => {
        setFilter(newVal);
      }, 450),
    [],
  );

  useEffect(() => {
    debouncedEventHandler(val);
  }, [debouncedEventHandler, val]);

  useEffect(() => {
    return () => {
      debouncedEventHandler.cancel();
    };
  }, []);

  useEffect(() => {
    setVal(filterValue);
  }, [filterValue]);

  return (
    <Input
      innerRef={input}
      value={val || ''}
      onChange={e => {
        setVal(e.target.value || undefined);
      }}
      placeholder={`${Header}...`}
    />
  );
};

export const SelectApiColumnFilter = ({ column }) => {
  const fetchValue = async () => {
    setVal(await column.filterFetchByValue(column.filterValue));
  };

  useEffect(() => {
    if (column.filterValue) {
      fetchValue();
    }
  }, []);

  useEffect(() => {
    if (!column.filterValue) {
      setVal(null);
    } else if (!val || val[column.filterSearchField ?? 'id'] !== column.filterValue) {
      fetchValue();
    }
  }, [column.filterValue]);

  const [val, setVal] = useState(column.filterValue ?? null);
  return (
    <SelectSingleAPI
      className="_"
      disableGroupMargin={true}
      size={{ md: 12 }}
      value={val}
      name={'search-field'}
      onChange={(value, name, objVal) => {
        if (objVal) {
          setVal(objVal);
          column.setFilter(objVal[column.filterSearchField ?? 'id']);
        } else {
          setVal(null);
          column.setFilter(null);
        }
      }}
      placeholder={`${column.Header}...`}
      endpoint={(column.filterSearchUrl ?? '/') + '?relationFieldLabel'}
      buildQuery={v => ({ searchRelationFieldLabel: v })}
      optionLabel="relationFieldLabel"
      isClearable={true}
    />
  );
};

export const SelectModuleRecordColumnFilter = ({ column }) => {
  const [val, setVal] = useState(column.filterValue ?? null);
  const ModuleContext = useModuleContext(column.filterModuleName ?? column.filterModule.urlPrefix ?? '');

  useEffect(() => {
    if (column.filterModule && column.filterValue && typeof val === 'string') {
      get(column.filterModule.api.item.get({ id: val }))
        .then(filterObject => {
          setVal(filterObject);
        })
        .catch(err => {
          console.error('Ann error while fetching filter default value', err);
          column.setFilter(undefined);
        });
    }
  }, [val, column]);

  useEffect(() => {
    if (!column.filterValue) {
      setVal(null);
    }
  }, [column.filterValue]);

  if (!ModuleContext) {
    return null;
  }

  return (
    <div className={'module-filter-wrapper'}>
      <RelationSinglePicker
        moduleName={ModuleContext.configuration.urlPrefix}
        value={val}
        inputProps={{ placeholder: `${column.Header}...` }}
        onChange={(mappedVal, name, gridRow) => {
          setVal(gridRow);
          column.setFilter(mappedVal);
        }}
        fetchValue={id => (id ? ModuleContext.api.get({ id }) : null)}
        optionLabel="relationFieldLabel"
        optionTrackBy="id"
        disableGroupMargin={true}
        className={''}
      />
      {column.filterValue && (
        <div className={'module-filter-icon'}>
          <Backspace
            onClick={() => {
              setVal(null);
              column.setFilter(null);
            }}
          />
        </div>
      )}
    </div>
  );
};

export const SelectColumnFilter = ({ column }) => {
  const [val, setVal] = useState(column.filterValue ?? null);

  useEffect(() => {
    setVal(column.filterValue ?? null);
  }, [column.filterValue]);

  return (
    <ListPicker
      className="_"
      disableGroupMargin={true}
      size={{ md: 12 }}
      value={val}
      options={column.filterOptions}
      name={'search-field'}
      onChange={value => {
        if (value) {
          setVal(value);
          column.setFilter(value);
        } else {
          setVal(null);
          column.setFilter(null);
        }
      }}
      placeholder={`${column.Header}...`}
      optionTrackBy="value"
      optionLabel="label"
      isClearable={true}
    />
  );
};

export const SelectPropertyFilter = ({ moduleName, propertyName, column, multiple = false }) => {
  const [val, setVal] = useState(column.filterValue ?? (multiple ? [] : null));
  const { data: statuses, isLoading } = usePropertyValues(moduleName, propertyName);

  useEffect(() => {
    setVal(column.filterValue ?? (multiple ? [] : null));
  }, [column.filterValue]);

  if (isLoading) {
    return <></>;
  }

  const handleOnChange = value => {
    if (value !== null) {
      setVal(value);
      column.setFilter(value);
    } else {
      setVal(multiple ? [] : null);
      column.setFilter(multiple ? [] : null);
    }
  };

  return (
    <ListPicker
      className="_"
      disableGroupMargin={true}
      size={{ md: 12 }}
      value={val}
      name={'search-field'}
      onChange={handleOnChange}
      multiple={multiple}
      options={statuses ?? []}
      optionLabel={item => (
        <Button
          variant={'contained'}
          startIcon={item.icon ? <i className={`mdi mdi-${item.icon}`} style={{ lineHeight: 'initial', paddingLeft: 4 }}></i> : undefined}
          style={{
            width: '100%',
            padding: '3px',
            color: '#fff',
            margin: '0 2px',
            backgroundColor: item?.color,
          }}
          onClick={() => handleOnChange(item.value)}
        >
          {item?.label}
        </Button>
      )}
      placeholder={`${column.Header}...`}
      optionTrackBy="value"
      isClearable={true}
    />
  );
};
