import toggleRoomIsFavorite from 'Modules/Chat/Func/ToggleRoomIsFavorite';
import toggleRoomIsHidden from 'Modules/Chat/Func/ToggleRoomIsHidden';
import updateRoomQueriesOnNewMessage from 'Modules/Chat/Func/UpdateRoomQueriesOnNewMessage';
import { MessageCollectionElement } from 'Modules/Chat/Types/Api';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router';

export default function useChatManipulationFunctions() {
  const queryClient = useQueryClient();
  const currentlyOpenedRoomId = useRouteMatch<{ id: string }>('/chat/:id')?.params.id;

  const toggleRoomIsHiddenFn = useCallback(
    (roomIri: string, isHidden: boolean) => void toggleRoomIsHidden(roomIri, isHidden, queryClient),
    [queryClient],
  );
  const toggleRoomIsFavoriteFn = useCallback(
    (roomIri: string, isFavorite: boolean) => void toggleRoomIsFavorite(roomIri, isFavorite, queryClient),
    [queryClient],
  );
  return useMemo(() => {
    const openRooms = currentlyOpenedRoomId ? [currentlyOpenedRoomId] : [];
    return {
      addMessageToRoom: (message: MessageCollectionElement) => updateRoomQueriesOnNewMessage(queryClient, message, openRooms),
      toggleRoomIsHidden: toggleRoomIsHiddenFn,
      toggleRoomIsFavorite: toggleRoomIsFavoriteFn,
    };
  }, [queryClient, currentlyOpenedRoomId, toggleRoomIsFavoriteFn, toggleRoomIsHiddenFn]);
}

export type ChatManipulationFunction = ReturnType<typeof useChatManipulationFunctions>;
