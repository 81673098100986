import LabelerId from '../CustomFields/LabelerId';
import { useFileUpload } from 'Modules/Core/Hooks/useFileUpload';
import { confirmRemove } from 'common/sweetalerts';
import { get } from 'helpers/Axios';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { useAppSelector } from 'store';

interface FileUploadProps {
  className?: string;
  value?: any;
  name: string;
  helpText?: string;
  label?: string | null;
  size?: { md: number };
  returnObject?: boolean;
  onChange?: (value: any, name: string) => void;
  disabled?: boolean;
  errorKey?: string;
  multiple?: boolean;
  labelerId?: number;
}

const FileUpload: React.FC<FileUploadProps> = ({
  className = 'mb-3',
  value,
  name,
  helpText,
  label = null,
  size = { md: 6 },
  returnObject = false,
  onChange = () => undefined,
  disabled,
  errorKey,
  multiple = false,
  labelerId,
}) => {
  const { t } = useTranslation();
  const htmlInput = useRef<HTMLInputElement | null>(null);
  const textInput = useRef<HTMLInputElement | null>(null);
  const fileUpload = useFileUpload();
  const [lastUploadedFileName, setLastUploadedFileName] = useState<string>(
    value ? (Array.isArray(value) ? value.map((el: any) => el.originalName).join(', ') : value.originalName) : '',
  );
  const [isEventListenerRegistered, setIsEventListenerRegistered] = useState<boolean>(false);
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));

  const hasError = (): boolean => {
    return violations.hasOwnProperty(errorKey ?? name);
  };

  const getError = (): string => {
    if (hasError()) {
      return violations[errorKey ?? name].join('\n');
    }
    return '';
  };

  const handleAcceptedFiles = (files: FileList) => {
    const file = files[0];
    setLastUploadedFileName(file.name);
    fileUpload(file, (res: any) => onChange(returnObject ? res : res['@id'], name));
  };

  useEffect(() => {
    if (value && typeof value === 'string' && returnObject) {
      get(value).then(res => {
        onChange(res, name);
      });
    }
    if (!isEventListenerRegistered) {
      setIsEventListenerRegistered(true);
      textInput.current?.addEventListener('drop', handleDrop);
    }
  }, []);

  useEffect(() => {
    setLastUploadedFileName(value ? (Array.isArray(value) ? value.map((el: any) => el.originalName).join(', ') : value.originalName) : '');
  }, [value]);

  const handleDrop = (e: any | DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      handleAcceptedFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  return (
    <Col {...size} className={className}>
      <Row>
        <FormGroup>
          <Label>
            {typeof labelerId !== 'undefined' && <LabelerId labelerId={labelerId} />}
            {t(label || 'Nazwa pola')}
          </Label>
          <InputGroup>
            {!disabled && !multiple && value && (
              <InputGroupText
                style={{ cursor: 'pointer' }}
                className="bg-danger text-white"
                onClick={() =>
                  confirmRemove(() => onChange(null, name), 'Czy jesteś pewien że chcesz skasować ten plik?', undefined, false)
                }
              >
                Skasuj plik
              </InputGroupText>
            )}
            {!disabled && !multiple && !value && (
              <InputGroupText style={{ cursor: 'pointer' }} onClick={() => htmlInput.current?.click()} onDrop={handleDrop}>
                Wybierz plik
              </InputGroupText>
            )}
            <Input
              className="form-control"
              type="text"
              value={lastUploadedFileName}
              onChange={() => false}
              innerRef={textInput}
              invalid={hasError()}
              autoComplete={'off'}
              name={name}
              disabled={disabled}
              style={{ cursor: 'pointer' }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                htmlInput.current?.click();
                return false;
              }}
              onDrop={handleDrop}
            />
            {!multiple && value && (
              <InputGroupText
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  Object.assign(document.createElement('a'), {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    href: value.contentUrl,
                  }).click()
                }
              >
                Pobierz plik
              </InputGroupText>
            )}
            <Input
              className="form-control d-none"
              type="file"
              innerRef={htmlInput}
              invalid={hasError()}
              onChange={e => e.target.files && handleAcceptedFiles(e.target.files)}
              autoComplete={'off'}
              name={name}
              disabled={disabled}
            />
            {helpText && <FormText>{helpText}</FormText>}
            {hasError() && <FormFeedback>{getError()}</FormFeedback>}
          </InputGroup>
        </FormGroup>
      </Row>
    </Col>
  );
};

export default FileUpload;
