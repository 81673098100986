import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type DictionaryCategory = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<DictionaryCategory>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
];

const module: CTMModule<DictionaryCategory> = {
  id: '1e36a6a3-d525-47ea-ac38-6220d5cfc83c',
  dataClass: 'CTM\\Core\\Entity\\DictionaryCategory',
  urlPrefix: 'core-dictionary-category',
  name: 'Kategorie słowników',
  role: 'CORE_DICTIONARY',
  api: {
    item: {
      get: ({ id }) => `/core/dictionary-categories/${id}`,
      put: ({ id }) => `/core/dictionary-categories/${id}`,
      delete: ({ id }) => `/core/dictionary-categories/${id}`,
    },
    collection: {
      get: `/core/dictionary-categories`,
      post: `/core/dictionary-categories`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {},
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
