import { Field } from '../Field';
import { Template } from '../Template';
import Section from './Section';
import { AddCircle } from '@mui/icons-material';
import { Box, Paper } from '@mui/material';
import { MemoStrongCompare } from 'Modules/Common/Utils/MemoComparision';
import { confirmRemove } from 'common/sweetalerts';
import { ReactNode, memo } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface TabPanelProps {
  children?: ReactNode;
  sections: any[];
  onFieldSaved: any;
  moduleId: any;
  id: string;
  selectedTab: null | string;
  fields: Field[];

  updateTemplate: (Template) => Template;
}

function TabPanel(props: TabPanelProps) {
  const { sections, id, selectedTab, fields, updateTemplate, onFieldSaved, moduleId } = props;

  const addNewSection = () => {
    updateTemplate(prev => ({
      ...prev,
      tabs: prev.tabs.map(prevTab => {
        if (prevTab.id === selectedTab) {
          prevTab.sections = [
            ...prevTab.sections,
            {
              id: uuidv4(),
              position: prevTab.sections.length + 1,
              useGridLayout: true,
              name: '',
              layout: [],
            },
          ];
        }

        return prevTab;
      }),
    }));
  };
  const onRemove = id => {
    confirmRemove(
      () => {
        return new Promise<boolean>(res => {
          updateTemplate(prev => ({
            ...prev,
            tabs: prev.tabs.map(prevTab => {
              if (prevTab.id === selectedTab) {
                prevTab.sections = prevTab.sections
                  .filter(el => el.id !== id)
                  .map((newSection, index) => ({ ...newSection, position: index + 1 }));
              }

              return prevTab;
            }),
          }));
          res(true);
        });
      },
      'Czy jesteś pewien skasowania tej sekcji?',
      'Jeśli po skasowaniu się rozmyślisz możesz użyć przycisku zamknij spowoduje to utratę wszystkich niezapisanych zmian w szablonie.',
      false,
    );
  };
  return (
    <div role="tabpanel" hidden={id !== selectedTab} id={`simple-tabpanel-${selectedTab}`} aria-labelledby={`simple-tab-${selectedTab}`}>
      {id === selectedTab && (
        <>
          <Box sx={{ p: 0 }} border={1} borderColor={'#eef1fd'}>
            {sections.map(el => (
              <Section
                key={el.id}
                tabId={id}
                fields={fields}
                section={el}
                updateTemplate={updateTemplate}
                onRemove={onRemove}
                onFieldSaved={onFieldSaved}
              />
            ))}
            <Paper
              variant="outlined"
              style={{
                padding: '64px 0',
                textAlign: 'center',
                fontSize: '1.7rem',
                color: '#2a3042',
                cursor: 'pointer',
              }}
              onClick={() => addNewSection()}
            >
              <AddCircle /> Dodaj nową sekcję
            </Paper>
          </Box>
        </>
      )}
    </div>
  );
}

export default memo(TabPanel, MemoStrongCompare);
