import Tooltip from '@mui/material/Tooltip';
import { AssemblingOrderModule } from 'Modules/CTMModules';
import { confirmAction } from 'common/sweetalerts';
import { put } from 'helpers/Axios';
import { Button } from 'reactstrap';

interface RegenerateAssemblingOrderProps {
  assemblingOrderId: string;
  ApiFormComponent: any;
}

const RegenerateAssemblingOrder = (props: RegenerateAssemblingOrderProps) => {
  const { assemblingOrderId, ApiFormComponent } = props;

  return (
    <>
      <Button
        onClick={() => {
          confirmAction(
            () => put(AssemblingOrderModule.configuration.api.item.refreshQuantities({ id: assemblingOrderId }), {}),
            'Czy na pewno chcesz zaktualizować ilości w zleceniu kompletacji?',
            'Spowoduje to aktualizację ilości w zleceniu kompletacji względem ilości w zamówieniu klienta.',
            true,
            'Stany odświeżone',
          ).then(() => {
            ApiFormComponent?.current?.refresh();
          });
        }}
        className={'btn btn-accent ml-1'}
      >
        <Tooltip title={'Kliknięcie spowoduje to aktualizację ilości w zleceniu kompletacji względem ilości w zamówieniu klienta.'}>
          <div>
            <i className="mdi mdi-refresh" /> Aktualizuj ilości
          </div>
        </Tooltip>
      </Button>
    </>
  );
};

export default RegenerateAssemblingOrder;
