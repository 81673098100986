import ListPicker from 'components/Form/ListPicker';

export const ErrandStatuses = {
  1000: 'Utworzone',
  2000: 'Zaakceptowane',
  3000: 'Gotowe do produkcji',
  4000: 'Zakończone',
  5000: 'Odrzucone',
};

const StatusPicker = ({ field, value, onChange, readonly, errorKey, customProps = {} }) => {
  return (
    <ListPicker
      name={field.id}
      label={field.name}
      tabIndex={field._tabindex}
      value={parseInt(value || field.defaultValue || 1000)}
      options={Object.keys(ErrandStatuses).map(el => ({ val: parseInt(el), label: ErrandStatuses[el] }))}
      optionLabel="label"
      optionTrackBy="val"
      onChange={onChange}
      disabled={readonly}
      disableGroupMargin={true}
      labelBadge={field.position}
      errorKey={errorKey}
      {...customProps}
    />
  );
};

export default StatusPicker;
