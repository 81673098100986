import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type ProductColor = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<ProductColor>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    id: 'ral',
    filterable: true,
    sortable: true,
    Header: 'RAL',
    accessor: 'ral',
  },
  {
    id: 'color',
    filterable: false,
    sortable: false,
    Header: 'Kolor',
    accessor: ({ color }) =>
      color ? (
        <div style={{ background: color, color: '#303030', width: '100%', padding: 8, textAlign: 'center' }}>
          <span style={{ color: color, filter: 'invert(100%)' }}>{color}</span>
        </div>
      ) : null,
  },
  {
    id: 'supplierSymbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol u dostawcy',
    accessor: 'supplierSymbol',
  },
  {
    id: 'type.name',
    filterable: true,
    sortable: true,
    Header: 'Typ',
    accessor: 'type.name',
  },
];

const module: CTMModule<ProductColor> = {
  id: '930dcd07-9502-4216-ae9b-b6a81154204b',
  dataClass: 'CTM\\Manufacture\\Entity\\ProductColor',
  urlPrefix: 'manufacture-product-colors',
  name: 'Kolory produktów',
  role: 'MANUFACTURE_PRODUCT',
  api: {
    item: {
      get: ({ id }) => `/manufacture/product-colors/${id}`,
      put: ({ id }) => `/manufacture/product-colors/${id}`,
      delete: ({ id }) => `/manufacture/product-colors/${id}`,
    },
    collection: {
      get: `/manufacture/product-colors`,
      post: `/manufacture/product-colors`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {},
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
