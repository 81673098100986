import { EmployeeModule } from 'Modules/CTMModules';
import { get, post } from 'helpers/Axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getConfiguration } from 'store/Employee/Configuration/Actions';
// Login Redux States
import { LOGIN_USER, LOGIN_USER_BY_PIN, LOGOUT_USER } from 'store/auth/login/actionTypes';
import { apiError, loginSuccess, setPermissions } from 'store/auth/login/actions';

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(data => post('/login_check', data), {
      username: user.email,
      password: user.password,
    });
    yield put(loginSuccess(response));
    const responsePermissions = yield call(() => get(EmployeeModule.configuration.api.item.myPermissions));
    yield put(setPermissions(responsePermissions?.permissions ?? []));
    yield put(getConfiguration());
    history.push('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* loginUserByPin({ payload: { pin, history } }) {
  try {
    const response = yield call(data => post('/login_check_pin', data), { pin });
    yield put(loginSuccess(response));
    const responsePermissions = yield call(() => get(EmployeeModule.configuration.api.item.myPermissions));
    yield put(setPermissions(responsePermissions?.permissions ?? []));
    history.push('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    if (!history) {
      return (window.location.href = '/login');
    } else {
      history.push('/login');
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGIN_USER_BY_PIN, loginUserByPin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
