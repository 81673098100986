import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import { baseBuilder } from 'Modules/ModuleBuilder';

export type ProductSeries = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<ProductSeries>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
];

const module: CTMModule<ProductSeries> = baseBuilder<ProductSeries>({
  id: '3bf7aff7-473f-4030-8f70-dbfd7a09d5df',
  baseUrl: '/manufacture/product-series',
  dataClass: 'CTM\\Manufacture\\Entity\\ProductSeries',
  urlPrefix: 'manufacture-product-series',
  name: 'Seria',
  role: 'MANUFACTURE_PRODUCT',
  columns: columns,
});

export default module;
