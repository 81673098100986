import { Add, Delete } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import { CoreModuleModule, ProductCollectionModule, ProductModule, TariffModule } from 'Modules/CTMModules';
import useIsGranted from 'common/hooks/isGranted';
import BaseInput from 'components/Form/BaseInput';
import ListPicker from 'components/Form/ListPicker';
import SelectMultiple from 'components/Form/SelectMultiple';
import Switch from 'components/Form/Switch';
import { Col, Container } from 'components/Theme/Grid';
import { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';

const BaseLinker = ({ form, updateConfiguration }) => {
  const canEdit = useIsGranted('ROLE_EDIT_CONFIGURATION');
  const [productFields, setProductFields] = useState([]);
  const [productCollectionFields, setProductCollectionFields] = useState([]);
  const [tariffs, setTariffs] = useState([]);

  const resolveProductFields = () => {
    CoreModuleModule.api.get({ id: ProductModule.configuration.id }).then(configuration => {
      setProductFields(configuration.fields);
    });
  };

  const resolveProductCollectionFields = () => {
    CoreModuleModule.api.get({ id: ProductCollectionModule.configuration.id }).then(configuration => {
      setProductCollectionFields(configuration.fields);
    });
  };

  const resolveTariffs = () => {
    TariffModule.api.getAll().then(data => {
      setTariffs(data['hydra:member']);
    });
  };

  useEffect(() => {
    resolveProductFields();
    resolveProductCollectionFields();
    resolveTariffs();
  }, []);

  const updateFormValue = (value, field) => {
    updateConfiguration({ ...form, [field]: value });
  };

  const updateFieldValue = (value, field) => {
    const fieldsValues = {
      ...form.fields,
      ...{
        [field]: value,
      },
    };

    updateConfiguration({ ...form, fields: fieldsValues });
  };

  const isReady = useMemo(
    () => productFields.length > 0 && productCollectionFields.length > 0 && tariffs.length > 0,
    [productFields, productCollectionFields, tariffs],
  );

  const onAddKey = () => {
    const apiKeys = form.apiKeys ?? [];
    const index = apiKeys.length + 1;
    apiKeys.push({ name: 'Nowa nazwa #' + index, key: 'Nowa klucz #' + index });
    updateConfiguration({ ...form, apiKeys: apiKeys });
  };

  const onEditKey = (value, field, index) => {
    const apiKeys = [...form.apiKeys];
    apiKeys[index] = { ...apiKeys[index], [field]: value };
    updateConfiguration({ ...form, apiKeys: apiKeys });
  };

  const onRemoveKey = index => {
    const apiKeys = [...form.apiKeys];
    apiKeys.splice(index, 1);
    updateConfiguration({ ...form, apiKeys: apiKeys });
  };

  return (
    isReady && (
      <Container>
        <Col xs={12} md={12} lg={12}>
          <h5>Ogólne</h5>
        </Col>
        <Col xs={12} md={12} lg={12}>
          <Switch
            size={{ md: 12 }}
            label={'Aktywne'}
            value={form.active}
            name="active"
            onChange={updateFormValue}
            disabled={!canEdit}
            switchOnRight={true}
          />
        </Col>
        <Col xs={12} md={12} lg={12}>
          <h5>Klucze</h5>
        </Col>
        <Col xs={12} md={12} lg={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nazwa</TableCell>
                <TableCell>Klucz</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(form.apiKeys ?? []).map((apiKey, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <BaseInput
                      pure={true}
                      size={{ md: 12 }}
                      label={false}
                      value={apiKey.name}
                      name="name"
                      onChange={(value, field) => onEditKey(value ? value.replace(/[^a-zA-Z]/g, '') : value, field, index)}
                      disabled={!canEdit}
                    />
                  </TableCell>
                  <TableCell>
                    <BaseInput
                      pure={true}
                      size={{ md: 12 }}
                      label={false}
                      value={apiKey.key}
                      name="key"
                      onChange={(value, field) => onEditKey(value, field, index)}
                      disabled={!canEdit}
                    />
                  </TableCell>
                  <TableCell>
                    <Button variant={'contained'} size={'sm'} color={'error'} onClick={() => onRemoveKey(index)}>
                      <Delete />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button variant={'contained'} color={'success'} style={{ width: '100%' }} onClick={onAddKey}>
            <Add /> Dodaj
          </Button>
        </Col>
        <Col xs={12} md={12} lg={12}>
          <h5>Mapowanie pól</h5>
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Szerokość paczki'}
            options={productFields.filter(field => field.type === 'NUMBER' && !field.system)}
            disableGroupMargin={true}
            name={'packageWidth'}
            value={form.fields?.packageWidth ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Wysokość paczki'}
            options={productFields.filter(field => field.type === 'NUMBER' && !field.system)}
            disableGroupMargin={true}
            name={'packageHeight'}
            value={form.fields?.packageHeight ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Długość paczki'}
            options={productFields.filter(field => field.type === 'NUMBER' && !field.system)}
            disableGroupMargin={true}
            name={'packageLength'}
            value={form.fields?.packageLength ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'VAT'}
            options={productFields.filter(field => field.type === 'NUMBER' && !field.system)}
            disableGroupMargin={true}
            name={'vat'}
            value={form.fields?.vat ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Cennik'}
            options={tariffs}
            disableGroupMargin={true}
            name={'tariff'}
            value={form.tariff ?? null}
            onChange={updateFormValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <SelectMultiple
            label={'Zdjęcia kolekcji'}
            options={productCollectionFields.filter(field => field.type === 'IMAGE' && !field.system)}
            disableGroupMargin={true}
            name={'groupImages'}
            value={form.fields?.groupImages ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <SelectMultiple
            label={'Zdjęcia produktu'}
            options={productFields.filter(field => field.type === 'IMAGE' && !field.system)}
            disableGroupMargin={true}
            name={'images'}
            value={form.fields?.images ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Opis'}
            options={productFields.filter(field => field.type === 'TEXT' && !field.system)}
            disableGroupMargin={true}
            name={'description'}
            value={form.fields?.description ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Opis #1'}
            options={productFields.filter(field => field.type === 'TEXT' && !field.system)}
            disableGroupMargin={true}
            name={'description1'}
            value={form.fields?.description1 ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Opis #2'}
            options={productFields.filter(field => field.type === 'TEXT' && !field.system)}
            disableGroupMargin={true}
            name={'description2'}
            value={form.fields?.description2 ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Opis #3'}
            options={productFields.filter(field => field.type === 'TEXT' && !field.system)}
            disableGroupMargin={true}
            name={'description3'}
            value={form.fields?.description3 ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Opis #4'}
            options={productFields.filter(field => field.type === 'TEXT' && !field.system)}
            disableGroupMargin={true}
            name={'description4'}
            value={form.fields?.description4 ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            label={'Rysunek techniczny'}
            options={productFields.filter(field => field.type === 'IMAGE' && !field.system)}
            disableGroupMargin={true}
            name={'draw'}
            value={form.fields?.draw ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
      </Container>
    )
  );
};

export default withRouter(BaseLinker);
