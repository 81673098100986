import NumberInput from '@Components/Form/MUI/NumberInput';

const StringTypeConfiguration = ({ configuration, onConfigurationUpdated }) => {
  const updateConfiguration = (value, field) => {
    onConfigurationUpdated({ ...configuration, [field]: value });
  };

  return (
    <>
      <NumberInput label="Limit znaków" name={'limit'} value={configuration?.limit || null} onChange={updateConfiguration} />
    </>
  );
};

export default StringTypeConfiguration;
