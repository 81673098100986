import { ClientOrderModule } from '../CTMModules';
import StatusView from '../Core/Components/StatusView';
import ReportTrouble from '../Ecommerce/Components/Trouble/ReportTrouble';
import ViewTrouble from '../Ecommerce/Components/Trouble/ViewTrouble';
import Employee from '../Employee/Employee';
import ShipmentRows from './Components/Shipment/ShipmentRows';
import StatusPicker from './Components/Shipment/StatusPicker';
import Contractor from 'Modules/Contractor/Contractor';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import SelectStatusColumnFilter from 'Modules/Warehouse/Components/Shipment/SelectColumStatusFilter';
import dayjs from 'dayjs';
import { createModuleColumnConfig } from 'helpers/ModuleUtils';
import { Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';

export type Shipment = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<Shipment>[] = [
  {
    id: 'clientOrder.number',
    filterable: true,
    sortable: true,
    Header: 'Numer',
    noBreak: true,
    accessor: 'clientOrder.number',
  },
  {
    id: 'clientOrder.contractor.id',
    Header: 'Kontrahent',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('clientOrder.client', Contractor),
  },
  {
    id: 'clientOrder.trader.id',
    Header: 'Handlowiec',
    filterable: true,
    minWidth: 160,
    ...createModuleColumnConfig('clientOrder.trader', Employee),
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'warehouse-shipment'} status={status} />,
    sortable: true,
    filterable: true,
    Filter: SelectStatusColumnFilter,
  },
];

const module: CTMModule<Shipment> = {
  id: 'd66cc193-c57f-4328-ba92-fb51b412832f',
  dataClass: 'CTM\\Warehouse\\Entity\\Shipment\\Shipment',
  urlPrefix: 'warehouse-shipment',
  name: 'Wysyłka',
  role: 'WAREHOUSE_SHIPMENT',
  api: {
    item: {
      get: ({ id }) => `/warehouse/shipments/${id}`,
      put: ({ id }) => `/warehouse/shipments/${id}`,
      delete: ({ id }) => `/warehouse/shipments/${id}`,
    },
    collection: {
      get: `/warehouse/shipments`,
      post: `/warehouse/shipments`,
    },
  },
  recordLabel: ({ name, clientOrder }) => `Wysyłka zamówienia ${clientOrder?.number ?? ''} ${name ?? ''}`,
  form: {
    disableCreateNewRecord: true,
    prepareRecordToSave: record => ({
      ...record,
      clientOrder: record.clientOrder?.['@id'] ?? record.clientOrder,
      rows: record.rows?.map(el => ({
        ...el,
        product: el.product?.['@id'] ?? el.product,
        unit: el.unit?.['@id'] ?? el.unit,
      })),
    }),
    fieldComponents: {
      status: StatusPicker,
      rows: ShipmentRows,
    },
    contextActions: ({ readonly, record, ApiFormComponent }) => {
      return (
        <>
          {record.id && record.clientOrder && (
            <Link className={'btn btn-success ml-1'} to={ClientOrderModule.showUrl(record.clientOrder.id)}>
              {record.clientOrder.number}
            </Link>
          )}
          {record.id && <ReportTrouble clientOrderId={record.clientOrder.id} initialTrouble={record.clientOrder.trouble} />}
        </>
      );
    },
    inlineComponentAppendHeader: record =>
      record.clientOrder ? <ViewTrouble clientOrderId={record.clientOrder.id} initialTrouble={record.clientOrder.trouble} /> : null,
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'createdAt', desc: true }],
    storeFilters: true,
    board: true,
    boardCardOverride: ({ item, module }) => {
      return (
        <CardBody className="borad-width">
          <div>
            <h5 className="font-size-15">
              <Link to={module.showUrl(item.id)} className="text-dark">
                {item?.clientOrder?.number}
              </Link>
            </h5>
            <p className="text-muted mb-4">{item?.clientOrder?.client?.relationFieldLabel}</p>
            <p className="text-muted mb-4">{item?.clientOrder?.trader?.relationFieldLabel}</p>
          </div>
          <div className="text-end">
            <p className="mb-0 text-muted">{dayjs(item?.createdAt).locale('pl').format('LLL')}</p>
          </div>
        </CardBody>
      );
    },
  },
};

export default module;
