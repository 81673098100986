import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import ModuleList from '@Components/Module/ModuleList';
import { Alert } from '@mui/material';
import { useModuleContext } from 'context/ModulesContext';

const SubresourceListField: CustomFieldComponentType = props => {
  const { field, recordId } = props;
  const { targetModule, mappedBy } = field.config;
  const moduleContext = useModuleContext(targetModule, 'dataClass');

  if (!moduleContext) {
    return <Alert color="error">Źle skonfigurowane pole!</Alert>;
  }
  if (!recordId) {
    return <Alert color="warning">Sekcja będzie dostępna po zapisaniu rekordu.</Alert>;
  }

  return (
    <>
      <ModuleList
        key={moduleContext.configuration.urlPrefix}
        moduleName={moduleContext.configuration.urlPrefix}
        useModal={true}
        defaultFilters={[{ id: `${mappedBy}.id`, value: recordId.split('/').pop() }]}
        overrideFormProps={{
          recordCallback: async fields => {
            return {
              '@formValues': {
                [fields.find(el => el.propertyPath === mappedBy).id]: recordId,
              },
            };
          },
          forceReadonlyField: field => {
            return [mappedBy].includes(field.propertyPath);
          },
        }}
        overrideListProps={{
          defaultPerPage: 5,
          perPageOptions: [5, 10, 20, 30, 40, 50],
          columns: oryginalColumns => oryginalColumns.filter(column => ![`${mappedBy}.id`, mappedBy].includes(column.id)),
        }}
      />
    </>
  );
};

export default SubresourceListField;
