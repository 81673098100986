import FsLightbox from 'fslightbox-react';
import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store';

const LightboxContainer: FC<void> = () => {
  const { toggler, images } = useAppSelector(
    state => ({
      toggler: state.Lightbox.toggler,
      images: state.Lightbox.images,
    }),
    shallowEqual,
  );

  return <FsLightbox type="image" toggler={toggler} sources={images} index={'Lightbox'} />;
};

export default LightboxContainer;
