import Auth from 'pages/Configuration/GeneralTabs/Auth';
import BaseLinker from 'pages/Configuration/GeneralTabs/BaseLinker';
import BaseLinkerIntegration from 'pages/Configuration/GeneralTabs/BaseLinkerIntegration';
//import Shopify from 'pages/Configuration/GeneralTabs/Shopify';
import DeepL from 'pages/Configuration/GeneralTabs/DeepL';
import GS1 from 'pages/Configuration/GeneralTabs/GS1';
import IMoje from 'pages/Configuration/GeneralTabs/IMoje';
import MailerConfiguration from 'pages/Configuration/GeneralTabs/MailerConfiguration';
import Manufacture from 'pages/Configuration/GeneralTabs/Manufacture';
import MikroTik from 'pages/Configuration/GeneralTabs/MikroTik';
import Multinational from 'pages/Configuration/GeneralTabs/Multinational';
import PrestaShop from 'pages/Configuration/GeneralTabs/PrestaShop';
import Store from 'pages/Configuration/GeneralTabs/Store';
import ThreeDBinPacking from 'pages/Configuration/GeneralTabs/ThreeDBinPacking';
import WooCommerce from 'pages/Configuration/GeneralTabs/WooCommerce';

export default [
  { label: 'Autoryzacja', module: 'auth', component: Auth },
  { label: 'Mailer', module: 'mailerConfiguration', component: MailerConfiguration },
  { label: 'Ustawienia regionalne', module: 'multinational', component: Multinational },
  { label: 'WooCommerce', module: 'wooCommerce', component: WooCommerce },
  { label: 'PrestaShop', module: 'prestaShop', component: PrestaShop },
  //{ label: 'Shopify', module: 'shopify', component: Shopify },
  { label: 'BaseLinker', module: 'baseLinker', component: BaseLinker },
  { label: 'BaseLinker v2', module: 'baseLinker', component: BaseLinkerIntegration, disabledSubmit: true },
  { label: 'GS1', module: 'gs1', component: GS1 },
  { label: 'Produkcja', module: 'manufacture', component: Manufacture },
  { label: 'Sklep', module: 'store', component: Store },
  { label: 'MikroTik', module: 'mikroTik', component: MikroTik },
  { label: 'Nesting paczek 3D Bin Packing', module: 'threeDBinPackingConfiguration', component: ThreeDBinPacking },
  { label: 'DeepL', module: 'deepL', component: DeepL },
  { label: 'IMoje', module: 'iMoje', component: IMoje },
];
