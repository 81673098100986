import { extractIRIFromRecord } from '../../helpers/ModuleUtils';
import ProductName from '@Manufacture/Product/List/ProductName';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type Image = CTMRecord & {
  id?: string;
};

const columns: CTMListColumn<Image>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    autofocus: true,
    Header: 'Nazwa',
    accessor: ({ name, image }) => <ProductName name={name} image={image?.contentUrl} />,
  },
];

const module: CTMModule<Image> = {
  id: 'bd004e30-f15d-4c88-8cef-6026b7ca440b',
  dataClass: 'CTM\\CustomField\\Entity\\Image',
  urlPrefix: 'cf-images',
  name: 'Zdjęcia pól dodatkowych',
  role: 'CUSTOM_FIELD_IMAGE',
  api: {
    item: {
      get: ({ id }) => `/custom-field/images/${id}`,
      put: ({ id }) => `/custom-field/images/${id}`,
      delete: ({ id }) => `/custom-field/images/${id}`,
    },
    collection: {
      get: `/custom-field/images`,
      post: `/custom-field/images`,
    },
  },
  recordLabel: (record, allFields) => (record.name ? record.name : 'Nowa zdjęcie'),
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'name', desc: false }],
    storeFilters: false,
    board: false,
  },
  form: {
    prepareRecordToSave: (record, allFields) => {
      return {
        ...record,
        image: extractIRIFromRecord(record?.image),
      };
    },
  },
};

export default module;
