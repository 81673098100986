import { useAppSelector } from 'store';

const useFormError = (errorKey: string): [boolean, string] => {
  const { violations } = useAppSelector(state => state.FormErrors);
  const hasError = (): boolean => {
    return violations.hasOwnProperty(errorKey);
  };
  const getError = (): string => {
    if (hasError()) {
      return violations[errorKey].join('\n');
    }
    return '';
  };

  return [hasError(), getError()];
};

export default useFormError;
