import { UNREAD_ROOMS_QUERY_KEY, UnreadRoomsQueryData } from '../Hooks/UseUnreadRoomsQuery';
import { InfiniteRoomListQueryKey, RoomsCollection } from 'Modules/Chat/Hooks/UseRoomsList';
import { MessageCollectionElement } from 'Modules/Chat/Types/Api';
import { Room } from 'Modules/Chat/Types/ApiModel';
import { get } from 'helpers/Axios';
import { InfiniteData, QueryClient } from 'react-query';

export default function updateRoomQueriesOnNewMessage(queryClient: QueryClient, message: MessageCollectionElement, openRoomsIds: string[]) {
  updateUnreadRoomsQuery(queryClient, message, openRoomsIds);
  void updateRoomsListQuery(queryClient, message);
}

function updateUnreadRoomsQuery(queryClient: QueryClient, message: MessageCollectionElement, openRoomsIds: string[]): void {
  if (openRoomsIds.includes(message.room.id)) {
    return;
  }

  if (queryClient.getQueryData<UnreadRoomsQueryData>(UNREAD_ROOMS_QUERY_KEY)?.includes(message.room['@id'])) {
    return;
  }

  queryClient.setQueryData<UnreadRoomsQueryData | undefined>(UNREAD_ROOMS_QUERY_KEY, currentData => {
    return [message.room['@id'], ...(currentData || [])];
  });
}

async function updateRoomsListQuery(queryClient: QueryClient, message: MessageCollectionElement) {
  const isOwnerOfMessage = (room?: Room) => room?.['@id'] === message.room['@id'];
  const roomResource: Room =
    queryClient
      .getQueryData<InfiniteData<RoomsCollection>>(InfiniteRoomListQueryKey)
      ?.pages.map(page => page['hydra:member'])
      .flat()
      .find(isOwnerOfMessage) || (await get(message.room['@id']));

  // @ts-ignore-next-line
  roomResource.lastMessage = message;
  roomResource.isHidden = false;

  queryClient.setQueriesData<InfiniteData<RoomsCollection> | undefined>(InfiniteRoomListQueryKey, data => {
    if (!data) {
      return data;
    }

    const latestRoomInQuery = data.pages[0]?.['hydra:member'][0];

    if (latestRoomInQuery?.lastMessage.id === message.id) {
      return data;
    }

    return {
      ...data,
      pages: data.pages.map((page, index) => {
        const filteredRooms = page['hydra:member'].filter(room => !isOwnerOfMessage(room));

        if (index === 0) {
          filteredRooms.unshift(roomResource);
        }

        return {
          ...page,
          'hydra:member': filteredRooms,
        };
      }),
    };
  });
}
