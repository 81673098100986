import QuantityField from './QuantityField';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ProductModule } from 'Modules/CTMModules';
import { Product, RequiredProduct } from 'Modules/Manufacture/Product';
import { confirmRemove } from 'common/sweetalerts';
import EditableField from 'components/DataGrid/Field/EditableField';
import ModalFormWrapper from 'components/Form/ModalFormWrapper';
import ModuleForm from 'components/Module/ModuleForm';
import ModuleListScanner from 'components/Module/ModuleListScanner';
import { axiosApi, get } from 'helpers/Axios';
import { FC, useRef, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

interface NodeTableProps {
  record: Product;
  readonly: boolean;
  path: string | null;
  updateRecordByPropertyPath: (propertyPath: string, val: any, refresh: boolean) => void;
  deleteRecordByPropertyPath: (propertyPath: string) => void;
}

const NodeTable: FC<NodeTableProps> = ({ record, readonly, path, updateRecordByPropertyPath, deleteRecordByPropertyPath }) => {
  const editModal = useRef<any>();
  const [lastEditedId, setLastEditedId] = useState<string | null>(null);

  const onAddRow = (item: Product): void => {
    setLastEditedId(item.id ?? null);
    get(item['@id']).then(data => {
      updateRecordByPropertyPath(
        path ? path + '.product' : '',
        {
          ...record,
          requiredProducts: [
            ...record.requiredProducts,
            {
              id: uuidv4(),
              quantity: 1,
              product: { ...data, requiredProducts: [...data.requiredProducts], '@formValues': [] },
            },
          ],
        },
        true,
      );
    });
  };

  const onChange = (name: string, path: string, index: number, value: number | string | object) => {
    setLastEditedId(record.requiredProducts[index].product?.id ?? null);

    const item = { ...record.requiredProducts[index], [name]: value };

    updateRecordByPropertyPath(path, item, false);
  };

  const onChangeProduct = (name: string, path: string, index: number, value: number | string | object) => {
    setLastEditedId(record.requiredProducts[index].product?.id ?? null);

    const item = {
      ...record.requiredProducts[index],
      product: { ...record.requiredProducts[index].product, [name]: value },
    };

    updateRecordByPropertyPath(path, item, false);
  };

  const onChangeName = (path: string | null, index: number, name: string) => {
    setLastEditedId(record.requiredProducts[index].product?.id ?? null);
    const newItem = {
      ...record.requiredProducts[index],
      product: { ...record.requiredProducts[index].product, name: name },
    };

    record.requiredProducts.map((item: RequiredProduct, currentIndex: number) => {
      if (item.product?.id !== newItem.product?.id) {
        return;
      }

      updateRecordByPropertyPath(
        path ? path + '.product.requiredProducts[' + currentIndex + ']' : 'requiredProducts[' + currentIndex + ']',
        newItem,
        false,
      );
    });
  };

  const copy = (productId: string) => {
    axiosApi.post(`manufacture/products/copy`, { id: productId }).then(response => {
      onAddRow(response.data);
    });
  };

  return (
    <div style={{ flexGrow: 1 }} className={'form-control'}>
      <Button variant={'contained'} color={'success'} style={{ margin: '10px 0' }} onClick={() => record.id && copy(record.id)}>
        <i className="mdi mdi-content-copy" />
        Powiel ten produkt
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: '4px' }}>ID</TableCell>
            <TableCell style={{ padding: '4px' }}>Typ</TableCell>
            <TableCell style={{ padding: '4px' }}>Nazwa</TableCell>
            <TableCell style={{ padding: '4px' }}>Ilość</TableCell>
            <TableCell style={{ padding: '4px' }}>Jednostka</TableCell>
            <TableCell style={{ padding: '4px', minWidth: '50%' }}>Technologia</TableCell>
            <TableCell style={{ padding: '4px', width: '100px' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(record.requiredProducts ?? [])
            .filter((item: RequiredProduct) => item.product)
            .map(
              (item: RequiredProduct, index: number): JSX.Element => (
                <TableRow
                  key={(item.product?.id ?? '') + index}
                  style={item.product?.id === lastEditedId ? { backgroundColor: 'rgba(255, 0, 0, 0.1)' } : {}}
                >
                  <TableCell style={{ padding: '4px' }}>{item.product?.labelerId}</TableCell>
                  <TableCell style={{ padding: '4px' }}>
                    <EditableField
                      id={item.product?.id ?? ''}
                      currentValue={item.product?.type}
                      fieldName={'type'}
                      moduleName={'manufacture-products'}
                      type={'relation'}
                      relationModuleName={'manufacture-product-types'}
                      renderLabel={(object: any) => (
                        <>
                          {object ? (
                            <i
                              style={{ color: `${object.color || '#484f56'}` }}
                              className={`mdi mdi-${object.icon ?? 'text-box-outline'} font-size-16`}
                            />
                          ) : (
                            <span style={{ padding: '0 8px' }} />
                          )}
                          {object.name}
                        </>
                      )}
                      onChange={value => {
                        onChangeProduct(
                          'type',
                          path ? path + '.product.requiredProducts[' + index + ']' : 'requiredProducts[' + index + ']',
                          index,
                          value,
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ padding: '4px' }}>
                    <EditableField
                      key={'name_' + item.product?.id + index}
                      id={item.product?.id ?? ''}
                      currentValue={item.product?.name}
                      fieldName={'name'}
                      moduleName={'manufacture-products'}
                      onChange={value => {
                        onChangeName(path, index, value.toString());
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ padding: '4px' }}>
                    <QuantityField
                      key={item.product?.id}
                      currentValue={item.quantity}
                      onChange={value =>
                        onChange(
                          'quantity',
                          path ? path + '.product.requiredProducts[' + index + ']' : 'requiredProducts[' + index + ']',
                          index,
                          value,
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <EditableField
                      id={item.product?.id ?? ''}
                      currentValue={item.product?.unit}
                      fieldName={'unit'}
                      moduleName={'manufacture-products'}
                      type={'relation'}
                      relationModuleName={'manufacture-units'}
                      onChange={value => {
                        onChangeProduct(
                          'unit',
                          path ? path + '.product.requiredProducts[' + index + ']' : 'requiredProducts[' + index + ']',
                          index,
                          value,
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <EditableField
                      id={item.product?.id ?? ''}
                      currentValue={item.product?.technology}
                      fieldName={'technology'}
                      moduleName={'manufacture-products'}
                      type={'relation'}
                      relationModuleName={'manufacture-technology'}
                      onChange={value => {
                        onChangeProduct(
                          'technology',
                          path ? path + '.product.requiredProducts[' + index + ']' : 'requiredProducts[' + index + ']',
                          index,
                          value,
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ padding: '4px' }}>
                    <ButtonGroup>
                      <ModalFormWrapper
                        ref={editModal}
                        title={`${ProductModule?.configuration?.name} - ${item.product?.name} - Edytuj`}
                        id={item.product?.id ?? ''}
                        onOpen={id => setLastEditedId(id)}
                        form={
                          <ModuleForm
                            moduleName={ProductModule.configuration.urlPrefix}
                            id={item.product?.id}
                            showContextActions={false}
                            showBackButton={false}
                            showConfigurationSwitcher={true}
                            afterSave={() => {
                              editModal.current.close();
                            }}
                            readonly={false}
                            overrideFormProps={{ resetTab: true }}
                          />
                        }
                        wrapperComponent={Button}
                        wrapperProps={{ className: 'btn btn-info btn-sm' }}
                      >
                        <i className="mdi mdi-pencil" />
                      </ModalFormWrapper>
                      <Button
                        onClick={() =>
                          confirmRemove(() => {
                            return new Promise<boolean>(res => {
                              deleteRecordByPropertyPath(
                                path ? path + '.product.requiredProducts[' + index + ']' : 'requiredProducts[' + index + ']',
                              );
                              res(true);
                            });
                          })
                        }
                        className="btn btn-danger btn-sm"
                      >
                        <i className="mdi mdi-delete" />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ),
            )}
          <TableRow>
            <TableCell style={{ padding: '4px' }} colSpan={7}>
              <ModuleListScanner
                disabled={readonly}
                moduleName={'manufacture-products'}
                onChange={onAddRow}
                disableClear={true}
                forceFocus={true}
                inputScannerLabel={'Wprowadź ID/EAN/Symbol'}
                filterFields={['productIdentifier']}
                defaultFilters={[{ id: 'active', value: true }]}
                onClickReset={false}
                isSelected={undefined}
                children={undefined}
                namePrefix={undefined}
                name={undefined}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default NodeTable;
