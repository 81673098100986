import FieldConfig from './FieldConfig';
import { MemoStrongCompare } from 'Modules/Common/Utils/MemoComparision';
import { confirmRemove } from 'common/sweetalerts';
import { memo, useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';

const ResponsiveGridLayout = WidthProvider(Responsive);

function SectionLayout(props) {
  const { useGridLayout, fields, updateSection, layout: givenLayout, onFieldSaved, moduleId } = props;
  const [layout, setLayout] = useState<any>(givenLayout);
  const [layouts, setLayouts] = useState<any>({
    xxs: givenLayout,
  });
  const getField = key => fields.find(el => el.id === key);
  const dispatch = useDispatch();

  const onDrop = (newLayout, newItem, ev) => {
    const fieldId = ev.dataTransfer.getData('Text');
    if (layout.find(el => el.i === fieldId)) {
      dispatch(addSingleToast({ title: 'To pole już jest występuje tej sekcji', config: { appearance: 'error' } }));
      return;
    }
    const computedNewLayout = newLayout.map(el => {
      if (el.i === newItem.i) {
        el = {
          ...el,
          i: fieldId,
          w: 4,
          h: 2,
          minH: 2,
          minW: 1,
          maxW: 12,
        };
      }

      return el;
    });
    setLayouts({ xxs: computedNewLayout });
    setLayout(computedNewLayout);
  };
  const onLayoutChange = newLayout => {
    const isDropOverNewField = newLayout.find(el => el.i === '__DROPPING__');

    if (!isDropOverNewField) {
      setLayouts({ xxs: newLayout });
      setLayout(newLayout);
    }
  };

  useEffect(() => {
    if (JSON.stringify(givenLayout) !== JSON.stringify(layout)) {
      updateSection({ layout: layout });
    }
  }, [layout]);

  const onRemove = removedId => {
    confirmRemove(
      () => {
        return new Promise<boolean>(res => {
          setLayouts(prev => ({ xxs: prev.xxs.filter(el => el.i !== removedId) }));
          setLayout(prev => prev.filter(el => el.i !== removedId));
          res(true);
        });
      },
      'Czy jesteś pewien?',
      'Pole można przywrócić przeciągając je z pól dostępnych w module po lewej stronie.',
      false,
    );
  };

  return (
    <ResponsiveGridLayout
      key={JSON.stringify(useGridLayout)}
      className="layout-config"
      layouts={layouts}
      onLayoutChange={onLayoutChange}
      breakpoints={{
        xxs: 0,
      }}
      resizeHandles={useGridLayout ? ['se', 'ne'] : ['e', 'w']}
      cols={{ xxs: 12 }}
      droppingItem={{ i: '__DROPPING__', w: 4, h: 2 }}
      rowHeight={34}
      draggableHandle=".move-field"
      isDroppable={true}
      onDrop={onDrop}
    >
      {layout
        .filter(layoutRow => getField(layoutRow.i))
        .map(layoutRow => (
          <div key={layoutRow.i} className="field-config-wrapper">
            <FieldConfig
              field={getField(layoutRow.i)}
              fields={fields}
              w={layoutRow.w}
              h={layoutRow.h}
              onFieldSaved={onFieldSaved}
              onRemove={onRemove}
              moduleId={moduleId}
            />
          </div>
        ))}
    </ResponsiveGridLayout>
  );
}

export default memo(SectionLayout, MemoStrongCompare);
