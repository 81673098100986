import { AssemblingOrderModule } from '../../CTMModules';
import { Tooltip } from '@mui/material';
import { withRouter } from 'react-router';
import { Button } from 'reactstrap';

const GenerateAssemblingOrderButton = ({ clientOrder, history }) => {
  const assemblingExists = clientOrder.assemblingOrder;

  if (clientOrder.reservation !== 1000 && !assemblingExists) {
    return <></>;
  }

  const onClick = () => {
    if (assemblingExists) {
      history.push(AssemblingOrderModule.showUrl(clientOrder.assemblingOrder.id));
      return;
    } else {
      const assemblingOrder = {
        clientOrder: clientOrder['@id'],
        costPallet: 25,
        costHalfPallet: 14,
        costPackagesBelow30: 10,
        rows: clientOrder.rows.map(row => {
          return {
            seq: row.seq,
            product: row.product?.['@id'] ?? row.product,
            quantity: row.quantity,
            unit: row.unit?.['@id'] ?? row.unit,
          };
        }),
      };
      AssemblingOrderModule.api.post(assemblingOrder).then(assemblingOrderResponse => {
        history.push(AssemblingOrderModule.showUrl(assemblingOrderResponse.id));
      });
    }
  };

  return (
    <Button onClick={() => onClick()} className={'btn btn-accent ml-1'}>
      <Tooltip title={assemblingExists ? 'Przejdź do kompletacji' : 'Wygeneruj zlecenie kompletacji'}>
        <>
          <i className="bx bx-git-merge" /> {assemblingExists ? ' Przejdź do kompletacji' : ' Wygeneruj zlecenie kompletacji'}
        </>
      </Tooltip>
    </Button>
  );
};

export default withRouter<any, any>(GenerateAssemblingOrderButton);
