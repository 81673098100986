import { useMercureFeedListener } from 'Modules/Mercure/Context/MercureFeedProvider';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { addSingleToast } from 'store/Toast/actions';

export default function useChatToasts() {
  const isChatOpen = Boolean(useRouteMatch('/chat'));
  const history = useHistory();
  const dispatch = useDispatch();

  useMercureFeedListener(
    'CHAT_MESSAGE',
    message => {
      if (isChatOpen) {
        return;
      }

      const onClick = () => {
        // removeToast(message['@id']); //@TODO
        history.push(`/chat/${message.room.id}`);
      };

      dispatch(
        addSingleToast({
          title: (
            <div onClick={onClick} style={{ cursor: 'pointer', maxHeight: 80, maxWidth: 260 }} className="overflow-hidden">
              <Link
                to={`/chat/${message.room.id}`}
                className="font-weight-semibold"
                children={`${message.author.firstName} ${message.author.lastName}`}
              />
              <div children={message.content} className="text-truncate" />
            </div>
          ),
          config: { appearance: 'info' },
        }),
      );
    },
    [isChatOpen, dispatch, addSingleToast, history],
  );
}
