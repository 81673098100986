import LabelerId from '../CustomFields/LabelerId';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { Col, FormFeedback, FormGroup, FormText } from 'reactstrap';
import { useAppSelector } from 'store';

interface SwitchProps {
  className?: string;
  value: boolean;
  name: string;
  helpText?: string;
  label?: string;
  size?: { [key: string]: number };
  onChange?: (newState: boolean, name: string) => void;
  disabled?: boolean;
  labelBadge?: string;
  verticalCenter?: boolean;
  switchOnRight?: boolean;
  labelerId?: number;
}

const Switch: React.FC<SwitchProps> = ({
  className = '',
  value,
  name,
  helpText,
  label = '',
  size = { md: 12 },
  onChange = () => {},
  disabled,
  labelBadge,
  verticalCenter = false,
  switchOnRight = false,
  labelerId,
}) => {
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));

  const [checked, setChecked] = useState<boolean>(value === true);

  const hasError = (): boolean => {
    return !!name && violations.hasOwnProperty(name);
  };

  const getError = (): string => {
    if (hasError()) {
      return name ? violations[name].join('\n') : '';
    }
    return '';
  };

  const toggle = () => {
    if (disabled) return;

    const newState = !checked;
    setChecked(newState);
    onChange(newState, name);
  };

  useEffect(() => {
    setChecked(value === true);
  }, [value]);

  return (
    <Col {...size} className={className}>
      <FormGroup
        check={true}
        className={classnames('ctm-form-check', {
          'switch-on-right': switchOnRight,
          checked,
          'not-checked': !checked,
        })}
        disabled={disabled}
        style={verticalCenter ? { top: '50%', transform: 'translateY(-50%)' } : {}}
      >
        <div className="form-switch">
          <label
            className="form-check-label"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              toggle();
              return false;
            }}
          >
            {labelBadge && (
              <span className="badge bg-warning" style={{ marginRight: 6, padding: 6 }}>
                {labelBadge}
              </span>
            )}
            {typeof labelerId !== 'undefined' && <LabelerId labelerId={labelerId} />}
            {label || 'Nazwa pola'}
          </label>
          <input
            type="checkbox"
            className="form-check-input"
            checked={checked}
            onChange={() => false}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              toggle();
              return false;
            }}
            disabled={disabled}
          />
        </div>
        {helpText && <FormText>{helpText}</FormText>}
        {hasError() && <FormFeedback>{getError()}</FormFeedback>}
      </FormGroup>
    </Col>
  );
};

export default Switch;
