import SectionLayout from './SectionLayout';
import { CheckBox, CheckBoxOutlineBlank, ExpandCircleDown, HighlightOff, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Collapse } from '@mui/material';
import { MemoStrongCompare } from 'Modules/Common/Utils/MemoComparision';
import FieldConditionModal from 'pages/Configuration/CustomFields/FieldConditionModal';
import { memo, useState } from 'react';

function Section(props) {
  const { tabId, section, fields, updateTemplate, onRemove, hideConditionsTemplates, onFieldSaved, moduleId } = props;
  const [open, setOpen] = useState<boolean>(true);
  const updateSection = newData => {
    updateTemplate(prev => ({
      ...prev,
      tabs: (prev?.tabs ?? []).map(prevTab => ({
        ...prevTab,
        sections: prevTab.sections.map(prevSection => {
          if (prevSection.id === section.id) {
            prevSection = { ...prevSection, ...newData };
          }
          return prevSection;
        }),
      })),
    }));
  };

  const toggleSectionGridMode = () => {
    updateSection({ useGridLayout: !section.useGridLayout });
  };

  const handleHideCondition = hideConditions => {
    updateSection({ hideConditions });
  };

  return (
    <>
      <Box sx={{ p: 1, background: '#eef1fd' }}>
        <div className="d-flex">
          <div style={{ width: 250 }}>
            <input
              type="text"
              value={section.name}
              placeholder="Nazwa sekcji"
              onChange={({ target: { value } }) => updateSection({ name: value })}
              className="transparent-input"
            />
          </div>
          <div className="d-flex" style={{ width: 'calc(100% - 250px)', justifyContent: 'space-between' }}>
            <div>
              <FieldConditionModal wrapperProps={{}} value={section.hideConditions ?? []} fields={fields} onChange={handleHideCondition}>
                <Button color="primary" size="small" variant="contained" style={{ color: '#fff', padding: 0, minWidth: 0 }}>
                  <VisibilityOff />
                </Button>
              </FieldConditionModal>
              <Button color="error" size="small" variant="contained" style={{ color: '#fff', padding: 0, minWidth: 0, marginLeft: 6 }}>
                <HighlightOff onClick={() => onRemove(section.id)} />
              </Button>
            </div>
            <div>
              <Button
                startIcon={section.useGridLayout ? <CheckBox /> : <CheckBoxOutlineBlank />}
                onClick={() => toggleSectionGridMode()}
                color="info"
                size="small"
                variant="contained"
              >
                Zarządzaj wysokością pól
              </Button>
            </div>
            <div>
              <Button
                color="info"
                size="small"
                variant="contained"
                style={{ color: '#fff', padding: 0, minWidth: 0 }}
                onClick={() => setOpen(!open)}
              >
                <ExpandCircleDown
                  style={{
                    transform: `rotate(${open ? 0 : 180}deg)`,
                    transition: 'all .5s linear',
                  }}
                />
              </Button>
            </div>
          </div>
        </div>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box>
          <SectionLayout
            layout={section.layout}
            updateSection={updateSection}
            fields={fields}
            useGridLayout={section.useGridLayout}
            onFieldSaved={onFieldSaved}
            moduleId={moduleId}
          />
        </Box>
      </Collapse>
    </>
  );
}

export default memo(Section, MemoStrongCompare);
