import { CoreModuleModule, ProductModule } from '../../CTMModules';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import ListPicker from 'components/Form/ListPicker';
import { Col, Container } from 'components/Theme/Grid';
import { useEffect, useState } from 'react';

const ProductCollectionFields: CustomFieldComponentType = ({ field, value, onChange }) => {
  const [productFields, setProductFields] = useState([]);
  const resolveProductFields = () => {
    CoreModuleModule.api.get({ id: ProductModule.configuration.id }).then(configuration => {
      setProductFields(configuration.fields.filter(field => !['BOOLEAN', 'IMAGE', 'FILE'].includes(field.type)));
    });
  };
  useEffect(() => {
    resolveProductFields();
  }, []);

  const handleChange = (val, index) => {
    const newValue = value ? [...value] : [];
    newValue[index] = val;
    onChange(newValue, field.id);
  };

  const getValue = index => {
    if (value && value.length > index) {
      return value[index];
    }
    return null;
  };

  if (productFields.length === 0) {
    return null;
  }

  return (
    <Container>
      <Col xs={12} md={3}>
        <ListPicker
          label={field.name + ' #1'}
          options={productFields}
          name={field.id}
          value={getValue(0)}
          onChange={(id, name, val) => handleChange(val, 0)}
        />
      </Col>
      <Col xs={12} md={3}>
        <ListPicker
          label={field.name + ' #2'}
          options={productFields}
          name={field.id}
          value={getValue(1)}
          onChange={(id, name, val) => handleChange(val, 1)}
        />
      </Col>
      <Col xs={12} md={3}>
        <ListPicker
          label={field.name + ' #3'}
          options={productFields}
          name={field.id}
          value={getValue(2)}
          onChange={(id, name, val) => handleChange(val, 2)}
        />
      </Col>
      <Col xs={12} md={3}>
        <ListPicker
          label={field.name + ' #4'}
          options={productFields}
          name={field.id}
          value={getValue(3)}
          onChange={(id, name, val) => handleChange(val, 3)}
        />
      </Col>
    </Container>
  );
};

export default ProductCollectionFields;
