import { ClientOrderModule } from 'Modules/CTMModules';
import ModuleList from 'components/Module/ModuleList';
import { FC } from 'react';

interface ChildTabProps {
  record: any;
}

const ChildTab: FC<ChildTabProps> = ({ record }) => {
  return (
    <ModuleList
      key={ClientOrderModule.configuration.urlPrefix}
      moduleName={ClientOrderModule.configuration.urlPrefix}
      defaultFilters={[{ id: 'parent', value: record.id }]}
      useModal={false}
      overrideListProps={{ storeFilters: false }}
    />
  );
};

export default ChildTab;
