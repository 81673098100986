import TextEditor from './TextEditor';
import Button from '@mui/material/Button';
import { TaskModule } from 'Modules/CTMModules';
import { Col, Container } from 'components/Theme/Grid';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';

const TaskModalDescription: FC<{ id: string; description: null | string }> = ({ id, description }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [savedDescription, setSavedDescription] = useState<string>(description ?? '');
  const [controlledDescription, setControlledDescription] = useState<string>(description ?? '');

  const saveDescription = () => {
    TaskModule.api
      .put({ description: controlledDescription }, { id: id })
      .then(() => {
        dispatch(addSingleToast({ title: `Zapisano opis`, config: { appearance: 'success' } }));
        setSavedDescription(controlledDescription);
        setEditMode(false);
      })
      .catch(err => {
        dispatch(
          addSingleToast({ title: err?.response?.['hydra:description'] ?? 'Wystąpił błąd z zapisem', config: { appearance: 'error' } }),
        );
      });
  };
  return (
    <Container>
      <Col xs={12}>
        <TextEditor disabled={!editMode} value={controlledDescription} onChange={val => setControlledDescription(val)} />
      </Col>
      <Col xs={12} style={{ textAlign: 'right' }}>
        {!editMode && (
          <Button size="small" style={{ border: '1px #dddddd solid' }} onClick={() => setEditMode(true)}>
            Edytuj opis
          </Button>
        )}
        {editMode && (
          <Button
            size="small"
            style={{ border: '1px #dddddd solid', marginRight: 12 }}
            onClick={() => {
              setControlledDescription(savedDescription);
              setEditMode(false);
            }}
          >
            Odrzuć
          </Button>
        )}
        {editMode && (
          <Button size="small" style={{ border: '1px #dddddd solid' }} onClick={saveDescription}>
            Zapisz
          </Button>
        )}
      </Col>
    </Container>
  );
};

export default TaskModalDescription;
