import { CLEAR_ERRORS, STORE_ERRORS } from 'store/form/errors/actionTypes';

export type FormErrorsState = {
  violations: {
    [key: string]: string[];
  };
};

const initialState: FormErrorsState = {
  violations: {},
};

const errors = (state = initialState, action) => {
  switch (action.type) {
    case STORE_ERRORS: {
      const violationsObject = {};
      action.payload.violations.forEach(el => {
        if (!violationsObject.hasOwnProperty(el.propertyPath)) {
          violationsObject[el.propertyPath] = [];
        }
        violationsObject[el.propertyPath].push(el.message);
      });
      state = {
        ...state,
        violations: violationsObject,
      };
      break;
    }
    case CLEAR_ERRORS:
      state = {
        ...state,
        violations: {},
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default errors;
