import { DeleteForever } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { PlaceModule, ProductModule } from 'Modules/CTMModules';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import ModuleListScanner from 'components/Module/ModuleListScanner';
import RecordInputReader from 'components/Module/RecordInputReader';
import useScanner from 'components/Scanner/Scanner';
import ModalLoader from 'components/Theme/Common/ModalLoader';
import Col from 'components/Theme/Grid/Col';
import Container from 'components/Theme/Grid/Container';
import React, { memo, useCallback, useEffect, useState } from 'react';

interface ProductScannedBoxProps {
  index: number;
  quantity: number;
  product: any;
  place: any | null;
  warehouse: any | null;
  onRemoveRowQuantity: (index: number) => void;
  onAddRowQuantity: (index: number) => void;
  onRemoveRow: (index: number) => void;
  onPlaceSelected: (index: number, place) => void;
}

interface ScannedRow {
  product: any;
  place: any | null;
  quantity: any | null;
  unit: any | null;
}

const ProductScannedBox = (props: ProductScannedBoxProps) => {
  const { index, quantity, onRemoveRow, product, place, warehouse, onRemoveRowQuantity, onPlaceSelected, onAddRowQuantity } = props;
  return (
    <Col xs={12} sm={12} md={6} lg={4} key={index}>
      <Card style={{ height: '100%' }}>
        <CardContent style={{ height: '100%' }}>
          <Container gap={0} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
            <Col xs={12}>
              <Container gap={0}>
                <Col xs={10}>{product.name}</Col>
                <Col xs={2} style={{ textAlign: 'right' }}>
                  <DeleteForever
                    style={{
                      background: '#f00',
                      cursor: 'pointer',
                      color: '#fff',
                      borderRadius: '100%',
                      padding: 4,
                    }}
                    onClick={() => onRemoveRow(index)}
                  />
                </Col>
              </Container>
            </Col>
            <Col xs={12}>
              <Container gap={0}>
                <Col xs={6}>{product.symbol}</Col>
                <Col xs={6} style={{ textAlign: 'right' }}>
                  {product.ean}
                </Col>
              </Container>
            </Col>
            <Col xs={12}>
              <Container gap={0}>
                <Col xs={12} style={{ textAlign: 'right' }}>
                  <ModuleListScanner
                    disabled={false}
                    moduleName={'warehouse-places'}
                    disableClear={!place}
                    onChange={gridRow => onPlaceSelected(index, gridRow)}
                    defaultFilters={warehouse ? [{ id: 'warehouse.id', value: warehouse?.id }] : []}
                    forceFocus={false}
                    inputScannerLabel={'Wprowadź nazwę miejsca magazynowego'}
                    filterFields={['name']}
                    onClickReset={true}
                    showColumns={null}
                    isSelected={!!place}
                    namePrefix={undefined}
                    name={undefined}
                    moduleFormWrapperProps={undefined}
                  >
                    <RecordInputReader
                      namePrefix={'rows[' + index + ']'}
                      name={'place'}
                      value={place}
                      module={PlaceModule}
                      label={'Miejsce magazynowe'}
                      readonly={false}
                    />
                  </ModuleListScanner>
                </Col>
                <Col xs={12} style={{ textAlign: 'right' }}>
                  <Button variant="contained" size="small" onClick={() => onRemoveRowQuantity(index)}>
                    -1
                  </Button>
                  <span style={{ paddingLeft: 24, paddingRight: 24 }}>Ilość: {quantity}</span>
                  <Button variant="contained" size="small" onClick={() => onAddRowQuantity(index)}>
                    +1
                  </Button>
                </Col>
              </Container>
            </Col>
          </Container>
        </CardContent>
      </Card>
    </Col>
  );
};

const ProductScannedBoxMemo = memo(ProductScannedBox);
const ProductListScanner = props => {
  const [rows, setRows] = useState<ScannedRow[]>([]);
  const [scannedTmpData, setScannedTmpData] = useState<string>('');
  const [lastScannedTmpData, setLastScannedTmpData] = useState<string>('');
  const [scanning, setScanning] = useState<boolean>(false);
  const { warehouse } = props;

  useScanner(scannedData => {
    if (document.activeElement?.tagName === 'INPUT') {
      return;
    }
    setLastScannedTmpData(scannedData);
    setScannedTmpData(scannedData);
  });

  useEffect(() => {
    if (scannedTmpData.length > 0) {
      setScanning(true);
      setScannedTmpData('');
      const existingRow = rows.findIndex(r => r.product?.ean === scannedTmpData || r.product?.symbol === scannedTmpData);
      if (existingRow === -1) {
        ProductModule.api.getAll({ params: { orEan: scannedTmpData, orSymbol: scannedTmpData } }).then(result => {
          const rowsFetched = result['hydra:member'];

          if (rowsFetched.length === 1) {
            addNewProductRow(rowsFetched[0]);
            setScanning(false);
          }
        });
      } else {
        const newRows = [...rows];
        newRows[existingRow] = { ...rows[existingRow], quantity: rows[existingRow].quantity + 1 };
        setRows(newRows);
        setScanning(false);
      }
    }
  }, [scannedTmpData, setRows, rows]);

  const addNewProductRow = product => {
    setRows([
      ...rows,
      {
        place: null,
        product: product,
        unit: product?.unit ?? null,
        quantity: 1,
      },
    ]);
  };

  const addRowQuantity = useCallback(rowIndex => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      newRows[rowIndex] = { ...prevRows[rowIndex], quantity: prevRows[rowIndex].quantity + 1 };

      return newRows;
    });
  }, []);

  const removeRowQuantity = useCallback(rowIndex => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      newRows[rowIndex] = { ...prevRows[rowIndex], quantity: Math.max(prevRows[rowIndex].quantity - 1, 0) };

      return newRows;
    });
  }, []);

  const removeRow = useCallback(rowIndex => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      newRows.splice(rowIndex, 1);

      return newRows;
    });
  }, []);

  const selectPlace = useCallback((rowIndex, place) => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      newRows[rowIndex] = { ...prevRows[rowIndex], place: place };

      return newRows;
    });
  }, []);

  const onCancel = () => {
    setRows([]);
    props.onCancel();
  };

  const onAccept = () => {
    props.onAccept(rows);
    setRows([]);
  };

  return (
    <div className="position-fixed" style={{ left: 0, top: 0, width: '100vw', height: '100vh', background: '#fff', zIndex: 1010 }}>
      {scanning && <ModalLoader />}
      <Container className="p-3" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
        {rows.map((row, index) => (
          <ProductScannedBoxMemo
            key={index}
            product={row.product}
            index={index}
            onRemoveRow={removeRow}
            onRemoveRowQuantity={removeRowQuantity}
            onAddRowQuantity={addRowQuantity}
            quantity={row.quantity}
            place={row.place}
            warehouse={warehouse}
            onPlaceSelected={selectPlace}
          />
        ))}
      </Container>
      <Container className="p-3">
        <Col xs={12}>
          <Card>
            <ModuleListPicker
              key={'ModuleListPicker_' + lastScannedTmpData}
              moduleName={'manufacture-products'}
              onChange={gridRow => {
                addNewProductRow(gridRow);
              }}
              disableClear={true}
              defaultFilters={[
                { id: 'active', value: true },
                { id: 'ean', value: '426' },
              ]}
              moduleFormComponentProps={undefined}
              moduleFormComponent={undefined}
              moduleFormWrapperProps={undefined}
              allowMultipleSelect={false}
              onMultipleSelect={console.error}
              overrideUrl={undefined}
              headerActionsOnlyLabel={true}
              disableEditAction={true}
              disableShowAction={true}
              disableRemoveAction={true}
            >
              <Button>Zeskanuj towar lub kliknij aby wybrać z listy</Button>
            </ModuleListPicker>
          </Card>
        </Col>
      </Container>
      <div className="fixed-bottom w-100">
        <ButtonGroup className="w-100">
          <Button color="error" variant="contained" className="w-100" onClick={onCancel}>
            Anuluj
          </Button>
          <Button color="success" variant="contained" className="w-100" onClick={onAccept}>
            Gotowe
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

interface ProductListScannerWrapperProps {
  onAccepted: (rows: ScannedRow[]) => void;
  children?: React.ReactNode;
}

const ProductListScannerWrapper = (props: ProductListScannerWrapperProps) => {
  const [showScanner, setShowScanner] = useState<boolean>(false);

  const onCanceled = () => {
    setShowScanner(false);
  };

  const onAccepted = rows => {
    props.onAccepted(rows);
    setShowScanner(false);
  };
  return (
    <div className="w-100">
      <div className="w-100" onClick={() => setShowScanner(true)}>
        {props.children}
      </div>
      {showScanner && <ProductListScanner {...props} onCancel={onCanceled} onAccept={onAccepted} />}
    </div>
  );
};
export default ProductListScannerWrapper;
