import { SelectModuleRecordColumnFilter } from '@Components/DataGrid/Filter';
import ExportConfigFields from '@Core/Components/ExportConfig/Fields/ExportConfigFields';
import CoreModule, { Module } from '@CustomFields/CoreModule';
import { Field } from '@CustomFields/Field';
import CTMModule, { CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { pickValueFromRecordByPropertyPath } from 'helpers/ModuleUtils';

export type ExportConfigField = CTMStrictRecord & {
  name: string;
  field: string | Field;
  exportFieldConfig?: null | ExportConfigField;
};

export type ExportConfig = CTMStrictRecord & {
  name: string;
  module: Module;
  fields: ExportConfigField[];
};

const prepareFieldConfigToSave = (exportConfigField: ExportConfigField): ExportConfigField => {
  return {
    ...exportConfigField,
    field: exportConfigField.field['@id'],
    exportFieldConfig: exportConfigField.exportFieldConfig ? prepareFieldConfigToSave(exportConfigField.exportFieldConfig) : null,
  };
};

const columns: CTMListColumn<ExportConfig>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    id: 'module.id',
    Filter: SelectModuleRecordColumnFilter,
    filterModule: CoreModule,
    filterable: true,
    sortable: true,
    Header: 'Moduł',
    accessor: 'module.relationFieldLabel',
  },
];

const module: CTMModule<ExportConfig> = {
  id: 'af39938f-34a0-4317-9402-e3a92f474858',
  dataClass: 'CTM\\Core\\Entity\\ExportConfig',
  urlPrefix: 'core-export-config',
  name: 'Konfiguracja exportów',
  role: 'CORE_EXPORT_CONFIG',
  api: {
    item: {
      get: ({ id }) => `/core/export-configs/${id}`,
      put: ({ id }) => `/core/export-configs/${id}`,
      delete: ({ id }) => `/core/export-configs/${id}`,
    },
    collection: {
      get: `/core/export-configs`,
      post: `/core/export-configs`,
    },
  },
  recordLabel: (record, allFields) => {
    const { name } = pickValueFromRecordByPropertyPath<ExportConfig, Pick<ExportConfig, 'name'>>(record, ['name'], allFields);
    return name;
  },
  form: {
    prepareRecordToSave: record => ({
      ...record,
      module: record.module?.['@id'] ?? record.module ?? null,
      fields: (record?.fields ?? []).map(prepareFieldConfigToSave).filter(Boolean),
    }),
    fieldComponents: {
      fields: ExportConfigFields,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
