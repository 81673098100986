import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import CurrencySelectSingle from 'components/Form/CurrencySelectSingle';

const CurrencyField: CustomFieldComponentType = ({ field, value, onChange, readonly, errorKey, customProps = {} }) => {
  return (
    <>
      <CurrencySelectSingle
        size={{ md: 12 }}
        value={value}
        name={field.id}
        tabIndex={field._tabindex}
        onChange={(value, id) => {
          onChange(value, id);
        }}
        disabled={readonly}
        errorKey={errorKey}
        {...customProps}
      />
    </>
  );
};

export default CurrencyField;
