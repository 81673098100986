import { SelectModuleRecordColumnFilter } from '@Components/DataGrid/Filter';
import ExportConfigFields from '@Core/Components/ExportConfig/Fields/ExportConfigFields';
import { Field } from '@CustomFields/Field';
import ProductKind, { ProductKind as ProductKindModel } from '@Manufacture/ProductKind';
import { ProductModule } from '@Modules/CTMModules';
import CTMModule, { CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { pickValueFromRecordByPropertyPath } from 'helpers/ModuleUtils';

export type ProcurementOrderExportField = CTMStrictRecord & {
  name: string;
  field: string | Field;
};

export type ProcurementOrderExport = CTMStrictRecord & {
  name: string;
  kind: ProductKindModel;
  fields: ProcurementOrderExportField[];
};

const prepareFieldConfigToSave = (exportConfigField: ProcurementOrderExportField): ProcurementOrderExportField => {
  return {
    ...exportConfigField,
    field: exportConfigField.field['@id'],
  };
};

const columns: CTMListColumn<ProcurementOrderExport>[] = [
  {
    id: 'kind.id',
    Filter: SelectModuleRecordColumnFilter,
    filterModule: ProductKind,
    filterable: true,
    sortable: true,
    Header: 'Rodzaj produktu',
    accessor: 'kind.relationFieldLabel',
  },
];

const module: CTMModule<ProcurementOrderExport> = {
  id: '7f0cca46-19b5-4e87-a87d-f36e3a1c9ecd',
  dataClass: 'CTM\\Warehouse\\Entity\\ProcurementOrder\\ProcurementOrderExport',
  urlPrefix: 'warehouse-procument-order-exports',
  name: 'Pola przy eksporcie',
  role: 'WAREHOUSE_PROCUREMENT_ORDER',
  api: {
    item: {
      get: ({ id }) => `/warehouse/procurement-order-exports/${id}`,
      put: ({ id }) => `/warehouse/procurement-order-exports/${id}`,
      delete: ({ id }) => `/warehouse/procurement-order-exports/${id}`,
    },
    collection: {
      get: `/warehouse/procurement-order-exports`,
      post: `/warehouse/procurement-order-exports`,
    },
  },
  recordLabel: (record, allFields) => {
    const { name } = pickValueFromRecordByPropertyPath<ProcurementOrderExport, Pick<ProcurementOrderExport, 'name'>>(
      record,
      ['name'],
      allFields,
    );
    return name;
  },
  form: {
    prepareRecordToSave: record => ({
      ...record,
      kind: record.kind?.['@id'] ?? record.kind ?? null,
      fields: (record?.fields ?? []).map(prepareFieldConfigToSave).filter(Boolean),
    }),
    fieldComponents: {
      fields: props => <ExportConfigFields moduleId={ProductModule.configuration.id} {...props} />,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
