import classnames from 'classnames';
import * as url from 'helpers/Api/Url';
import { axiosApi } from 'helpers/Axios';
import { downloadBlobFile } from 'helpers/File';
import React, { FC, useState } from 'react';
import { Button } from 'reactstrap';

interface DownloadSectionContentProps {
  sectionId: string;
  recordId: string;
}

const DownloadSectionContent: FC<DownloadSectionContentProps> = ({ sectionId, recordId }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const download = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setLoading(true);

    const ids = recordId.split('/');

    axiosApi
      .get(url.GET_SECTION_CONTENT(sectionId, ids[ids.length - 1]), {
        responseType: 'blob',
        headers: {
          Accept: 'ip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed',
        },
      })
      .then(response => {
        downloadBlobFile(response.data, `${recordId}.zip`);
        setLoading(false);
      });
  };

  return loading ? (
    <Button className={classnames('btn btn-sm btn-warning ml-1')}>Ładowanie...</Button>
  ) : (
    <Button onClick={event => download(event)} className={classnames('btn btn-sm btn-info ml-1')}>
      <i className="mdi mdi-download" style={{ padding: '0 4px' }} /> Pobierz zawartość
    </Button>
  );
};

export default DownloadSectionContent;
