import { ShipmentModule } from '../../CTMModules';
import CostOfShipmentNet from './CostOfShipmentNet';
import { Button } from '@mui/material';
import BaseScalarInput from 'components/CustomFields/FieldTypes/BaseScalarInput';
import RelationField from 'components/CustomFields/FieldTypes/RelationField';

const ShipmentField = props => {
  const createShipment = () => {
    ShipmentModule.api.post({ clientOrder: props.recordId }).then(res => {
      props.onChange(res, props.field.id);
    });
  };

  if (!props.value) {
    return (
      <BaseScalarInput
        {...props}
        type="text"
        onChange={() => false}
        afterInputContent={
          <>
            {!props.readonly && (props.recordId ?? '').length > 10 && (
              <Button variant="contained" color="primary" onClick={createShipment}>
                Wygeneruj
              </Button>
            )}
          </>
        }
      />
    );
  }

  return <RelationField {...props} />;
};

CostOfShipmentNet.provideAllValues = true;
export default ShipmentField;
