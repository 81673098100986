import { CTMRecord } from 'Modules/Core/Types/CTMModule';
import List from 'pages/Warehouse/Document/List';
import { FC } from 'react';

interface DocumentTabProps {
  record: CTMRecord;
  field: string;
  property: string;
}

const DocumentTab: FC<DocumentTabProps> = ({ record, field, property }) => {
  return <List fullView={false} defaultFilters={[{ id: field, value: record[property] as string }]} />;
};

export default DocumentTab;
