import CurrencySelectSingle from 'components/Form/CurrencySelectSingle';
import { Col, Container } from 'components/Theme/Grid';
import { withRouter } from 'react-router-dom';

const Multinational = ({ form, updateConfiguration }) => {
  const updateFormValue = (value, field) => {
    updateConfiguration({ ...form, [field]: value });
  };

  return (
    <Container>
      <Col xs={12} md={6} lg={4}>
        <CurrencySelectSingle value={form.defaultCurrency} label={'Domyślna waluta'} name="defaultCurrency" onChange={updateFormValue} />
      </Col>
    </Container>
  );
};

export default withRouter(Multinational);
