import { CoreModuleModule, DictionaryModule, ProductGroupModule, ProductModule, TariffModule } from 'Modules/CTMModules';
import useIsGranted from 'common/hooks/isGranted';
import BaseInput from 'components/Form/BaseInput';
import ListPicker from 'components/Form/ListPicker';
import SelectMultiple from 'components/Form/SelectMultiple';
import SelectSingleAPI from 'components/Form/SelectSingleAPI';
import Switch from 'components/Form/Switch';
import { Col, Container } from 'components/Theme/Grid';
import { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';

const PrestaShop = ({ form, updateConfiguration }) => {
  const [productFields, setProductFields] = useState([]);
  const [productGroupFields, setProductGroupFields] = useState([]);
  const [dictionaries, setDictionaries] = useState([]);
  const [tariffs, setTariffs] = useState([]);

  const resolveProductFields = () => {
    CoreModuleModule.api.get({ id: ProductModule.configuration.id }).then(configuration => {
      setProductFields(configuration.fields);
    });
  };

  const resolveProductGroupFields = () => {
    CoreModuleModule.api.get({ id: ProductGroupModule.configuration.id }).then(configuration => {
      setProductGroupFields(configuration.fields);
    });
  };

  const resolveDictionaries = () => {
    DictionaryModule.api.getAll({ params: { ['category.id']: ProductModule.configuration.id } }).then(data => {
      setDictionaries(data['hydra:member']);
    });
  };

  const resolveTariffs = () => {
    TariffModule.api.getAll().then(data => {
      setTariffs(data['hydra:member']);
    });
  };

  const canEdit = useIsGranted('ROLE_EDIT_CONFIGURATION');

  const updateFormValue = (value, field) => {
    updateConfiguration({ ...form, [field]: value });
  };

  const updateFieldValue = (value, field) => {
    const fieldsValues = {
      ...form.fields,
      ...{
        [field]: value,
      },
    };

    updateConfiguration({ ...form, fields: fieldsValues });
  };

  useEffect(() => {
    resolveProductFields();
    resolveProductGroupFields();
    resolveDictionaries();
    resolveTariffs();
  }, []);

  const isReady = useMemo(
    () => productFields.length > 0 && productGroupFields.length > 0 && dictionaries.length > 0 && tariffs.length > 0,
    [productFields, productGroupFields, dictionaries, tariffs],
  );

  return (
    isReady && (
      <Container>
        <Col xs={12} md={12} lg={12}>
          <h5>Ogólne</h5>
        </Col>
        <Col xs={12} md={12} lg={12}>
          <Switch
            size={{ md: 12 }}
            label={'Aktywne'}
            value={form.active}
            name="active"
            onChange={updateFormValue}
            disabled={!canEdit}
            switchOnRight={true}
          />
        </Col>
        <Col xs={6} md={6} lg={6}>
          <BaseInput
            size={{ md: 12 }}
            label={'API URL[https://adres-sklepu.pl]'}
            value={form.url}
            name="url"
            onChange={updateFormValue}
            disabled={!canEdit}
          />
        </Col>
        <Col xs={6} md={6} lg={6}>
          <BaseInput size={{ md: 12 }} label={'API key'} value={form.key} name="key" onChange={updateFormValue} disabled={!canEdit} />
        </Col>

        <Col xs={12} md={12} lg={12}>
          <h5>Mapowanie pól</h5>
        </Col>

        <Col xs={4} md={4} lg={4}>
          <h6>Ogólne</h6>
          <SelectSingleAPI
            label={'Typ produktu dla eksportu'}
            disableGroupMargin={true}
            name={'productType'}
            value={form.productType}
            onChange={updateFormValue}
            endpoint={`manufacture/product-types`}
            isClearable={false}
          />
          <BaseInput
            size={{ md: 12 }}
            label={'ID domyślnego sklepu'}
            value={form.defaultShopId}
            name="defaultShopId"
            onChange={updateFormValue}
            disabled={!canEdit}
          />
          <BaseInput
            size={{ md: 12 }}
            label={'ID domyślnej kategorii'}
            value={form.defaultCategoryId}
            name="defaultCategoryId"
            onChange={updateFormValue}
            disabled={!canEdit}
          />
        </Col>

        <Col xs={4} md={4} lg={4}>
          <h6>Kategoria</h6>
          <ListPicker
            label={'Eksportowanie kategorii'}
            options={productGroupFields.filter(field => field.type === 'BOOLEAN' && !field.system)}
            disableGroupMargin={true}
            name={'importProductGroup'}
            value={form.fields?.importProductGroup ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
          <ListPicker
            label={'Oznaczanie aktywnej kategorii'}
            options={productGroupFields.filter(field => field.type === 'BOOLEAN' && !field.system)}
            disableGroupMargin={true}
            name={'activeProductGroup'}
            value={form.fields?.activeProductGroup ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
        <Col xs={4} md={4} lg={4}>
          <h6>Produkt</h6>
          <ListPicker
            label={'Eksporotwanie produktu'}
            options={productFields.filter(field => field.type === 'BOOLEAN' && !field.system)}
            disableGroupMargin={true}
            name={'importProduct'}
            value={form.fields?.importProduct ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
          <ListPicker
            label={'Nazwa'}
            options={productFields}
            disableGroupMargin={true}
            name={'name'}
            value={form.fields?.name ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
          <ListPicker
            label={'Cena'}
            options={tariffs}
            disableGroupMargin={true}
            name={'price'}
            value={form.fields?.price ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
          <ListPicker
            label={'Opis'}
            options={productFields}
            disableGroupMargin={true}
            name={'description'}
            value={form.fields?.description ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
          <ListPicker
            label={'Skrócony opis'}
            options={productFields}
            disableGroupMargin={true}
            name={'shortDescription'}
            value={form.fields?.shortDescription ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
          <SelectMultiple
            label={'Zdjęcia'}
            options={productFields.filter(field => field.type === 'IMAGE' && !field.system)}
            disableGroupMargin={true}
            name={'images'}
            value={form.fields?.images ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
          <SelectMultiple
            label={'Słowniki'}
            options={dictionaries}
            disableGroupMargin={true}
            name={'dictionaries'}
            value={form.fields?.dictionaries ?? null}
            onChange={updateFieldValue}
            isClearable={false}
          />
        </Col>
      </Container>
    )
  );
};

export default withRouter(PrestaShop);
