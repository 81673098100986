import { Note } from 'Modules/Note/Model';
import useApiResourceQuery, { ApiCollection, patch } from 'helpers/Axios';
import { PropsWithChildren, useCallback, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { Link, NavLink } from 'react-router-dom';

export default function NoteList() {
  const [listSearch, setListSearch] = useState('');
  const [list, setList] = useState<Note[]>([]);
  useApiResourceQuery<ApiCollection<Note>>('/notes', {
    qp: { title: listSearch || undefined },
    keepPreviousData: true,
    onSuccess: data => setList(data['hydra:member']),
  });

  const onDragEnd = (result: DropResult) => {
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination?.index;

    if (destinationIndex === undefined || sourceIndex === destinationIndex) {
      return;
    }
    const offset = sourceIndex > destinationIndex ? 0 : 1;

    const target = list[sourceIndex];
    const insertBefore = list[destinationIndex + offset];

    if (result.destination !== undefined) {
      setList(list => reorder(list, result.source.index, destinationIndex));
    }

    void patch(target['@id'] + '/move', {
      insertBefore: insertBefore?.['@id'],
    });
  };

  const onSearchChange = useCallback(e => setListSearch(e.target.value), [setListSearch]);

  return (
    <ListContainer>
      <div className="search-box mb-2 me-2">
        <div className="position-relative">
          <input
            type="text"
            className="form-control bg-light border-light rounded"
            placeholder="Szukaj..."
            value={listSearch}
            onChange={onSearchChange}
          />
          <i className="bx bx-search-alt search-icon"></i>
        </div>
      </div>
      <ul className="list-unstyled categories-list">
        <li className="active">
          <Link className="text-body fw-medium py-1 d-flex align-items-center active" to="/notes/create">
            <i className="mdi mdi-plus font-size-16 text-warning me-2"></i>
            Utwórz notatkę
          </Link>
        </li>
      </ul>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="list"
          children={provided => (
            <ul {...provided.droppableProps} ref={provided.innerRef} className="list-unstyled categories-list">
              {list.map((v, i) => (
                <Draggable
                  key={v.id}
                  draggableId={v['@id']}
                  index={i}
                  children={provided => (
                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <ListNoteElementContent note={v} key={v['@id']} />
                    </li>
                  )}
                />
              ))}
              {provided.placeholder}
            </ul>
          )}
        />
      </DragDropContext>
    </ListContainer>
  );
}

interface ListNoteElementContentProps {
  note: Note;
}
function ListNoteElementContent({ note }: ListNoteElementContentProps) {
  return (
    <NavLink
      className={`text-body py-1 d-flex align-items-center`}
      to={'/notes/' + note.id}
      activeClassName="fw-medium ml-1"
      style={{ fontWeight: 'normal' }}
    >
      <i className="mdi mdi-file font-size-16 text-warning me-2"></i>
      {note.title || <i>Bez tytułu</i>}
    </NavLink>
  );
}

function ListContainer({ children }: PropsWithChildren) {
  return (
    <div className="card filemanager-sidebar me-md-2 h-100 w-100">
      <div className="card-body w-100">
        <div className="d-flex flex-column h-100">
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
}

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}
