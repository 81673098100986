import { Card } from '@mui/material';
import { PropsWithChildren } from 'react';

export default function SectionCard({ children }: PropsWithChildren) {
  return (
    <Card
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}
      elevation={1}
      square
      children={children}
    />
  );
}
