import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import ContentField from 'Modules/Manufacture/Label/Component/ContentField';
import { SelectApiColumnFilter } from 'components/DataGrid/Filter';
import { axiosApi } from 'helpers/Axios';
import { downloadBlobFile } from 'helpers/File';
import { Button } from 'reactstrap';

export type Label = CTMRecord & {
  name?: string;
};

const InlineComponentPrependActions = (row, refList) => {
  const onClick = id => {
    axiosApi
      .get(`/manufacture/labels/${id}`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then(response => {
        downloadBlobFile(response.data, `label_${id}`);
      });
  };

  return (
    <Button onClick={() => onClick(row.id)} className="btn btn-warning btn-sm">
      <i className="mdi mdi-printer" />
    </Button>
  );
};

const columns: CTMListColumn<Label>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    id: 'type.id',
    filterable: true,
    sortable: true,
    Header: 'Typ',
    accessor: row => {
      if (!row.type) {
        return null;
      }
      return row.type.name;
    },
    Filter: SelectApiColumnFilter,
    filterSearchUrl: '/manufacture/label-types',
    filterSearchField: 'id',
  },
  {
    id: 'width',
    filterable: true,
    sortable: true,
    Header: 'Szerokość',
    accessor: 'width',
  },
  {
    id: 'height',
    filterable: true,
    sortable: true,
    Header: 'Wysokość',
    accessor: 'height',
  },
];

const module: CTMModule<Label> = {
  id: 'a48b2a8c-91fc-11ed-8cfc-3b90d4c35d89',
  dataClass: 'CTM\\Manufacture\\Entity\\Label\\Label',
  urlPrefix: 'manufacture-labels',
  name: 'Etykiety',
  role: 'MANUFACTURE_LABEL',
  api: {
    item: {
      get: ({ id }) => `/manufacture/labels/${id}`,
      put: ({ id }) => `/manufacture/labels/${id}`,
      delete: ({ id }) => `/manufacture/labels/${id}`,
    },
    collection: {
      get: `/manufacture/labels`,
      post: `/manufacture/labels`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {
      content: ContentField,
    },
    prepareRecordToSave: record => ({
      ...record,
      type: record.type?.['@id'] ?? record.type,
    }),
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
    inlineComponentPrependActions: InlineComponentPrependActions,
  },
};

export default module;
