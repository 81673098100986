import ListPicker from 'components/Form/ListPicker';

const BooleanTypeConfiguration = ({ configuration, onConfigurationUpdated }) => {
  const updateConfiguration = (value, field) => {
    onConfigurationUpdated({ ...configuration, [field]: value });
  };

  return (
    <ListPicker
      name={'component'}
      label={'Typ prezentacji'}
      value={configuration?.component || 'SWITCH'}
      options={[
        { val: 'SWITCH', label: 'Przełacznik' },
        { val: 'SELECT', label: 'Lista wyboru' },
      ]}
      optionLabel="label"
      optionTrackBy="val"
      onChange={updateConfiguration}
      disableGroupMargin={true}
    />
  );
};

export default BooleanTypeConfiguration;
