import PackingAndShipmentRowPositionButton from './PackingAndShipmentRowPositionButton';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import NumberField from 'components/CustomFields/FieldTypes/NumberField';
import { useMemo } from 'react';

const CostOfPackingNet: CustomFieldComponentType = ({ field, fields, values, value, onChange, readonly, errorKey }) => {
  const assemblingOrderFieldId = fields.find(el => el.propertyPath === 'assemblingOrder')?.id as string;

  const assemblingOrder = useMemo(() => {
    return values?.[assemblingOrderFieldId];
  }, [values?.[assemblingOrderFieldId]?.['@id']]);

  return (
    <>
      <NumberField
        field={field}
        readonly={readonly}
        errorKey={errorKey}
        value={value}
        onChange={onChange}
        fields={[]}
        afterInputContent={
          <>
            {!readonly && (
              <ButtonGroup>
                <Tooltip title="Wylicz koszt palet na podstawie zlecenia kompletacji" placement="right">
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!assemblingOrder}
                      onClick={() => {
                        const cost =
                          assemblingOrder.usedPackagesBelow30 * assemblingOrder.costPackagesBelow30 +
                          assemblingOrder.usedHalfPallet * assemblingOrder.costHalfPallet +
                          assemblingOrder.usedPallet * assemblingOrder.costPallet;
                        onChange(cost, field.id);
                      }}
                    >
                      <i className="fa fa-calculator" />
                    </Button>
                  </span>
                </Tooltip>
                <PackingAndShipmentRowPositionButton values={values} onChange={onChange} fields={fields} />
              </ButtonGroup>
            )}
          </>
        }
      />
    </>
  );
};
CostOfPackingNet.provideAllValues = true;
export default CostOfPackingNet;
