import FileTypeRow from './FileTypeRow';
import { Button, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

const FileTypesField: FC<{ value?: any[]; readonly: boolean; onChange: any; field: any }> = ({ value, readonly, field, onChange }) => {
  const values = (value ?? []).map(el => {
    el._id = uuidv4();

    return el;
  });
  const collectNewValue = newValue => {
    onChange([...values, newValue], field.id);
  };

  const onRemove = _id => {
    onChange([...values.filter(el => el._id !== _id)], field.id);
  };

  const updateExistingValue = (index, newValue) => {
    const newValues = [...values];
    newValues[index] = newValue;
    onChange(newValues, field.id);
  };
  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="head" style={{ width: 70 }}>
                    #
                  </TableCell>
                  <TableCell variant="head">Nazwa</TableCell>
                  <TableCell variant="head">Opis</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((el, i) => (
                  <FileTypeRow
                    key={el['@id']}
                    readonly={readonly}
                    fileType={el}
                    onUpdated={newValue => updateExistingValue(i, newValue)}
                    onRemove={() => onRemove(el._id)}
                  />
                ))}
              </TableBody>
              {!readonly && (
                <TableFooter>
                  <TableRow>
                    <TableCell variant="head" colSpan={3}>
                      <Button
                        variant="contained"
                        color="success"
                        className="w-100"
                        onClick={() => collectNewValue({ _id: uuidv4(), name: '', description: '' })}
                      >
                        Dodaj nowy typ
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default FileTypesField;
