import BaseInput from 'components/Form/BaseInput';
import { Col, Container } from 'components/Theme/Grid';
import { useEffect, useState } from 'react';

const ProductFilterRangeInput = ({ field, value, onChange, readonly, recordId }) => {
  const parseValue = v => {
    try {
      return { from: null, to: null, ...(JSON.parse(v ?? '{}') ?? {}) };
    } catch (e) {
      return { from: null, to: null };
    }
  };

  const [range, setRange] = useState(parseValue(value));

  useEffect(() => {
    onChange(JSON.stringify(range), field.id);
  }, [JSON.stringify(range)]);
  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(range)) {
      setRange(parseValue(value));
    }
  }, [value]);
  const handleChangeFrom = val => {
    setRange(p => ({ ...p, from: val }));
  };
  const handleChangeTo = val => {
    setRange(p => ({ ...p, to: val }));
  };

  return (
    <Container>
      <Col xs={6}>
        <BaseInput
          disabled={readonly}
          disableGroupMargin={true}
          value={range?.from}
          type={'number'}
          label={`${field.name} od`}
          onChange={handleChangeFrom}
        />
      </Col>
      <Col xs={6}>
        <BaseInput
          disabled={readonly}
          disableGroupMargin={true}
          value={range?.to}
          type={'number'}
          label={`${field.name} do`}
          onChange={handleChangeTo}
        />
      </Col>
    </Container>
  );
};

export default ProductFilterRangeInput;
