import { ErrandType } from './Types/Errand';
import Contractor from 'Modules/Contractor/Contractor';
import CTMModule, { CTMListColumn } from 'Modules/Core/Types/CTMModule';
import ReportDownloadButton from 'components/DataGrid/Button/ReportDownloadButton';
import { createModuleColumnConfig } from 'helpers/ModuleUtils';
import ErrandPositions from 'pages/Manufacture/Errand/ErrandPositions';
import StatusPicker, { ErrandStatuses } from 'pages/Manufacture/Errand/StatusPicker';
import { Link } from 'react-router-dom';

const columns: CTMListColumn<ErrandType>[] = [
  {
    id: 'contractor.id',
    Header: 'Kontrahent',
    filterable: true,
    sortable: true,
    ...createModuleColumnConfig('contractor', Contractor),
  },
  {
    id: 'number',
    Header: 'Numer',
    accessor: 'number',
    filterable: true,
    sortable: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => ErrandStatuses[status] ?? '-',
    filterable: false,
    sortable: true,
  },
];

const module: CTMModule<ErrandType> = {
  id: '9f974802-c2b9-4049-a526-12b13b194fad',
  dataClass: 'CTM\\Manufacture\\Entity\\Errand',
  urlPrefix: 'manufacture-errand',
  name: 'Zlecenia',
  role: 'MANUFACTURE_ERRAND',
  api: {
    item: {
      get: ({ id }) => `/manufacture/errands/${id}`,
      put: ({ id }) => `/manufacture/errands/${id}`,
      delete: ({ id }) => `/manufacture/errands/${id}`,
    },
    collection: {
      get: `/manufacture/errands`,
      post: `/manufacture/errands`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    prepareRecordToSave: record => ({
      ...record,
      contractor: record.contractor?.['@id'] ?? record.contractor,
      rows: record.rows?.map(el => ({
        ...el,
        product: el.product?.['@id'] ?? el.product,
        unit: el.unit?.['@id'] ?? el.unit,
      })),
    }),
    fieldComponents: {
      rows: ErrandPositions,
      status: StatusPicker,
    },
    contextActions: ({ readonly, record, ApiFormComponent }) => (
      <>
        {!readonly && record.status === 1000 && (
          <Link to={`/modules/manufacture-errand/process/${record.id}`} className="btn btn-success ml-1">
            <i className="mdi mdi-cog-sync" />
            Procesowanie zlecenia
          </Link>
        )}
      </>
    ),
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'number', desc: true }],
    inlineComponentPrependActions: (row, refList) => (
      <ReportDownloadButton
        url={`manufacture/errand-component-reports?errand=${row.id}`}
        name={`Składniki ${row.number}`}
        description={'Raport składników dla zlcenia'}
      />
    ),
  },
};

export default module;
