import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import NumberInput from 'components/Form/MUI/NumberInput';
import TextInput from 'components/Form/MUI/TextInput';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import { post } from 'helpers/Axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

const RequestProductDemand = () => {
  const [modal, setModal] = useState(false);
  const [demands, setDemands] = useState([]);
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();

  const onClose = () => {
    setModal(false);
    setDemands([]);
    setComment('');
  };

  const onSave = () => {
    const data = {
      requests: demands.map(d => ({
        product: d.product?.['@id'],
        unit: d.unit?.['@id'],
        quantity: parseFloat(d.demand),
      })),
      comment,
    };

    post('/manufacture/product-demand-logs', data)
      .then(() => {
        dispatch(
          addSingleToast({
            title: `Zgłoszono zapotrzebowanie. Pojawi się na liście w ciągu kilku chwil`,
            config: { appearance: 'success' },
          }),
        );
        onClose();
      })
      .catch(err => {
        dispatch(
          addSingleToast({
            title: `Wystąpił problem. Spróbuj ponownie, w przypadku dalszych problemów zgłoś błąd do działu IT`,
            config: { appearance: 'error' },
          }),
        );
      });
  };

  return (
    <>
      <div className="text-center">
        <Button variant="contained" color="primary" onClick={() => setModal(true)}>
          Zgłoś zapotrzebowanie
        </Button>
      </div>
      <Modal isOpen={modal} size={'lg'} className="modal-dialog-centered">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Zgłoś zapotrzebowanie</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}></button>
        </div>
        <div className="modal-body">
          <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 50 }}>#</TableCell>
                  <TableCell>Towar</TableCell>
                  <TableCell style={{ width: 150 }}>Ilość</TableCell>
                  <TableCell style={{ width: 100 }}>Jednostka</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {demands.map(demand => (
                  <TableRow key={demand?.product?.id}>
                    <TableCell style={{ width: 50 }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="btn-close"
                        style={{ padding: 12 }}
                        onClick={() => {
                          const newDemands = [...demands];
                          newDemands.splice(
                            newDemands.findIndex(d => d.product.id === demand.product.id),
                            1,
                          );
                          setDemands(newDemands);
                        }}
                      ></Button>
                    </TableCell>
                    <TableCell>{demand?.product?.name}</TableCell>
                    <TableCell style={{ width: 150 }}>
                      <NumberInput
                        value={demand?.demand}
                        onChange={val => {
                          const newDemands = [...demands];
                          newDemands.find(d => d.product.id === demand.product.id).demand = val;
                          setDemands(newDemands);
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ width: 100 }}>
                      <ModuleListPicker
                        moduleName="manufacture-units"
                        onChange={selected => {
                          const newDemands = [...demands];
                          newDemands.find(d => d.product.id === demand.product.id).unit = selected;
                          setDemands(newDemands);
                        }}
                      >
                        {demand?.unit?.name ?? 'Szt.'}
                      </ModuleListPicker>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}>
                    <div className="text-center w-100">
                      <ModuleListPicker
                        moduleName="manufacture-products"
                        onChange={selected => {
                          setDemands([
                            ...demands,
                            {
                              product: selected,
                              unit: selected.unit,
                              demand: 0,
                            },
                          ]);
                        }}
                      >
                        <Button variant="contained" color="primary">
                          Dodaj zapotrzebowanie
                        </Button>
                      </ModuleListPicker>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <hr />
            <TextInput label="Komentarz" value={comment} textarea={true} onChange={val => setComment(val)} />
          </div>
        </div>
        <div className="modal-footer">
          <Button type="button" color="secondary" data-bs-dismiss="modal" onClick={onClose}>
            Zamknij
          </Button>
          <Button type="button" color="primary" onClick={onSave}>
            Zapisz
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RequestProductDemand;
