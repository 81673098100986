import Date from '../../Form/Date';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { useEffect } from 'react';

const DateField: CustomFieldComponentType = ({ field, value, onChange, readonly, customProps = {} }) => {
  useEffect(() => {
    if (value === null && field.defaultValue) {
      onChange(field.defaultValue, field.id);
    }
  }, []);

  const isReadonly = readonly || field.config.readonly;

  return (
    <Date
      name={field.id}
      label={field.name}
      tabIndex={field._tabindex}
      value={value || field.defaultValue}
      onChange={onChange}
      disabled={isReadonly}
      disableGroupMargin={true}
      {...customProps}
    />
  );
};
export default DateField;
