import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import { TabConfiguration } from 'pages/Warehouse/Warehouse/FormComponents/Places';

export type Warehouse = CTMRecord & {
  id?: string;
  name?: string;
  type?: object;
};

const columns: CTMListColumn<Warehouse>[] = [
  {
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    filterable: true,
    sortable: true,
  },
  {
    id: 'name',
    Header: 'Nazwa',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    id: 'symbol',
    Header: 'Symbol',
    accessor: 'symbol',
    filterable: true,
    sortable: true,
  },
];

const module: CTMModule<Warehouse> = {
  id: '83360f0b-00ee-40fa-a811-e5ed7a7fc241',
  dataClass: 'CTM\\Warehouse\\Entity\\Warehouse\\Warehouse',
  urlPrefix: 'warehouse-warehouse',
  name: 'Magazyny',
  role: 'WAREHOUSE_WAREHOUSE',
  api: {
    item: {
      get: ({ id }) => `/warehouse/warehouses/${id}`,
      put: ({ id }) => `/warehouse/warehouses/${id}`,
      delete: ({ id }) => `/warehouse/warehouses/${id}`,
    },
    collection: {
      get: `/warehouse/warehouses`,
      post: `/warehouse/warehouses`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    prepareRecordToSave: (record: Warehouse) => {
      return {
        ...record,
        type: record.type?.['@id'] ?? record.type ?? null,
      };
    },
    customTabs: [TabConfiguration],
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
