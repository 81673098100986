import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import IconPicker from 'components/CustomFields/FieldTypes/IconPicker';

export type Workplace = CTMRecord & {
  name?: string;
  symbol?: string;
};

const columns: CTMListColumn<Workplace>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: row => (
      <>
        <span className="badge text-white font-size-12" style={{ padding: 6, background: `${row.color || '#edf0f5'}` }}>
          <i className={`mdi mdi-${row?.icon}`} />
        </span>
        &nbsp;{row.name}
      </>
    ),
  },
  {
    id: 'symbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol',
    accessor: 'symbol',
  },
];

const module: CTMModule<Workplace> = {
  id: 'fe6cf1d2-096a-4257-87c2-b2ada1fec1c9',
  dataClass: 'CTM\\Manufacture\\Entity\\Workplace',
  urlPrefix: 'manufacture-workplaces',
  name: 'Stanowiska',
  role: 'MANUFACTURE_WORKPLACE',
  api: {
    item: {
      get: ({ id }) => `/manufacture/workplaces/${id}`,
      put: ({ id }) => `/manufacture/workplaces/${id}`,
      delete: ({ id }) => `/manufacture/workplaces/${id}`,
    },
    collection: {
      get: `/manufacture/workplaces`,
      post: `/manufacture/workplaces`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {
      icon: IconPicker,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
