import InputAdditionalAction from '../../Form/InputAdditionalAction';
import LabelerId from '../LabelerId';
import { CustomFieldComponentInnerProps } from '@Components/CustomFields/CustomField';
import LanguagePicker from 'Modules/Core/Components/LanguagePicker';
import BaseInput from 'components/Form/BaseInput';
import { FC } from 'react';

export type BaseScalarInputType = FC<
  CustomFieldComponentInnerProps<false> & { type?: string; inputProps?: any; afterInputContent?: any; nextRowContent?: any }
>;

const BaseScalarInput: BaseScalarInputType = ({
  field,
  value,
  onChange,
  type,
  inputProps = {},
  readonly,
  errorKey,
  customProps = {},
  ...rest
}) => {
  const isReadonly = readonly || field.config.readonly;

  return (
    <>
      <BaseInput
        {...rest}
        label={
          <div style={{ display: 'flex', position: 'relative', width: '100%', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', position: 'relative' }}>
              <div>{field.multiLanguage && <LanguagePicker isReadonly={isReadonly} />}</div>
              <div>
                <LabelerId labelerId={field.labelerId} />
                {field.name || 'Nazwa pola'}
              </div>
            </div>

            <div>
              <InputAdditionalAction value={value} onChange={value => onChange(value, field.id)} readonly={isReadonly} fieldId={field.id} />
            </div>
          </div>
        }
        value={value}
        name={field.id}
        type={type}
        onChange={onChange}
        inputProps={{ ...inputProps, tabIndex: field._tabindex }}
        disabled={isReadonly}
        errorKey={errorKey}
        disableGroupMargin={true}
        afterInputContent={rest?.afterInputContent}
        {...customProps}
      />
    </>
  );
};

export default BaseScalarInput;
