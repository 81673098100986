import { TemplateModule } from '../../CTMModules';
import Button from '@mui/material/Button';
import SelectInput from 'components/Form/MUI/SelectInput';
import TextInput from 'components/Form/MUI/TextInput';
import { Col, Container } from 'components/Theme/Grid';
import { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

export function NewTemplateModal(props) {
  const { children, templates, moduleId, afterSave } = props;
  const [opened, setOpened] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [copyFromTemplate, setCopyFromTemplate] = useState<string | null>(null);

  const handleSave = () => {
    const data = { name, copyFromTemplate, module: `/custom-fields/modules/${moduleId}` };

    TemplateModule.api.post(data).then(res => {
      afterSave(res);
      setOpened(false);
    });
  };

  return (
    <>
      <div onClick={() => setOpened(true)}>{children}</div>
      {opened && (
        <Modal isOpen={opened} centered={true} backdrop={true} toggle={() => false} size="md">
          <ModalBody>
            <Container>
              <Col xs={12}>
                <h4 style={{ textAlign: 'center' }}>Nowy szablon</h4>
              </Col>
              <Col xs={12}>
                <TextInput label={'Nazwa szablonu'} value={name} onChange={val => setName(val ?? '')} placeholder="Nazwa szablonu" />
              </Col>
              <Col xs={12}>
                <SelectInput
                  name="type"
                  label="Szablon do skopiowania (pozostaw pusty aby zbudować od zera)"
                  value={copyFromTemplate}
                  options={templates}
                  labelKey="name"
                  trackKey="@id"
                  multiple={false}
                  onChange={val => setCopyFromTemplate(val === null || typeof val === 'string' ? val : null)}
                />
              </Col>
              <Col xs={12} display="flex" justifyContent="space-between">
                <Button variant="contained" color="info" onClick={() => setOpened(false)}>
                  Anuluj
                </Button>
                <Button variant="contained" color="success" onClick={() => handleSave()}>
                  Dodaj
                </Button>
              </Col>
            </Container>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}

export default NewTemplateModal;
