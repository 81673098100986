import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import FormatField from 'pages/Warehouse/DocumentNumber/FormComponents/FormatField';

export type DocumentNumber = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<DocumentNumber>[] = [
  {
    id: 'name',
    Header: 'Nazwa',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    id: 'format',
    Header: 'Format',
    accessor: 'format',
    filterable: true,
    sortable: true,
  },
  {
    id: 'type',
    Header: 'Typ dokumentu',
    accessor: 'type.name',
    filterable: false,
    sortable: false,
  },
];

const module: CTMModule<DocumentNumber> = {
  id: '7c9265be-8a1d-4765-9175-a32362771861',
  dataClass: 'CTM\\Warehouse\\Entity\\Document\\DocumentNumber',
  urlPrefix: 'warehouse-document-numbers',
  name: 'Numeracja',
  role: 'WAREHOUSE_DOCUMENT',
  api: {
    item: {
      get: ({ id }) => `/warehouse/document-numbers/${id}`,
      put: ({ id }) => `/warehouse/document-numbers/${id}`,
      delete: ({ id }) => `/warehouse/document-numbers/${id}`,
    },
    collection: {
      get: `/warehouse/document-numbers`,
      post: `/warehouse/document-numbers`,
      documentNumberParameters: '/warehouse/document-number-parameters',
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {
      format: FormatField,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
