import { CustomFieldComponentType, IndexedField } from '@Components/CustomFields/CustomField';
import StatusPicker from '@Ecommerce/Components/StatusPicker';
import { BaseLinkerIntegrationStatusMapping } from '@Integration/BaseLinkerIntegration';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface StatusesMappingsRowProps {
  field: IndexedField;
  mapping: BaseLinkerIntegrationStatusMapping;
  readonly: boolean;
  onChange: (mapping: BaseLinkerIntegrationStatusMapping) => void;
}

const StatusesMappingsRow: FC<StatusesMappingsRowProps> = props => {
  return (
    <TableRow>
      <TableCell>{props.mapping.baseLinkerStatusData?.id}</TableCell>
      <TableCell
        style={{
          textAlign: 'center',
          backgroundColor: props.mapping.baseLinkerStatusData?.color ?? '#fff',
          textShadow: '0px 0px 10px white',
        }}
      >
        {props.mapping.baseLinkerStatusData?.name}
      </TableCell>
      <TableCell>{props.mapping.baseLinkerStatusData?.nameForCustomer}</TableCell>
      <TableCell>
        <StatusPicker
          showLabel={false}
          field={props.field}
          fields={[]}
          value={props.mapping.status}
          onChange={val => props.onChange({ ...props.mapping, status: typeof val === 'number' ? val : null })}
          readonly={false}
          errorKey={'_'}
        />
      </TableCell>
    </TableRow>
  );
};

const StatusesMappings: CustomFieldComponentType = ({ field, value, onChange, readonly }) => {
  const [mappings, setMappings] = useState<{ [key: number]: BaseLinkerIntegrationStatusMapping }>(value ?? {});

  useEffect(() => {
    if (JSON.stringify(mappings) !== JSON.stringify(value)) {
      onChange(mappings, field.id);
    }
  }, [JSON.stringify(mappings)]);

  return (
    <Table style={{ minWidth: 640 }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 120 }}>ID Statusu</TableCell>
          <TableCell style={{ width: 300, textAlign: 'center' }}>Nazwa</TableCell>
          <TableCell style={{ width: 300 }}>Nazwa dla klienta</TableCell>
          <TableCell>Status ECM</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(mappings).map(itemKey => (
          <StatusesMappingsRow
            field={field}
            key={itemKey}
            mapping={mappings[itemKey]}
            readonly={readonly}
            onChange={mapping => setMappings({ ...mappings, [itemKey]: mapping })}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default StatusesMappings;
