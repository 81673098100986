import { ProcurementOrderModule } from '@Modules/CTMModules';
import { ProcurementOrder as ProcurementOrderModel } from '@Warehouse/ProcurementOrder';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ProductionDemandOrder: FC<{ order: string | null | undefined }> = ({ order }) => {
  const id = order ? order.split('/').at(-1) : null;

  const [currentOrder, setCurrentOrder] = useState<ProcurementOrderModel | null>(null);

  const fetch = () => {
    if (!id) {
      return;
    }

    ProcurementOrderModule.api.get({ id: id }).then(result => {
      setCurrentOrder(result);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  if (!currentOrder) {
    return <>Brak</>;
  }

  return (
    <Link target={'_blank'} to={ProcurementOrderModule.editUrl(currentOrder.id ?? '')}>
      ZDD {currentOrder.labelerId}
    </Link>
  );
};

export default ProductionDemandOrder;
