import { Note } from 'Modules/Note/Model';
import { post } from 'helpers/Axios';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

export default function CreateNote() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const createNoteMutation = useMutation<Note>({
    mutationFn: () => post('/notes'),
    onSuccess: data => {
      void queryClient.invalidateQueries('/notes');
      history.replace('/notes/' + data.id);
    },
    retry: false,
  });

  !createNoteMutation.isLoading && !createNoteMutation.isSuccess && !createNoteMutation.isError && createNoteMutation.mutate();

  if (createNoteMutation.isLoading) {
    return <>Właśnie tworzymy twoją notatkę...</>;
  }

  if (createNoteMutation.isError) {
    return <>Wystąpił błąd podczas tworzenia twojej notatki. :(</>;
  }

  return <></>;
}
