import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { BoxModule, ProductModule } from 'Modules/CTMModules';
import { CTMRecord } from 'Modules/Core/Types/CTMModule';
import ImagesFastSlider from 'components/Image/ImagesFastSlider';
import LightboxWrapper from 'components/Lightbox/LightboxWrapper';
import RecordInputReader from 'components/Module/RecordInputReader';

export interface BoxProps {
  box: CTMRecord;
  completedImage: string;
  products: any[];
  stepByStepImages: string[];
}

const GalleryMediaHeader = ({ images, completed }: { images: string[]; completed: string }) => {
  return (
    <LightboxWrapper images={images}>
      <ImagesFastSlider images={images} className={'packing-instruction'} />
    </LightboxWrapper>
  );
};

export default function Box(props: BoxProps) {
  return (
    <Card className={'card-instructions'}>
      <CardHeader title={<RecordInputReader readonly={true} value={props.box} module={BoxModule} label={'Opakowanie'} />} />
      <GalleryMediaHeader images={props.stepByStepImages} completed={props.completedImage} />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Produkt</TableCell>
              <TableCell>Ilość</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.products.map((product, index) => (
              <TableRow key={index}>
                <TableCell>
                  <RecordInputReader readonly={true} value={product.product} module={ProductModule} label={'Produkt'} />
                </TableCell>
                <TableCell>{product.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
