// @ts-nocheck
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, FormText, InputGroup, Label, Row } from 'reactstrap';
import { useAppSelector } from 'store';

export const DEFAULT_KEYBOARDS = {
  'custom-ctm': {
    label: '123',
    tooltip: 'Podstawowa klawiatura',
    layer: 'custom-ctm',
  },
};

export const DEFAULT_KEYBOARD_LAYERS = {
  'custom-ctm': {
    styles: '',
    rows: [
      [
        { class: 'separator w5' },
        { class: 'separator w5' },
        { class: 'separator w5' },
        { label: '7', key: '7' },
        { label: '8', key: '8' },
        { label: '9', key: '9' },
        { latex: '\\div' },
        { class: 'separator w5' },
        {
          class: 'tex small',
          label: '<span><i>x</i>&thinsp;²</span>',
          insert: '$$#@^{2}$$',
        },
        { class: 'separator w5' },
        { class: 'separator w5' },
        {
          class: 'small',
          latex: '\\sqrt{#0}',
          insert: '$$\\sqrt{#0}$$',
        },
      ],
      [
        { class: 'separator w5' },
        { class: 'separator w5' },
        { class: 'separator w5' },
        { label: '4', latex: '4' },
        { label: '5', key: '5' },
        { label: '6', key: '6' },
        { latex: '\\times' },
        { class: 'separator w5' },
        { latex: '(' },
        { latex: ')' },
        { class: 'small', latex: '\\frac{#0}{#0}' },
      ],
      [
        { class: 'separator w5' },
        { class: 'separator w5' },
        { class: 'separator w5' },
        { label: '1', key: '1' },
        { label: '2', key: '2' },
        { label: '3', key: '3' },
        { latex: '-' },
        { class: 'separator w5' },
        { class: 'separator' },
        { class: 'separator' },
        { class: 'separator' },
      ],
      [
        { class: 'separator w5' },
        { class: 'separator w5' },
        { class: 'separator w5' },
        { label: '0', key: '0' },
        { latex: '.' },
        { class: 'separator w5' },
        { class: 'separator w5' },
        { latex: '+' },
        { class: 'separator w5' },
        {
          class: 'action',
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ['performWithFeedback', 'moveToPreviousChar'],
        },
        {
          class: 'action',
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ['performWithFeedback', 'moveToNextChar'],
        },
        {
          class: 'action font-glyph bottom right',
          label: '&#x232b;',
          command: ['performWithFeedback', 'deleteBackward'],
        },
      ],
    ],
  },
};

const DEFAULT_STYLES: React.CSSProperties = {
  fontSize: '1.2em',
  border: '1px solid rgba(0, 0, 0, .3)',
  verticalAlign: 'middle',
  padding: '5px',
  minWidth: '300px',
};

interface MathFieldProps {
  className?: string;
  value?: string;
  name?: string;
  id?: string;
  helpText?: string;
  label?: string | null;
  size?: { md: number };
  onChange?: (value: string, name?: string, additionalData?: any) => void;
  beforeInputContent?: React.ReactNode;
  afterInputContent?: React.ReactNode;
  disabled?: boolean;
  disableGroupMargin?: boolean;
  labelBadge?: string;
  errorKey?: string;
  fullHeight?: boolean;
  fullInputHeight?: boolean;
  verticalCenter?: boolean;
  mathFieldStyles?: React.CSSProperties;
  mathFieldProps?: Record<string, any>;
  keyboards?: typeof DEFAULT_KEYBOARDS;
  keyboardLayers?: typeof DEFAULT_KEYBOARD_LAYERS;
  virtualKeyboards?: string;
}

const MathField: React.FC<MathFieldProps> = ({
  className = 'mb-3',
  value,
  name = undefined,
  id = undefined,
  helpText = undefined,
  label = null,
  size = { md: 12 },
  onChange = () => undefined,
  beforeInputContent = undefined,
  afterInputContent = undefined,
  disabled = undefined,
  disableGroupMargin = undefined,
  labelBadge = undefined,
  errorKey = undefined,
  fullHeight = undefined,
  fullInputHeight = undefined,
  verticalCenter = undefined,
  mathFieldStyles = DEFAULT_STYLES,
  mathFieldProps = {},
  keyboards = DEFAULT_KEYBOARDS,
  keyboardLayers = DEFAULT_KEYBOARD_LAYERS,
  virtualKeyboards = 'custom-ctm',
}) => {
  const [controlledState, setControlledState] = useState<string | undefined>(value);
  const { t } = useTranslation();
  const mathFieldRef = useRef<HTMLElement | null>(null);
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));

  useEffect(() => {
    if (mathFieldRef.current) {
      mathFieldRef.current.setOptions({
        macros: {
          ...(mathFieldRef.current.getOptions('macros') || {}),
        },
        customVirtualKeyboardLayers: keyboardLayers,
        customVirtualKeyboards: keyboards,
      });

      mathFieldRef.current.addEventListener('input', (ev: Event) => {
        const target = ev.target as HTMLElement & { value: string };
        setControlledState(target.value);
        onChange(target.value, name, {
          asciiMath: mathFieldRef.current?.getValue('ascii-math'),
          latexExpanded: mathFieldRef.current?.getValue('latex-expanded'),
          latexUnstyled: mathFieldRef.current?.getValue('latex-unstyled'),
          mathJson: mathFieldRef.current?.getValue('math-json'),
        });
      });
    }
  }, [keyboardLayers, keyboards, onChange]);

  useEffect(() => {
    if (value !== controlledState) {
      setControlledState(value);
    }
  }, [value]);

  const hasError = (): boolean => {
    return violations.hasOwnProperty(errorKey ?? name);
  };

  const getError = (): string => {
    if (hasError()) {
      return violations[errorKey ?? name].join('\n');
    }
    return '';
  };

  return (
    <Col {...size} className={className} style={fullHeight ? { height: '100%' } : {}}>
      <Row className={classnames('', { 'h-100': fullHeight })}>
        <FormGroup className={classnames({ 'disable-group-margin': disableGroupMargin })}>
          {label && label !== '' && <Label>{t(label)}</Label>}
          <InputGroup
            style={{
              ...(verticalCenter ? { top: '50%', transform: 'translateY(-50%)' } : {}),
              ...(fullInputHeight ? { height: 'calc(100% - 34px)' } : {}),
            }}
          >
            {beforeInputContent}
            <math-field
              ref={mathFieldRef}
              id={id ?? 'math_id'}
              style={{ ...DEFAULT_STYLES, ...mathFieldStyles }}
              virtual-keyboard-mode={'onfocus'}
              virtual-keyboards={virtualKeyboards}
              locale={'PL_pl'}
              disabled={disabled}
              {...mathFieldProps}
            >
              {value}
            </math-field>
            {afterInputContent}
            {helpText && <FormText>{helpText}</FormText>}
            {hasError() && <FormFeedback>{getError()}</FormFeedback>}
          </InputGroup>
        </FormGroup>
      </Row>
    </Col>
  );
};

export default MathField;
