import { Box, LinearProgress } from '@mui/material';
import { FC } from 'react';

export type CharsCounterProps = {
  value: string;
  maxChars: number;
};

const CharsCounter: FC<CharsCounterProps> = props => {
  if (props.maxChars < 1) {
    return null;
  }
  const chars = props.value.length;
  const progress = Math.round((chars * 100) / props.maxChars);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%', paddingRight: 20 }}>
        <Box>
          <LinearProgress variant="determinate" value={Math.min(100, progress)} color={progress > 100 ? 'error' : 'primary'} />
        </Box>
      </div>
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          marginTop: -10,
          border: '1px solid #dedede',
          background: '#fff',
          marginRight: 20,
          borderRadius: 10,
          zIndex: 100,
        }}
      >
        {chars}/{props.maxChars}
      </div>
    </div>
  );
};

export default CharsCounter;
