import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Button, Tooltip } from '@mui/material';
import TextareaField from 'components/CustomFields/FieldTypes/TextareaField';
import { axiosApi } from 'helpers/Axios';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';

type ContentField = {
  field: any;
  fields: any;
  value: any;
  values: any[];
  onUpdate: any;
  readonly: any;
  components: any;
  size: any;
  customProps: any;
  recordId: any;
};

type Variable = {
  name: string;
  number: boolean;
  label: string;
};

const ContentField: CustomFieldComponentType = props => {
  const dispatch = useDispatch();
  const { values, fields } = props;
  const [variables, setVariables] = useState<Variable[]>([]);

  const id = useMemo(() => {
    return (
      fields.find(item => {
        return item.propertyPath === 'type';
      })?.id ?? ''
    );
  }, [fields]);

  const copyHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const button: HTMLButtonElement = event.currentTarget;
    navigator.clipboard.writeText(button.innerText);
    dispatch(
      addSingleToast({
        title: `Skopiowano do schowka. Wklej w odpowiednie miejsce.`,
        config: { appearance: 'success' },
      }),
    );
  };

  useEffect(() => {
    if (!values?.[id]?.id) {
      return;
    }
    axiosApi.get(`manufacture/label-types/${values[id].id}`).then(result => {
      setVariables(result.data.fields);
    });
  }, [id, values]);

  return (
    <>
      <TextareaField {...props} />
      {variables.length > 0 && (
        <>
          <h5>Dostępne pola</h5>
          <div className={'mb-4'}>
            {variables.map(function (field) {
              return (
                <Tooltip title={field.label} key={field.name}>
                  <Button
                    style={{ textTransform: 'none' }}
                    size="small"
                    variant="contained"
                    className={'ml-1 mr-1 mb-1 mt-1'}
                    onClick={copyHandler}
                  >
                    &#123;&#123; {field.name} &#125;&#125;
                  </Button>
                </Tooltip>
              );
            })}
          </div>
          <h5>Generowanie barcode</h5>
          <div className={'mb-4'}>
            {variables.map(function (field) {
              return (
                field.number && (
                  <Tooltip title={field.label} key={field.name}>
                    <Button
                      style={{ textTransform: 'none' }}
                      size="small"
                      variant="contained"
                      key={field.name}
                      className={'ml-1 mr-1 mb-1 mt-1'}
                      onClick={copyHandler}
                    >
                      &#123;&#123; {field.name} | barcode &#125;&#125;
                    </Button>
                  </Tooltip>
                )
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default ContentField;
