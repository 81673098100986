import logoLightSmallPng from 'assets/images/SF_30x30px_png.png';
import logoDark from 'assets/images/logo-dark.png';
import logoLightPng from 'assets/images/logo-light.png';
import logo from 'assets/images/logo.svg';
import SidebarContent from 'components/Theme/VerticalLayout/SidebarContent';
import PropTypes from 'prop-types';
import React from 'react';
//i18n
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

const Sidebar = () => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSmallPng} alt="" height="22" className="img-fluid" style={{ filter: 'contrast(0)' }} />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="19" style={{ filter: 'contrast(0)' }} />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)));
