import ProductCollectionRow, { DefaultProductRow } from './ProductCollectionRow';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Table, TableBody } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const cellStyles = { padding: 5 };
const ProductCollectionRows: CustomFieldComponentType = ({ field, fields, value, values, onChange, readonly }) => {
  const dispatch = useDispatch();
  const [positions, setPositions] = useState<any>(value ?? []);
  useEffect(() => {
    if (JSON.stringify(positions) !== JSON.stringify(value)) {
      console.log(positions);
      onChange(positions, field.id);
    }
  }, [JSON.stringify(positions)]);

  const customFields = useMemo(() => {
    const variantFieldsId = fields.find(el => el.propertyPath === 'variantFields')?.id as string;

    if (!variantFieldsId) {
      return [];
    }

    return values?.[variantFieldsId] ?? [];
  }, [fields, values]);

  const onUpdateItem = useCallback((index, newItem) => {
    setPositions(prevPositions => {
      const newItems = [...prevPositions];
      newItems[index] = newItem;

      return prevPositions.map((el, elIndex) => {
        if (index === elIndex) {
          return { ...newItem, seq: newItem.seq ?? prevPositions.length };
        }

        return el;
      });
    });
  }, []);
  const onRemove = useCallback(index => {
    setPositions(prevPositions => prevPositions.filter((r, i) => i !== index));
  }, []);

  const onNewItem = (index, newItems) => {
    if (newItems.hasOwnProperty('product')) {
      newItems = [newItems];
    }

    const validatedItems = newItems.filter(el => el.product);
    setPositions(prevPositions => {
      const currentProducts = prevPositions.map(el => el.product?.['@id']);
      const preparedNewPositions: any[] = [];
      validatedItems.forEach(newItem => {
        if (!currentProducts.includes(newItem.product?.['@id'])) {
          preparedNewPositions.push({ _key: uuidv4(), seq: prevPositions.length, ...newItem });
        }
      });
      return [...prevPositions, ...preparedNewPositions];
    });
  };

  const moveItem = (index, newIndex) => {
    setPositions(prevPositions => {
      const newItems = [...prevPositions];
      const itemToMove = newItems[index];
      newItems.splice(index, 1);
      newItems.splice(newIndex, 0, itemToMove);
      return newItems.map((item, i) => ({ ...item, seq: i + 1 }));
    });
  };

  const moveUp = index => {
    if (index > 0) {
      moveItem(index, index - 1);
    }
  };

  const moveDown = index => {
    if (index < positions.length - 1) {
      moveItem(index, index + 1);
    }
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table style={{ minWidth: 640 }}>
        <TableBody>
          {positions.map((item, key) => (
            <ProductCollectionRow
              key={item['@id'] ?? item._key ?? `${key}${item.product?.id}`}
              index={key}
              item={item}
              readonly={readonly || item.locked}
              onChange={onUpdateItem}
              onRemove={onRemove}
              onUp={moveUp}
              onDown={moveDown}
              cellStyles={cellStyles}
              customFields={customFields}
              selectedProducts={[]}
              newRow={false}
            />
          ))}
          {!readonly && (
            <ProductCollectionRow
              newRow={true}
              key={positions.length + 1}
              item={{ ...DefaultProductRow }}
              onChange={onNewItem}
              cellStyles={cellStyles}
              customFields={[]}
              selectedProducts={positions.map(el => el.product?.['@id'])}
              readonly={false}
              onRemove={console.log}
              index={-1}
              onUp={console.log}
              onDown={console.log}
            />
          )}
        </TableBody>
      </Table>
    </div>
  );
};

ProductCollectionRows.provideAllValues = true;
export default ProductCollectionRows;
