import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import NumberField from 'components/CustomFields/FieldTypes/NumberField';
import { useEffect } from 'react';

const TotalPriceNetto: CustomFieldComponentType = ({ field, fields, values, value, onChange, errorKey }) => {
  const rowsFieldId = fields.find(el => el.propertyPath === 'rows')?.id as string;
  useEffect(() => {
    const val =
      (values?.[rowsFieldId] ?? []).map(el => (el.quantity ?? 1) * (el.unitPriceGross - el.unitPriceNet)).reduce((p, c) => c + p, 0) / 100;

    if (val !== value) {
      onChange(val, field.id);
    }
  }, [values]);

  return (
    <>
      <NumberField field={field} readonly={true} errorKey={errorKey} value={value} fields={[]} onChange={console.log} />
    </>
  );
};
TotalPriceNetto.provideAllValues = true;
export default TotalPriceNetto;
