import Products from './Components/Contractor/Products';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import { TinLoader } from 'components/Contractor/TinLoader';

export type Contractor = CTMRecord & {
  name?: string;
};

export const TARIFF_TYPE_CONSTANT = 'CONSTANT';
export const TARIFF_TYPE_QUANTITY = 'QUANTITY';

const columns: CTMListColumn<Contractor>[] = [
  {
    id: 'shortName',
    Header: 'Identyfikator',
    accessor: 'shortName',
    filterable: true,
    sortable: true,
  },
  {
    id: 'name',
    Header: 'Nazwa',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    id: 'tin.number',
    Header: 'NIP',
    accessor: row => {
      return (row.tin.prefix ? row.tin.prefix : '') + (row.tin.number ? row.tin.number : '-');
    },
    filterable: true,
    sortable: true,
  },
  {
    id: 'address',
    Header: 'Adres',
    accessor: row => {
      return (
        (row.address.street ? row.address.street : '-') +
        ' ' +
        (row.address.house ? row.address.house : '-') +
        (row.address.flat ? '/' + row.address.flat : '') +
        ', ' +
        (row.address.post ? row.address.post : '-') +
        ' ' +
        (row.address.city ? row.address.city : '-')
      );
    },
  },
  {
    id: 'contact.firstPhone',
    Header: 'Telefon',
    accessor: 'contact.firstPhone',
    filterable: true,
    sortable: true,
  },
  {
    id: 'contact.firstMail',
    Header: 'Email',
    accessor: 'contact.firstMail',
    filterable: true,
    sortable: true,
  },
];

export type CTMModuleContractor = CTMModule<Contractor, { getTin: (tin: string) => string }>;

const module: CTMModuleContractor = {
  id: 'a262b0bc-1d4f-11ed-861d-0242ac120002',
  dataClass: 'CTM\\Contractor\\Entity\\Contractor',
  urlPrefix: 'contractor-contractor',
  name: 'Kontrahenci',
  role: 'CONTRACTOR_CONTRACTOR',
  api: {
    item: {
      get: ({ id }) => `/contractor/contractors/${id}`,
      put: ({ id }) => `/contractor/contractors/${id}`,
      delete: ({ id }) => `/contractor/contractors/${id}`,
      getTin: tin => `/contractor/contractors/${tin}/report`,
    },
    collection: {
      get: `/contractor/contractors`,
      post: `/contractor/contractors`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {
      products: Products,
    },
    contextActions: ({ readonly, ApiFormComponent }) => (
      <>
        {!readonly && ApiFormComponent?.current && (
          <TinLoader
            onLoaded={data => {
              ApiFormComponent.current.overrideValues([
                { field: 'name', value: data.name },
                { field: 'shortName', value: data.name.replaceAll(' ', '-').toUpperCase() },
                { field: 'tin.prefix', value: 'PL' },
                { field: 'tin.number', value: data.tin },
                { field: 'address.street', value: data.street },
                { field: 'address.house', value: data.house },
                { field: 'address.flat', value: data.flat },
                { field: 'address.post', value: data.post },
                { field: 'address.city', value: data.city },
                { field: 'address.country', value: 'PL' },
              ]);
            }}
          />
        )}
      </>
    ),
    prepareRecordToSave: record => ({
      ...record,
      'accountancy.tradeCredit': record['accountancy.tradeCredit'] * 100,
      'accountancy.documentTradeCredit': record['accountancy.documentTradeCredit'] * 100,
      tariff: record.tariff?.['@id'] ?? record.tariff,
      valueAddedTax: record.valueAddedTax?.['@id'] ?? record.valueAddedTax,
    }),
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
