import { ProductionDemand as ProductionDemandModel } from '@Manufacture/ProductionDemand';
import { ProductionDemandModule } from '@Modules/CTMModules';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ProcurementOrderSource: FC<{ id: string }> = ({ id }) => {
  const [currentSource, setCurrentSource] = useState<ProductionDemandModel | null>(null);

  const fetch = () => {
    if (!id) {
      return;
    }

    ProductionDemandModule.api.getAll({ params: { order: '/warehouse/procurement-orders/' + id } }).then(result => {
      setCurrentSource(result['hydra:member'][0]);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  if (!currentSource) {
    return <>Brak</>;
  }

  return (
    <Link target={'_blank'} to={ProductionDemandModule.editUrl(currentSource.id ?? '')}>
      {currentSource.number}
    </Link>
  );
};

export default ProcurementOrderSource;
