import BaseScalarInput, { BaseScalarInputType } from 'components/CustomFields/FieldTypes/BaseScalarInput';
import ListPicker from 'components/Form/ListPicker';
import { InputGroupText } from 'reactstrap';

const StringField: BaseScalarInputType = props => {
  const isReadonly = props.readonly || props.field.config.readonly;

  if (
    props.field.config.predefinedOptions &&
    typeof props.field.config.predefinedOptions === 'string' &&
    props.field.config.predefinedOptions.split(',').length > 0
  ) {
    const options = props.field.config.predefinedOptions
      .split(',')
      .map(el => ({ val: parseFloat(el) }))
      .filter(x => !isNaN(x.val));
    return (
      <ListPicker
        name={props.field.id}
        label={props.field.name}
        tabIndex={props.field._tabindex}
        value={props.value || props.field.defaultValue}
        options={options}
        optionLabel="val"
        optionTrackBy="val"
        onChange={props.onChange}
        disabled={isReadonly}
        errorKey={props.errorKey}
      />
    );
  }

  return (
    <BaseScalarInput
      {...props}
      readonly={isReadonly}
      type="number"
      onChange={(value, field) => props.onChange(isNaN(parseFloat(value)) ? null : parseFloat(value), field)}
      afterInputContent={
        props.afterInputContent ??
        (props.field.config.suffix && props.field.config.suffix !== '' ? (
          <InputGroupText>{props.field.config.suffix}</InputGroupText>
        ) : null)
      }
    />
  );
};

export default StringField;
