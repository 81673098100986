import useFormError from '../useFormError';
import { BaseInputProps } from './Types';
import TextField from '@mui/material/TextField';
import { CSSProperties, useEffect, useState } from 'react';

interface NumberInputProps extends BaseInputProps {
  value?: null | number;
  className?: string;
  style?: CSSProperties;
  onChange?: (value: number | null, name: undefined | string) => void;
  inputProps?: any;
  namePrefix?: string;
  forcedErrorMessage?: string;
}

const NumberInput = ({
  value,
  label,
  onChange,
  name,
  disabled,
  style,
  className,
  inputProps,
  namePrefix,
  forcedErrorMessage,
}: NumberInputProps) => {
  const [hasErrors, errorMessage] = useFormError((namePrefix ? namePrefix + '.' : '') + (name ?? ''));
  const containsError = (forcedErrorMessage ?? '').length > 0 || hasErrors;
  const [waitingForChange, setWaitingForChange] = useState(false);
  const [timeoutId, setTimeoutId] = useState<any>(null);

  const [controlledVal, setControlledVal] = useState<any>(value);

  useEffect(() => {
    if (value === null || value === undefined) {
      setControlledVal('');
      return;
    }
    if (
      (isNaN(parseFloat(controlledVal)) ? null : parseFloat(controlledVal)) !==
      (isNaN(parseFloat(value + '')) ? null : parseFloat(value + ''))
    ) {
      setControlledVal(value);
    }
  }, [value]);

  useEffect(() => {
    setWaitingForChange(true);
  }, [controlledVal]);

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (waitingForChange) {
      setTimeoutId(
        setTimeout(() => {
          setWaitingForChange(false);
          emmitChange();
        }, 500),
      );
    }
  }, [waitingForChange, controlledVal]);

  const emmitChange = () => {
    if (waitingForChange && onChange) {
      setWaitingForChange(false);
      if (
        (isNaN(parseFloat(controlledVal)) ? null : parseFloat(controlledVal)) !==
        (isNaN(parseFloat(value + '')) ? null : parseFloat(value + ''))
      ) {
        onChange(isNaN(parseFloat(controlledVal)) ? null : parseFloat(controlledVal), name);
      }
    } else {
      console.warn('Text component not implement onChange callback');
    }
  };

  const onBlur = () => {
    emmitChange();
    setWaitingForChange(false);
  };

  return (
    <TextField
      className={className}
      label={label}
      name={name}
      disabled={disabled}
      InputProps={inputProps}
      value={controlledVal ?? ''}
      type={'number'}
      helperText={hasErrors ? errorMessage : forcedErrorMessage}
      error={containsError}
      // defaultValue={value}
      onChange={({ target: { value: v } }) => {
        setControlledVal(v);
      }}
      onBlur={onBlur}
      variant="outlined"
      style={style}
      InputLabelProps={{ shrink: !(value === null || value === undefined) }}
    />
  );
};

export default NumberInput;
