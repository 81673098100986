import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import RelationSinglePicker from 'components/Form/RelationSinglePicker';
import SelectSingleAPI from 'components/Form/SelectSingleAPI';
import { useModuleContext } from 'context/ModulesContext';

const RelationField: CustomFieldComponentType = props => {
  const { field, value, onChange, readonly, errorKey, customProps = {} } = props;
  const moduleContext = useModuleContext(field.config.targetModule, 'dataClass');

  if (moduleContext) {
    return (
      <RelationSinglePicker
        moduleName={moduleContext.configuration.urlPrefix}
        size={{ md: 12 }}
        label={field.name}
        value={value}
        name={field.id}
        tabIndex={field._tabindex}
        onChange={(iri, id, obj) => {
          onChange(obj, id);
        }}
        optionLabel="relationFieldLabel"
        disabled={readonly || field.config.readonly}
        errorKey={errorKey}
        helpText={field.config?.helpText}
        labelerId={field.labelerId}
        {...customProps}
      />
    );
  }

  return (
    <>
      <SelectSingleAPI
        size={{ md: 12 }}
        label={field.name}
        value={value}
        name={field.id}
        tabIndex={field._tabindex}
        onChange={(iri, id, obj) => {
          onChange(obj, id);
        }}
        endpoint={(field.config.searchUrl ?? '/') + '?relationFieldLabel'}
        buildQuery={v => ({ searchRelationFieldLabel: v })}
        optionLabel="relationFieldLabel"
        disabled={readonly || field.config.readonly}
        errorKey={errorKey}
        labelerId={field.labelerId}
        {...customProps}
      />
    </>
  );
};

export default RelationField;
