import { Task } from '../../Types/Task';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ProductModule } from 'Modules/CTMModules';
import { Product } from 'Modules/Manufacture/Types/Product';
import { FC, useEffect, useState } from 'react';

const TaskModalColumnIngredients: FC<{ task: Task }> = ({ task }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<null | Product>(null);

  useEffect(() => {
    if (open && task.product?.id) {
      setLoading(true);
      ProductModule.api.get({ id: task.product?.id }).then(res => {
        setProduct(res);
        setLoading(false);
      });
    } else {
      setLoading(false);
      setProduct(null);
    }
  }, [open, task.product?.id]);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Składniki" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        {loading && (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress size={16} />
          </div>
        )}
        {!loading && (
          <List component="div" disablePadding>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                    Ilość
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Towar</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>Na szt.</TableCell>
                  <TableCell style={{ textAlign: 'left' }}>Łącznie</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(product?.requiredProducts ?? []).map((requiredProduct, i) => (
                  <TableRow key={i}>
                    <TableCell>{requiredProduct.product?.name}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{requiredProduct.quantity}</TableCell>
                    <TableCell style={{ textAlign: 'left' }}>{(requiredProduct.quantity ?? 0) * task.amountToMake}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </List>
        )}
      </Collapse>
    </>
  );
};
export default TaskModalColumnIngredients;
