import StatusView from '../Core/Components/StatusView';
import ProductionDemandRows from './Components/ProductionDemand/ProductionDemandRows';
import ProductionOrderQuantity from './Components/ProductionOrder/ProductionOrderQuantity';
import ProductionDemandOrder from '@Manufacture/Components/ProductionDemand/ProductionDemandOrder';
import ProductType from '@Manufacture/ProductType';
import { ProductModule } from '@Modules/CTMModules';
import { Tooltip } from '@mui/material';
import StatusPicker from 'Modules/Core/Components/StatusPicker';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import StoreValues from 'Modules/Ecommerce/Components/StoreValues';
import classnames from 'classnames';
import { SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';
import SelectStatusColumnFilter from 'components/DataGrid/Filter/SelectStatusColumnFilter';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';

export type ProductionDemand = CTMRecord & {
  number?: string;
};

const columns: CTMListColumn<ProductionDemand>[] = [
  {
    id: 'number',
    filterable: true,
    sortable: true,
    Header: 'Numer',
    noBreak: true,
    accessor: 'number',
  },
  {
    id: 'rows.product.type.id',
    filterable: true,
    sortable: true,
    Header: 'Typ',
    accessor: '',
    Cell: ({ row }) => (
      <>
        <span>
          <i
            style={{ color: `${row.original?.product?.type?.color || '#484f56'}` }}
            className={classnames('mdi font-size-16', {
              [`mdi-${row.original?.product.type?.icon ?? 'folder'}`]: true,
            })}
          />
          {row.original?.product?.type?.name}
        </span>
      </>
    ),
    Filter: SelectModuleRecordColumnFilter,
    filterModule: ProductType,
  },
  {
    id: 'rows.product.labelerId',
    filterable: true,
    sortable: true,
    Header: 'ID',
    noBreak: true,
    accessor: 'product.labelerId',
    Cell: ({ row }) => (
      <Link target={'_blank'} to={ProductModule.editUrl(row.original.product.id)}>
        {row.original.product.labelerId}
      </Link>
    ),
    filterSearchField: 'rows.product.labelerId',
  },
  {
    id: 'rows.product.symbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol',
    noBreak: true,
    accessor: 'product.symbol',
    filterSearchField: 'rows.product.symbol',
  },
  {
    id: 'rows.product.name',
    filterable: true,
    sortable: true,
    Header: 'Produkt',
    noBreak: true,
    accessor: '',
    Cell: ({ row }) => (
      <>
        {!row.original.supplier ? (
          <Tooltip title={'Brak domyślnego dostawcy!'}>
            <i className="mdi mdi-alert text-danger" style={{ padding: '0 4px' }} />
          </Tooltip>
        ) : (
          ''
        )}{' '}
        {row.original.product.name}
      </>
    ),
    filterSearchField: 'rows.product.name',
  },
  {
    id: 'rows.product.store',
    filterable: false,
    sortable: false,
    Header: 'Stany',
    noBreak: true,
    accessor: '',
    Cell: ({ row }) => <StoreValues key={row.original.id} product={row.original.product} renderFetch={true} withOrdered={false} />,
  },
  {
    id: 'rows.quantity',
    filterable: true,
    sortable: true,
    Header: 'Ilość',
    Cell: ({ row }) => (
      <ProductionOrderQuantity
        key={row.original.rowId}
        id={row.original.rowId}
        quantity={row.original.quantity}
        url={'/manufacture/production-demand-rows'}
      />
    ),
    noBreak: true,
    accessor: 'quantity',
  },
  {
    id: 'rows.product.unit',
    filterable: false,
    sortable: false,
    Header: 'Jednostka',
    noBreak: true,
    accessor: 'product.unit.relationFieldLabel',
  },
  {
    id: 'createdAt',
    Header: 'Data utworzenia',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('DD.MM.YYYY') : null),
    filterable: true,
    sortable: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'manufacture-production-demand'} status={status} />,
    sortable: true,
    filterable: true,
    Filter: ({ column }) => <SelectStatusColumnFilter column={column} moduleName={'manufacture-production-demand'} property={'status'} />,
  },
  {
    id: 'order',
    Header: 'Zamówienie',
    accessor: ({ order }) => <ProductionDemandOrder order={order} />,
    sortable: false,
    filterable: false,
  },
  {
    id: 'deliveryAt',
    Header: 'Planowana data dostawy',
    accessor: ({ deliveryAt }) => (deliveryAt ? dayjs(deliveryAt).locale('pl').format('DD.MM.YYYY') : null),
    filterable: true,
    sortable: true,
  },
];

const module: CTMModule<ProductionDemand> = {
  id: '5d4afb29-bead-4da8-86ce-9a2a45c36a9b',
  dataClass: 'CTM\\Manufacture\\Entity\\Production\\ProductionDemand',
  urlPrefix: 'manufacture-production-demand',
  name: 'Zapotrzebowanie',
  role: 'MANUFACTURE_PRODUCTION_DEMAND',
  api: {
    item: {
      get: ({ id }) => `/manufacture/production-demands/${id}`,
      put: ({ id }) => `/manufacture/production-demands/${id}`,
      delete: ({ id }) => `/manufacture/production-demands/${id}`,
    },
    collection: {
      get: `/manufacture/production-demands`,
      post: `/manufacture/production-demands`,
    },
  },
  recordLabel: record => record.number,
  form: {
    prepareRecordToSave: record => ({
      ...record,
      rows: record.rows?.map(el => ({
        ...el,
        product: el.product?.['@id'] ?? el.product,
      })),
    }),
    fieldComponents: {
      status: props => <StatusPicker {...props} moduleName={'manufacture-production-demand'} propertyName={'status'} />,
      rows: ProductionDemandRows,
    },
    contextActions: ({ record }) => (
      <>
        {record.id && (
          <>
            <Link
              to={`/warehouse/documents/add/38bb0ca6-1e0b-11ed-861d-0242ac120002?warehouse=4573d28f-42c1-4a51-aa39-fb6e82e3c838&owner=${record['@id']}`}
              className="btn btn-success ml-1"
            >
              <i className="mdi mdi-warehouse" /> Przyjęcie na stan
            </Link>
          </>
        )}
      </>
    ),
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'createdAt', desc: false }],
    storeFilters: true,
    board: true,
    boardCardOverride: ({ item, module }) => {
      return (
        <CardBody className="borad-width">
          <div>
            <h5 className="font-size-15">
              <Link to={module.showUrl(item.id)} className="text-dark">
                {item?.number}
              </Link>
            </h5>
          </div>
          <div className="text-end">
            <p className="mb-0 text-muted">{dayjs(item?.createdAt).locale('pl').format('LLL')}</p>
          </div>
        </CardBody>
      );
    },
  },
};

export default module;
