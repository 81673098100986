import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import ModulePropertyPicker from '@Components/Form/ModulePropertyPicker';
import { Status } from 'Modules/Core/Hooks/usePropertyValues';

const StatusPicker: CustomFieldComponentType<false, Status | null | number, { showLabel: boolean }> = ({
  field,
  value,
  onChange,
  readonly,
  errorKey,
  showLabel = true,
}) => {
  return (
    <ModulePropertyPicker
      moduleName={'ecommerce-client-order'}
      propertyName={'status'}
      name={field.id}
      showLabel={showLabel}
      label={field.name}
      value={value}
      defaultValue={field.defaultValue}
      disabled={readonly}
      errorKey={errorKey}
      onChange={onChange}
    />
  );
};

export default StatusPicker;
