import { put } from '../../../helpers/Axios';
import { useAppDispatch } from '../../../store';
import { addSingleToast } from '../../../store/Toast/actions';
import { PriorityPicker } from '@Core/Components/PriorityFilter';
import { Priority, PriorityColor } from '@Core/Types/Priority';
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded, KeyboardDoubleArrowUpRounded, RemoveRounded } from '@mui/icons-material';
import { FC } from 'react';

type PriorityViewProps = {
  priority: Priority;
  resourceIRI?: string;
};

export const PriorityView: FC<PriorityViewProps> = ({ priority, resourceIRI }) => {
  const dispatch = useAppDispatch();
  const updatePriority = (priority: null | Priority) => {
    if (priority === null) {
      return;
    }
    put(resourceIRI, { priority: priority })
      .then(() => {
        dispatch(
          addSingleToast({
            title: 'Priorytet został zaktualizowany',
            config: { appearance: 'success' },
          }),
        );
      })
      .catch(() => {
        dispatch(
          addSingleToast({
            title: 'Nie udało się zaktualizować priorytetu',
            config: { appearance: 'error' },
          }),
        );
      });
  };

  if (resourceIRI) {
    return <PriorityPicker value={priority} onChange={updatePriority} disableNull={true} />;
  }

  switch (priority) {
    case Priority.LOW:
      return <KeyboardArrowDownRounded style={{ color: PriorityColor.LOW }} />;
    case Priority.MEDIUM:
      return <RemoveRounded style={{ color: PriorityColor.MEDIUM }} />;
    case Priority.HIGH:
      return <KeyboardArrowUpRounded style={{ color: PriorityColor.HIGH }} />;
    case Priority.CRITICAL:
      return <KeyboardDoubleArrowUpRounded style={{ color: PriorityColor.CRITICAL }} />;
  }
};
