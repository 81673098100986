import Loader from '@Components/Theme/Common/Loader';
import { Status, usePropertyValues } from 'Modules/Core/Hooks/usePropertyValues';
import ListPicker from 'components/Form/ListPicker';
import { FC } from 'react';

export type ModulePropertyPickerProps = {
  moduleName: string;
  propertyName: string;
  name: string;
  label: string | undefined;
  defaultValue: Status | null | number;
  value: Status | null | number;
  onChange: (value: Status | null | number, name: string) => void;
  disabled: boolean;
  errorKey: string;
  showLabel?: boolean;
};

const ModulePropertyPicker: FC<ModulePropertyPickerProps> = props => {
  const { data: statuses, isLoading } = usePropertyValues(props.moduleName, props.propertyName);

  if (isLoading || !statuses) {
    return <Loader />;
  }

  const hasColors = statuses.some(status => status.color);

  return (
    <ListPicker<Status>
      name={props.name}
      label={props.showLabel ? props.label : undefined}
      value={props.value !== null ? props.value : props.defaultValue}
      options={statuses}
      removeWrapperClass={props.value != null && hasColors}
      optionLabel={item => (
        <span
          style={{
            width: '100%',
            display: 'block',
            padding: hasColors ? '10px 8px' : 2,
            color: item?.color ? '#fff' : '#303030',
            backgroundColor: item?.color,
            borderRadius: 10,
          }}
        >
          {item?.label}
        </span>
      )}
      optionTrackBy="value"
      disabled={props.disabled}
      errorKey={props.errorKey}
      onChange={val => props?.onChange?.(val, props.name)}
    />
  );
};

export default ModulePropertyPicker;
