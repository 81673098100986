import { Paper } from '@mui/material';
import { CoreModuleModule, ProductModule } from 'Modules/CTMModules';
import ProductFilterRangeInput from 'Modules/Manufacture/Components/ProductFilter/ProductFilterRangeInput';
import CustomField from 'components/CustomFields/CustomField';
import DataGrid from 'components/DataGrid';
import ListPicker from 'components/Form/ListPicker';
import { Col, Container } from 'components/Theme/Grid';
import { useEffect, useRef, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

const FileTypesField = ({ value, readonly, field, onChange }) => {
  const values = (value ?? []).map(el => {
    el._id = uuidv4();

    return el;
  });

  const [fields, setFields] = useState([]);
  const filterGrid = useRef();
  const [filters, setFilters] = useState(value ?? []);

  useEffect(() => {
    filterGrid?.current?.refresh();
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    CoreModuleModule.api.get({ id: ProductModule.configuration.id }).then(moduleConfiguration => {
      setFields(moduleConfiguration.fields);
      filterGrid?.current?.refresh();
    });
  }, []);

  useEffect(() => {
    onChange([...filters], field.id);
  }, [filters]);

  const pickField = (i, el) => {
    setFilters(prevFilters =>
      prevFilters.map((prevFilterRow, index) => {
        if (i === index) {
          return { ...prevFilterRow, field: el };
        }
        return prevFilterRow;
      }),
    );
  };
  const onRemoveIndex = indexToRemove => {
    setFilters(prevFilters => prevFilters.filter((prevFilterRow, index) => index !== indexToRemove));
  };
  const pickValue = (i, el) => {
    setFilters(prevFilters =>
      prevFilters.map((prevFilterRow, index) => {
        if (i === index) {
          return { ...prevFilterRow, value: (el?.['@id'] ?? el) + '' };
        }
        return prevFilterRow;
      }),
    );
  };

  const addNewFilter = () => {
    setFilters(prevFilters => [
      ...prevFilters,
      {
        field: null,
        value: null,
      },
    ]);
  };

  const parseRangeValue = v => {
    try {
      return {
        from: undefined,
        to: undefined,
        ...(JSON.parse(v ?? '{}') ?? {}),
      };
    } catch (e) {
      return { from: undefined, to: undefined };
    }
  };
  const manipulateFiltersBeforeSend = () => {
    return (filters ?? [])
      .filter(el => el.field && el.value && el.value !== 'null')
      .map(el => {
        const field = fields.find(f => f['@id'] === (el.field?.['@id'] ?? el.field));

        switch (field.propertyPath) {
          case 'technology':
            return [{ id: 'technology.id', value: el.value }];
          case 'type':
            return [{ id: 'type.id', value: el.value }];
          case 'width':
            return [
              { id: 'width[gte]', value: parseRangeValue(el.value)?.from },
              { id: 'width[lte]', value: parseRangeValue(el.value)?.to },
            ];
          case 'thickness':
            return [
              { id: 'thickness[gte]', value: parseRangeValue(el.value)?.from },
              { id: 'thickness[lte]', value: parseRangeValue(el.value)?.to },
            ];
          case 'length':
            return [
              { id: 'length[gte]', value: parseRangeValue(el.value)?.from },
              { id: 'length[lte]', value: parseRangeValue(el.value)?.to },
            ];
        }
        return [{ id: field.propertyPath, value: el.value }];
      })
      .reduce((prev, curr) => [...prev, ...curr], []);
  };

  if (fields.length === 0) {
    return <></>;
  }

  return (
    <Container>
      <Col xs={12}>
        <Container>
          {(filters ?? []).map((filter, i) => (
            <Col key={i} xs={3}>
              <Paper variant="outlined">
                <div style={{ padding: 12, position: 'relative' }}>
                  {!readonly && (
                    <div
                      style={{
                        color: '#fff',
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        background: '#f00',
                        borderRadius: '100%',
                        padding: '0 5px',
                        zIndex: 20,
                      }}
                      onClick={() => onRemoveIndex(i)}
                    >
                      <i className={'mdi mdi-delete'} style={{ fontSize: 16 }} />
                    </div>
                  )}
                  <Container>
                    <Col xs={12}>
                      <ListPicker
                        label={'Pole'}
                        disabled={readonly}
                        options={fields}
                        optionTrackBy="@id"
                        optionLabel="name"
                        disableGroupMargin={true}
                        className={'_s'}
                        value={filter.field?.['@id'] ?? filter.field ?? null}
                        onChange={el => pickField(i, el)}
                      />
                    </Col>
                    <Col xs={12}>
                      {filter.field && fields.find(el => el['@id'] === (filter.field?.['@id'] ?? filter.field)) && (
                        <CustomField
                          field={fields.find(el => el['@id'] === (filter.field?.['@id'] ?? filter.field))}
                          fields={fields}
                          components={{
                            ...(ProductModule.configuration.form.fieldComponents ?? {}),
                            width: ProductFilterRangeInput,
                            length: ProductFilterRangeInput,
                            thickness: ProductFilterRangeInput,
                          }}
                          value={filter.value ?? null}
                          values={{}}
                          onUpdate={el => pickValue(i, el)}
                          readonly={readonly}
                          recordId={null}
                          disableHideRules={true}
                          customProps={{ overriddenEditor: 'textarea' }}
                        />
                      )}
                    </Col>
                  </Container>
                </div>
              </Paper>
            </Col>
          ))}
          {!readonly && (
            <Col xs={3}>
              <Paper variant="outlined" onClick={addNewFilter}>
                <div style={{ padding: 12, cursor: 'pointer' }}>Dodaj nowy filtr</div>
              </Paper>
            </Col>
          )}
        </Container>
      </Col>
      <Col xs={12}>
        <h5 style={{ textAlign: 'center' }}>Przykładowe towary które będą wybierane przez system:</h5>
        <Card>
          <CardBody style={{ padding: 0 }}>
            <DataGrid
              ref={filterGrid}
              url={ProductModule.api.getAllUrl}
              fullWidth={false}
              responsive={true}
              className={'table-clickable'}
              columns={ProductModule?.configuration?.list?.columns ?? []}
              defaultOrderBy={ProductModule.configuration.list.defaultOrderBy}
              defaultFilters={ProductModule.configuration.list.defaultFilters}
              defaultPerPage={5}
              perPageOptions={[1, 3, 5, 10, 20, 30, 40, 50]}
              onRowClick={ProductModule.configuration.list.onRowClick}
              manipulateFiltersBeforeSend={manipulateFiltersBeforeSend}
              canRenderSubRow={false}
              selectionActions={ProductModule.configuration.list.selectionActions}
              queryKey={'modalFilter'}
              storeFilters={false}
              disableTop={true}
              disableColumnActions={true}
              disableFooter={false}
            />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default FileTypesField;
