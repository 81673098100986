import { Paper } from '@mui/material';
import ListPicker from 'components/Form/ListPicker';
import { Fragment, useMemo } from 'react';

function TemplateSelector(props) {
  const { loading, templates, selectedTemplate, handleChangeTemplate, wrapperComponent } = props;
  const Wrapper = wrapperComponent ?? Paper;

  const wrapperProps = useMemo(() => {
    if (Wrapper === Fragment) {
      return {};
    }

    return { style: props.style, variant: 'outlined' };
  }, [props.style, Wrapper]);
  return (
    <Wrapper {...wrapperProps}>
      <ListPicker
        disabled={loading}
        options={templates}
        optionTrackBy="id"
        name={''}
        className={'_s'}
        value={selectedTemplate}
        onChange={handleChangeTemplate}
      />
    </Wrapper>
  );
}

export default TemplateSelector;
