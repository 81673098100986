import Contractor from './../Contractor/Contractor';
import Product from './Product';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import { SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';

export type ProductSupplier = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<ProductSupplier>[] = [
  {
    id: 'supplier.id',
    filterable: true,
    sortable: true,
    Header: 'Dostawca',
    accessor: 'supplier.relationFieldLabel',
    Filter: SelectModuleRecordColumnFilter,
    filterModule: Contractor,
    minWidth: 160,
  },
  {
    id: 'product.id',
    filterable: true,
    sortable: true,
    Header: 'Produkt',
    accessor: 'product.relationFieldLabel',
    Filter: SelectModuleRecordColumnFilter,
    filterModule: Product,
    minWidth: 160,
  },
  {
    id: 'priceFromOffer',
    filterable: false,
    sortable: true,
    Header: 'Cena oferowana',
    accessor: 'priceFromOffer',
  },
  {
    id: 'priceFromLastDelivery',
    filterable: false,
    sortable: true,
    Header: 'Cena z ostatniej dostawy',
    accessor: 'priceFromLastDelivery',
  },
  {
    id: 'supplierSymbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol',
    accessor: 'supplierSymbol',
  },
  {
    id: 'supplierLink',
    filterable: true,
    sortable: true,
    Header: 'Link',
    accessor: 'supplierLink',
  },
];

const module: CTMModule<ProductSupplier> = {
  id: 'b82456c1-0acf-4f8b-beca-f69b91a2ab8e',
  dataClass: 'CTM\\Manufacture\\Entity\\ProductSupplier',
  urlPrefix: 'manufacture-product-supplier',
  name: 'Dostawcy produktów',
  role: 'MANUFACTURE_PRODUCT',
  api: {
    item: {
      get: ({ id }) => `/manufacture/product-suppliers/${id}`,
      put: ({ id }) => `/manufacture/product-suppliers/${id}`,
      delete: ({ id }) => `/manufacture/product-suppliers/${id}`,
    },
    collection: {
      get: `/manufacture/product-suppliers`,
      post: `/manufacture/product-suppliers`,
    },
  },
  recordLabel: ({ product }) => product?.relationFieldLabel ?? '',
  form: {
    prepareRecordToSave: record => {
      return {
        ...record,
        product: record.product?.['@id'] ?? record.product ?? null,
        supplier: record.supplier?.['@id'] ?? record.supplier ?? null,
      };
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
