import ConfigureTemplateModal from './ConfigureTemplateModal';
import NewTemplateModal from './NewTemplateModal';
import { Button, CircularProgress, Paper } from '@mui/material';

function HeaderActions(props) {
  const { loading, onSave, onModalToggle, templates, moduleId, onNewTemplate, onRemove, templateId, fields, templateUpdated } = props;

  return (
    <Paper variant="outlined" style={{ padding: 6, background: '#192031', color: '#fff' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            disabled={loading}
            color="error"
            size="small"
            variant="contained"
            style={{ color: '#fff', marginRight: 12 }}
            onClick={() => onRemove()}
          >
            Skasuj
          </Button>
          <ConfigureTemplateModal templateId={templateId} moduleId={moduleId} fields={fields} afterSaved={templateUpdated}>
            <Button disabled={loading} color="primary" size="small" variant="contained" style={{ color: '#fff', marginRight: 12 }}>
              Ustawienia
            </Button>
          </ConfigureTemplateModal>
          <NewTemplateModal templates={templates} moduleId={moduleId} afterSave={onNewTemplate}>
            <Button disabled={loading} color="success" size="small" variant="contained" style={{ color: '#fff', marginRight: 12 }}>
              Nowy szablon
            </Button>
          </NewTemplateModal>
        </div>
        <div>
          <Button
            disabled={loading}
            color="success"
            size="small"
            variant="contained"
            style={{ color: '#fff', marginRight: 12 }}
            onClick={() => onSave(false)}
          >
            {loading ? <CircularProgress color="inherit" size={22} /> : 'Zapisz'}
          </Button>
          <Button
            disabled={loading}
            color="success"
            size="small"
            variant="contained"
            style={{ color: '#fff', marginRight: 12 }}
            onClick={() => onSave(true)}
          >
            {loading ? <CircularProgress color="inherit" size={22} /> : 'Zapisz i zamknij'}
          </Button>
          <Button
            disabled={loading}
            color="primary"
            size="small"
            variant="contained"
            style={{ color: '#fff', marginRight: 12 }}
            onClick={() => onModalToggle()}
          >
            Zamknij
          </Button>
        </div>
      </div>
    </Paper>
  );
}
export default HeaderActions;
