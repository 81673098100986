import { FavoriteRoomListQueryKey } from 'Modules/Chat/Hooks/UseFavoriteRooms';
import { RoomsCollection } from 'Modules/Chat/Hooks/UseRoomsList';
import { Room } from 'Modules/Chat/Types/ApiModel';
import useApiResourceQuery, { patch } from 'helpers/Axios';
import { QueryClient } from 'react-query';

export default async function toggleRoomIsFavorite(roomIri: string, isFavorite: boolean, queryClient: QueryClient) {
  patch<Room>(`${roomIri}/properties`, {
    isFavorite: isFavorite,
  }).then(updatedRoom => {
    queryClient.setQueryData(useApiResourceQuery.makeKey(roomIri), updatedRoom);
    queryClient.setQueryData<RoomsCollection | undefined>(FavoriteRoomListQueryKey, data => {
      if (!data) {
        return data;
      }

      const filtered = data['hydra:member'].filter(room => room['@id'] !== updatedRoom['@id']);

      return {
        ...data,
        'hydra:member': !updatedRoom.isFavorite ? filtered : filtered.concat(updatedRoom),
      };
    });
  });
}
