import * as url from '../../../helpers/Api/Url';
import { BrokenImage, Close, ContentPaste, Download, InsertLink } from '@mui/icons-material';
import { TextField } from '@mui/material';
import classnames from 'classnames';
import NumberInput from 'components/Form/MUI/NumberInput';
import { axiosApi } from 'helpers/Axios';
import { downloadBlobFile } from 'helpers/File';
import { FC, useState } from 'react';
import { Button, Col, Container, Modal, ModalBody, Row } from 'reactstrap';

interface ResizeImageBoxProps {
  fileId: string;
}

const ResizeImageBox: FC<ResizeImageBoxProps> = ({ fileId }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [width, setWidth] = useState<number | null | undefined>(1024);
  const [mediaFile, setMediaFile] = useState<any>(null);

  const resize = (): void => {
    axiosApi.get(url.RESIZE_MEDIA(fileId, width)).then(response => {
      setMediaFile(response.data);
    });
  };

  const download = (): void => {
    axiosApi
      .get(url.DOWNLOAD_MEDIA(mediaFile.id), {
        responseType: 'blob',
        headers: {
          Accept: 'image/*',
        },
      })
      .then(response => {
        downloadBlobFile(response.data, mediaFile.originalName);
      });
  };

  return (
    <>
      <Button onClick={() => setOpened(true)} size={'sm'} color={'warning'}>
        <InsertLink />
        Kopiuj link
      </Button>
      <Modal isOpen={opened} backdrop={true} toggle={() => setOpened(!opened)} centered={true} size={'lg'}>
        <ModalBody>
          <h4>Generowanie linku</h4>
          <Button style={{ padding: '3px 6px', position: 'absolute', top: 10, right: 16 }} onClick={() => setOpened(false)}>
            <Close />
          </Button>
          <Container fluid={true}>
            <Row className={classnames('mt-2')}>
              <Col lg="12">
                <NumberInput
                  value={width}
                  label={'Szerokość'}
                  name={'width'}
                  onChange={value => {
                    setWidth(value);
                  }}
                  inputProps={{ min: 1 }}
                />
              </Col>
            </Row>
            <Row className={classnames('mt-2')}>
              <Col lg="12">
                <Button size={'sm'} color={'success'} onClick={resize}>
                  <BrokenImage />
                  Generuj
                </Button>
              </Col>
            </Row>
            {mediaFile && (
              <>
                <Row className={classnames('mt-2')}>
                  <Col lg="12">
                    <TextField
                      value={`${process.env.REACT_APP_API_BASE_URL}/media/${mediaFile.filePath}`}
                      label={'Link'}
                      name={'link'}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row className={classnames('mt-2')}>
                  <Col lg="12">
                    <Button
                      style={{ marginRight: '10px' }}
                      size={'sm'}
                      color={'success'}
                      onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_API_BASE_URL}/media/${mediaFile.filePath}`)}
                    >
                      <ContentPaste />
                      Kopiuj do schowka
                    </Button>
                    <Button size={'sm'} color={'primary'} onClick={download}>
                      <Download />
                      Pobierz
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ResizeImageBox;
