import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import ImageUpload from 'components/Form/ImageUpload';

const CFImageField: CustomFieldComponentType = ({ field, value, onChange, readonly, errorKey, customProps = {}, values }) => {
  return (
    <>
      <ImageUpload
        className="_"
        label={field.name}
        name={field.id}
        value={value}
        onChange={onChange}
        returnObject={true}
        disabled={true}
        labelBadge={field.position + ''}
        errorKey={errorKey}
        autoHeight={true}
        fullHeight={true}
        fullInputHeight={true}
        moduleValues={values}
        labelerId={field.labelerId}
        {...customProps}
      />
    </>
  );
};

export default CFImageField;
