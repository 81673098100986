import BaseInput from '@Components/Form/BaseInput';
import IconInput from '@Components/Form/IconInput';
import { CheckCircle, DeleteForever, Edit, HighlightOff } from '@mui/icons-material';
import { Button, ButtonGroup, TableCell, TableRow } from '@mui/material';
import { DictionaryValueModule } from 'Modules/CTMModules';
import { confirmRemove } from 'common/sweetalerts';
import { useState } from 'react';

const DictionaryValueRow = ({ dictionaryValue, onUpdated, onRemove, readonly }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [ctrlHold, setCtrlHold] = useState(false);
  const [form, setForm] = useState({ ...dictionaryValue });

  const updateFormValue = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const rows = parseInt((dictionaryValue.description ?? '').length / 20);

  const onSave = () => {
    setIsEdit(false);
    onUpdated(form);
  };
  const onCancelEdit = () => {
    setIsEdit(false);
    setForm({ ...dictionaryValue });
  };

  return (
    <TableRow
      key={dictionaryValue['@id']}
      onDoubleClick={() => !readonly && setIsEdit(true)}
      onKeyDown={({ code }) => {
        switch (code) {
          case 'ControlLeft':
            setCtrlHold(true);
            break;
          case 'Escape':
            onCancelEdit();
            break;
          case 'NumpadEnter':
          case 'Enter':
            if (!ctrlHold) {
              onSave();
            } else {
              updateFormValue(form.description + '\n', 'description');
            }
            break;
        }
      }}
      onKeyUp={({ code }) => {
        if (code === 'ControlLeft') {
          setCtrlHold(false);
        }
      }}
    >
      {!readonly && (
        <TableCell size="small" variant="body">
          {isEdit && (
            <>
              <Button fullWidth={true} color={'warning'} variant={'contained'} size="small" onClick={() => onCancelEdit()}>
                <HighlightOff />
                &nbsp;Anuluj
              </Button>
              <Button fullWidth={true} color={'success'} variant={'contained'} size="small" onClick={() => onSave()}>
                <CheckCircle />
                &nbsp;Zapisz
              </Button>
            </>
          )}
          {!isEdit && (
            <>
              <ButtonGroup>
                <Button
                  color={'error'}
                  variant={'contained'}
                  size="small"
                  onClick={() => confirmRemove(() => DictionaryValueModule.api.delete({ id: dictionaryValue.id })).then(() => onRemove())}
                >
                  <DeleteForever />
                </Button>
                <Button color={'success'} variant={'contained'} size="small" onClick={() => setIsEdit(true)}>
                  <Edit />
                </Button>
              </ButtonGroup>
            </>
          )}
        </TableCell>
      )}
      <TableCell size="small" variant="body">
        {isEdit && (
          <BaseInput size={{ md: 12 }} disableGroupMargin={true} className={'_'} value={form.name} name="name" onChange={updateFormValue} />
        )}
        {!isEdit && <>{form.name}</>}
      </TableCell>
      <TableCell size="small" variant="body">
        {isEdit && (
          <BaseInput
            disableGroupMargin={true}
            className={'_'}
            size={{ md: 12 }}
            type="textarea"
            value={form.description}
            name="description"
            onChange={updateFormValue}
            inputProps={{ rows: rows > 0 ? rows : 1 }}
          />
        )}
        {!isEdit && <>{form.description}</>}
      </TableCell>
      <TableCell size="small" variant="body">
        {isEdit && (
          <BaseInput
            size={{ md: 12 }}
            disableGroupMargin={true}
            className={'_'}
            value={form.symbol}
            name="symbol"
            onChange={updateFormValue}
          />
        )}
        {!isEdit && <>{form.symbol}</>}
      </TableCell>
      <TableCell size="small" variant="body">
        {isEdit && (
          <IconInput size={{ md: 12 }} disableGroupMargin={true} className={'_'} value={form.icon} name="icon" onChange={updateFormValue} />
        )}
        {!isEdit && <i className={`mdi mdi-${form.icon}`} />}
      </TableCell>
      <TableCell size="small" variant="body">
        {isEdit && (
          <BaseInput
            size={{ md: 12 }}
            disableGroupMargin={true}
            className={'_'}
            value={form.color}
            name="color"
            type={'color'}
            onChange={updateFormValue}
          />
        )}
        {!isEdit && (
          <>
            {form.color ? (
              <div style={{ background: form.color, color: '#303030', width: '100%', padding: 8, textAlign: 'center' }}>
                <span style={{ color: form.color, filter: 'invert(100%)' }}>{form.color}</span>
              </div>
            ) : null}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DictionaryValueRow;
