import { memo } from 'react';

function FlowProductNode({ data }) {
  return (
    <div
      className="technology-flow-node-label"
      style={{
        background: '#303030',
        padding: 10,
        borderRadius: 5,
        border: '1px solid #000',
        transform: 'rotate(-90deg)',
        textAlign: 'center',
        height: '100%',
      }}
    >
      {data.label}
    </div>
  );
}

export default memo(FlowProductNode);
