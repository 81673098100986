import { Task } from '../../Types/Task';
import TaskModalColumnIngredients from './TaskModalColumnIngredients';
import TaskModalColumnMain from './TaskModalColumnMain';
import List from '@mui/material/List';
import { FC } from 'react';

const TaskModalColumn: FC<{ task: Task }> = ({ task }) => {
  return (
    <List sx={{ width: '100%' }} component="nav">
      <TaskModalColumnMain task={task} />
      <TaskModalColumnIngredients task={task} />
    </List>
  );
};
export default TaskModalColumn;
