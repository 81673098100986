import SelectSingleAPI from 'components/Form/SelectSingleAPI';
import { axiosApi } from 'helpers/Axios';
import { downloadBlobFile } from 'helpers/File';
import { FC, useState } from 'react';

type LabelPicker = {
  id: string | null | number;
  dataClass: string;
  ean: string;
};

const LabelPicker: FC<LabelPicker> = ({ id, ean, dataClass }) => {
  const [label, setLabel] = useState(null);

  const download = () => {
    axiosApi
      .get(`${label}?objectId=${id}`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then(response => {
        downloadBlobFile(response.data, ean);
      });
  };

  return (
    <>
      <SelectSingleAPI
        disableGroupMargin={true}
        name={'label'}
        onChange={value => {
          setLabel(value);
        }}
        endpoint={`manufacture/labels?type.class=${dataClass}`}
        isClearable={false}
        style={{ width: '200px', display: 'inline-block' }}
        className={'ml-1 mb-1'}
      />
      <button className="btn btn-primary ml-1" onClick={download} disabled={!label}>
        <i className="mdi mdi-download" />
        Pobierz
      </button>
    </>
  );
};

export default LabelPicker;
