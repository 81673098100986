import Loader from '@Components/Theme/Common/Loader';
import useIsGranted from 'common/hooks/isGranted';
import BaseForm from 'components/Theme/Common/BaseForm';
import { getConfiguration, putConfiguration } from 'helpers/Api/CoreHelper';
import { useErrorHandler } from 'helpers/FormHandler';
import GeneralTabs from 'pages/Configuration/GeneralTabs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';
import { clearErrors } from 'store/form/errors/actions';

const General = () => {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const canEdit = useIsGranted('ROLE_EDIT_CONFIGURATION');

  const handleSubmit = () => {
    setIsSubmitting(true);
    dispatch(clearErrors());
    putConfiguration(configuration.form)
      .then(() => dispatch(addSingleToast({ title: `Zapisano konfigurację.`, config: { appearance: 'success' } })))
      .catch(errorHandler)
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    if (null === configuration) {
      getConfiguration().then(response => {
        setConfiguration(response);
      });
    }
  }, []);

  const updateConfiguration = (module, data) => {
    setConfiguration({
      ...configuration,
      form: {
        ...configuration.form,
        [module]: {
          ...configuration.form[module],
          ...data,
        },
      },
    });
  };

  if (null === configuration) {
    return (
      <div className="page-content">
        <Loader />
      </div>
    );
  }
  return (
    <div className="container-fluid">
      <BaseForm
        title="Konfiguracja"
        tabs={GeneralTabs.map(tab => ({
          title: tab.label,
          content: (
            <tab.component form={configuration.form[tab.module]} updateConfiguration={data => updateConfiguration(tab.module, data)} />
          ),
          disabledSubmit: tab.disabledSubmit,
        }))}
        onSubmit={handleSubmit}
        renderOnlySelectedTab={true}
        isSubmitting={isSubmitting}
        readonly={!canEdit}
      />
    </div>
  );
};

export default General;
