export const StatusValues = {
  canceled: 0,
  created: 1000,
  waiting: 1500,
  readyForWork: 2000,
  inProgress: 3000,
  done: 4000,
};

const Statuses = {
  [StatusValues.canceled]: 'Anulowane',
  [StatusValues.created]: 'Utworzone',
  [StatusValues.waiting]: 'Oczekujące',
  [StatusValues.readyForWork]: 'Gotowe do pracy',
  [StatusValues.inProgress]: 'W toku',
  [StatusValues.done]: 'Gotowe',
};

export default Statuses;
