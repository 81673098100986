import useIsGranted from 'common/hooks/isGranted';
import React from 'react';
import { Alert } from 'reactstrap';

interface SecuredViewProps {
  role: string;
  displayError?: boolean;
  alternativeContent?: React.ReactNode;
  children: React.ReactNode;
}

const SecuredView = (props: SecuredViewProps) => {
  const { children, role, displayError, alternativeContent } = props;
  const isGranted = useIsGranted(role);

  if (!isGranted) {
    if (displayError) {
      return (
        <Alert color="danger" role="alert">
          Brak dostępu do wyświetlenia tego elementu
        </Alert>
      );
    }

    return <>{alternativeContent}</>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export const withSecure = (WrappedComponent: any, role: string, displayError: boolean): typeof React.Component => {
  return class extends React.Component {
    displayName = `Secured(${WrappedComponent.displayName || WrappedComponent.name})`;

    render() {
      return (
        <SecuredView role={role} displayError={displayError}>
          <WrappedComponent {...this.props} />
        </SecuredView>
      );
    }
  };
};

export default SecuredView;
