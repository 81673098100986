import ModuleTemplatesBuilder from 'Modules/CustomFields/ModuleTemplatesBuilder';
import { Col, Container } from 'components/Theme/Grid';
import { useRef } from 'react';
import { withRouter } from 'react-router-dom';

const SingleModuleConfiguration = ({ match }) => {
  const module = match?.params?.module || null;
  const moduleTemplatesBuilder = useRef();
  return (
    <Container fluid={true}>
      <Col xs={12} justifyItems={'center'} style={{ textAlign: 'center' }}>
        <ModuleTemplatesBuilder
          ref={moduleTemplatesBuilder}
          moduleId={module}
          templateId={null}
          afterSave={() => false}
          initialOpened={true}
        >
          <button className="btn btn-primary ml-1" onClick={() => moduleTemplatesBuilder.current.open()}>
            <i className={'mdi mdi-arrow-left-bold-box-outline'} /> Otwórz tryb konfiguracji ponownie
          </button>
        </ModuleTemplatesBuilder>
      </Col>
    </Container>
  );
};

export default withRouter(SingleModuleConfiguration);
