import CheckboxInput from 'components/Form/MUI/CheckboxInput';
import { Col, Container } from 'components/Theme/Grid';
import { FC } from 'react';

interface TabConditionalModalProps {
  value: { tabView: boolean };
  onChange: (config: object) => void;
}

const TabConfiguration: FC<TabConditionalModalProps> = ({ value, onChange }) => {
  return (
    <Container>
      <Col xs={12}>
        <CheckboxInput
          name={'tabView'}
          value={value?.['tabView'] ?? false}
          disabled={false}
          label={'Wyświetlaj zawartość jako zakładki'}
          onChange={newValue => onChange({ ...value, tabView: newValue ? newValue : false })}
        />
      </Col>
    </Container>
  );
};

export default TabConfiguration;
