import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { FieldType } from '@Components/CustomFields/FieldTypes';
import BaseInput from '@Components/Form/BaseInput';
import ListPicker from '@Components/Form/ListPicker';
import { Field } from '@CustomFields/Field';
import { BaseLinkerIntegrationFieldMapping } from '@Integration/BaseLinkerIntegration';
import { CoreModuleModule, ProductModule } from '@Modules/CTMModules';
import { DeleteForeverRounded } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import Swal from 'sweetalert2';

interface FieldMappingRowProps {
  mappings: BaseLinkerIntegrationFieldMapping[];
  readonly: boolean;
  baseLinkerPropertyPath: string;
  onChange: (mapping: BaseLinkerIntegrationFieldMapping) => void;
  onRemove: (removedField: string) => void;
  fields: Field[];
  integrations: (null | string)[];
}

const FieldMappingRow: FC<FieldMappingRowProps> = props => {
  const filteredFields = props.fields.filter(field => {
    if (props.baseLinkerPropertyPath.includes('image.')) {
      return field.type === 'IMAGE';
    }

    const allowedTypes: FieldType[] = ['STRING', 'TEXT', 'NUMBER', 'BOOLEAN', 'DATE', 'IMAGE'];

    return allowedTypes.includes(field.type);
  });

  const getIntegrationMapping = (integration: null | string) => {
    return (
      props.mappings.find(mapping => mapping.integration === integration) ?? {
        field: null,
        baseLinkerPropertyPath: props.baseLinkerPropertyPath,
        integration: integration,
      }
    );
  };

  const confirmRemove = () => {
    Swal.fire({
      title: 'Czy na pewno chcesz usunąć to mapowanie?',
      showCancelButton: true,
      confirmButtonText: 'Tak',
      cancelButtonText: 'Nie',
    }).then(result => {
      if (result.isConfirmed) {
        props.onRemove(props.baseLinkerPropertyPath);
      }
    });
  };

  return (
    <TableRow hover={true}>
      <TableCell
        style={{
          minWidth: 180,
          position: 'sticky',
          left: 0,
          background: 'white',
          zIndex: '200',
        }}
      >
        <BaseInput
          value={props.baseLinkerPropertyPath}
          name={''}
          onChange={() => false}
          disabled={true}
          disableGroupMargin={true}
          beforeInputContent={
            <Button variant="contained" style={{ backgroundColor: 'transparent', boxShadow: 'none' }} onClick={confirmRemove}>
              <DeleteForeverRounded style={{ color: '#f26969' }} />
            </Button>
          }
        />
      </TableCell>
      {props.integrations.map((integration, index) => (
        <TableCell key={index} style={{ minWidth: 180 }}>
          {!(props.baseLinkerPropertyPath.includes('image.') && integration !== null) && (
            <ListPicker<Field>
              value={getIntegrationMapping(integration).field}
              label={''}
              options={filteredFields}
              optionTrackBy="@id"
              optionLabel={fieldCfg => `(${fieldCfg.labelerId}) ${(fieldCfg.name ?? '') === '' ? 'Nazwa pola' : fieldCfg.name}`}
              onChange={selectedField =>
                props.onChange({
                  ...getIntegrationMapping(integration),
                  field: selectedField,
                })
              }
              name={'field'}
            />
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

const FieldMappings: CustomFieldComponentType = ({ field, value, onChange, readonly }) => {
  const [mappings, setMappings] = useState<BaseLinkerIntegrationFieldMapping[]>(Object.values(value ?? {}));
  const [fields, setFields] = useState<Field[]>([]);
  const [baseLinkerFields, setBaseLinkerFields] = useState<string[]>(_.uniq(mappings.map(mapping => mapping.baseLinkerPropertyPath)));

  useEffect(() => {
    if (JSON.stringify(mappings) !== JSON.stringify(Object.values(value ?? {}))) {
      onChange(mappings, field.id);
    }
  }, [JSON.stringify(mappings)]);

  useEffect(() => {
    if (fields.length === 0) {
      CoreModuleModule.api.get({ id: ProductModule.configuration.id }).then(configuration => {
        setFields(configuration.fields);
      });
    }
  }, []);

  if (fields.length === 0) {
    return null;
  }

  const addNewMapping = () => {
    Swal.fire<string>({
      title: 'Podaj nazwę pola z baselinker, w formacie extra_field_[ID]',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Dodaj',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => false,

      preConfirm: async val => {
        if (!val.includes('extra_field_')) {
          return Swal.showValidationMessage('Nazwa pola musi zaczynać się od extra_field_');
        }
        return val;
      },
    }).then(result => {
      if ((result.value ?? '').length > 0) {
        setBaseLinkerFields([...baseLinkerFields, result.value ?? '']);
      }
    });
  };

  const onRemove = (baseLinkerField: string) => {
    setBaseLinkerFields(prevState => prevState.filter(field => field !== baseLinkerField));
    setMappings(prevState => prevState.filter(mapping => mapping.baseLinkerPropertyPath !== baseLinkerField));
  };

  const handleChangeRow = (mapping: BaseLinkerIntegrationFieldMapping) => {
    setMappings(prevValue => {
      const hasMapping = prevValue.find(
        item => item.baseLinkerPropertyPath === mapping.baseLinkerPropertyPath && item.integration === mapping.integration,
      );

      if (hasMapping) {
        return prevValue.map((item, index) => {
          if (item.baseLinkerPropertyPath === mapping.baseLinkerPropertyPath && item.integration === mapping.integration) {
            return mapping;
          }
          return item;
        });
      }

      return [...prevValue, mapping];
    });
  };

  const getUniqueIntegrations = () => {
    return _.uniq(mappings.map(mapping => mapping.integration)).sort((a, b) => (null === a ? -1 : (a ?? 'aa').localeCompare(b ?? 'aa')));
  };
  return (
    <TableContainer sx={{ maxHeight: '600px', maxWidth: '100%' }}>
      <Table style={{ minWidth: (getUniqueIntegrations().length + 1) * 140 }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                position: 'sticky',
                left: 0,
                background: 'white',
                zIndex: '201',
                width: 300,
              }}
            >
              BaseLinker path
            </TableCell>
            {getUniqueIntegrations().map((integration, index) => (
              <TableCell key={index}>{integration ?? 'Domyślna'}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {baseLinkerFields.map((baseLinkerPropertyPath, itemKey) => (
            <FieldMappingRow
              key={itemKey}
              baseLinkerPropertyPath={baseLinkerPropertyPath}
              mappings={mappings.filter(m => m.baseLinkerPropertyPath === baseLinkerPropertyPath)}
              readonly={readonly}
              fields={fields}
              onChange={changedMapping => handleChangeRow(changedMapping)}
              onRemove={onRemove}
              integrations={getUniqueIntegrations()}
            />
          ))}
          <TableRow>
            <TableCell colSpan={2}>
              <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => addNewMapping()}>
                Dodaj mapowanie własnego pola
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FieldMappings;
