import { OnlineStatus } from 'Modules/Chat/Types/Api';
import { Room } from 'Modules/Chat/Types/ApiModel';
import { Employee } from 'Modules/Employee/Model';

export default function isRoomOnline(room: Room | undefined, currentUser: Employee | undefined, onlineUsers: OnlineStatus[]) {
  if (!room) {
    return false;
  }

  if (room.members.length === 1) {
    return true;
  }

  for (const member of room.members) {
    if (member.user['@id'] === currentUser?.['@id']) {
      continue;
    }

    if (onlineUsers.includes(member.user['@id'])) {
      return true;
    }
  }

  return false;
}
