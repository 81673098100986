import Flow from './NewTree/Flow';
import ViewPortController from './NewTree/ViewPortController';
import { CTMCustomTab } from '@Core/Types/CTMModule';
import { Field } from '@CustomFields/Field';
import { Product } from '@Manufacture/Product';
import { get } from 'helpers/Axios';
import { FC, createRef, useEffect, useState } from 'react';
import { ReactFlowProvider } from 'reactflow';

interface ProductionTreeProps {
  record: Product;
  parentRecord: Product | null;
  rootRecord: Product | null;
  parentTitlePath: string | null;
  onUpdate: any;
  readonly: boolean;
  fields: Field[];
}

const ProductionTree: FC<ProductionTreeProps> = ({ record, onUpdate, fields, parentRecord, rootRecord, parentTitlePath, readonly }) => {
  const [technolgies, setTechnologies] = useState([]);
  const wrapper = createRef<HTMLDivElement>();
  const fetchTechnologies = async () => {
    const { ['hydra:member']: data } = await get('/manufacture/technologies', { params: { pagination: false } });
    setTechnologies(data.map(el => ({ name: el.name, position: el.position, '@id': el['@id'] })));
  };

  useEffect(() => {
    fetchTechnologies();
  }, []);

  return (
    <div className="product-builder-graph position-relative">
      <div ref={wrapper} style={{ width: '100%', height: 'calc(100vh - 350px)' }}>
        <ReactFlowProvider>
          {technolgies.length > 0 && <Flow technologies={technolgies} rootProduct={record} fields={fields} onUpdate={onUpdate} />}
          <ViewPortController />
        </ReactFlowProvider>
      </div>
    </div>
  );
};

export const TabConfiguration: CTMCustomTab = {
  title: 'Składniki',
  component: ProductionTree,
};
export default ProductionTree;
