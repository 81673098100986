import {
  FETCH_APP_CONFIGURATION,
  FETCH_APP_CONFIGURATION_FAILED,
  FETCH_APP_CONFIGURATION_SUCCESS,
  SET_APP_LANGUAGE,
} from 'store/AppConfiguration/Configuration/ActionTypes';

export type AppConfigurationState = {
  loading: boolean;
  loaded: boolean;
  language: string;
  configuration: any;
  languages: Array<string>;
};

const initState = (): AppConfigurationState => ({
  loading: false,
  loaded: false,
  language: 'pl',
  configuration: {},
  languages: [],
});

const initialState = initState();

const Configuration = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APP_CONFIGURATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_APP_CONFIGURATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        configuration: action.payload,
      };
      break;
    case SET_APP_LANGUAGE:
      state = {
        ...state,
        language: action.payload,
      };
      break;
    case FETCH_APP_CONFIGURATION_FAILED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        configuration: {},
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Configuration;
