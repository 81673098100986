import useErrorHandler from '../../../../../helpers/FormHandler';
import { Close } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { TaskModule } from 'Modules/CTMModules';
import NumberInput from 'components/Form/MUI/NumberInput';
import TextInput from 'components/Form/MUI/TextInput';
import { Col, Container } from 'components/Theme/Grid';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { reloadTask } from 'store/TaskManagement/Actions';

interface AmountChangeModalProps {
  defaultOpened: boolean;
  taskIRI: string;
  children: any;
  style?: object;
  className?: string;
}

const AmountChangeModal: FC<AmountChangeModalProps> = props => {
  const { defaultOpened = false, children, style, className, taskIRI } = props;
  const [opened, setOpened] = useState<boolean>(defaultOpened);
  const [value, setValue] = useState<number>(0);
  const [comment, setComment] = useState<null | string>(null);
  const errorHandler = useErrorHandler();
  const dispatch = useDispatch();

  const onSave = () => {
    TaskModule.api.custom
      .changeAmount({
        task: taskIRI,
        amount: value,
        comment: comment,
      })
      .then(() => {
        setValue(0);
        setComment(null);
        setOpened(false);
        dispatch(reloadTask());
      })
      .catch(errorHandler);
  };

  return (
    <>
      <Modal
        isOpen={opened}
        centered={true}
        size={'xl'}
        backdrop={true}
        toggle={() => setOpened(!opened)}
        style={{ maxWidth: 480, maxHeight: '90vw', background: 'transparent' }}
        className={'task-modal'}
      >
        <ModalBody className={'amount-change-modal'}>
          <Container style={{ position: 'relative' }} spacing={0}>
            <Button style={{ position: 'absolute', top: 10, right: 4 }} onClick={() => setOpened(false)}>
              <Close />
            </Button>
            <Col xs={12}>
              <h3 style={{ textAlign: 'center', padding: 12 }}>Wprowadź zmianę ilości</h3>
            </Col>
            <Col xs={12}>
              <Container style={{ padding: 28, textAlign: 'center' }}>
                <Col xs={12}>
                  <NumberInput value={value} className={'change-input'} />
                </Col>
                <Col xs={12}>
                  <Container>
                    <Col xs={3}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 1)}>
                        +1
                      </Button>
                    </Col>
                    <Col xs={3}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 10)}>
                        +10
                      </Button>
                    </Col>
                    <Col xs={3}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 100)}>
                        +100
                      </Button>
                    </Col>
                    <Col xs={3}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 1000)}>
                        +1000
                      </Button>
                    </Col>
                  </Container>
                </Col>
                <Col xs={12}>
                  <Container>
                    <Col xs={3}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 1)}>
                        -1
                      </Button>
                    </Col>
                    <Col xs={3}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 10)}>
                        -10
                      </Button>
                    </Col>
                    <Col xs={3}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 100)}>
                        -100
                      </Button>
                    </Col>
                    <Col xs={3}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 1000)}>
                        -1000
                      </Button>
                    </Col>
                  </Container>
                </Col>
                <Col xs={12}>
                  <TextInput
                    placeholder={'Jeśli chcesz wpisz tutaj jakiś komentarz...'}
                    textarea={true}
                    maxRows={5}
                    value={comment}
                    onChange={val => setComment(val ?? null)}
                  />
                </Col>
              </Container>
            </Col>
            <Col xs={12} style={{ textAlign: 'center', padding: 20 }}>
              <Button color={'success'} variant={'contained'} fullWidth={true} onClick={onSave}>
                Zapisz
              </Button>
            </Col>
          </Container>
        </ModalBody>
      </Modal>
      <div style={style} className={className} onClick={() => setOpened(true)}>
        {children}
      </div>
    </>
  );
};

export default AmountChangeModal;
