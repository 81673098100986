import CheckboxColumn from '@Components/DataGrid/ColumnView/CheckboxColumn';
import { SelectColumnFilter } from '@Components/DataGrid/Filter';
import { ValueAddedTax } from '@Core/Accountancy/ValueAddedTax';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type Country = CTMRecord & {
  name: string;
  currency: string;
  defaultVatRate: null | ValueAddedTax;
  id: string;
  enabled: boolean;
};

const columns: CTMListColumn<Country>[] = [
  {
    id: 'id',
    filterable: true,
    sortable: true,
    Header: 'Kod',
    accessor: 'id',
  },
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    id: 'currency',
    filterable: true,
    sortable: true,
    Header: 'Waluta',
    accessor: 'currency',
  },
  {
    id: 'defaultVatRate',
    filterable: true,
    sortable: true,
    Header: 'Domyślna stawka VAT',
    accessor: ({ defaultVatRate }) => `${defaultVatRate?.name ?? ''}` + `${defaultVatRate?.value ? ` (${defaultVatRate.value}%)` : ''}`,
  },
  {
    id: 'enabled',
    Header: 'Aktywny',
    accessor: ({ enabled }) => <CheckboxColumn active={enabled} />,
    filterable: true,
    Filter: SelectColumnFilter,
    filterOptions: [
      { value: null, label: 'Wszystkie' },
      { value: true, label: 'Tak' },
      { value: '0', label: 'Nie' },
    ],
  },
];

const module: CTMModule<Country> = {
  id: '770f818e-7352-486e-9368-0250f154f46b',
  dataClass: 'CTM\\Core\\Entity\\Country',
  urlPrefix: 'core-country',
  name: 'Kraje',
  role: null,
  roleEdit: 'ROLE_EMPLOYEE',
  api: {
    item: {
      get: ({ id }) => `/core/countries/${id}`,
      put: ({ id }) => `/core/countries/${id}`,
      delete: ({ id }) => `/`,
    },
    collection: {
      get: `/core/countries`,
      post: `/core/countries`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    disableRemoveRecord: true,
    disableCreateNewRecord: true,
    prepareRecordToSave: record => ({ ...record, defaultVatRate: record.defaultVatRate?.['@id'] ?? record.defaultVatRate }),
    forceReadonlyField: field => {
      return ['id', 'name', 'currency'].includes(field.propertyPath);
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
