import useIsGranted from 'common/hooks/isGranted';
import BaseInput from 'components/Form/BaseInput';
import Switch from 'components/Form/Switch';
import { Col, Container } from 'components/Theme/Grid';
import { withRouter } from 'react-router-dom';

const DeepL = ({ form, updateConfiguration }) => {
  const updateFormValue = (value, field) => {
    updateConfiguration({ ...form, [field]: value });
  };

  const canEdit = useIsGranted('ROLE_EDIT_CONFIGURATION');

  return (
    <Container>
      <Col xs={12} md={12} lg={12}>
        <Switch
          size={{ md: 12 }}
          label={'Aktywne'}
          value={form.active}
          name="active"
          onChange={updateFormValue}
          disabled={!canEdit}
          switchOnRight={true}
        />
      </Col>
      <Col xs={12} md={6} lg={4}>
        <BaseInput size={{ md: 12 }} label={'API Key'} value={form.key} name="key" onChange={updateFormValue} disabled={!canEdit} />
      </Col>
    </Container>
  );
};

export default withRouter(DeepL);
