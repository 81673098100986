import CTMModule, { CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';

export type ValueAddedTax = CTMStrictRecord & {
  name?: string;
  value?: number;
};

const columns: CTMListColumn<ValueAddedTax>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    id: 'value',
    filterable: true,
    sortable: true,
    Header: 'Faktyczna stawka[%]',
    accessor: 'value',
  },
];

const module: CTMModule<ValueAddedTax> = {
  id: '739ce6b4-09fa-484f-a632-895e1cd64299',
  dataClass: 'CTM\\Core\\Entity\\Accountancy\\ValueAddedTax',
  urlPrefix: 'core-accountancy-value-added-taxes',
  name: 'Stawki VAT',
  role: 'CONFIGURATION',
  api: {
    item: {
      get: ({ id }) => `/core/accountancy/value-added-taxes/${id}`,
      put: ({ id }) => `/core/accountancy/value-added-taxes/${id}`,
      delete: ({ id }) => `/core/accountancy/value-added-taxes/${id}`,
    },
    collection: {
      get: `/core/accountancy/value-added-taxes`,
      post: `/core/accountancy/value-added-taxes`,
    },
  },
  recordLabel: record => record.name,
  form: {},
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
