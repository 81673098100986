import { ClientOrderSource } from '@Ecommerce/ClientOrderSource';
import { Button } from '@mui/material';
import { FC } from 'react';

const SourceView: FC<{ source: null | ClientOrderSource }> = ({ source }) => {
  if (source === null) {
    return null;
  }
  return (
    <Button
      variant={'contained'}
      startIcon={source?.icon ? <i className={`mdi mdi-${source?.icon}`} style={{ lineHeight: 'initial', paddingLeft: 4 }}></i> : undefined}
      style={{
        padding: '3px',
        color: '#fff',
        margin: '0 2px',
        backgroundColor: source?.color ?? '#303030',
        width: '100%',
      }}
    >
      {source?.name}
    </Button>
  );
};

export default SourceView;
