import PhotoLibraryModuleTab from './PhotoLibraryModuleTab';
import PhotoLibraryTab from './PhotoLibraryTab';
import { HighlightOff, SelectAllTwoTone } from '@mui/icons-material';
import { Media as MediaItem } from 'Modules/Core/Types/ApiModel';
import classnames from 'classnames';
import { FC, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Modal, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

interface PhotoLibrarySelectProps {
  moduleImages: MediaItem[];
  onSelect: (file: MediaItem) => void;
  buttonStyle: object;
}

const modalTabs = [
  { title: 'Moduł', component: PhotoLibraryModuleTab },
  { title: 'Biblioteka', component: PhotoLibraryTab },
];

const PhotoLibrarySelect: FC<PhotoLibrarySelectProps> = ({ moduleImages, onSelect, buttonStyle = {} }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  return (
    <>
      <Button variant="contained" color="info" className={'btn btn-accent ml-1'} onClick={() => setOpen(true)} style={{ ...buttonStyle }}>
        <SelectAllTwoTone style={{ fontSize: '0.8rem' }} /> Wybierz...
      </Button>
      <Modal
        style={{ maxWidth: '600px' }}
        isOpen={open}
        centered={true}
        size={'xl'}
        backdrop={true}
        toggle={() => setOpen(!open)}
        backdropClassName={'_'}
      >
        <ModalBody style={{ padding: 0 }}>
          <HighlightOff
            style={{ position: 'absolute', top: 6, right: 10, color: '#FFFFF', zIndex: '9999' }}
            onClick={() => setOpen(!open)}
          />
          <Container fluid={true}>
            <Row className={'form-tabs'}>
              <Col>
                <Nav tabs className="bg-dark-accent border-radius-top position-relative">
                  {modalTabs.map((el, i) => (
                    <NavItem key={`NavItem_${i}`}>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === i,
                          'dark-accent-color': activeTab !== i,
                        })}
                        onClick={() => {
                          setActiveTab(i);
                        }}
                      >
                        <span>{el.title}</span>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="border-small border-top-0 border-radius-top-left-none">
                  <CardBody style={{ padding: 0 }}>
                    <TabContent activeTab={activeTab} className="text-muted">
                      {modalTabs.map((el, index) => (
                        <TabPane tabId={index} key={`ModTabPane${index}`}>
                          {activeTab === index && <el.component onSelect={onSelect} moduleImages={moduleImages} />}
                        </TabPane>
                      ))}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PhotoLibrarySelect;
