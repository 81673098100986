import NumberField from '@Components/CustomFields/FieldTypes/NumberField';
import { Field } from '@CustomFields/Field';
import { computePrice } from '@Manufacture/Price/Tariff';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Product } from 'Modules/Manufacture/Types/Product';
import BaseInput from 'components/Form/BaseInput';
import { Col, Container } from 'components/Theme/Grid';
import { FC, useMemo } from 'react';

interface ProductPricesProps {
  record: Product;
  onUpdate: any;
  setRecord: any;
  readonly: boolean;
  fields: Field[];
}

const TariffRow: FC<any> = ({ priceQuantity, record }) => {
  const price = useMemo(() => {
    return computePrice(priceQuantity.tariff, record);
  }, [priceQuantity.id, record]);
  return (
    <TableRow>
      <TableCell>{priceQuantity.tariff.name}</TableCell>
      <TableCell>
        <BaseInput
          disableGroupMargin={true}
          value={price.toFixed(2)}
          type={'number'}
          name={''}
          disabled={true}
          onChange={() => {
            return;
          }}
        />
      </TableCell>
      <TableCell>
        <BaseInput
          disableGroupMargin={true}
          value={(price * 1.23).toFixed(2)}
          type={'number'}
          name={''}
          disabled={true}
          onChange={() => {
            return;
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const ProductPrices: FC<ProductPricesProps> = ({ record, fields, onUpdate, readonly }) => {
  const fifoField = fields.find(field => field.propertyPath === 'fifoPrice');

  if (!fifoField) {
    return null;
  }
  const updateFormValue = (value, field, key) => {
    const priceQuantities = record.priceValues.priceQuantities;
    priceQuantities[key][field] = value;

    onUpdate(prevRecord => ({
      ...prevRecord,
      priceValues: { ...record.priceValues, priceQuantities: [...priceQuantities] },
    }));
  };

  const handleFifoChange = value => {
    onUpdate(prevRecord => ({
      ...prevRecord,
      fifoPrice: value,
      ['@formValues']: {
        ...prevRecord['@formValues'],
        [fifoField.id]: value,
      },
    }));
  };
  return (
    <Container spacing={2}>
      <Col xs={4}>
        <Col xs={12} className={'p-3'}>
          <h4>Ceny stałe</h4>
        </Col>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Cena netto</TableCell>
              <TableCell>Cena brutto VAT 23%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Cena FIFO</TableCell>
              <TableCell>
                <NumberField
                  value={record?.fifoPrice}
                  type={'number'}
                  readonly={readonly}
                  onChange={handleFifoChange}
                  errorKey={'fifoPrice'}
                  field={{ ...fifoField, _tabindex: 1 }}
                  fields={fields.map(field => ({ ...field, _tabindex: 1 }))}
                />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            {record.priceValues?.priceQuantities.map(priceQuantity => (
              <TariffRow key={priceQuantity['@id']} priceQuantity={priceQuantity} record={record} />
            ))}
          </TableBody>
        </Table>
      </Col>
      <Col xs={8}>
        <Col xs={12} className={'p-3'}>
          <h4>Ceny ilościowe</h4>
        </Col>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Ilość od</TableCell>
              <TableCell>Ilość do</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} style={{ height: '85px' }}></TableCell>
            </TableRow>
            {record.priceValues?.priceQuantities.map((item, key) => (
              <TableRow key={key}>
                <TableCell>{item.tariff.name}</TableCell>
                <TableCell>
                  <BaseInput
                    disableGroupMargin={true}
                    value={item.from}
                    type={'number'}
                    name={'from'}
                    onChange={(value, field) => {
                      updateFormValue(parseInt(value), field, key);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <BaseInput
                    disableGroupMargin={true}
                    value={item.to}
                    type={'number'}
                    name={'to'}
                    onChange={(value, field) => {
                      updateFormValue(parseInt(value), field, key);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Col>
    </Container>
  );
};

export default ProductPrices;
