import LabelerId from '../CustomFields/LabelerId';
import InputAdditionalAction from './InputAdditionalAction';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import BuildEditor from 'ckeditor5-custom-build';
import classnames from 'classnames';
import * as URL from 'helpers/Api/Url';
import { axiosApi } from 'helpers/Axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, FormText, InputGroup, Label, Row } from 'reactstrap';
import { useAppSelector } from 'store';

interface EditorProps {
  className?: string;
  value: string;
  name: string;
  helpText?: string;
  label?: string | null;
  size?: { md: number };
  onChange: (data: string | number | null) => void;
  inputProps?: Record<string, unknown>;
  beforeInputContent?: React.ReactNode;
  afterInputContent?: React.ReactNode;
  disabled?: boolean;
  disableGroupMargin?: boolean;
  pure?: boolean;
  labelBadge?: string;
  errorKey?: string;
  fullHeight?: boolean;
  fullInputHeight?: boolean;
  verticalCenter?: boolean;
  fieldId?: string | null;
  labelerId?: number;
}

const Editor: React.FC<EditorProps> = ({
  className = 'mb-3',
  value,
  name,
  helpText,
  label = null,
  size = { md: 12 },
  onChange,
  inputProps = {},
  beforeInputContent,
  afterInputContent,
  disabled = false,
  disableGroupMargin = false,
  pure,
  errorKey,
  fullHeight,
  fullInputHeight,
  verticalCenter,
  fieldId = null,
  labelerId,
}) => {
  const { t } = useTranslation();
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));

  const hasError = (): boolean => {
    return violations.hasOwnProperty(errorKey ?? name);
  };

  const getError = (): string => {
    if (hasError()) {
      return violations[errorKey ?? name].join('\n');
    }
    return '';
  };

  const onEditorChange = (event: any, editor: any) => {
    onChange(editor.getData());
  };

  const uploadAdapter = (loader: any) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();

          loader.file.then((file: File) => {
            formData.append('file', file);
            axiosApi
              .post(URL.POST_MEDIA, formData)
              .then(result => {
                resolve({
                  default: result.data.contentUrl,
                });
              })
              .catch(error => {
                reject(error);
              });
          });
        });
      },
    };
  };

  if (pure) {
    return disabled ? (
      <div dangerouslySetInnerHTML={{ __html: value }} />
    ) : (
      <CKEditor
        data={value}
        onChange={onEditorChange}
        editor={BuildEditor}
        readOnly={disabled}
        disabled={disabled}
        onReady={editor => {
          editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
            return uploadAdapter(loader);
          };
        }}
        {...inputProps}
      />
    );
  }

  return (
    <Col {...size} className={className} style={fullHeight ? { height: '100%' } : {}}>
      <Row className={classnames('', { 'h-100': fullHeight })}>
        <FormGroup className={classnames({ 'disable-group-margin': disableGroupMargin })}>
          {label && label !== '' && (
            <Label>
              {labelerId && <LabelerId labelerId={labelerId} />}
              {t(label)} <InputAdditionalAction value={value} onChange={onChange} readonly={disabled} fieldId={fieldId} />
            </Label>
          )}
          <InputGroup
            style={{
              ...(verticalCenter
                ? {
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }
                : {}),
              ...(fullInputHeight ? { height: 'calc(100% - 34px)' } : {}),
            }}
          >
            {beforeInputContent}
            <div style={{ width: '100%' }}>
              {disabled ? (
                <div dangerouslySetInnerHTML={{ __html: value }} />
              ) : (
                <CKEditor
                  data={value}
                  onChange={onEditorChange}
                  editor={BuildEditor}
                  readOnly={disabled}
                  disabled={disabled}
                  onReady={editor => {
                    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
                      return uploadAdapter(loader);
                    };
                  }}
                  {...inputProps}
                  config={{
                    allowedContent: true,
                  }}
                />
              )}
            </div>
            {afterInputContent}
            {helpText && <FormText>{helpText}</FormText>}
            {hasError() && <FormFeedback>{getError()}</FormFeedback>}
          </InputGroup>
        </FormGroup>
      </Row>
    </Col>
  );
};

export default Editor;
