import { useEffect, useState } from 'react';

const ImagesFastSlider = ({ images, className }: { images: string[]; className?: string }) => {
  const [displayedIndex, setDisplayedIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayedIndex(prevState => {
        if (prevState + 1 === images.length) {
          return 0;
        }
        return prevState + 1;
      });
    }, 200);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className={className}>
      {images.map((image, index) => (
        <img key={index} src={image} alt={'image'} style={{ display: displayedIndex === index ? 'block' : 'none' }} />
      ))}
    </div>
  );
};

export default ImagesFastSlider;
