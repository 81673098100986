import { withSecure } from 'components/Theme/Common/SecuredView';
import Process from 'pages/Manufacture/Errand/Process';

export default [
  {
    path: '/modules/manufacture-errand/process/:id',
    exact: false,
    component: withSecure(Process, 'ROLE_EDIT_MANUFACTURE_ERRAND', true),
  },
];
