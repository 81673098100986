import { useOnlineUsersQuery } from 'Modules/Chat/Hooks/UseOnlineUsersQuery';
import useRoomInfo from 'Modules/Chat/Hooks/UseRoomInfo';
import useRoomsList from 'Modules/Chat/Hooks/UseRoomsList';
import useUnreadRoomsQuery from 'Modules/Chat/Hooks/UseUnreadRoomsQuery';
import { OnlineStatus } from 'Modules/Chat/Types/Api';
import { Room } from 'Modules/Chat/Types/ApiModel';
import Avatar from 'assets/images/users/avatar-1.jpg';
import moment from 'moment/moment';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link, useLocation } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import SimpleBar from 'simplebar-react';

export default function ChatDropdownIcon() {
  const [isOpen, toggle] = useToggle(false);
  const { data, isFetchingNextPage, isSuccess, hasNextPage, fetchNextPage } = useRoomsList();
  const {
    data: onlineUsers = [],
    isStale: onlineUsersQueryIsStale,
    isFetching: onlineUsersQueryIsFetching,
    refetch: refetchOnlineUsers,
  } = useOnlineUsersQuery();

  const unreadRooms = useUnreadRoomsQuery().data || [];

  const [inViewRef, inView] = useInView();
  const location = useLocation();

  useEffect(() => {
    if (isOpen && onlineUsersQueryIsStale && !onlineUsersQueryIsFetching) {
      void refetchOnlineUsers();
    }
  }, [isOpen, onlineUsersQueryIsFetching, onlineUsersQueryIsStale, refetchOnlineUsers]);

  useEffect(() => {
    toggle(false);
  }, [location, toggle]);

  useEffect(() => {
    if (isSuccess && inView && hasNextPage && !isFetchingNextPage) {
      inViewRef(null);
      void fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, isSuccess, isFetchingNextPage, inViewRef]);

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} className="dropdown d-inline-block" tag="li">
      <DropdownToggle className="btn header-item noti-icon" tag="button">
        <i className={isOpen ? 'bx bxs-message' : 'bx bx-message'} />
        <span className="badge bg-danger rounded-pill" hidden={unreadRooms.length === 0} children={unreadRooms.length} />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
        <div className="p-3">
          <Row className="align-items-center">
            <Col>
              <h6 className="m-0" children="Wiadomości" />
            </Col>
            <div className="col-auto">
              <Link to="/chat" className="small" children="Zobacz wszystkie" />
            </div>
          </Row>
        </div>
        <SimpleBar style={{ height: '230px' }}>
          {data.map(room => (
            <RoomElement key={room['@id']} room={room} onlineUsers={onlineUsers} unreadRooms={unreadRooms} />
          ))}
          <div ref={inViewRef} hidden={!hasNextPage}>
            <RoomPlaceHolder />
            <RoomPlaceHolder />
            <RoomPlaceHolder />
          </div>
          <div className="text-center" hidden={hasNextPage}>
            <span className="title">To już wszystko...</span>
          </div>
        </SimpleBar>
      </DropdownMenu>
    </Dropdown>
  );
}

interface RoomElementProps {
  room: Room;
  onlineUsers: OnlineStatus[];
  unreadRooms: string[];
}

function RoomElement({ room, onlineUsers, unreadRooms }: RoomElementProps) {
  const roomInfo = useRoomInfo(room, onlineUsers, unreadRooms);
  return (
    <Link to={`/chat/${room.id}`} className="text-reset notification-item">
      <div className="d-flex">
        <div className="position-relative me-3">
          <img src={Avatar} className="rounded-circle avatar-xs" alt="user-pic" />
          <span
            className={`position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle ${
              roomInfo.online ? 'bg-success' : 'bg-secondary'
            } p-1`}
          >
            <span className="visually-hidden">ONLINE</span>
          </span>
        </div>
        <div className="flex-grow-1 overflow-hidden">
          <h6 className={`mt-0 mb-1 text-truncate ${roomInfo.isRead ? '' : 'text-black'}`}>{roomInfo.name}</h6>
          <div className="font-size-12 text-muted">
            <p className={`mb-1 text-truncate ${roomInfo.isRead ? '' : 'text-black'}`}>
              {room?.lastMessage?.content ||
                (room.lastMessage?.attachments.length > 0 && `Wysłano ${room.lastMessage?.attachments.length} plików`)}
            </p>
            <p className={`mb-0 ${roomInfo.isRead ? '' : 'text-black'}`}>
              <i className="mdi mdi-clock-outline" />
              {moment(room?.lastMessage.createdAt).calendar()}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

function RoomPlaceHolder() {
  return (
    <div className="text-reset notification-item">
      <div className="d-flex placeholder-glow">
        <div className="avatar-xs me-3">
          <span className="avatar-title bg-primary rounded-circle font-size-16 placeholder"></span>
        </div>
        <div className="flex-grow-1">
          <h6 className="mt-0 mb-1 placeholder col-6"></h6>
          <div className="font-size-12 text-muted">
            <p className="mb-1">
              <span className="placeholder col-12" />
              <span className="placeholder col-12" />
            </p>
            <p className="mb-0">
              <span className="placeholder col-3"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
