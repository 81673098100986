// ts-ignore
import useStore from './store';
import { ContentCopy } from '@mui/icons-material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { shallowEqual } from 'react-redux';

interface ContextMenuProps {
  id: string;
  top: number;
  left: number;
  right: number;
  bottom: number;
  [key: string]: any;
}
export default function ContextMenu({ id, top, left, right, bottom, ...props }: ContextMenuProps) {
  const { removeNode, getSelectedProducts, updateState, nodes } = useStore(
    state => ({
      removeNode: state.removeNode,
      node: state.getNode(id),
      fields: state.fields,
      nodes: state.nodes,
      getSelectedProducts: state.getSelectedProducts,
      updateState: state.updateState,
    }),
    shallowEqual,
  );

  const selectedProducts = getSelectedProducts();

  return (
    <div style={{ left, top }} className="context-menu" {...props}>
      <Paper sx={{ width: 320 }}>
        <MenuList dense>
          <MenuItem onClick={() => removeNode(id)}>
            <ListItemIcon>
              <RemoveCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Usuń węzeł i jego dzieci</ListItemText>
          </MenuItem>
          {selectedProducts.length > 0 && (
            <MenuItem onClick={() => updateState({ nodesToDuplicate: selectedProducts, nodesToDuplicateModal: true })}>
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>Duplikuj wybrane ({selectedProducts.length}) produkt(y)</ListItemText>
            </MenuItem>
          )}
          <Divider />
          <MenuItem>
            <ListItemText>Zamknij</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    </div>
  );
}
