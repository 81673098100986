import SectionCard from '../Atoms/SectionCard';
import Header from './Header';
import MessagesWithInput from './MessagesWithInput';
import { Divider } from '@mui/material';
import useChatManipulationFunctions from 'Modules/Chat/Hooks/UseChatManipulationFunctions';
import { Room } from 'Modules/Chat/Types/ApiModel';
import useApiResourceQuery from 'helpers/Axios';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export default function RoomView() {
  const roomId = useParams<{ id: string }>().id;
  const roomIri = '/chat/rooms/' + roomId;
  const history = useHistory();
  const [{ data: room }] = useApiResourceQuery<Room>(roomIri, {
    onError: () => history.replace('/chat'),
  });
  const { toggleRoomIsHidden } = useChatManipulationFunctions();
  useEffect(() => {
    if (!room || !room.isHidden) {
      return;
    }

    toggleRoomIsHidden(room['@id'], false);
  });

  return (
    <SectionCard>
      {room && <Header room={room} />}
      <Divider />
      {room && <MessagesWithInput room={room} />}
    </SectionCard>
  );
}
