import Loader from '@Components/Theme/Common/Loader';
import { Button } from '@mui/material';
import { usePropertyValues } from 'Modules/Core/Hooks/usePropertyValues';
import ListPicker from 'components/Form/ListPicker';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';
import swal from 'sweetalert';

const StatusPicker = ({ field, value, onChange, readonly, errorKey }) => {
  const { data: statuses, isLoading } = usePropertyValues('manufacture-production-order', 'status');
  const dispatch = useDispatch();

  if (isLoading) {
    return <Loader />;
  }

  const onChangeConfirm = newValue => {
    if (value >= 1200 && value > newValue) {
      dispatch(addSingleToast({ title: `Status zlecenia nie może zostać cofnięty!`, config: { appearance: 'error' } }));
      setTimeout(() => {
        onChange(newValue, field.id);
      }, 1);
      setTimeout(() => {
        onChange(value, field.id);
      }, 2);
    }

    if (newValue !== 2000) {
      onChange(newValue, field.id);

      return;
    }

    return swal({
      title: 'Czy jesteś pewien?',
      text: 'Wszystkie produkty dla tego zlecenia nie zostały jeszcze wpyrodukowane.',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Anuluj',
          value: null,
          visible: true,
        },
        confirm: {
          text: 'Tak, jestem pewien',
          visible: true,
          value: true,
          closeModal: true,
        },
      },
    }).then(result => {
      onChange(result ? newValue : 1000, field.id);
    });
  };

  return (
    <ListPicker
      name={field.id}
      label={field.name}
      tabIndex={field._tabindex}
      value={(value !== null ? value : field.defaultValue) ?? 500}
      options={statuses}
      optionLabel={item => (
        <Button
          variant={'contained'}
          style={{
            width: '100%',
            padding: '3px',
            color: '#fff',
            margin: '0 2px',
            backgroundColor: item?.color,
          }}
        >
          {item?.label}
        </Button>
      )}
      optionTrackBy="value"
      disabled={readonly}
      disableGroupMargin={true}
      labelBadge={field.position}
      errorKey={errorKey}
      onChange={value => onChangeConfirm(value)}
    />
  );
};

export default StatusPicker;
