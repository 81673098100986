import { all, fork } from 'redux-saga/effects';
import AppConfigurationSaga from 'store/AppConfiguration/Configuration/Saga';
import EmployeeConfigurationSaga from 'store/Employee/Configuration/Saga';
import TaskManagementSaga from 'store/TaskManagement/Saga';
import AuthSaga from 'store/auth/login/saga';
import LayoutSaga from 'store/layout/saga';

export default function* rootSaga() {
  yield all([fork(AuthSaga), fork(LayoutSaga), fork(EmployeeConfigurationSaga), fork(AppConfigurationSaga), fork(TaskManagementSaga)]);
}
