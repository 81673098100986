import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import IconPicker from 'components/CustomFields/FieldTypes/IconPicker';

export type Machine = CTMRecord & {
  name?: string;
  symbol?: string;
};

const columns: CTMListColumn<Machine>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: row => (
      <>
        <span className="badge text-white font-size-12" style={{ padding: 6, background: `${row.color || '#edf0f5'}` }}>
          <i className={`mdi mdi-${row?.icon}`} />
        </span>
        &nbsp;{row.name}
      </>
    ),
  },
  {
    id: 'symbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol',
    accessor: 'symbol',
  },
];

const module: CTMModule<Machine> = {
  id: '706e3d8e-7fce-4c8d-88ca-5fbde563f5d2',
  dataClass: 'CTM\\Manufacture\\Entity\\Machine',
  urlPrefix: 'manufacture-machines',
  name: 'Maszyny',
  role: 'MANUFACTURE_MACHINE',
  api: {
    item: {
      get: ({ id }) => `/manufacture/machines/${id}`,
      put: ({ id }) => `/manufacture/machines/${id}`,
      delete: ({ id }) => `/manufacture/machines/${id}`,
    },
    collection: {
      get: `/manufacture/machines`,
      post: `/manufacture/machines`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {
      icon: IconPicker,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
