import Loader from '@Components/Theme/Common/Loader';
import { SystemFieldModule } from 'Modules/CTMModules';
import BaseInput from 'components/Form/BaseInput';
import ListPicker from 'components/Form/ListPicker';
import BaseForm from 'components/Theme/Common/BaseForm';
import { Col, Container } from 'components/Theme/Grid';
import { useErrorHandler } from 'helpers/FormHandler';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { clearErrors } from 'store/form/errors/actions';

const Form = ({ match, readonly = undefined }) => {
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState<{
    name?: string;
    type?: string;
    defaultValue?: string;
  }>({
    name: '',
    type: '',
    defaultValue: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const id = match?.params?.id || null;

  const updateFormValue = (value, field) => {
    setForm(prevForm => ({ ...prevForm, [field]: value }));
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    dispatch(clearErrors());
    if (id) {
      SystemFieldModule.api
        .put(form, { id: id })
        .then(() => history.push('/configuration/system-field'))
        .catch(errorHandler)
        .finally(() => setIsSubmitting(false));
    } else {
      SystemFieldModule.api
        .post(form)
        .then(() => history.push(`/configuration/system-field`))
        .catch(errorHandler)
        .finally(() => setIsSubmitting(false));
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(clearErrors());
    const fetchData = async () => {
      if (id) {
        const field = await SystemFieldModule.api.get({ id: id });
        setForm({
          ...form,
          ...field,
        });
      }
    };

    fetchData().then(() => {
      setLoading(false);
    });
  }, []);

  const DetailsTab = () => (
    <>
      <Container justifyContent="space-between">
        <Col xs={12} md={42} lg={12}>
          <Container spacing={2}>
            <Col xs={12} md={4} lg={4}>
              <BaseInput
                size={{ md: 12 }}
                className={''}
                value={form.name}
                type={'text'}
                name="name"
                onChange={updateFormValue}
                label={'Nazwa pola'}
              />
            </Col>
            <Col xs={12} md={4} lg={4}>
              <ListPicker
                value={form.type}
                label={'Typ pola'}
                options={[
                  { value: 'STRING', label: 'Tekst' },
                  { value: 'NUMBER', label: 'Numeryczne' },
                ]}
                optionTrackBy="value"
                optionLabel="label"
                onChange={value => updateFormValue(value, 'type')}
                name={''}
              />
            </Col>
            <Col xs={12} md={4} lg={4}>
              <BaseInput
                size={{ md: 12 }}
                className={''}
                value={form.defaultValue}
                type={'text'}
                name="defaultValue"
                onChange={updateFormValue}
                label={'Wartość pola'}
              />
            </Col>
          </Container>
        </Col>
      </Container>
    </>
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <BaseForm
      title={`Pole systemowe - ${readonly ? 'Podgląd' : id ? 'Edytuj' : 'Dodaj'}`}
      tabs={[{ title: 'Ogólne', content: DetailsTab() }]}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      readonly={readonly}
      contextActions={
        <>
          <button
            className="btn btn-primary ml-1"
            onClick={() => {
              history.goBack();
            }}
          >
            <i className="mdi mdi-arrow-left" />
            Wróć do listy
          </button>
        </>
      }
    />
  );
};

export default withRouter(Form);
