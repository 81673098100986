import { CLOSE_TASK, RELOAD_TASK, SET_LOADING, SET_MODAL_OPENED, SET_TASK, SET_TASK_IRI } from 'store/TaskManagement/ActionTypes';

export const openTask = id => {
  return {
    type: SET_TASK_IRI,
    payload: id,
  };
};
export const closeTask = () => {
  return {
    type: CLOSE_TASK,
    payload: null,
  };
};
export const reloadTask = () => {
  return {
    type: RELOAD_TASK,
    payload: null,
  };
};

export const setLoading = loading => {
  return {
    type: SET_LOADING,
    payload: loading,
  };
};

export const setModalOpened = loading => {
  return {
    type: SET_MODAL_OPENED,
    payload: loading,
  };
};
export const setTask = task => {
  return {
    type: SET_TASK,
    payload: task,
  };
};
