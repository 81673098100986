import BaseScalarInput, { BaseScalarInputType } from 'components/CustomFields/FieldTypes/BaseScalarInput';

const ReadonlyField: BaseScalarInputType = props =>
  BaseScalarInput({
    ...props,
    type: 'text',
    inputProps: { style: { height: '100%' } },
    readonly: true,
    onChange: () => false,
  });

export default ReadonlyField;
