import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import CalculatorsField from 'pages/Manufacture/Unit/Components/CalculatorsField';

export type Unit = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<Unit>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
];

const module: CTMModule<Unit> = {
  id: 'd8da397d-0793-4880-862a-efb237d9a37f',
  dataClass: 'CTM\\Manufacture\\Entity\\Unit',
  urlPrefix: 'manufacture-units',
  name: 'Jednostki',
  role: 'MANUFACTURE_PRODUCT',
  api: {
    item: {
      get: ({ id }) => `/manufacture/units/${id}`,
      put: ({ id }) => `/manufacture/units/${id}`,
      delete: ({ id }) => `/manufacture/units/${id}`,
    },
    collection: {
      get: `/manufacture/units`,
      post: `/manufacture/units`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {
      sourceCalculators: CalculatorsField,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
