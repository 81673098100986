// Auth
import CalendarRoutes from 'Modules/Calendar/Routes';
import ContractorRoutes from 'Modules/Contractor/AdditionalRoutes';
import CoreRoutes from 'Modules/Core/routes';
import ManufactureProductRoutes from 'Modules/Manufacture/Route/ProductRoutes';
import Login from 'pages/Authentication/Login';
import Logout from 'pages/Authentication/Logout';
import LostPassword from 'pages/Authentication/LostPassword';
import LostPin from 'pages/Authentication/LostPin';
import ResetPassword from 'pages/Authentication/ResetPassword';
import ChatRoutes from 'pages/Chat/routes';
import ConfigurationRoutes from 'pages/Configuration/routes';
// Dashboard
import Dashboard from 'pages/Dashboard/Dashboard';
import ManufactureErrandRoutes from 'pages/Manufacture/Errand/routes';
import ManufactureRoutes from 'pages/Manufacture/routes';
import Form from 'pages/Module/Form';
import List from 'pages/Module/List';
import NotesRoutes from 'pages/Note/routes';
import WarehouseRoutes from 'pages/Warehouse/routes';
import { Redirect } from 'react-router-dom';

const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },
  ...ConfigurationRoutes,
  ...WarehouseRoutes,
  ...NotesRoutes,
  ...ChatRoutes,
  ...CoreRoutes,
  ...ManufactureErrandRoutes,
  ...ManufactureRoutes,
  ...ContractorRoutes,
  ...CalendarRoutes,
  ...ManufactureProductRoutes,
  { path: '/modules/:module/list', exact: true, component: () => <List /> },
  { path: '/modules/:module/add', exact: true, component: () => <Form readonly={false} /> },
  { path: '/modules/:module/edit/:id', exact: true, component: () => <Form readonly={false} /> },
  { path: '/modules/:module/show/:id', exact: true, component: () => <Form readonly={true} /> },
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  { path: '/forgot-password', component: LostPassword },
  { path: '/forgot-pin', component: LostPin },
  { path: '/reset-password/:token', component: ResetPassword },
];

export { authProtectedRoutes, publicRoutes };
