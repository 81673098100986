import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type Document = CTMRecord & {
  number?: string;
};

const columns: CTMListColumn<Document>[] = [];

const module: CTMModule<Document> = {
  id: 'NOT_SET',
  dataClass: 'CTM\\Warehouse\\Entity\\Document\\Document',
  urlPrefix: 'warehouse-document',
  name: 'Dokumenty',
  role: 'WAREHOUSE_DOCUMENT',
  api: {
    item: {
      get: ({ id }) => `/warehouse/documents/${id}`,
      put: ({ id }) => `/warehouse/documents/${id}`,
      delete: ({ id }) => `/warehouse/documents/${id}`,
    },
    collection: {
      get: `/warehouse/documents`,
      post: `/warehouse/documents`,
    },
  },
  recordLabel: ({ number }) => number,
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
