// users
import user1 from 'assets/images/users/avatar-1.jpg';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
//i18n
import { withTranslation } from 'react-i18next';
// Redux
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useAppSelector } from 'store';

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);

  const { user } = useAppSelector(state => ({
    user: state.Login.user,
  }));

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <img className="rounded-circle header-profile-user" src={user?.details?.image || user1} alt="Header Avatar" />
          <span className="d-none d-xl-inline-block ms-2 me-1">{user?.email}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ProfileMenu));
