import BaseInput from 'components/Form/BaseInput';

const NumberTypeConfiguration = ({ configuration, onConfigurationUpdated, system }) => {
  const updateConfiguration = (value, field) => {
    onConfigurationUpdated({ ...configuration, [field]: value });
  };

  return (
    <>
      <BaseInput
        size={{ md: 12 }}
        label={'Predefiniowane opcje (pozostaw puste aby pozwolić wprowadzać dowolną liczbę)'}
        value={configuration?.predefinedOptions ?? ''}
        name={'predefinedOptions'}
        onChange={updateConfiguration}
      />
      <BaseInput
        size={{ md: 12 }}
        label={'Suffix pola (np. %)'}
        value={configuration?.suffix ?? ''}
        disabled={system}
        name={'suffix'}
        onChange={updateConfiguration}
      />
    </>
  );
};

export default NumberTypeConfiguration;
