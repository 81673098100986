import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import ModuleListPicker from '@Components/Module/ModuleListPicker';
import { ValueAddedTax } from '@Core/Accountancy/ValueAddedTax';
import { Country } from '@Core/Country';
import { BaseLinkerIntegrationPriceGroupMapping } from '@Integration/BaseLinkerIntegration';
import { Tariff } from '@Manufacture/Price/Tariff';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface GroupMappingRowProps {
  mapping: BaseLinkerIntegrationPriceGroupMapping;
  readonly: boolean;
  onChange: (mapping: BaseLinkerIntegrationPriceGroupMapping) => void;
}

const GroupMappingRow: FC<GroupMappingRowProps> = props => {
  return (
    <TableRow>
      <TableCell>{props.mapping.baseLinkerPriceGroupId}</TableCell>
      <TableCell>{props.mapping.name}</TableCell>
      <TableCell>{props.mapping.currency}</TableCell>
      <TableCell>
        <ModuleListPicker
          disabled={props.readonly}
          moduleName={'manufacture-price-tariffs'}
          onChange={(selectedTariff: Tariff | null) => props.onChange({ ...props.mapping, tariff: selectedTariff })}
          readerValue={props.mapping.tariff}
        />
      </TableCell>
      <TableCell>
        <ModuleListPicker
          disabled={props.readonly}
          moduleName={'core-accountancy-value-added-taxes'}
          onChange={(selectedVat: ValueAddedTax | null) => props.onChange({ ...props.mapping, vat: selectedVat })}
          readerValue={props.mapping.vat}
        />
      </TableCell>
      <TableCell>
        <ModuleListPicker<Country>
          disabled={props.readonly}
          moduleName={'core-country'}
          onChange={selectedCountry => props.onChange({ ...props.mapping, deliveryCountry: selectedCountry })}
          readerValue={props.mapping.deliveryCountry}
        />
      </TableCell>
    </TableRow>
  );
};

const GroupMappings: CustomFieldComponentType = ({ field, value, onChange, readonly }) => {
  const [mappings, setMappings] = useState<{ [key: number]: BaseLinkerIntegrationPriceGroupMapping }>(value ?? {});

  useEffect(() => {
    if (JSON.stringify(mappings) !== JSON.stringify(value)) {
      onChange(mappings, field.id);
    }
  }, [JSON.stringify(mappings)]);

  return (
    <Table style={{ minWidth: 640 }}>
      <TableHead>
        <TableRow>
          <TableCell>ID grupy cenowej</TableCell>
          <TableCell>Nazwa</TableCell>
          <TableCell>Waluta</TableCell>
          <TableCell>Taryfa</TableCell>
          <TableCell>Stawka VAT</TableCell>
          <TableCell>Kraj dostawy</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(mappings).map(itemKey => (
          <GroupMappingRow
            key={itemKey}
            mapping={mappings[itemKey]}
            readonly={readonly}
            onChange={mapping => setMappings({ ...mappings, [itemKey]: mapping })}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default GroupMappings;
