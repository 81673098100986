import ListPicker from 'components/Form/ListPicker';
import * as DocumentApi from 'helpers/Api/Warehouse/Document/Document';
import { ReactComponentElement, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';
import { v4 as uuidv4 } from 'uuid';

const LabelCreated = () => (
  <span style={{ paddingRight: 4, color: '#303030', display: 'flex', alignItems: 'center' }}>
    <i className="bx bx-checkbox" style={{ paddingRight: 4, fontSize: 24, display: 'initial' }} />
    <span>Utworzony</span>
  </span>
);

const LabelAccepted = () => (
  <span style={{ paddingRight: 4, color: '#2e7d32', display: 'flex', alignItems: 'center' }}>
    <i className="bx bx-checkbox-checked" style={{ paddingRight: 4, fontSize: 24 }} />
    <span>Zaakceptowany</span>
  </span>
);

const LabelCompleted = () => (
  <span style={{ paddingRight: 4, color: '#556ee6ff', display: 'flex', alignItems: 'center' }}>
    <i className="bx bx-transfer" style={{ paddingRight: 4, fontSize: 24 }} />
    <span>Zakończony</span>
  </span>
);

const LabelRejected = () => (
  <span style={{ paddingRight: 4, color: '#d32f2f', display: 'flex', alignItems: 'center' }}>
    <i className="bx bx-checkbox-square" style={{ paddingRight: 4, fontSize: 24 }} />
    <span>Odrzucony</span>
  </span>
);

const Labels = {
  1000: {
    value: '1000',
    label: <LabelCreated />,
  },
  2000: {
    value: '2000',
    label: <LabelAccepted />,
  },
  3000: {
    value: '3000',
    label: <LabelRejected />,
  },
};

const LabelsAccepted = {
  2000: {
    value: '2000',
    label: <LabelAccepted />,
  },
  2100: {
    value: '2100',
    label: <LabelCompleted />,
  },
};
const StatusPicker = ({ id, value, placeEvent }): ReactComponentElement<any> => {
  const [val, setVal] = useState<string>(value);
  const [key, setKey] = useState<string>(uuidv4());
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onChange = useCallback((changedVal: string | (string | null)[] | null) => {
    if (changedVal && changedVal.constructor === String && changedVal !== val) {
      setLoading(true);
      DocumentApi.update(id, { status: parseInt(changedVal) })
        .then(() => {
          setVal(changedVal);
          setLoading(false);
          dispatch(addSingleToast({ title: `Zapisano zmiany`, config: { appearance: 'success' } }));
        })
        .catch(err => {
          setLoading(false);
          setKey(uuidv4());
          dispatch(
            addSingleToast({
              title: err?.response?.['hydra:description'] ?? 'Wystąpił błąd z zapisem',
              config: { appearance: 'error' },
            }),
          );
        });
    }
  }, []);

  const isChangeAvailable = useMemo<boolean>((): boolean => {
    return val === '1000' || (val === '2000' && ['RESERVATION_WAREHOUSE', 'RESERVATION_ORDER'].indexOf(placeEvent) >= 0);
  }, [placeEvent, val]);

  const options = useMemo((): any[] => {
    if (['RESERVATION_WAREHOUSE', 'RESERVATION_ORDER'].indexOf(placeEvent) >= 0 && val === '2000') {
      return Object.values(LabelsAccepted);
    }
    return Object.values(Labels);
  }, [placeEvent, val]);
  if (!isChangeAvailable) {
    return <>{{ ...Labels, ...LabelsAccepted }[val]?.label}</>;
  }

  return (
    <ListPicker
      key={key}
      value={val + ''}
      onChange={onChange}
      disabled={loading || !isChangeAvailable}
      options={options}
      optionTrackBy={'value'}
      name={'currency'}
      optionLabel={'label'}
    />
  );
};
export default StatusPicker;
