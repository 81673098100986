import Loader from '@Components/Theme/Common/Loader';
import ReportDownloadButton from 'components/DataGrid/Button/ReportDownloadButton';
import BaseForm from 'components/Theme/Common/BaseForm';
import { Col, Container } from 'components/Theme/Grid';
import moment from 'moment';
import { useMemo, useState } from 'react';

const StoreItemReport = () => {
  const [loading, setLoading] = useState(true);

  const url = useMemo(() => {
    const parameters = { limit: 9999999999999 };
    return `warehouse/report/warehouse-amounts?${parameters}`;
  }, []);

  setTimeout(() => setLoading(false), 500);

  const DetailsTab = () => (
    <>
      <Container justifyContent="space-between">
        <Col xs={12} md={6} lg={6}>
          <div className={'align-self-center justify-content-end mt-4'}>
            <ReportDownloadButton
              url={url}
              name={`raport_wartości_magazynów_${moment().format('YYYY_MM_DD_HH_mm_ss')}`}
              description={'Kliknij aby pobrać raport'}
              label={'Pobierz raport'}
            />
          </div>
        </Col>
      </Container>
    </>
  );

  if (loading) {
    return (
      <div className="container-fluid">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <BaseForm
        title={`Raport wartości magazynów`}
        tabs={[{ title: 'Ogólne', content: <DetailsTab /> }]}
        readonly={true}
        contextActions={
          <>
            <button
              className="btn btn-primary ml-1"
              onClick={() => {
                history.goBack();
              }}
            >
              <i className="mdi mdi-arrow-left" />
              Wróć do listy
            </button>
          </>
        }
      />
    </div>
  );
};

export default StoreItemReport;
