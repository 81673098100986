import { HighlightOff } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Container } from 'components/Theme/Grid';
import { useModuleContext } from 'context/ModulesContext';
import { get } from 'helpers/Axios';
import { FC, ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Modal, ModalBody } from 'reactstrap';

interface ReservationModalProps {
  productId: string;
  type: string;
  children: ReactElement;
}

interface DocumentQuantityProps {
  productId: string;
  documentId: string;
}

interface DocumentOwnerProps {
  ownerIri: string;
}

const DocumentQuantity: FC<DocumentQuantityProps> = ({ productId, documentId }) => {
  const [quantity, setQuantity] = useState<number>(0);

  const resolveQuantity = (): void => {
    get(`/warehouse/documents/${documentId}`).then((response: any) => {
      const quantity = response.rows.reduce((accumulator, row) => accumulator + (row.product.id === productId ? row.quantity : 0), 0);
      setQuantity(quantity);
    });
  };

  useEffect(() => {
    resolveQuantity();
  }, [documentId]);

  return <>{quantity}</>;
};

const DocumentOwner: FC<DocumentOwnerProps> = ({ ownerIri }) => {
  const [owner, setOwner] = useState<any>(null);
  const module = useModuleContext(owner?.moduleName ?? '');

  const resolveOwner = (): void => {
    get(ownerIri).then((response: any) => {
      setOwner(response);
    });
  };

  useEffect(() => {
    resolveOwner();
  }, [ownerIri]);

  return <Link to={module?.showUrl(owner?.id) ?? ''}>{owner?.relationFieldLabel}</Link>;
};

const ReservationModal: FC<ReservationModalProps> = ({ productId, type, children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [documents, setDocuments] = useState<any>([]);

  const resolveDocuments = (): void => {
    get(`/warehouse/documents?product=${productId}&type.code=${type}&status=2000&limit=9999`).then(response =>
      setDocuments(response['hydra:member']),
    );
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    resolveDocuments();
  }, [open]);

  return (
    <>
      <a onClick={() => setOpen(true)}>{children}</a>
      <Modal
        style={{ maxWidth: '400px' }}
        isOpen={open}
        centered={true}
        size={'sm'}
        backdrop={true}
        toggle={() => setOpen(false)}
        backdropClassName={'asd'}
      >
        <ModalBody className="py-3 px-5">
          <HighlightOff style={{ position: 'absolute', top: 10, right: 10 }} onClick={() => setOpen(false)} />
          <Container spacing={2}>
            <Col xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Dokument</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Powiązanie</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Ilość</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents.map((item: any, key: number) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Link to={`/warehouse/documents/${item.id}`}>{item.number}</Link>
                      </TableCell>
                      <TableCell>
                        <DocumentOwner ownerIri={item.owner} />
                      </TableCell>
                      <TableCell>
                        <DocumentQuantity documentId={item.id} productId={productId} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Col>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReservationModal;
