import { confirmRemove } from 'common/sweetalerts';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModuleContext } from 'context/ModulesContext';
import { useErrorHandler } from 'helpers/FormHandler';
import { useState } from 'react';
import { Button } from 'reactstrap';

interface LockButtonProps {
  id: string;
  locked: boolean;
  listRef: any;
  big?: boolean;
  onChanged?: (newState: boolean) => void;
}

const IconButton = ({ loading, locked }: { loading: boolean; locked: boolean }) => {
  return (
    <>
      {loading && <i className={'mdi mdi-spin mdi-cogs'} />}
      {!loading && (
        <>
          {locked && <i className="mdi mdi-lock" />}
          {!locked && <i className="mdi mdi-lock-open-variant" />}
        </>
      )}
    </>
  );
};

const BigButton = ({ onClick, loading, locked }) => {
  return (
    <span>
      <button className="btn btn-info ml-1" onClick={onClick}>
        <IconButton locked={locked} loading={loading} />
      </button>
    </span>
  );
};

const SmallButton = ({ onClick, loading, locked }) => {
  return (
    <Button className="btn btn-info  btn-sm" style={{ fontSize: 16, padding: '0 10px' }} onClick={onClick}>
      <IconButton locked={locked} loading={loading} />
    </Button>
  );
};

const LockButton = (props: LockButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [tmpState, setTmpState] = useState<boolean | null>(null);
  const ProductModule = useModuleContext('manufacture-products');
  const errorHandler = useErrorHandler();
  if (!ProductModule) {
    return null;
  }

  const Wrapper = props.big ? BigButton : SmallButton;

  const toggle = () => {
    if (loading) {
      return;
    }
    const newState = !(tmpState ?? props.locked);
    confirmRemove(
      () => toggleAction(newState),
      'Czy jesteś pewien',
      `Potwierdzając tą akcję ${newState ? 'zablokujesz' : 'odblokujesz'} dostęp do edycji towaru osobom bez odpowiedniej roli`,
      true,
      `${newState ? 'Zablokowano' : 'Odblokowano'} dostęp do edycji`,
    );
  };

  const toggleAction = (newState: boolean) => {
    return new Promise<null>((res, rej) => {
      setLoading(true);
      setTmpState(newState);
      ProductModule.api
        .put({ locked: newState }, { id: props.id })
        .then(() => {
          props.onChanged && props.onChanged(newState);
          setLoading(false);
        })
        .catch(errorHandler)
        .finally(() => {
          props.listRef?.refresh();
          res(null);
        });
    });
  };

  const locked = tmpState ?? props.locked;
  return (
    <SecuredView role={`ROLE_LOCK_MANUFACTURE_PRODUCT`}>
      <Wrapper onClick={toggle} loading={loading} locked={locked} />
    </SecuredView>
  );
};

export default LockButton;
