import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store';

export const NAME_GRID = 'Grid';

export function useGetConfiguration(name) {
  const { configuration } = useAppSelector(
    state => ({
      configuration: state.EmployeeConfiguration.configuration,
    }),
    shallowEqual,
  );

  return configuration
    .filter(item => {
      return item.name === name;
    })
    .find(Boolean)?.configuration;
}
