import { CLOSE_TASK, SET_LOADING, SET_MODAL_OPENED, SET_TASK, SET_TASK_IRI } from 'store/TaskManagement/ActionTypes';
import { v4 as uuidv4 } from 'uuid';

export type TaskManagementState = {
  queryKey: any;
  taskIRI: string | null;
  task: any | null;
  loading: boolean;
  modalOpened: boolean;
  lastOpenedTask: any | null;
};
const initState = (): TaskManagementState => ({
  queryKey: null,
  taskIRI: null,
  task: null,
  loading: false,
  modalOpened: false,
  lastOpenedTask: null,
});

const initialState = initState();

const Configuration = (state = initialState, action) => {
  switch (action.type) {
    case SET_TASK_IRI:
      state = {
        ...state,
        taskIRI: action.payload,
      };
      break;
    case SET_TASK:
      state = {
        ...state,
        task: action.payload,
      };
      break;
    case SET_LOADING:
      state = {
        ...state,
        loading: action.payload,
      };
      break;
    case SET_MODAL_OPENED:
      state = {
        ...state,
        modalOpened: action.payload,
      };
      break;
    case CLOSE_TASK:
      state = {
        ...state,
        queryKey: uuidv4(),
        taskIRI: null,
        task: null,
        loading: false,
        modalOpened: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Configuration;
