import ProductGroup from '../Manufacture/ProductGroup';
import ProductCollectionFields from './Components/ProductCollectionFields';
import ProductCollectionRows from './Components/ProductCollectionRows';
import CheckboxColumn from '@Components/DataGrid/ColumnView/CheckboxColumn';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import BaseLinker from 'Modules/Manufacture/ProductCollection/Component/BaseLinker';
import { SelectColumnFilter, SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';
import { extractIRIFromRecord } from 'helpers/ModuleUtils';
import ProductGroupView from 'pages/Manufacture/Product/ListComponents/ProductGroup';

export type ProductCollection = CTMRecord & {
  id?: string;
};

const columns: CTMListColumn<ProductCollection>[] = [
  {
    id: 'group.id',
    filterable: true,
    sortable: true,
    Header: 'Kategoria',
    accessor: 'group.name',
    Cell: ({ row }) => <ProductGroupView name={row.original.group?.name} path={row.original.group?.relationFieldLabel} />,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: ProductGroup,
  },
  {
    id: 'title',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    noBreak: true,
    accessor: 'title',
  },
  {
    id: 'elementsCount',
    filterable: false,
    sortable: false,
    Header: 'Liczba elementów',
    noBreak: true,
    accessor: 'elementsCount',
  },
  {
    id: 'elementsList',
    filterable: false,
    sortable: false,
    Header: 'Elementy',
    noBreak: false,
    accessor: 'elementsList',
    Cell: ({ row }) =>
      row.original.elementsList.map(item => (
        <>
          {item}
          <br />
        </>
      )),
  },
  {
    id: 'active',
    Header: 'Aktywny',
    accessor: ({ active }) => <CheckboxColumn active={active} />,
    filterable: true,
    Filter: SelectColumnFilter,
    filterOptions: [
      { value: null, label: 'Wszystkie' },
      { value: true, label: 'Tak' },
      { value: '0', label: 'Nie' },
    ],
  },
];

const module: CTMModule<ProductCollection> = {
  id: 'df217d5f-9cf1-4e11-9c64-795552c9d0c6',
  dataClass: 'CTM\\Ecommerce\\Entity\\ProductCollection',
  urlPrefix: 'ecommerce-product-collection',
  name: 'Kolekcje produktów',
  role: 'MANUFACTURE_PRODUCT',
  api: {
    item: {
      get: ({ id }) => `/ecommerce/product-collections/${id}`,
      put: ({ id }) => `/ecommerce/product-collections/${id}`,
      delete: ({ id }) => `/ecommerce/product-collections/${id}`,
    },
    collection: {
      get: `/ecommerce/product-collections`,
      post: `/ecommerce/product-collections`,
      baseLinker: `/ecommerce/product-collections/base-linker`,
    },
  },
  recordLabel: (record, allFields) => record.title ?? 'Nowa kolekcja produktów',
  form: {
    prepareRecordToSave: record => {
      return {
        ...record,
        variantFields: record.variantFields.map(extractIRIFromRecord),
        elements: (record.elements ?? []).map(el => ({
          ...el,
          product: extractIRIFromRecord(el.product),
          vat: extractIRIFromRecord(el.vat),
        })),
      };
    },
    defaultRecord: (allFields, currentUser) => {
      const indexedFields = allFields
        .filter(el => el.system)
        .reduce<{ [key: string]: string }>((acc, el) => {
          acc[el.propertyPath] = el.id;
          return acc;
        }, {});

      return {
        '@formValues': {
          [indexedFields.active]: false,
        },
        active: false,
      };
    },
    fieldComponents: {
      elements: ProductCollectionRows,
      variantFields: ProductCollectionFields,
    },
    contextActions: ({ readonly, record, ApiFormComponent }) => (
      <>{record.id && <BaseLinker id={record.id} externalIds={record.externalIds} />}</>
    ),
  },
  list: {
    columns: columns,
    storeFilters: true,
  },
};

export default module;
