import { GET_CONFIGURATION, SET_CONFIGURATION } from 'store/Employee/Configuration/ActionTypes';

export const getConfiguration = () => {
  return {
    type: GET_CONFIGURATION,
    payload: {},
  };
};

export const setConfiguration = configuration => {
  return {
    type: SET_CONFIGURATION,
    payload: configuration,
  };
};
