import { Tooltip } from '@mui/material';
import BaseInput from 'components/Form/BaseInput';
import { InputGroupText } from 'reactstrap';
import swal from 'sweetalert';

const EanField = ({ field, value, onChange, readonly }) => {
  const generate = () => {
    swal({
      title: 'Czy jesteś pewien?',
      text: 'Akacja spowoduje zajęcie kolejnego kodu EAN w bazie GS1, a take dodanie produktu do zewnętrznej bazy GS1.',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Anuluj',
          value: null,
          visible: true,
        },
        confirm: {
          text: 'Potwierdzam',
          visible: true,
          value: true,
          closeModal: false,
        },
      },
    }).then(result => {
      if (result) {
        onChange('5907222250014', field.id);
        swal.close();
      }
    });
  };

  return (
    <>
      <BaseInput
        size={{ md: 12 }}
        label={field.name}
        labelBadge={field.position}
        value={value}
        name={field.id}
        disableGroupMargin={true}
        type={'text'}
        onChange={onChange}
        disabled={readonly}
        afterInputContent={
          <>
            {!readonly && (
              <>
                <InputGroupText>
                  <Tooltip title={'Generowanie kodu EAN z puli GS1 i dodanie produktu do bazy GS1.'}>
                    <i className={`mdi mdi-barcode-scan`} style={{ cursor: 'pointer' }} onClick={generate} />
                  </Tooltip>
                </InputGroupText>
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default EanField;
