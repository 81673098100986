import { HighlightOff, RemoveCircle } from '@mui/icons-material';
import classnames from 'classnames';
import DataGrid, { DataGridRef } from 'components/DataGrid';
import { ComponentRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

type ListSelectorProps = {
  dataGridProps?: any;
  wrapperProps?: any;
  wrapperComponent?: any;
  children?: any;
  disabled?: boolean;
  modalStyles?: any;
  title: string;
  navbar: any;
  ref?: any;
};

type listSelectorHandle = {
  open: (parameters?: any[]) => void;
  close: () => void;
  refresh: () => void;
  getVisibleData: () => any;
};

export type ListSelectorRef = ComponentRef<typeof ListSelector>;

const ListSelector = forwardRef<listSelectorHandle, ListSelectorProps>((props, ref) => {
  const {
    dataGridProps = {},
    wrapperProps = {},
    wrapperComponent = null,
    children = undefined,
    disabled = false,
    modalStyles = {},
    title,
    navbar,
  } = props;
  const [open, setOpen] = useState(false);
  const [forcedFilters, setForcedFilters] = useState<any[]>([]);
  const [collapsed, setCollapsed] = useState(false);
  const dataGridRef = useRef<DataGridRef>();
  useImperativeHandle(ref, () => ({
    open(parameters) {
      setOpen(true);
      if (parameters) {
        setForcedFilters(parameters);
      }
    },
    close() {
      setOpen(false);
    },
    refresh() {
      dataGridRef.current?.refresh();
    },
    getVisibleData() {
      return dataGridRef.current?.getData();
    },
  }));

  const Wrapper = ({ children, ...props }) => {
    const WrapperComponent = wrapperComponent;
    if (wrapperComponent) {
      return <WrapperComponent {...props}>{children}</WrapperComponent>;
    }
    return <div {...props}>{children}</div>;
  };

  if (!open) {
    return (
      <Wrapper
        {...wrapperProps}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          !disabled && setOpen(true);
        }}
      >
        {children}
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper
        {...wrapperProps}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          !disabled && setOpen(true);
          !disabled && setCollapsed(false);
        }}
      >
        {children}
      </Wrapper>
      <Modal
        isOpen={open}
        centered={true}
        size={'xl'}
        modalClassName={collapsed ? 'collapsed' : ''}
        backdrop={!collapsed}
        fade={!collapsed}
        toggle={() => setOpen(!open)}
        style={{ maxWidth: '90vw', maxHeight: '90vw', ...(modalStyles ?? {}), ...(collapsed ? { height: 0, overflow: 'hidden' } : {}) }}
        className={classnames('list-selector-modal', { 'list-selector-collapsed': true })}
      >
        <ModalBody className={collapsed ? '' : 'py-3 px-5'}>
          {open && (
            <>
              <div className={classnames('modal-header-nav-bar', { collapsed: collapsed })}>
                <div className={'modal-header-title'}>{title}</div>
                {!collapsed && (
                  <>
                    <div>{navbar}</div>
                  </>
                )}
                <div className={'d-flex'} style={{ justifyContent: 'end' }}>
                  <div className={'modal-header-close'}>
                    <RemoveCircle onClick={() => setCollapsed(!collapsed)} />
                  </div>
                  <div className={'modal-header-close'}>
                    <HighlightOff onClick={() => setOpen(false)} />
                  </div>
                </div>
              </div>
              <div className={classnames('modal-datagrid-wrapper', { collapsed: collapsed })}>
                <DataGrid
                  ref={dataGridRef}
                  {...{ ...dataGridProps, defaultFilters: forcedFilters.length > 0 ? forcedFilters : dataGridProps.defaultFilters }}
                />
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
});
ListSelector.displayName = 'ListSelector';

export default ListSelector;
