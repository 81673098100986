import { useEffect } from 'react';

const useScanner = (cb: (data: string) => void) => {
  const onKeyUp = ({ key }) => {
    if (key === 'TVSatelliteToggle' || key === 'Unidentified') {
      if (window.scannerTimeout) {
        clearTimeout(window.scannerTimeout);
      }
      window.scannedText = '';
      window.scanning = true;
      window.scannerTimeout = setTimeout(() => {
        window.scanning = false;
        if (window.scannerTimeout) {
          clearTimeout(window.scannerTimeout);
        }
      }, 5000);
    }
  };

  const onKeyPress = ({ key }) => {
    if (window.scanning) {
      window.scannedText += key;
      if (window.scannerTimeout) {
        clearTimeout(window.scannerTimeout);
      }
      window.scannerTimeout = setTimeout(() => {
        window.scanning = false;
        if (window.scannerTimeout) {
          window.scannerTimeout = null;
        }

        cb(window.scannedText);
        window.scannedText = '';
      }, 50);
    }
  };
  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('keypress', onKeyPress);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('keypress', onKeyPress);
    };
  }, []);
};

export default useScanner;
