import ListSelector from '../Form/ListSelector';
import TextInput from '../Form/MUI/TextInput';
import ModalFormWrapper from '../Form/ModalFormWrapper';
import ModuleForm from './ModuleForm';
import ModuleListPicker from './ModuleListPicker';
import { Tooltip } from '@mui/material';
import { useModuleContext } from 'context/ModulesContext';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';
import { v4 as uuidv4 } from 'uuid';

const ModuleListScanner = ({
  moduleName,
  disableClear = false,
  disabled = false,
  isSelected,
  children,
  forceFocus,
  inputScannerLabel,
  filterFields,
  defaultFilters,
  onChange,
  onClickReset,
  namePrefix,
  name,
  moduleFormWrapperProps = {},
  showColumns = null,
}) => {
  const module = useModuleContext(moduleName);
  const dispatch = useDispatch();

  const scannerInputRef = useRef(null);
  const [query, setQuery] = useState('');
  const [identifier, setIdentifier] = useState('');
  const list = useRef();
  const showModal = useRef();

  const filters = useMemo(
    () => [...filterFields.map(item => ({ id: item, value: identifier })), ...defaultFilters],
    [defaultFilters, filterFields, identifier],
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => setIdentifier(query), 400);
    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    if (identifier === '') {
      return;
    }

    const abortController = new AbortController();
    const opts = { signal: abortController.signal };

    module.api
      .getAll({ params: { ...Object.assign({}, ...filters.map(item => ({ [item.id]: item.value }))) }, opts })
      .then(response => response['hydra:member'])
      .then(data => {
        if (data.length < 1) {
          throw new Error('Nie znaleziono pozycji dla tego identyfikatora.');
        }

        if (data.length > 1) {
          list.current.open();
          dispatch(
            addSingleToast({
              title: 'Dla tego wyszukiwania znaleziono więcej pozycji, wybierz odpowiednią z listy.',
              config: { appearance: 'info' },
            }),
          );

          return;
        }

        onChange(data[0]);
      })
      .catch(error => {
        dispatch(
          addSingleToast({
            title: error.message,
            config: { appearance: 'error' },
          }),
        );
        setQuery('');
      });

    return () => abortController.abort();
  }, [identifier]);

  useEffect(() => {
    if (!forceFocus || !scannerInputRef?.current) {
      return;
    }

    scannerInputRef.current.focus();
  }, []);

  const onClickReader = event => {
    if (!onClickReset) {
      return;
    }
    event.preventDefault();

    onChange(null);
    setTimeout(() => scannerInputRef.current.focus());
  };

  const modalListActions = useMemo(
    () => [
      {
        id: 'actionsStickyRight',
        disableSortBy: true,
        disableFilters: true,
        disableExpandAction: () => true,
        accessor: row => (
          <div className={'actions'}>
            <ButtonGroup>
              <Button
                onClick={() => {
                  onChange(row);
                  list.current.close();
                }}
                className="btn btn-info btn-sm"
              >
                <i className="mdi mdi-cursor-pointer" style={{ padding: '0 20px' }} />
              </Button>
              {module.configuration.form && (
                <>
                  <ModalFormWrapper
                    {...moduleFormWrapperProps}
                    ref={showModal}
                    title={`${module.configuration.name} - ${module.configuration.recordLabel(row)} - Podgląd`}
                    form={
                      <ModuleForm
                        moduleName={module.configuration.urlPrefix}
                        id={row.id}
                        showContextActions={false}
                        showBackButton={false}
                        showConfigurationSwitcher={false}
                        afterSave={() => {
                          showModal.current.close();
                          list.current.refresh();
                        }}
                        readonly={true}
                      />
                    }
                    wrapperComponent={Button}
                    wrapperProps={{ className: 'btn btn-success btn-sm' }}
                  >
                    <i className="mdi mdi-eye" style={{ padding: '0 4px' }} />
                  </ModalFormWrapper>
                </>
              )}
            </ButtonGroup>
          </div>
        ),
      },
    ],
    [module, onChange, defaultFilters, filterFields],
  );
  const columns = useMemo(
    () =>
      [...(module?.configuration?.list?.columns ?? []), ...modalListActions]
        .map(item => ({ ...item, filterable: false, sortable: false }))
        .filter(item => !showColumns || [...showColumns, 'actionsStickyRight'].indexOf(item.id) !== -1),
    [module, modalListActions, showColumns],
  );

  const [queryKey] = useState(uuidv4());
  const dataGridProps = useMemo(
    () => ({
      url: module.api.getAllUrl,
      columns,
      className: 'table-clickable',
      responsive: true,
      allowChangeLimit: false,
      disableTop: true,
      disableHeader: false,
      disableFooter: true,
      fullWidth: true,
      defaultPerPage: 100,
      defaultFilters: filters,
      defaultOrderBy: module.configuration.list.defaultOrderBy,
      queryKey,
    }),
    [columns, module, filters],
  );

  return (
    <>
      {isSelected && (
        <ModuleListPicker
          disabled={disabled}
          moduleName={moduleName}
          onChange={onChange}
          disableClear={disableClear}
          defaultFilters={defaultFilters}
        >
          <div onClick={onClickReader}>{children}</div>
        </ModuleListPicker>
      )}
      {!isSelected && (
        <div style={{ position: 'relative' }}>
          <Tooltip title={'Wprowadź EAN/Symbol'}>
            <TextInput
              label={inputScannerLabel}
              disabled={disabled}
              value={query ?? ''}
              onChange={value => setQuery(value)}
              variant="outlined"
              InputProps={{
                readOnly: false,
              }}
              inputRef={scannerInputRef}
              name={name}
              namePrefix={namePrefix}
            />
          </Tooltip>
          <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 999 }}>
            <ModuleListPicker
              name={name}
              namePrefix={namePrefix}
              disabled={disabled}
              moduleName={moduleName}
              onChange={onChange}
              disableClear={disableClear}
              defaultFilters={defaultFilters}
            >
              <Button className={'btn btn-info'}>
                <i className={'bx bx-list-ul'} />
              </Button>
            </ModuleListPicker>
          </div>
        </div>
      )}
      <ListSelector title={`${module.configuration.name} - Lista wyboru`} dataGridProps={dataGridProps} ref={list} />
    </>
  );
};

export default ModuleListScanner;
