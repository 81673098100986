import { FC } from 'react';
import { EdgeLabelRenderer, EdgeProps, SmoothStepEdge, getBezierPath } from 'reactflow';

const TechnologyEdge: FC<EdgeProps> = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data }) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <SmoothStepEdge
        id={id}
        source={''}
        target={''}
        sourceX={sourceX}
        sourceY={sourceY}
        targetX={targetX}
        targetY={targetY}
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: '#fc0',
            color: '#2a3042',
            padding: 10,
            borderRadius: 5,
            fontSize: 14,
            fontWeight: 700,
            zIndex: 9,
          }}
          className="nodrag nopan"
        >
          {data.label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default TechnologyEdge;
