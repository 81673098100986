import { DeleteForeverRounded } from '@mui/icons-material';
import { Box, LinearProgress, TableCell, TableRow, Typography } from '@mui/material';
import { DocumentModule, ProductGroupModule, ProductModule } from 'Modules/CTMModules';
import StoreValues from 'Modules/Ecommerce/Components/StoreValues';
import NumberInput from 'components/Form/MUI/NumberInput';
import PlaceholderInput from 'components/Form/MUI/PlaceholderInput';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import RecordInputReader from 'components/Module/RecordInputReader';
import { get } from 'helpers/Axios';
import _ from 'lodash';
import { FC, memo, useEffect, useMemo, useState } from 'react';

interface ProductionDemandRowFormProps {
  item: any;
  readonly?: boolean;
  onChange: any;
  onRemove?: any;
  cellStyles: any;
  newRow: boolean;
  index: number;
  recordId?: string | null;
}

type Color = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';

const ProductionDemandRowForm: FC<ProductionDemandRowFormProps> = ({
  item,
  readonly,
  onChange,
  onRemove,
  cellStyles,
  newRow,
  index,
  recordId,
}) => {
  const [row, setRow] = useState({ product: null, quantity: 0, ...item });
  const [stored, setStored] = useState<number>(0);

  useEffect(() => {
    if (!recordId || !item.id || newRow) {
      return;
    }

    DocumentModule.api
      .getAll({
        params: {
          owner: recordId,
          status: 2000,
        },
      })
      .then(response => {
        response['hydra:member'].forEach((document: any) => {
          get(document['@id']).then(response => {
            if (!response.rows) {
              return;
            }

            response.rows.forEach((child: any) => {
              if (child.product.id !== row.product.id) {
                return;
              }
              setStored(prevState => prevState + child.quantity);
            });
          });
        });
      });
  }, [item.id, newRow, recordId]);

  const progress = useMemo((): number => Math.round((stored * 100) / row.quantity), [row.quantity, stored]);

  const color = useMemo((): Color => {
    if (progress > 25 && progress <= 50) {
      return 'warning';
    }

    if (progress > 50 && progress <= 75) {
      return 'info';
    }

    return 'success';
  }, [progress]);

  useEffect(() => {
    if (!_.isEqual(row, item)) {
      onChange(index, row);
    }
  }, [row]);

  useEffect(() => {
    if (!_.isEqual(row, item)) {
      setRow({ product: null, quantity: 0, quantityProduced: 0, ...item });
    }
  }, [item]);

  const updateRowValue = (value: any, field: string | undefined | null) => {
    if (!field) {
      return;
    }

    setRow(prevRow => ({ ...prevRow, [field]: value }));
  };

  return (
    <>
      <TableRow>
        {!newRow && (
          <TableCell
            style={{
              position: 'relative',
              ...cellStyles,
            }}
          >
            <div style={{ paddingLeft: readonly ? 0 : 25 }}>{index !== undefined ? index + 1 : ''}</div>
            {!readonly && row.product && (
              <DeleteForeverRounded
                style={{
                  color: '#b00020',
                  position: 'absolute',
                  left: 15,
                  top: '50%',
                  cursor: 'pointer',
                  transform: 'translate(-50%,-50%)',
                }}
                onClick={() => onRemove(index)}
              />
            )}
          </TableCell>
        )}
        <TableCell
          style={{
            width: '30%',
            minWidth: 220,
            position: 'relative',
            ...cellStyles,
          }}
          colSpan={row.product ? 1 : 14}
        >
          <div>
            <ModuleListPicker
              disabled={readonly}
              moduleName={'manufacture-products'}
              onChange={gridRow =>
                setRow(prevState => {
                  return {
                    ...prevState,
                    product: gridRow,
                  };
                })
              }
              disableClear={true}
              defaultFilters={[]}
              overrideUrl={undefined}
              moduleFormComponent={undefined}
              moduleFormComponentProps={undefined}
              moduleFormWrapperProps={undefined}
              allowMultipleSelect={false}
              onMultipleSelect={console.error}
            >
              <RecordInputReader
                readonly={readonly}
                value={row.product}
                module={ProductModule}
                label={newRow ? 'Wybierz produkt aby dodać...' : 'Produkt'}
              />
            </ModuleListPicker>
          </div>
        </TableCell>
        {row.product && (
          <>
            <TableCell style={{ minWidth: 220, ...(cellStyles ?? {}) }}>
              <StoreValues product={row.product} />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'Symbol'} value={row?.product?.symbol} />
            </TableCell>
            <TableCell style={{ minWidth: 80, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'ID'} value={row?.product?.labelerId} />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <RecordInputReader readonly={true} value={row?.product?.group} module={ProductGroupModule} label={'Grupa'} />
            </TableCell>
            <TableCell style={{ minWidth: 120, ...(cellStyles ?? {}) }}>
              <PlaceholderInput disabled={true} label={'EAN'} value={row?.product?.ean} />
            </TableCell>
            <TableCell style={{ minWidth: 90, ...(cellStyles ?? {}) }}>
              <NumberInput
                value={row.quantity}
                label={'Ilość'}
                name={'quantity'}
                disabled={readonly}
                onChange={(value, field) => {
                  updateRowValue(value, field);
                }}
                inputProps={{ min: 1 }}
                namePrefix={'rows[' + index + ']'}
              />
            </TableCell>
          </>
        )}
      </TableRow>
      {!newRow && (
        <TableRow>
          <TableCell colSpan={7} padding="none">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={progress} color={color} />
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                {`${stored} / ${row.quantity}`}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default memo(ProductionDemandRowForm);
