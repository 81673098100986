import { SET_CONFIGURATION } from 'store/Employee/Configuration/ActionTypes';

export type EmployeeConfigurationState = {
  configuration: any;
};
const initState = (): EmployeeConfigurationState => ({
  configuration: [],
});

const initialState = initState();

const Configuration = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIGURATION:
      state = {
        ...state,
        configuration: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Configuration;
