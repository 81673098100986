import { withSecure } from 'components/Theme/Common/SecuredView';
import ProductDemandList from 'pages/Manufacture/ProductDemand/ProductDemandList';

export default [
  {
    path: '/manufacture/products-demand-list',
    exact: true,
    component: withSecure(ProductDemandList, 'ROLE_SHOW_MANUFACTURE_PRODUCT', true),
  },
];
