import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import BaseScalarInput from 'components/CustomFields/FieldTypes/BaseScalarInput';

const StringField: CustomFieldComponentType = props =>
  BaseScalarInput({
    ...props,
    type: 'color',
  });

export default StringField;
