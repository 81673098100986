import { SystemFieldModule } from 'Modules/CTMModules';
import classNames from 'classnames';
import { confirmRemove } from 'common/sweetalerts';
import DataGrid from 'components/DataGrid';
import Breadcrumbs from 'components/Theme/Common/Breadcrumb';
import SecuredView from 'components/Theme/Common/SecuredView';
import { FC, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';

const List: FC = () => {
  const list = useRef<any>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const columns = useMemo(
    () => [
      ...(SystemFieldModule?.configuration?.list?.columns ?? []),
      ...[
        {
          id: 'actionsStickyRight',
          Header: 'Akcje',
          filterable: false,
          sortable: false,
          accessor: row => (
            <ButtonGroup className="w-100">
              <SecuredView role="ROLE_EDIT_CONFIGURATION">
                <Link to={`/configuration/system-field/${row.id}/edit`} className="btn btn-primary  btn-sm">
                  <i className="mdi mdi-cogs" />
                </Link>
              </SecuredView>
              <Link to={`/configuration/system-field/${row.id}`} className="btn btn-success  btn-sm">
                <i className="mdi mdi-eye" />
              </Link>
              <SecuredView role="ROLE_EDIT_CONFIGURATION">
                <Button
                  onClick={() =>
                    confirmRemove(() =>
                      SystemFieldModule.api.delete({ id: row.id }).then(() => {
                        setRefresh(row.id);
                      }),
                    )
                  }
                  className="btn btn-danger  btn-sm"
                >
                  <i className="mdi mdi-delete" />
                </Button>
              </SecuredView>
            </ButtonGroup>
          ),
        },
      ],
    ],
    [SystemFieldModule],
  );

  return (
    <div className={classNames({ 'container-fluid': true })}>
      <Breadcrumbs title="Pola systemowe globalne" breadcrumbItem={'Lista'} />
      <DataGrid
        ref={list}
        url={SystemFieldModule.api.getAllUrl}
        fullWidth={true}
        responsive={true}
        className={'table-clickable'}
        columns={columns}
        defaultOrderBy={SystemFieldModule?.configuration?.list?.defaultOrderBy}
        renderRowSubComponent={SystemFieldModule?.configuration?.list?.renderRowSubComponent}
        defaultFilters={SystemFieldModule?.configuration?.list?.defaultFilters}
        canRenderSubRow={SystemFieldModule?.configuration?.list?.canRenderSubRow}
        allowAdd={true}
        addUrl={'/configuration/system-field/create'}
        refresh={refresh}
      />
    </div>
  );
};

export default List;
