import CurrencyExchange from '../../Core/Services/CurrencyExchange';
import FormProductRow, { DefaultProductRow } from './FormProductRow';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Warning } from '@mui/icons-material';
import { Alert, Table, TableBody, TableCell, TableRow } from '@mui/material';
import MoneyView from 'components/View/MoneyView';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSingleToast } from 'store/Toast/actions';
import { v4 as uuidv4 } from 'uuid';

export const computeClientOrderPriceNet = positions =>
  positions.map(el => (el.quantity ?? 1) * Math.round(el.unitPriceNet)).reduce((p, c) => c + p, 0);
export const computeClientOrderPriceGross = positions =>
  positions
    .map(el => (el.quantity ?? 1) * Math.round(el.unitPriceNet) * (el.vat?.value ? 1 + el.vat?.value / 100 : 1))
    .reduce((p, c) => c + p, 0);

const cellStyles = { padding: 5 };
const OrderPositions: CustomFieldComponentType = ({ field, fields, value, values, onChange, readonly }) => {
  const rowsFieldId = fields.find(el => el.propertyPath === 'currency')?.id as string;
  const contractorId = fields.find(el => el.propertyPath === 'contractor')?.id as string;
  const exchangeRateDateId = fields.find(el => el.propertyPath === 'exchangeRateDate')?.id as string;
  const currency = values?.[rowsFieldId] ?? 'PLN';
  const contractor = values?.[contractorId];
  const exchangeRateDate = values?.[exchangeRateDateId];
  const dispatch = useDispatch();
  const [currencyExchange, setCurrencyExchange] = useState<any>(null);

  const exchangeDate = dayjs(exchangeRateDate ?? new Date()).format('YYYY-MM-DD');
  const [positions, setPositions] = useState(value ?? []);
  useEffect(() => {
    if (JSON.stringify(positions) !== JSON.stringify(value)) {
      onChange(positions, field.id);
    }
  }, [JSON.stringify(positions)]);

  // useEffect(() => {
  //   if (JSON.stringify(value) !== JSON.stringify(positions)) {
  //     setPositions(value ?? []);
  //   }
  // }, [JSON.stringify(value)]);

  useEffect(() => {
    CurrencyExchange.getCurrencyExchangeRate('PLN', currency, exchangeDate).then(result => {
      setCurrencyExchange(result);
    });
  }, [currency, exchangeDate]);

  const onUpdateItem = useCallback((index, newItem) => {
    setPositions(prevPositions => {
      const newItems = [...prevPositions];
      newItems[index] = newItem;

      return prevPositions.map((el, elIndex) => {
        if (index === elIndex) {
          return { ...newItem, seq: newItem.seq ?? prevPositions.length };
        }

        return el;
      });
    });
  }, []);
  const onRemove = useCallback(index => {
    setPositions(prevPositions => prevPositions.filter((r, i) => i !== index));
  }, []);

  const onNewItem = (index, newItem) => {
    if (!newItem.product) {
      return;
    }
    setPositions(prevPositions => {
      const currentProducts = prevPositions.map(el => el.product?.['@id']);

      if (currentProducts.includes(newItem.product?.['@id'])) {
        dispatch(
          addSingleToast({
            title: `Produkt ${newItem.product?.name} został już dodany do zamówienia. Usuń go jeśli nie zostało to zrobione celowo`,
            config: { appearance: 'warning' },
          }),
        );
      }

      return [...prevPositions, { _key: uuidv4(), seq: positions.length, ...newItem, vat: contractor?.valueAddedTax }];
    });
  };

  const contractorAlerts = useMemo(() => {
    return (
      <>
        {contractor && !contractor?.tariff && (
          <Alert icon={<Warning />} color={'warning'} style={{ marginLeft: 25, marginRight: 25, marginBottom: 25 }}>
            Cennik dla klienta {contractor?.name} nie został ustawiony. Zastosowane zostaną ceny FIFO!
          </Alert>
        )}
        {contractor && !contractor?.valueAddedTax && (
          <Alert icon={<Warning />} color={'warning'} style={{ marginLeft: 25, marginRight: 25, marginBottom: 25 }}>
            Brak ustawionej stawki VAT dla klienta {contractor?.name}.
          </Alert>
        )}
      </>
    );
  }, [contractor]);

  const totalNetto = useMemo(() => {
    return computeClientOrderPriceNet(positions);
  }, [positions]);

  const totalGross = useMemo(() => {
    return computeClientOrderPriceGross(positions);
  }, [positions]);

  return (
    <div style={{ overflowX: 'auto' }}>
      {contractorAlerts}
      <Table style={{ minWidth: 640 }}>
        <TableBody>
          {positions.map((item, key) => (
            <FormProductRow
              contractor={contractor}
              key={item['@id'] ?? item._key ?? `${key}${item.product?.id}`}
              index={key}
              item={item}
              readonly={readonly || item.locked || currencyExchange === null}
              onChange={onUpdateItem}
              onRemove={onRemove}
              cellStyles={cellStyles}
              currency={currency}
              exchangeDate={exchangeDate}
              newRow={false}
            />
          ))}
          {!readonly && (
            <FormProductRow
              contractor={contractor}
              newRow={true}
              key={positions.length + 1}
              item={{ ...DefaultProductRow }}
              onChange={onNewItem}
              cellStyles={cellStyles}
              currency={currency}
              exchangeDate={exchangeDate}
              readonly={false}
              onRemove={console.log}
              index={-1}
            />
          )}

          {positions.length > 0 && (
            <>
              <TableRow>
                <TableCell colSpan={14} style={{ textAlign: 'right' }}>
                  Wartość netto:
                </TableCell>
                <TableCell colSpan={2} style={{ textAlign: 'left' }}>
                  <MoneyView value={totalNetto} currency={currency} exchangeDate={exchangeDate} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={14} style={{ textAlign: 'right' }}>
                  Wartość VAT:
                </TableCell>
                <TableCell colSpan={2} style={{ textAlign: 'left' }}>
                  <MoneyView value={totalGross - totalNetto} currency={currency} exchangeDate={exchangeDate} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={14} style={{ textAlign: 'right' }}>
                  Wartość brutto:
                </TableCell>
                <TableCell colSpan={2} style={{ textAlign: 'left' }}>
                  <MoneyView value={totalGross} currency={currency} exchangeDate={exchangeDate} />
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

OrderPositions.provideAllValues = true;
export default OrderPositions;
