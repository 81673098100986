//@ts-nocheck
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import BaseScalarInput from 'components/CustomFields/FieldTypes/BaseScalarInput';
import MathFieldComponent from 'components/Form/MathField';
import _ from 'lodash';
import { useMemo } from 'react';

const parseBlock = (block, values) => {
  return block.map(el => {
    if (el.constructor === Array) {
      if (el[0] === 'FIELD_DATA') {
        const fieldId = el[1].replaceAll(`'`, '').replaceAll(`id=`, '');
        return values[fieldId] ?? 0.0;
      }

      return parseBlock(el, values);
    }

    return el;
  });
};
const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    background: '#fff',
    color: '#303030',
    border: '1px solid #303030',
  },
});

const doMath = (block, values) => {
  if (!window.ce?.parse) {
    console.error('Library for math not loaded!');
    return 0.0;
  }
  try {
    const parsedBlock = parseBlock(block, values);
    const result = window.ce.parse(window.ce.serialize(parsedBlock)).N();
    const stringVal = result._value.toString();
    const numberResult = Number(stringVal).toFixed(4);
    return isNaN(numberResult) ? 0.0 : numberResult;
  } catch (e) {
    console.error(e);
    return 0.0;
  }
};

const MathField: CustomFieldComponentType = props => {
  const mathJSON = _.cloneDeep(props.field.config.math?._json ?? []);
  const values = props.values;
  const value = useMemo(() => {
    return doMath(mathJSON, values);
  }, [values]);

  if (value !== props.value) {
    props.onChange(value, props.field.id);
  }

  return (
    <NoMaxWidthTooltip
      title={
        <div>
          <MathFieldComponent
            id={props.field.id}
            label={`Obliczenia do pola ${props.field.name ?? 'Nazwa pola'}`}
            mathFieldStyles={{ width: '85%', border: undefined }}
            value={props.field.config?.math?.latex ?? null}
            disabled={true}
          />
        </div>
      }
      placement="top"
      enterDelay={0}
    >
      <div>
        <BaseScalarInput {...props} value={parseFloat(value ?? '0.0').toFixed(2)} readonly={true} type={'number'} onChange={() => false} />
      </div>
    </NoMaxWidthTooltip>
  );
};

export default MathField;
