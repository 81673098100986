import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import CFImageField from '@Components/CustomFields/FieldTypes/CFImageField';
import BooleanField from 'components/CustomFields/FieldTypes/BooleanField';
import ChoiceField from 'components/CustomFields/FieldTypes/ChoiceField';
import ColorField from 'components/CustomFields/FieldTypes/ColorField';
import CountryField from 'components/CustomFields/FieldTypes/CountryField';
import CurrencyField from 'components/CustomFields/FieldTypes/CurrencyField';
import DateField from 'components/CustomFields/FieldTypes/DateField';
import DictionaryField from 'components/CustomFields/FieldTypes/DictionaryField';
import DividerField from 'components/CustomFields/FieldTypes/DividerField';
import FileField from 'components/CustomFields/FieldTypes/FileField';
import ImageField from 'components/CustomFields/FieldTypes/ImageField';
import MathField from 'components/CustomFields/FieldTypes/MathField';
import NumberField from 'components/CustomFields/FieldTypes/NumberField';
import PasswordField from 'components/CustomFields/FieldTypes/PasswordField';
import ReadonlyField from 'components/CustomFields/FieldTypes/ReadonlyField';
import RelationField from 'components/CustomFields/FieldTypes/RelationField';
import StringField from 'components/CustomFields/FieldTypes/StringField';
import SubresourceListField from 'components/CustomFields/FieldTypes/SubresourceListField';
import TextareaField from 'components/CustomFields/FieldTypes/TextareaField';
import YouTubeField from 'components/CustomFields/FieldTypes/YouTubeField';

export type FieldType =
  | 'STRING'
  | 'PASSWORD'
  | 'TEXT'
  | 'NUMBER'
  | 'IMAGE'
  | 'RELATION'
  | 'YOU_TUBE'
  | 'SUBRESOURCE_LIST'
  | 'FILE'
  | 'BOOLEAN'
  | 'CHOICE_FIELD'
  | 'COLOR'
  | 'READONLY'
  | 'DICTIONARY'
  | 'MATH'
  | 'CURRENCY'
  | 'DIVIDER'
  | 'DATE'
  | 'COUNTRY'
  | 'CF_IMAGE';

const FieldTypes: { [key in FieldType]: CustomFieldComponentType } = {
  STRING: StringField,
  PASSWORD: PasswordField,
  TEXT: TextareaField,
  NUMBER: NumberField,
  IMAGE: ImageField,
  RELATION: RelationField,
  YOU_TUBE: YouTubeField,
  SUBRESOURCE_LIST: SubresourceListField,
  FILE: FileField,
  BOOLEAN: BooleanField,
  CHOICE_FIELD: ChoiceField,
  COLOR: ColorField,
  READONLY: ReadonlyField,
  DICTIONARY: DictionaryField,
  MATH: MathField,
  CURRENCY: CurrencyField,
  DIVIDER: DividerField,
  DATE: DateField,
  COUNTRY: CountryField,
  CF_IMAGE: CFImageField,
};

export default FieldTypes;
