import ProductionOrderRowForm from './ProductionOrderRowForm';
import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Table, TableBody } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const cellStyles = { padding: 5 };

const ProductionOrderRows: CustomFieldComponentType = ({ value, onChange, readonly, field }) => {
  const [rows, setRows] = useState<any[]>(value ?? []);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(rows)) {
      setRows(value ?? []);
    }
  }, [JSON.stringify(value)]);

  const handleChange = useCallback((index: number, row: any) => {
    setRows(prevRows =>
      prevRows.map((el, elIndex) => {
        if (elIndex === index) {
          return { ...el, ...row };
        }
        return el;
      }),
    );
  }, []);

  useEffect(() => {
    onChange(rows, field.id);
  }, [rows]);

  const onRemove = useCallback((index: number) => {
    setRows(prevRows => prevRows.filter((r, i) => i !== index));
  }, []);

  const onNewItem = (index: number, newItem: any) => {
    if (!newItem.product) {
      return;
    }

    setRows(prevRows => [...prevRows, { _key: uuidv4(), seq: rows.length, ...newItem }]);
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table style={{ minWidth: 640 }}>
        <TableBody>
          {rows.map((item, key) => (
            <ProductionOrderRowForm
              key={item['@id'] ?? item._key ?? `${key}${item.product?.id}`}
              index={key}
              item={item}
              readonly={readonly || item.locked}
              onChange={handleChange}
              onRemove={onRemove}
              cellStyles={cellStyles}
              newRow={false}
            />
          ))}
          {!readonly && rows.length < 1 && (
            <ProductionOrderRowForm
              newRow={true}
              key={rows.length + 1}
              index={rows.length + 1}
              item={{}}
              onChange={onNewItem}
              cellStyles={cellStyles}
            />
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProductionOrderRows;
