import { Close, MergeOutlined } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { InquiryModule } from 'Modules/CTMModules';
import CheckboxInput from 'components/Form/MUI/CheckboxInput';
import TextInput from 'components/Form/MUI/TextInput';
import { Col, Container } from 'components/Theme/Grid';
import { get } from 'helpers/Axios';
import { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
    PopoverClasses={{ root: 'table-selectable-actions' }}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const RequestForAnOffer = props => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [defaultModalOpened, setDefaultModalOpened] = useState<boolean>(false);
  const [messageModalOpened, setMessageModalOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [supplierMessage, setSupplierMessage] = useState<string>('');
  const [supplierTitle, setSupplierTitle] = useState<string>('');
  const [products, setProducts] = useState<any[]>([]);
  const [contractors, setContractors] = useState<any[]>([]);
  const [selectedContractor, setSelectedContractor] = useState<null | string>(null);
  const [checked, setChecked] = useState<string[]>([]);
  const handleDefaultModalOpen = () => {
    if (defaultModalOpened) {
      return;
    }
    if (props.selectedFlatRows.length === 0) {
      handleDefaultModalClose();
      return;
    }
    setDefaultModalOpened(true);
    setLoading(true);

    get('/prepare-offer-queries/0', {
      params: {
        products: props.selectedFlatRows.map(row => row.original.id),
      },
    }).then(response => {
      setProducts(response.products);
      setContractors(
        Object.values(
          response.products.reduce((acc, product) => {
            const contractors = product.supplierContractors;

            contractors.forEach(el => {
              acc[el.id] = el;
            });

            return acc;
          }, {}),
        ),
      );
      setChecked(
        response.products.reduce((acc, product) => {
          const contractors = product.supplierContractors;
          const checkedContractors = contractors.map(contractor => `${product['@id']}___${contractor['@id']}`);

          return [...acc, ...checkedContractors];
        }, []),
      );
      setLoading(false);
    });
  };

  const handleDefaultModalClose = () => {
    setDefaultModalOpened(!defaultModalOpened);
    setMessageModalOpened(false);
  };
  const handleMessageModalOpen = () => {
    setMessageModalOpened(true);
  };

  const handleToggleChange = (val, name) => {
    if (val) {
      setChecked([...checked, name]);
    } else {
      setChecked(checked.filter(el => el !== name));
    }
  };

  const prepareMessageForSupplier = contractor => {
    const indexedProducts = {};
    setSelectedContractor(contractor['@id']);

    products.forEach(product => {
      indexedProducts[product['@id']] = product;
    });
    const selectedProducts = checked.filter(el => el.includes(contractor['@id'])).map(el => el.split('___')[0]);

    let message = '';
    message += `Szanowni Państwo,` + '\n';
    message += `zwracamy się z prośbą o przedstawienie przez Państwa oferty cenowej na poniższe poniżej towary.` + '\n';
    message += `Prosimy o podanie: terminu realizacji zamówienia, warunków płatności\n`;
    message += `\n\n`;

    selectedProducts.forEach((selectedProductIRI, index) => {
      message += `${index}. ${indexedProducts[selectedProductIRI].name} \n`;
    });

    message += `\nBędziemy wdzięczni za szybką odpowiedź.`;
    message += `\n\nPozdrawiamy, zespół Stalflex sp.z.o.o`;
    message += `\n\n\n---`;
    message += `\nWiadomość wygenerowana automatycznie`;

    setSupplierMessage(message);
    setSupplierTitle('Zapytanie ofertowe z firmy Stalflex sp.z.o.o');
    handleMessageModalOpen();
  };

  const sendInquiryForSupplier = () => {
    const products = checked.filter(el => el.includes(selectedContractor ?? '')).map(el => el.split('___')[0]);

    InquiryModule.api
      .post({
        title: supplierTitle,
        message: supplierMessage,
        contractor: selectedContractor,
        rows: products.map(el => ({
          product: el,
        })),
      })
      .then(() => {
        dispatch(addSingleToast({ title: `Wysłano zapytanie`, config: { appearance: 'success' } }));
        setMessageModalOpened(false);
      })
      .catch(err => {
        if (err.response && err.response.status === 422) {
          err.response.data.violations.forEach(({ message, propertyPath }) => {
            dispatch(addSingleToast({ title: `${propertyPath} - ${message}`, config: { appearance: 'error' } }));
          });
        } else {
          dispatch(
            addSingleToast({
              title: `Wystąpił błąd podczas wysyłki - zgłoś problem developerowi`,
              config: { appearance: 'error' },
            }),
          );
          console.error(err);
        }
      });
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Wybierz akcję
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleDefaultModalOpen} disableRipple>
          {props.selectedFlatRows.length === 0 && (
            <>
              <i>Wybierz przynajmniej jeden towar aby wygenerować zapytanie o ofertę</i>
            </>
          )}
          {props.selectedFlatRows.length > 0 && (
            <>
              <MergeOutlined />
              Wygeneruj zapytanie o ofertę
              <Modal
                isOpen={defaultModalOpened}
                centered={true}
                size={'xl'}
                backdrop={true}
                toggle={handleDefaultModalClose}
                style={{ maxWidth: '70vw', maxHeight: '90vw', background: 'transparent', zIndex: 1500 }}
                wrapClassName={'merge-tasks-modal'}
              >
                {!messageModalOpened && (
                  <>
                    <ModalBody style={{ minHeight: '70vh' }}>
                      {defaultModalOpened && (
                        <>
                          <Container spacing={0} style={{ padding: 16, fontSize: '1.1em' }}>
                            <Col xs={12}>
                              <Container spacing={0} direction={'row-reverse'}>
                                <Col xs={1} style={{ textAlign: 'right' }}>
                                  <Button onClick={handleDefaultModalClose}>
                                    <Close />
                                  </Button>
                                </Col>
                                <Col xs={11} style={{ textAlign: 'center' }}>
                                  <h1>Zapytanie o ofertę</h1>
                                </Col>
                              </Container>
                            </Col>
                            <Col xs={12}>
                              {loading && (
                                <div style={{ textAlign: 'center', padding: 12 }}>
                                  <CircularProgress size={64} />
                                </div>
                              )}
                              {!loading && (
                                <div className="table-responsive position-relative" style={{ maxHeight: '70vh' }}>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            position: 'sticky',
                                            background: '#2a3042',
                                            left: 0,
                                            top: 0,
                                            zIndex: 20,
                                          }}
                                        >
                                          Towar\Dostawca
                                        </TableCell>
                                        {contractors.map(contractor => (
                                          <TableCell
                                            key={contractor.id}
                                            className="text-center"
                                            style={{
                                              position: 'sticky',
                                              background: '#2a3042',
                                              top: 0,
                                              zIndex: 10,
                                            }}
                                          >
                                            {contractor.name}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {products.map(product => (
                                        <TableRow key={product.id}>
                                          <TableCell
                                            style={{
                                              position: 'sticky',
                                              background: '#2a3042',
                                              left: 0,
                                              zIndex: 10,
                                            }}
                                          >
                                            {product.name}
                                          </TableCell>
                                          {contractors.map(contractor => (
                                            <TableCell key={contractor.id} className="text-center">
                                              <CheckboxInput
                                                label={''}
                                                name={`${product['@id']}___${contractor['@id']}`}
                                                value={checked.includes(`${product['@id']}___${contractor['@id']}`)}
                                                onChange={(val, name) => handleToggleChange(val, name)}
                                              />
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                    <TableFooter>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            position: 'sticky',
                                            background: '#2a3042',
                                            left: 0,
                                            bottom: 0,
                                            zIndex: 20,
                                          }}
                                        >
                                          &nbsp;
                                        </TableCell>
                                        {contractors.map(contractor => (
                                          <TableCell
                                            key={contractor.id}
                                            className="text-center"
                                            style={{
                                              position: 'sticky',
                                              background: '#2a3042',
                                              bottom: 0,
                                              zIndex: 10,
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              color="success"
                                              onClick={() => prepareMessageForSupplier(contractor)}
                                            >
                                              Wyślij
                                            </Button>
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    </TableFooter>
                                  </Table>
                                </div>
                              )}
                            </Col>
                          </Container>
                        </>
                      )}
                    </ModalBody>
                  </>
                )}
                {messageModalOpened && (
                  <>
                    <ModalBody style={{ padding: 24 }}>
                      <Button variant="contained" color="primary" className="w-100" onClick={() => setMessageModalOpened(false)}>
                        Cofnij
                      </Button>
                      <hr />
                      <TextInput value={supplierTitle} label="Tytuł" onChange={val => setSupplierTitle(val ?? '')} />
                      <hr />
                      <TextInput
                        value={supplierMessage}
                        onChange={val => setSupplierMessage(val ?? '')}
                        textarea={true}
                        label="Treść zapytania"
                        maxRows={20}
                      />
                      <hr />
                      <Button variant="contained" color="success" className="w-100" onClick={() => sendInquiryForSupplier()}>
                        Wyślij
                      </Button>
                    </ModalBody>
                  </>
                )}
              </Modal>
            </>
          )}
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default RequestForAnOffer;
