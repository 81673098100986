import ListPicker from './ListPicker';
import { getAllISOCodes } from 'iso-country-currency';

export const COUNTRY_INDEXED = getAllISOCodes().reduce((prev, country) => {
  prev[country.iso] = { iso: country.iso, countryName: country.countryName };
  return prev;
}, {});
export const COUNTRIES = Object.values(COUNTRY_INDEXED);
const CountrySelectSingle = props => {
  return (
    <ListPicker {...props} options={COUNTRIES} optionTrackBy={'iso'} optionLabel={({ iso, countryName }) => `${countryName} [${iso}]`} />
  );
};

export default CountrySelectSingle;
