import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type Box = CTMRecord & {
  name?: string;
  type?: string;
};

const gridColumn = (name, label, accessor: null | string | ((Box) => null | string | JSX.Element) = null): CTMListColumn<Box> => ({
  id: name,
  Header: label,
  accessor: accessor || name,
  filterable: false,
  sortable: false,
});

const types = {
  box: 'Pudełko',
  pallet: 'Paleta',
};

const columns: CTMListColumn<Box>[] = [
  gridColumn('name', 'Nazwa'),
  gridColumn('type', 'Typ', ({ type }) => types[type] || type),
  gridColumn('length', 'Długość/Głębokość (X)'),
  gridColumn('width', 'Szerokość (Y)'),
  gridColumn('thickness', 'Grubość/Wysokość (Z)'),
  gridColumn('weight', 'Waga'),
  gridColumn('maxWeigh', 'Maksymalne obciążenie'),
  gridColumn('costForCompany', 'Koszt dla firmy'),
  gridColumn('costForClient', 'Koszt dla klienta'),
];

const module: CTMModule<Box> = {
  id: '9ed417cd-77ae-46c6-bb0f-c2d4bec245dc',
  dataClass: 'CTM\\Warehouse\\Entity\\Box\\Box',
  urlPrefix: 'warehouse-boxes',
  name: 'Box',
  role: 'WAREHOUSE_WAREHOUSE',
  api: {
    item: {
      get: ({ id }) => `/warehouse/boxes/${id}`,
      put: ({ id }) => `/warehouse/boxes/${id}`,
      delete: ({ id }) => `/warehouse/boxes/${id}`,
    },
    collection: {
      get: `/warehouse/boxes`,
      post: `/warehouse/boxes`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {},
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'name', desc: false }],
  },
};

export default module;
