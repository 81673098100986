import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

interface TextEditorProps {
  value: string | null;
  onChange: (val) => void;
  onBlur?: () => void;
  disabled?: boolean;
}

const TextEditor: FC<TextEditorProps> = ({ value, onChange, disabled, onBlur }) => {
  const [controlled, setControlled] = useState<string>(value ?? '');

  useEffect(() => {
    if (value !== controlled) {
      onChange(controlled);
    }
  }, [controlled]);
  useEffect(() => {
    if (value !== controlled) {
      setControlled(value ?? '');
    }
  }, [value]);
  return (
    <div className={classNames('cke-wrapper', { disabled: disabled, enabled: !disabled })}>
      <CKEditor
        data={controlled}
        disabled={disabled}
        onChange={(ev, editor) => setControlled(editor.getData())}
        onBlur={onBlur}
        editor={Editor}
      />
    </div>
  );
};

export default TextEditor;
