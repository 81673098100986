import CTMModule, { CTMListColumn, CTMRecord, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { FieldTypesIndexed } from 'Modules/CustomFields/const/FieldTypes';
import { pickValueFromRecordByPropertyPath } from 'helpers/ModuleUtils';

export type SystemField = CTMStrictRecord &
  CTMRecord & {
    id?: string;
    name?: string;
    type: string;
    defaultValue?: string;
  };

const columns: CTMListColumn<SystemField>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    id: 'type',
    filterable: true,
    sortable: true,
    Header: 'Typ pola',
    accessor: row => {
      return FieldTypesIndexed[row.type];
    },
  },
  {
    id: 'defaultValue',
    filterable: true,
    sortable: true,
    Header: 'Wartość',
    accessor: 'defaultValue',
  },
];

const module: CTMModule<SystemField> = {
  id: 'SystemFieldModule',
  dataClass: 'CTM\\CustomField\\Entity\\Field',
  urlPrefix: 'core-system-field',
  name: 'Pola systemowe globalne',
  role: null,
  api: {
    item: {
      get: ({ id }) => `/custom-fields/fields/${id}`,
      put: ({ id }) => `/custom-fields/fields/${id}`,
      delete: ({ id }) => `/custom-fields/fields/${id}`,
    },
    collection: {
      get: `/custom-fields/fields?exists[module]=false`,
      post: `/custom-fields/fields`,
    },
  },
  recordLabel: (record, allFields) => {
    const { name } = pickValueFromRecordByPropertyPath<SystemField, any>(record, ['name'], allFields);
    return name ? `${name ?? ''}` : null;
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
