import { CoreModuleModule } from '../../CTMModules';
import { useQuery } from 'react-query';

type ModuleData = {
  dataClass: string;
  exports: any[];
  fields: any[];
  id: string;
  listEndpoint: string;
  name: string;
  system: boolean;
  templates: any[];
};

type ModuleResponse<T = ModuleData> = {
  data: T;
  isLoading: boolean;
  error: any;
};
const useModuleData = (moduleId: string): ModuleResponse => {
  const moduleUrl = CoreModuleModule.configuration.api.item.get({ id: moduleId });
  const { data, isLoading, isLoadingError, error } = useQuery(moduleUrl, () => CoreModuleModule.api.get({ id: moduleId }), {
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchInterval: false,
  });

  return {
    data: isLoadingError ? undefined : data,
    isLoading: isLoading || isLoadingError,
    error,
  };
};

export const useProductModule = () => useModuleData('7ae85ce1-0b95-4c3a-9ff8-f95474af4683');

export default useModuleData;
