import { Task } from '../../Types/Task';
import AmountColumn from '../List/AmountColumn';
import StatusPicker from '../List/StatusPicker';
import UserPicker from '../List/UserPicker';
import { OpenInBrowser } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { openTask } from 'store/TaskManagement/Actions';

const RelationTaskRow: FC<{ task: Task }> = ({ task }) => {
  const dispatch = useDispatch();
  return (
    <TableRow>
      <TableCell style={{ padding: '4px 4px 4px 0' }}>
        <Button endIcon={<OpenInBrowser />} onClick={() => dispatch(openTask(task['@id']))}>
          {task.name}
        </Button>
      </TableCell>
      <TableCell style={{ padding: '4px 4px 4px 0' }}>
        <div style={{ paddingRight: 20 }}>
          <AmountColumn key={task.id} task={task} />
        </div>
      </TableCell>
      <TableCell style={{ padding: '4px 4px 4px 0' }}>
        <UserPicker task={task} />
      </TableCell>
      <TableCell style={{ padding: '4px 4px 4px 0' }}>
        <StatusPicker id={task.id} value={task.status} />
      </TableCell>
    </TableRow>
  );
};

const RelationTaskTable: FC<{ tasks: Task[] }> = ({ tasks }) => {
  return (
    <Table padding={'none'}>
      <TableHead>
        <TableRow>
          <TableCell>Zadanie</TableCell>
          <TableCell>Ilość</TableCell>
          <TableCell>Pracownik</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map(el => (
          <RelationTaskRow key={el.id} task={el} />
        ))}
      </TableBody>
    </Table>
  );
};

export default RelationTaskTable;
