import Contractor from './Contractor';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import { createModuleColumnConfig } from 'helpers/ModuleUtils';

export type ShipmentAddress = CTMRecord & {
  name?: string;
};

const columns: CTMListColumn<ShipmentAddress>[] = [
  {
    id: 'contractor.id',
    Header: 'Kontrahent',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('contractor', Contractor),
  },
  {
    id: 'name',
    Header: 'Nazwa',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    id: 'address.post',
    Header: 'Kod pocztowy',
    accessor: 'address.post',
    filterable: true,
    sortable: true,
  },
  {
    id: 'address.city',
    Header: 'Miasto',
    accessor: 'address.city',
    filterable: true,
    sortable: true,
  },
  {
    id: 'address.street',
    Header: 'Ulica',
    accessor: 'address.street',
    filterable: true,
    sortable: true,
  },
  {
    id: 'address.house',
    Header: 'Numer domu',
    accessor: 'address.house',
    filterable: true,
    sortable: true,
  },
  {
    id: 'address.flat',
    Header: 'Numer lokalu',
    accessor: 'address.flat',
    filterable: true,
    sortable: true,
  },
  {
    id: 'address.district',
    Header: 'Gmina',
    accessor: 'address.district',
    filterable: true,
    sortable: true,
  },
  {
    id: 'address.province',
    Header: 'Województwo',
    accessor: 'address.province',
    filterable: true,
    sortable: true,
  },
  {
    id: 'address.country',
    Header: 'Kraj',
    accessor: 'address.country',
    filterable: true,
    sortable: true,
  },
];

export type CTMModuleShipmentAddress = CTMModule<ShipmentAddress>;

const module: CTMModuleShipmentAddress = {
  id: 'b6e575af-5963-46ec-99b5-1d3efe563a26',
  dataClass: 'CTM\\Contractor\\Entity\\ShipmentAddress',
  urlPrefix: 'contractor-shipment-address',
  name: 'Adresy dostaw',
  role: 'CONTRACTOR_CONTRACTOR',
  api: {
    item: {
      get: ({ id }) => `/contractor/shipment-addresses/${id}`,
      put: ({ id }) => `/contractor/shipment-addresses/${id}`,
      delete: ({ id }) => `/contractor/shipment-addresses/${id}`,
    },
    collection: {
      get: `/contractor/shipment-addresses`,
      post: `/contractor/shipment-addresses`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    fieldComponents: {
      // products: Products,
    },
    prepareRecordToSave: record => ({
      ...record,
      contractor: record.contractor?.['@id'] ?? record.contractor,
    }),
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
