import { BaseInputProps } from './Types';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pl';
import { useCallback, useEffect, useState } from 'react';

interface DateTimePickerProps extends BaseInputProps {
  label?: string;
  value?: null | Dayjs | string;
  valueFormat?: string;
  displayFormat?: string;
  onChange?: (value: string | null, name: undefined | string) => void;
  withTime?: boolean;
}

const CTMDateTimePicker = ({
  label,
  value,
  name,
  valueFormat,
  displayFormat,
  onChange,
  disabled,
  withTime = false,
}: DateTimePickerProps) => {
  const [val, setVal] = useState<string | null>(
    value ? dayjs(value).format(valueFormat ?? (withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')) : null,
  );

  useEffect(() => {
    if (val !== value) {
      onChange?.(val, name);
    }
  }, [val]);
  useEffect(() => {
    const tmpDate = value ? dayjs(value) : null;

    if (tmpDate?.format(valueFormat) != val) {
      setVal(tmpDate ? tmpDate.format(valueFormat ?? (withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')) : null);
    }
  }, [value ? dayjs(value).format(valueFormat ?? (withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')) : null]);

  const renderInput = useCallback(
    params => {
      return <TextField {...params} variant="outlined" focused={false} InputLabelProps={{ shrink: !!val }} />;
    },
    [!!val],
  );
  const handleChange = useCallback(
    newValue => {
      setVal(newValue ? newValue.format(valueFormat) : null);
    },
    [setVal],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pl'}>
      {!withTime && (
        <MobileDatePicker
          label={label}
          disabled={disabled}
          inputFormat={displayFormat ?? 'YYYY-MM-DD'}
          value={val}
          onChange={handleChange}
          renderInput={renderInput}
        />
      )}
      {withTime && (
        <MobileDateTimePicker
          label={label}
          disabled={disabled}
          inputFormat={displayFormat ?? 'YYYY-MM-DD HH:mm'}
          value={val}
          onChange={handleChange}
          renderInput={renderInput}
        />
      )}
    </LocalizationProvider>
  );
};

export default CTMDateTimePicker;
