import { useModuleContext } from '../../../context/ModulesContext';
import ModuleList from '@Components/Module/ModuleList';

const BaseLinkerIntegration = () => {
  const module = useModuleContext('integration-base_linker');

  if (!module) {
    throw 'Module not found integration-base_linker';
  }
  return (
    <>
      <ModuleList moduleName={module.configuration.urlPrefix} defaultFilters={undefined} overrideFormProps={{}} useModal={true} />
    </>
  );
};

export default BaseLinkerIntegration;
