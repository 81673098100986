import MessagesBox from './MessagesBox';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Divider, Fab, Fade } from '@mui/material';
import useMessageListQuery from 'Modules/Chat/Hooks/UseMessageListQuery';
import { UNREAD_ROOMS_QUERY_KEY, UnreadRoomsQueryData } from 'Modules/Chat/Hooks/UseUnreadRoomsQuery';
import { MessageCollectionElement, NewMessageModel } from 'Modules/Chat/Types/Api';
import { Room } from 'Modules/Chat/Types/ApiModel';
import MessageInput from 'Modules/Chat/View/Components/RoomView/MessageInput';
import useTabIsActive from 'Modules/Common/Hooks/UseTabIsActive';
import { useMercureFeedListener } from 'Modules/Mercure/Context/MercureFeedProvider';
import { AxiosError } from 'axios';
import { patch, post } from 'helpers/Axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMutation, useQueryClient } from 'react-query';
import { useDebounce, useUnmount } from 'react-use';

export type AddMessageFn = (message: MessageCollectionElement) => void;

interface MessagesProps {
  room: Room;
  onAddMessage?: VoidFunction;
}

export default function MessagesWithInput({ room, onAddMessage }: MessagesProps) {
  const roomIri = room['@id'];
  const { ref, inView } = useInView();
  const tabIsActive = useTabIsActive();
  const { ref: lastMessageRef, inView: lastMessageInView } = useInView();
  const {
    lastMessageId,
    setLastMessageId,
    lastSeenMessageId,
    setLastSeenMessageId,
    setPersistedLastSeenMessageId,
    setDebouncedLastSeenMessageId,
  } = useMarkChatAsRead(room);
  const messagesListQuery = useMessageListQuery(roomIri);
  const [liveMessages, setLiveMessages] = useState<MessageCollectionElement[]>([]);

  const simpleBarRef = useRef<HTMLElement>();
  const scrollToBottom = useCallback((smooth?: boolean) => {
    simpleBarRef.current?.scroll({ top: simpleBarRef.current?.scrollHeight, behavior: smooth ? 'smooth' : undefined });
  }, []);
  const smoothScrollToBottom = useCallback(() => scrollToBottom(true), [scrollToBottom]);
  const addMessage: AddMessageFn = useCallback(
    message => {
      if (message.room['@id'] !== roomIri) {
        return;
      }
      setLiveMessages(messages => {
        return messages.find(m => m.id === message.id) ? messages : [message, ...messages];
      });
    },
    [setLiveMessages, roomIri],
  );

  const sendMessage = useMutation<MessageCollectionElement, AxiosError, NewMessageModel>({
    mutationFn: message => {
      return post<MessageCollectionElement>('/chat/messages', {
        room: room['@id'],
        message: message,
      });
    },
    onSuccess: message => {
      addMessage(message);
      setLastSeenMessageId(message.id);
      setDebouncedLastSeenMessageId(message.id);
      setPersistedLastSeenMessageId(message.id);
      scrollToBottom();
      onAddMessage && onAddMessage();
    },
  });

  useMercureFeedListener('CHAT_MESSAGE', addMessage, [addMessage]);
  useEffect(() => {
    (lastMessageInView || messagesListQuery.isLoading) && scrollToBottom();
  }, [lastMessageInView, scrollToBottom, liveMessages, messagesListQuery.isFetchedAfterMount, messagesListQuery.isLoading]);

  useEffect(() => {
    if (inView && !messagesListQuery.isFetchingNextPage && messagesListQuery.hasNextPage) {
      ref(null);
      void messagesListQuery.fetchNextPage();
    }
  }, [inView, messagesListQuery, ref]);
  useEffect(() => {
    const currentLastMessageId = liveMessages.length ? liveMessages[0].id : messagesListQuery.data?.pages?.[0]['hydra:member'][0]?.id;

    currentLastMessageId !== lastMessageId && setLastMessageId(currentLastMessageId);
    tabIsActive && lastMessageInView && currentLastMessageId !== lastSeenMessageId && setLastSeenMessageId(currentLastMessageId);
  }, [messagesListQuery.isFetchedAfterMount, lastMessageInView, liveMessages, tabIsActive]);

  return (
    <>
      <Fade in={Boolean(!(lastMessageInView || !messagesListQuery.isFetchedAfterMount))}>
        <Fab
          color="primary"
          size="small"
          sx={{ position: 'absolute', bottom: 100, left: 0, right: 0, margin: 'auto' }}
          onClick={smoothScrollToBottom}
        >
          <ArrowDownwardIcon />
        </Fab>
      </Fade>
      <MessagesBox
        scrollContainerRef={simpleBarRef}
        lastMessageRef={lastMessageRef}
        oldestChunkRef={ref}
        liveMessages={liveMessages}
        chunks={messagesListQuery.data}
        hasNextPage={messagesListQuery.hasNextPage}
        isFetchingNextPage={messagesListQuery.isFetchingNextPage}
        isInitialLoading={messagesListQuery.isLoading}
      />
      <Divider />
      <MessageInput sendMessageMutation={sendMessage} autoFocus />
    </>
  );
}

function useMarkChatAsRead(room: Room) {
  const roomIri = room['@id'];
  const queryClient = useQueryClient();
  const [lastMessageId, setLastMessageId] = useState<number>();
  const [lastSeenMessageId, setLastSeenMessageId] = useState<number>();
  const [debouncedLastSeenMessageId, setDebouncedLastSeenMessageId] = useState<number>();
  const [persistedLastSeenMessageId, setPersistedLastSeenMessageId] = useState<number>();
  useEffect(() => {
    const updateFn = (curr?: number) => (curr && curr > room.currentMember.lastReadMessageId ? curr : room.currentMember.lastReadMessageId);
    setLastSeenMessageId(updateFn);
    setDebouncedLastSeenMessageId(updateFn);
    setPersistedLastSeenMessageId(updateFn);
  }, [room]);

  useUnmount(() => {
    if (lastSeenMessageId !== debouncedLastSeenMessageId) {
      void patch(room['@id'] + '/read-messages', {
        message: '/chat/messages/' + lastSeenMessageId,
      });
    }
  });

  useEffect(() => {
    if (queryClient.getQueryData<UnreadRoomsQueryData>(UNREAD_ROOMS_QUERY_KEY)?.includes(roomIri)) {
      queryClient.setQueryData<UnreadRoomsQueryData | undefined>(UNREAD_ROOMS_QUERY_KEY, currentData => {
        return currentData?.filter(v => v !== roomIri);
      });
    }
  }, [roomIri, queryClient]);

  useDebounce(() => setDebouncedLastSeenMessageId(lastSeenMessageId), 5000, [lastMessageId]);

  useEffect(() => {
    if (!debouncedLastSeenMessageId) {
      return;
    }

    if (debouncedLastSeenMessageId <= room.currentMember.lastReadMessageId) {
      return;
    }

    if ((persistedLastSeenMessageId || 0) >= debouncedLastSeenMessageId) {
      return;
    }
    patch(room['@id'] + '/read-messages', {
      message: '/chat/messages/' + debouncedLastSeenMessageId,
    }).then(() => setPersistedLastSeenMessageId(debouncedLastSeenMessageId));
  }, [debouncedLastSeenMessageId, room]);

  useEffect(() => {
    if (room.currentMember.lastReadMessageId < room.lastMessage.id) {
      void patch(room['@id'] + '/read-messages', {
        message: '/chat/messages/' + room.lastMessage.id,
      });
    }
  }, [room]);

  return {
    lastMessageId,
    setLastMessageId,
    lastSeenMessageId,
    setLastSeenMessageId,
    setPersistedLastSeenMessageId,
    setDebouncedLastSeenMessageId,
  };
}
