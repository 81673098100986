import { List } from '@mui/material';
import useChatManipulationFunctions from 'Modules/Chat/Hooks/UseChatManipulationFunctions';
import useFavoriteRoomsList from 'Modules/Chat/Hooks/UseFavoriteRooms';
import useOnlineUsers from 'Modules/Chat/Hooks/UseOnlineUsersQuery';
import useUnreadRoomsQuery from 'Modules/Chat/Hooks/UseUnreadRoomsQuery';
import RoomListElement from 'Modules/Chat/View/Components/Sidebar/RoomListElement';

export default function FavoriteRooms() {
  const { data } = useFavoriteRoomsList();
  const onlineUsers = useOnlineUsers();
  const unreadRooms = useUnreadRoomsQuery().data || [];
  const { toggleRoomIsHidden, toggleRoomIsFavorite } = useChatManipulationFunctions();

  return (
    <List>
      {data.map(room => (
        <RoomListElement
          key={room.id}
          room={room}
          onlineUsers={onlineUsers}
          unreadRooms={unreadRooms}
          lastMessage={room.lastMessage}
          toggleHidden={toggleRoomIsHidden}
          toggleFavorite={toggleRoomIsFavorite}
          variant="favorite"
        />
      ))}
    </List>
  );
}
