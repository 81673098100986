import { Modal, ModalBody } from 'reactstrap';

interface ModalLoaderProps {
  children?: React.ReactNode;
}

const ModalLoader = (props: ModalLoaderProps) => {
  const { children } = props;
  return (
    <Modal isOpen={true} centered={true} className="modal-dialog-centered">
      <ModalBody className="text-center">
        <i className="mdi mdi-spin mdi-loading mdi-48px" />
        <br />
        <span>{children ?? <>Trwa ładowanie...</>}</span>
      </ModalBody>
    </Modal>
  );
};

export default ModalLoader;
