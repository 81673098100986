import TabConfiguration from '../ConfigurationComponents/TabConfiguration';
import { Portal } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

interface TabConditionalModalProps {
  children: ReactNode;
  wrapperProps: any;
  value: { tabView: boolean };
  onChange: (config: object) => void;
}

const TabConfigurationModal: FC<TabConditionalModalProps> = ({ children, wrapperProps, value, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <span
        {...wrapperProps}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
      >
        {children}
      </span>
      <Portal>
        <Modal isOpen={open} centered={true} size={'lg'} backdrop={true} toggle={() => setOpen(!open)}>
          <div className="modal-header">
            <h5 className="modal-title position-relative" id="staticBackdropLabel">
              Konfiguracja sekcji
            </h5>
            <button type="button" className="btn btn-sm btn-success" onClick={() => setOpen(false)} aria-label="Close">
              Zapisz i zamknij
            </button>
          </div>
          <ModalBody className="py-3 px-5">
            <TabConfiguration value={value} onChange={onChange} />
          </ModalBody>
        </Modal>
      </Portal>
    </>
  );
};

export default TabConfigurationModal;
