import { Paper } from '@mui/material';
import TextInput from 'components/Form/MUI/TextInput';
import { Col, Container } from 'components/Theme/Grid';
import { Fragment, memo, useEffect, useState } from 'react';

const Place = memo(({ record, readonly, onChange, index }) => {
  const [form, setForm] = useState(record);

  const updateFormValue = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  useEffect(() => {
    onChange(index, form);
  }, [form]);

  return (
    <Fragment>
      <Col style={{ width: 225, height: '100%' }}>
        <Paper style={{ padding: 12 }}>
          <Container>
            <Col xs={12}>
              <TextInput label={'Nazwa'} value={form.name} name={'name'} onChange={updateFormValue} disabled={readonly} />
            </Col>
            <Col xs={12}>
              <TextInput
                textarea={true}
                label={'Opis'}
                value={form.description}
                name={'description'}
                onChange={updateFormValue}
                disabled={readonly}
              />
            </Col>
          </Container>
        </Paper>
      </Col>
    </Fragment>
  );
});
Place.displayName = 'Place';

export default Place;
