import Loader from '@Components/Theme/Common/Loader';
import Board from '@lourenci/react-kanban';
import CRUDModule from 'Modules/Core/CRUDModule';
import { Status, usePropertyValues } from 'Modules/Core/Hooks/usePropertyValues';
import { CTMModule, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { useModuleContext } from 'context/ModulesContext';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

interface ModuleBoardProps {
  moduleName: string;
}

interface CardHeaderProps<T extends CRUDModule<CTMStrictRecord, CTMModule<CTMStrictRecord>>> {
  item: Status;
  module: T;
}

function CardHeader<T extends CRUDModule<CTMStrictRecord, CTMModule<CTMStrictRecord>>>({ item, module }: CardHeaderProps<T>) {
  const CardHeaderComponent = module.configuration.list?.boardHeaderOverride ?? null;

  return (
    <>
      {!CardHeaderComponent && (
        <CardTitle className="mb-4" style={{ backgroundColor: item.color }}>
          {item.label}
        </CardTitle>
      )}
      {CardHeaderComponent && <CardHeaderComponent item={item} module={module} />}
    </>
  );
}

interface CardBoxProps<T> {
  item: any;
  module: CRUDModule<CTMStrictRecord, CTMModule<CTMStrictRecord>>;
}
function CardBox<T extends CRUDModule<CTMStrictRecord, CTMModule<CTMStrictRecord>>>({ item, module }: CardBoxProps<T>) {
  const CardBoxComponent = module.configuration.list?.boardCardOverride ?? null;

  return (
    <Card className="task-box">
      {!CardBoxComponent && (
        <CardBody className="borad-width">
          <div>
            <h5 className="font-size-15">
              <Link to={module.showUrl(item.id)} className="text-dark">
                {item.relationFieldLabel}
              </Link>
            </h5>
          </div>
          <div className="text-end">
            <p className="mb-0 text-muted">{dayjs(item?.createdAt).locale('pl').format('LLL')}</p>
          </div>
        </CardBody>
      )}
      {CardBoxComponent && <CardBoxComponent item={item} module={module} />}
    </Card>
  );
}

function ModuleBoard<
  T extends CRUDModule<CTMStrictRecord, CTMModule<CTMStrictRecord>> = CRUDModule<CTMStrictRecord, CTMModule<CTMStrictRecord>>,
>({ moduleName }: ModuleBoardProps) {
  const { data: statuses, isLoading } = usePropertyValues(moduleName, 'status');
  const [board, setBoard] = useState<{ columns: any[] }>({ columns: [] });
  const module = useModuleContext<T['configuration']>(moduleName) as unknown as T;
  const dispatch = useDispatch<any>();

  if (!module) {
    throw 'Module not found ' + moduleName;
  }

  useEffect(() => {
    const columns: any[] = [];
    Promise.all(
      (statuses ?? []).map(async (item, index) => {
        const cards = (await module.api.getAll({ params: { status: item.value, limit: 100 } }))['hydra:member'];
        columns.push({ ...item, id: index, cards: cards });
      }),
    ).then(() => {
      columns.sort((x, y) => x.id - y.id);
      setBoard({ columns: columns });
    });
  }, [statuses, module?.api]);

  const onDrag: (
    board: any,
    card: any,
    source: { fromPosition: number; fromColumnId: number },
    destination: { toPosition: number; toColumnId: number },
  ) => void = (board, card, source, destination) => {
    module.api.put({ status: board.columns[destination.toColumnId].value }, { id: card.id }).then(() => {
      dispatch(addSingleToast({ title: `Zapisano zmiany`, config: { appearance: 'success' } }));
    });
  };

  if (isLoading || isEmpty(board.columns)) {
    return (
      <div className="container-fluid">
        <Loader />
      </div>
    );
  }
  return (
    <CardBody>
      <Row>
        <Col>
          {!isEmpty(board.columns) && (
            <Board
              disableColumnDrag={true}
              initialBoard={board}
              renderColumnHeader={item => <CardHeader<T> item={item} module={module} />}
              renderCard={data => <CardBox<T> item={data ?? {}} module={module} />}
              onCardDragEnd={onDrag}
            />
          )}
        </Col>
      </Row>
    </CardBody>
  );
}

export default ModuleBoard;
