import BaseScalarInput, { BaseScalarInputType } from 'components/CustomFields/FieldTypes/BaseScalarInput';
import { useState } from 'react';
import { InputGroupText } from 'reactstrap';

const PasswordField: BaseScalarInputType = props => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <BaseScalarInput
      {...props}
      inputProps={{ ...props.inputProps, autoComplete: 'new-password' }}
      type={showPassword ? 'text' : 'password'}
      afterInputContent={
        !props.readonly && (
          <InputGroupText>
            <i
              className={`mdi mdi-eye${showPassword ? '-off' : ''}`}
              style={{ cursor: 'pointer' }}
              onClick={() => setShowPassword(!showPassword)}
            />
          </InputGroupText>
        )
      }
    />
  );
};

export default PasswordField;
