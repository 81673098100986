import { Add, Close } from '@mui/icons-material';
import Button from '@mui/material/Button';
import NumberInput from 'components/Form/MUI/NumberInput';
import { Col, Container } from 'components/Theme/Grid';
import { post } from 'helpers/Axios';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { useAppSelector } from 'store';
import { addSingleToast } from 'store/Toast/actions';

interface ProductionOrderRowAddModalProps {
  row: { id: string; product: { id: string; name: string }; ['@id']: string };
  active: boolean;
  onAdd: (quantity: number) => void;
}

const ProductionOrderRowAddModal: FC<ProductionOrderRowAddModalProps> = ({ row, active, onAdd }) => {
  const dispatch = useDispatch<any>();
  const [opened, setOpened] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const currentUser = useAppSelector(state => state.Login.user.details);

  const onSave = (): void => {
    setLoading(true);
    post('/manufacture/production-order-row-logs', {
      quantity: value,
      employee: currentUser.firstName + ' ' + currentUser.lastName,
      orderRow: row['@id'],
    })
      .then((): void => {
        dispatch(addSingleToast({ title: 'Wyprodukowana wartość została zaaktualizowana.', config: { appearance: 'success' } }));
        onAdd(value);
        setOpened(false);
      })
      .catch((error: string): void => dispatch(addSingleToast({ title: '' + error, config: { appearance: 'error' } })))
      .finally((): void => setLoading(false));
  };

  return (
    <>
      <Button variant={'contained'} color={'success'} style={{ width: '100%' }} disabled={!active} onClick={(): void => setOpened(true)}>
        <Add /> Dodaj
      </Button>
      <Modal
        isOpen={opened}
        centered={true}
        size={'xl'}
        backdrop={true}
        toggle={() => setOpened(!opened)}
        style={{ maxWidth: 520, maxHeight: '90vw', background: 'transparent' }}
        className={'task-modal'}
      >
        <ModalBody className={'amount-change-modal'}>
          <Container style={{ position: 'relative', paddingTop: 30 }} spacing={0}>
            <Button style={{ position: 'absolute', top: 10, right: 4 }} onClick={() => setOpened(false)}>
              <Close />
            </Button>
            <Col xs={12}>
              <h3 style={{ textAlign: 'center', padding: 12 }}>Wprowadź wyprodukowaną ilość {row.product.name}</h3>
            </Col>
            <Col xs={12}>
              <Container style={{ padding: 28, textAlign: 'center' }}>
                <Col xs={12}>
                  <NumberInput value={value} className={'change-input'} onChange={value => setValue(value ?? 0)} />
                </Col>
                <Col xs={12}>
                  <Container>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 1)}>
                        +1
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 5)}>
                        +5
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 10)}>
                        +10
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 50)}>
                        +50
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 100)}>
                        +100
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 1000)}>
                        +1000
                      </Button>
                    </Col>
                  </Container>
                </Col>
                <Col xs={12}>
                  <Container>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 1)}>
                        -1
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 5)}>
                        -5
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 10)}>
                        -10
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 50)}>
                        -50
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 100)}>
                        -100
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 1000)}>
                        -1000
                      </Button>
                    </Col>
                  </Container>
                </Col>
              </Container>
            </Col>
            <Col xs={12} style={{ textAlign: 'center', padding: 20 }}>
              <Button color={'success'} variant={'contained'} fullWidth={true} onClick={onSave} disabled={loading}>
                {loading ? <i className="mdi mdi-spin mdi-loading" /> : <Add />} Dodaj
              </Button>
            </Col>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ProductionOrderRowAddModal;
