import { Button, Menu, MenuItem } from '@mui/material';
import { ProductCollectionModule } from 'Modules/CTMModules';
import { post } from 'helpers/Axios';
import { FC, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { addSingleToast } from 'store/Toast/actions';

type BaseLinkerProps = {
  id: string | null | number;
  externalIds: [];
};

const BaseLinker: FC<BaseLinkerProps> = ({ id, externalIds }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { integrations } = useAppSelector(
    state => ({
      integrations: state.AppConfiguration.configuration.baseLinker.integrations,
    }),
    shallowEqual,
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const send = (name): void => {
    post(ProductCollectionModule.configuration.api.collection.baseLinker, {
      productCollectionId: id,
      name: name,
    }).then((): void => {
      dispatch(addSingleToast({ title: `Wysłano do BaseLinkera!`, config: { appearance: 'success' } }));
    });
  };

  if (integrations.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        style={{ textTransform: 'none', fontWeight: 400 }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
        disabled={false}
      >
        <>
          <i className="mdi mdi-cart" />
          BaseLinker
        </>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {integrations.map(item => (
          <MenuItem
            style={typeof externalIds['BaseLinker_' + item] !== 'undefined' ? { color: '#34c38f' } : {}}
            onClick={() => send(item)}
            key={item}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default BaseLinker;
