import { Task } from '../../Types/Task';
import RelationTaskTable from './RelationTaskTable';
import { FC } from 'react';

interface TaskTabRelationsProps {
  requires: Task[];
  requiredBy: Task[];
}

const TaskTabRelations: FC<TaskTabRelationsProps> = props => {
  return (
    <div className={'relations'}>
      {props.requiredBy.length > 0 && (
        <>
          <h5>Zadania nadrzędne</h5>
          <RelationTaskTable tasks={props.requiredBy} />
        </>
      )}
      {props.requires.length > 0 && (
        <>
          <h5 style={{ marginTop: 24 }}>Zadania podrzędne</h5>
          <RelationTaskTable tasks={props.requires} />
        </>
      )}
    </div>
  );
};
export default TaskTabRelations;
