import ProcessErrandRowTable from './ProcessErrandRowTable';
import { axiosApi } from 'helpers/Axios';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';

const ProcessErrandRow: FC<{ errandRowId: string }> = ({ errandRowId }) => {
  const { data: ingredients, isLoading: isLoading } = useQuery<any>(
    errandRowId,
    () => axiosApi.get(`/manufacture/errand-row-product-ingredients?errandRow.id=${errandRowId}`).then(res => res.data['hydra:member']),
    {
      onError: () => console.log('problem'),
    },
  );

  if (isLoading) {
    return <>Trwa ładowanie danych.</>;
  }

  return (
    <>
      <Row>
        <Col xs={12} lg={12}>
          <h5>Komponenty</h5>
          <ProcessErrandRowTable ingredients={ingredients.filter(item => item.product.type.external)} external={true} />
        </Col>
        <Col xs={12} lg={12} className={'mt-4'}>
          <h5>Półfabrykaty</h5>
          <ProcessErrandRowTable ingredients={ingredients.filter(item => !item.product.type.external)} external={false} />
        </Col>
      </Row>
    </>
  );
};

export default ProcessErrandRow;
