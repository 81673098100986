import { useModuleContext } from 'context/ModulesContext';
import { get } from 'helpers/Axios';
import { UseQueryResult, useQuery } from 'react-query';

export const queryKey = 'Core:PropertyValues:<entity>:<property>';

export interface Status {
  value: number | string;
  label: string;
  color: string;
  icon: string | null;
}

export const usePropertyValues = (moduleName: string, property: string): UseQueryResult<Status[]> => {
  const module = useModuleContext(moduleName);

  return useQuery(
    queryKey.replace('<entity>', module?.configuration.dataClass ?? 'undefiend').replace('<property>', property),
    () => get(`/core/property-values?entity=${module?.configuration.dataClass}&type=${property}`).then(res => res['hydra:member']),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: false,
    },
  );
};

export default { queryKey, usePropertyValues };
