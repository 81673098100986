import { Cancel } from '@mui/icons-material';
import { Button, TableCell, TableRow } from '@mui/material';
import BaseInput from 'components/Form/BaseInput';
import { FC } from 'react';

const FileTypeRow: FC<{ fileType: any; onUpdated: any; onRemove: any; readonly: boolean }> = ({
  fileType,
  onUpdated,
  onRemove,
  readonly,
}) => {
  const updateFormValue = (value, field) => {
    onUpdated({ ...fileType, [field]: value });
  };

  const rows = parseInt((fileType.description ?? '').length / 20 + '');

  return (
    <TableRow key={fileType['@id']}>
      <TableCell size="small" variant="body">
        <Button variant="contained" color="error" size="small" style={{ color: '#fff' }} onClick={onRemove}>
          <Cancel />
        </Button>
      </TableCell>
      <TableCell size="small" variant="body">
        {!readonly && (
          <BaseInput
            size={{ md: 12 }}
            disableGroupMargin={true}
            className={'_'}
            value={fileType.name}
            name="name"
            onChange={updateFormValue}
          />
        )}
        {readonly && <>{fileType.name}</>}
      </TableCell>
      <TableCell size="small" variant="body">
        {!readonly && (
          <BaseInput
            disableGroupMargin={true}
            className={'_'}
            size={{ md: 12 }}
            type="textarea"
            value={fileType.description}
            name="description"
            onChange={updateFormValue}
            inputProps={{ rows: rows > 0 ? rows : 1 }}
          />
        )}
        {readonly && <>{fileType.description}</>}
      </TableCell>
    </TableRow>
  );
};

export default FileTypeRow;
