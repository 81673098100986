import { Field } from '../Field';
import { EditFieldModal } from '../ModalComponents/FieldConfigurationModal';
import { FieldTypesIndexed } from '../const/FieldTypes';
import { HighlightOff, OpenWith, SettingsApplications } from '@mui/icons-material';
import { Button } from '@mui/material';
import { MemoStrongCompare } from 'Modules/Common/Utils/MemoComparision';
import TextInput from 'components/Form/MUI/TextInput';
import { memo } from 'react';
import { useDispatch } from 'react-redux';

interface FieldConfigProps {
  field: Field;
  fields: Field[];
  w: number;
  h: number;
  moduleId: any;
  onFieldSaved: (any) => any;
  onRemove: (id: string) => void;
}

function FieldConfig(props: FieldConfigProps) {
  const { field, fields, w, h, onRemove, moduleId, onFieldSaved } = props;
  const dispatch = useDispatch();
  return (
    <div className="field-config">
      <div className="move-field">
        <Button color="info" size="small" variant="contained" style={{ color: '#fff', padding: 0, minWidth: 0 }}>
          <OpenWith />
        </Button>
      </div>
      <div className="input-wrapper">
        {w >= 2 && (
          <TextInput
            disabled={true}
            label={FieldTypesIndexed[field.type ?? ''] ?? field.type}
            value={field.name}
            className="field-name-grid-input"
            placeholder="Nazwa pola"
          />
        )}
        {h >= 2 && w >= 2 && (
          <>
            <div className="badge-container">{field.system && <span className="badge bg-warning">Systemowe</span>}</div>
          </>
        )}
      </div>
      <div className="buttons-container">
        {/*<Button*/}
        {/*  color="primary"*/}
        {/*  size="small"*/}
        {/*  variant="contained"*/}
        {/*  style={{ color: '#fff', padding: 0, minWidth: 0 }}*/}
        {/*  onClick={() =>*/}
        {/*    dispatch(*/}
        {/*      addSingleToast({*/}
        {/*        title: `Funkcja nadpisywania ukrywania pól na szablonie dostępna wkrótce #staytuned`,*/}
        {/*        config: { appearance: 'info' },*/}
        {/*      })*/}
        {/*    )*/}
        {/*  }*/}
        {/*>*/}
        {/*  <VisibilityOff />*/}
        {/*</Button>*/}
        <EditFieldModal fields={fields} field={field} moduleId={moduleId} afterSave={onFieldSaved}>
          <Button color="primary" size="small" variant="contained" style={{ color: '#fff', padding: 0, minWidth: 0 }}>
            <SettingsApplications />
          </Button>
        </EditFieldModal>
        <Button
          color="error"
          size="small"
          variant="contained"
          style={{ color: '#fff', padding: 0, minWidth: 0 }}
          onClick={() => onRemove(field.id ?? '')}
        >
          <HighlightOff />
        </Button>
      </div>
    </div>
  );
}

export default memo(FieldConfig, MemoStrongCompare);
