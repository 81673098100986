import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Store, applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import rootReducer, { RootState } from 'store/reducers';
import rootSaga from 'store/sagas';

const sagaMiddleware: SagaMiddleware = createSagaMiddleware();
const composeEnhancers: typeof compose = (window?.['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

const store: Store<RootState> = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
