import dayjs from 'dayjs';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

const ICON_MAPPING = {
  CreatedEvent: 'bx-folder-plus',
  IncomingMailEvent: 'bx-envelope',
  OutgoingMailEvent: 'bx-mail-send',
  MeetingEvent: 'bx-calendar check',
};

const TITLE_MAPPING = {
  CreatedEvent: 'Utworzenie rekordu',
  IncomingMailEvent: 'Wiadomość od klienta: ',
  OutgoingMailEvent: 'Wiadomość do klienta: ',
  MeetingEvent: 'Zaplanowany kontakt',
};

const Timeline = ({ value }) => {
  return (
    value && (
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <div>
                <ul className="verti-timeline list-unstyled">
                  {value.reverse().map((item, key) => (
                    <li key={key} className="event-list">
                      <div className="event-timeline-dot">
                        <i className={'bx bx-right-arrow-circle'} />
                      </div>
                      <div className="d-flex">
                        <div className="me-3">
                          <i className={'bx ' + ICON_MAPPING[item['@type']] + ' h2 text-primary'} />
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5>
                              {TITLE_MAPPING[item['@type']]}
                              {item?.subject}
                            </h5>
                            <p className="text-muted">{item.text}</p>
                            {item.files.length > 0 && (
                              <p className="text-muted">
                                Załączniki:
                                <ul>
                                  {item.files.map(item => (
                                    <li key={item}>
                                      <a
                                        onClick={() =>
                                          Object.assign(document.createElement('a'), {
                                            target: '_blank',
                                            rel: 'noopener noreferrer',
                                            href: item.contentUrl,
                                          }).click()
                                        }
                                      >
                                        {item.originalName}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </p>
                            )}
                            <p className="text-primary">{dayjs(item.createdAt).locale('pl').format('LLL')}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="12">
          <Button className={'btn btn-info'}>Zaplanuj kontakt</Button>
          <Button className={'btn btn-success ml-1'}>Wyślij wiadomość</Button>
        </Col>
      </Row>
    )
  );
};

export default Timeline;
