export function downloadBlobFile(data: Blob | MediaSource, filename: string): void {
  const url = URL.createObjectURL(data);
  const a = document.createElement('a');

  a.download = filename;
  a.href = url;
  a.target = '_self';

  a.click();

  setTimeout(() => {
    a.remove();
    URL.revokeObjectURL(url);
  }, 100);
}
