import NumberField from 'components/CustomFields/FieldTypes/NumberField';

const CurrencyRateValue = props => {
  return (
    <>
      <NumberField {...props} readonly={true} />
    </>
  );
};
CurrencyRateValue.provideAllValues = true;
export default CurrencyRateValue;
