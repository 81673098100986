import CTMModule, { CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { SelectColumnFilter } from 'components/DataGrid/Filter';
import { getAllISOCodes } from 'iso-country-currency';

export type BankAccount = CTMStrictRecord & {
  name?: string;
  number?: string;
  bankName?: string;
  swift?: string;
  currency?: string;
  billingType?: string;
};

const BILLING_TYPES: { value: string; label: string }[] = [
  { value: 'STANDARD', label: 'Standard' },
  { value: 'FACTORING', label: 'Faktoring' },
];

const BILLING_TYPES_INDEXED: { [p: string]: string } = BILLING_TYPES.reduce(
  (prev, curr) => ({
    ...prev,
    [curr.value]: curr.label,
  }),
  {},
);

const columns: CTMListColumn<BankAccount>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: 'name',
  },
  {
    id: 'number',
    filterable: true,
    sortable: true,
    Header: 'Numer',
    accessor: 'number',
  },
  {
    id: 'bankName',
    filterable: true,
    sortable: true,
    Header: 'Nazwa banku',
    accessor: 'bankName',
  },
  {
    id: 'swift',
    filterable: true,
    sortable: true,
    Header: 'SWIFT',
    accessor: 'swift',
  },
  {
    id: 'currency',
    filterable: true,
    sortable: true,
    Header: 'Waluta',
    accessor: 'currency',
    Filter: SelectColumnFilter,
    filterOptions: [
      { value: null, label: 'Wszystkie' },
      ...getAllISOCodes().map(item => ({
        value: item.currency,
        label: item.currency,
      })),
    ],
  },
  {
    id: 'billingType',
    filterable: true,
    sortable: true,
    Header: 'Rodzaj rozliczenia',
    accessor: row => BILLING_TYPES_INDEXED[row.billingType ?? ''],
    Filter: SelectColumnFilter,
    filterOptions: [{ value: null, label: 'Wszystkie' }, ...(BILLING_TYPES ?? [])],
  },
];

const module: CTMModule<BankAccount> = {
  id: '902f29eb-495a-4ea8-86e4-d83b57cecce3',
  dataClass: 'CTM\\Core\\Entity\\Company\\BankAccount',
  urlPrefix: 'core-company-bank-accounts',
  name: 'Konta bankowe',
  role: 'CONFIGURATION',
  api: {
    item: {
      get: ({ id }) => `/core/company/bank-accounts/${id}`,
      put: ({ id }) => `/core/company/bank-accounts/${id}`,
      delete: ({ id }) => `/core/company/bank-accounts/${id}`,
    },
    collection: {
      get: `/core/company/bank-accounts`,
      post: `/core/company/bank-accounts`,
    },
  },
  recordLabel: record => record.name,
  form: {},
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
